import { Step, StepLabel, Stepper, Typography } from '@mui/material'
import React, { Fragment } from 'react'


// css
import './checkoutStep.css'


// icons
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';


function CheckoutStep({ activeStep }) {

    const steps = [
        {
            lable: <Typography> Shipping Details  </Typography>,
            icon: <LocalShippingIcon />
        },
        {
            lable: <Typography> Confirm Order  </Typography>,
            icon: <LibraryAddCheckIcon />
        },
        {
            lable: <Typography> Payment  </Typography>,
            icon: <AccountBalanceIcon />
        }
    ]
    const stepStyles = {
        boxSizing: 'border-box',
    }

    return (
        <Fragment>
            <Stepper alternativeLabel activeStep={activeStep} style={stepStyles} >
                {steps.map((item, index) => (
                    <Step key={index} active={activeStep === index ? true : false}
                        completed={activeStep >= index ? true : false}
                    >

                        <StepLabel style={{ color: activeStep >= index ? 'rgb(170, 80, 80)' : 'rgba(0, 0, 0, 0.674)' }} icon={item.icon}> {item.lable}   </StepLabel>
                    </Step>
                ))}

            </Stepper>



        </Fragment>
    )
}

export default CheckoutStep
