import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import store from "./redux/store";
import { Provider } from 'react-redux'
import { positions, transitions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'

const option = {
  timeout: 5000,
  positions: positions.TOP_CENTER,
  transitions: transitions.FADE

}


ReactDOM.render(
  < Provider store={store}>
    <AlertProvider template={AlertTemplate} {...option}  >
      <App />
    </AlertProvider>
  </Provider>,
  document.getElementById("root")
);



// <Provider store={store}>