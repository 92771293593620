import React, { Fragment } from 'react'
import { useHistory } from 'react-router-dom';
import ReactStars from "react-rating-stars-component";
import Loader from '../../../layout/loader'
import './realtedPproducts.css'
// import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';

import {textRapForNameSearch} from '../../../utils/textRapUtils'

function RealtedBrand({ loading, realtedBrand }) {
  const history = useHistory();

  const realtedPageHandler = (id) => {
    history.push(`/ProductDetail/${id}`)
    window.location.reload()
  }

  return (
    <Fragment>
      {loading ? <Loader /> : (<Fragment>
        {realtedBrand && realtedBrand.map((realtedBrand, index) => (
          <div className=".realted-brand-pro-container" key={index}>
            <div className="realted-brand-pro">
              <img src={realtedBrand.mainImage[0].imageurl} alt={realtedBrand.name} onClick={() => realtedPageHandler(realtedBrand._id)} />
              <div className="realted-brand-descripation">
                <div className="bestSeller-Star">
                  <p>  <ReactStars edit={false}
                    color='gray'
                    activeColor='#ffd700'
                    size={window.innerWidth < 600 ? 20 : 30}
                    value={realtedBrand.ratings}
                    isHalf={true} />             </p>
                </div>
                <h3 onClick={() => realtedPageHandler(realtedBrand._id)} >  {textRapForNameSearch(realtedBrand.name)}   </h3>
                <p> Price : <span> {realtedBrand.price} </span><span><CurrencyRupeeIcon /></span>  </p>
              </div>
              {/* <button className="bestSeller-descripation-cart-div" onClick={() => realtedPageHandler(realtedBrand._id)}>  <ShoppingCartIcon/> </button> */}
            </div>
          </div>))}
      </Fragment>)}
    </Fragment>
  )
}

export default RealtedBrand