import React from 'react'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import './successLogo.css'
import MetaTag from '../../layout/MetaTag';
import { Typography } from '@material-ui/core'
import {  useHistory } from 'react-router-dom'
import { myOrderAction } from '../../redux/actions/orderAction'

import {useDispatch} from 'react-redux'



// to={'/orders/Details/me'}
function SuccessLogo() {

  const history = useHistory();
  const dispatch = useDispatch();

  const viewOrderHandler = () => {
    history.push('/orders/Details/me')
    dispatch(myOrderAction())
  }


  return (
    <div className='orderSuccess'>
      <MetaTag title={'Your Order has been Placed Successfully'} />
      <CheckCircleIcon />
      <Typography> Your Order has been Placed Successfully</Typography>
      <p className='view-order-button' onClick={viewOrderHandler}>  View Order's </p>


    </div>
  )
}

export default SuccessLogo