import React, { Fragment } from 'react'

import Navbar from '../../HomeScreen/Navbar';
import MetaTag from '../../../layout/MetaTag';
import Footer from '../../HomeScreen/bottomsection/Footer';
import { Helmet } from 'react-helmet'

import SideBarAccount from './SideBarAccount'


function ProductAndPricing() {
    return (
        <Fragment>
            <Helmet  >
                <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=2.0" />
                <meta name="description" content={`  How do I find the most suitable sizes and fit? ||  How often is the website updated with new products? ||     How do I take care of my IM products? ||     Does IMONLINE.SHOP offer price adjustments?  ||   product and pricing details || product update details page || pricing about product || pricing updation page || updation page`} />
                <meta name="keywords" content={`  How do I find the most suitable sizes and fit? ||  How often is the website updated with new products? ||     How do I take care of my IM products? ||     Does IMONLINE.SHOP offer price adjustments?  ||   product and pricing details || product update details page || pricing about product || pricing updation page || updation page`} />
                <meta name="robots" content="index, follow " />
                <meta property="og:title" content={`PRODUCTS & PRICING || more details about product and pricing  with IMONLINE.SHOP , |   pricing and product details page || imonline || online shopping || pricing and product || imonline pricing page || imonline.shop product and pricing page || im product and pricing details || im online shopping comp .  || imonlineshoppingcomp || shopping comp ||   || shopping with im ||`} />
                <meta property="og:description" content={`  How do I find the most suitable sizes and fit? ||  How often is the website updated with new products? ||     How do I take care of my IM products? ||     Does IMONLINE.SHOP offer price adjustments?  ||   product and pricing details || product update details page || pricing about product || pricing updation page || updation page|`} />
                <meta property="og:type" content="website" />
                {/* <meta property="og:url" content={items.imageurl}   key={items.public_id}/>
            <meta property="og:image" content={items.imageurl}  key={items.public_id}/> */}
                <meta property="og:locale" content="en-GB_IN" />
                <meta property="og:site_name" content="IMONLINE.SHOP" />
                {/* <meta property="og:price:currency" content="INR" />
            <meta property="og:price:amount" content={product.price} /> */}
            </Helmet>

            <MetaTag title={'IM Online Shopping Product and Pricing Realted Details Page'} />
            <Navbar />
            <div className="container">
                <div className="row">
                    <div className="col-md-3">
                        <SideBarAccount />
                    </div>
                    <div className="col-md-9">
                        <div className="right-side-paragraph">
                            <div className="h2-heading">
                                <h2> PRODUCTS & PRICING </h2>
                            </div>
                            <div className="details-of-account-privileges">
                                <ul>
                                    How do I find the most suitable sizes and fit?
                                    <li>
                                        We recommend that you refer to our Size Guide to help you find the most suitable sizes for your products.
                                    </li>
                                    <li>
                                        If the item you’re looking for is out of stock online, we can check for its availability in our physical stores,
                                        provided it’s not an Online Exclusive. Please contact us at information@imonline.shop
                                        or on voice chat for further assistance.
                                    </li>
                                    <li>
                                        Our products are restocked regularly, depending on stock availability and demand.
                                        You can find them in our Back In Stock page.
                                    </li>
                                </ul>
                                <ul>
                                    How often is the website updated with new products?
                                    <li>
                                        New arrivals are introduced weekly. Browse our latest collections on the New Arrivals page.
                                    </li>
                                </ul>
                                <ul>
                                    How do I take care of my IM products?
                                    <li>
                                        Our products are made of genuine leather; synthetic fabrics and materials;
                                        and metallic accessories which can react to humidity, salt and atmospheric agents.
                                        This may cause irreversible wear and tear, so we recommend you keep your item(s) dry.
                                    </li>
                                    <li>
                                        Overexposure to light and heat; direct contact with rain; oil and grease (makeup); alcohol (perfume, solvents);
                                        and other abrasive products or excessive friction, should be avoided as colour fading may occur.
                                        Care should be taken when wearing lightly-coloured garments and/or when storing products with different
                                        colours together as the colours may transfer onto each other.
                                    </li>
                                    <li>
                                        To keep your shoes, bags and accessories clean, wipe them with a soft dry cloth.
                                        When they are not in use, store them in a protective bag in a clean, cool and dry place.
                                    </li>
                                    <li>
                                        All our materials are carefully crafted and sourced for their unique qualities.
                                        Any incidental marks, tonal changes and/or textural variances are part of
                                        the material’s natural characteristic and should
                                        not be considered as imperfections.

                                    </li>
                                    <li>
                                        Click here for a comprehensive material care guide with detailed instructions
                                        on how to care for your IM product(s).
                                    </li>
                                </ul>
                                <ul>
                                    Does IMONLINE.SHOP offer price adjustments?
                                </ul>
                                <li>
                                    Any form of price adjustment to orders is not applicable.
                                </li>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    )
}

export default ProductAndPricing