import {
  ADDRESS_DELETE_FAIL,
  ADDRESS_DELETE_REQUEST,
  ADDRESS_DELETE_RESET,
  ADDRESS_DELETE_SUCCESS,
  CLEAR_ERROR,
  GET_ALL_SAVE_INFO_FAIL,
  GET_ALL_SAVE_INFO_REQUEST,
  GET_ALL_SAVE_INFO_SUCCESS,
  GET_SAVE_INFO_FAIL,
  GET_SAVE_INFO_REQUEST,
  GET_SAVE_INFO_SUCCESS,
  GET_SINGEL_ADDRESS_BY_ADMIN,
  GET_SINGEL_ADDRESS_BY_FAIL,
  GET_SINGEL_ADDRESS_BY_SUCCESS,
  SAVE_ADDRESS_FAIL,
  SAVE_ADDRESS_REQUEST,
  SAVE_ADDRESS_RESET,
  SAVE_ADDRESS_SUCCESS,
} from "../constants/SaveAddressConstants";

export const saveAddressInfo = (state = {}, action) => {
  switch (action.type) {
    case SAVE_ADDRESS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SAVE_ADDRESS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        message: action.payload,
      };
    case SAVE_ADDRESS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SAVE_ADDRESS_RESET:
      return {
        ...state,
        loading: null,
        error: null,
        success: null,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

//  getting save data by user
export const gettingSaveInfoReducers = (state = { info: [] }, action) => {
  switch (action.type) {
    case GET_SAVE_INFO_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_SAVE_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        info: action.payload,
      };
    case GET_SAVE_INFO_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

// getting singel address with id by admin

export const singelSaveInfoByAdminReducers = (state = { info: [] }, action) => {
  switch (action.type) {
    case GET_SINGEL_ADDRESS_BY_ADMIN:
      return {
        ...state,
        loading: true,
      };
    case GET_SINGEL_ADDRESS_BY_SUCCESS:
      return {
        ...state,
        loading: false,
        info: action.payload,
      };
    case GET_SINGEL_ADDRESS_BY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

// getting all info by admin
export const allSaveInfoReducersByAdmin = (state = { info: [] }, action) => {
  switch (action.payload) {
    case GET_ALL_SAVE_INFO_REQUEST:
      return {
        ...state,
        loading: false,
      };

    case GET_ALL_SAVE_INFO_SUCCESS:
      return {
        ...state,
        loading: true,
        info: action.payload,
      };
    case GET_ALL_SAVE_INFO_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// deleting save address by admin
export const deleteSaveInfoByAdmin = (state = {}, action) => {
  switch (action.type) {
    case ADDRESS_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case ADDRESS_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        message: action.payload,
      };
    case ADDRESS_DELETE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ADDRESS_DELETE_RESET:
      return {
        ...state,
        success: null,
        loading: false,
        error: null,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
