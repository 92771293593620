import axios from 'axios';


import {
    CREATE_REVIEW_FAIL,
    CREATE_REVIEW_REQUEST,
    CREATE_REVIEW_SUCCESS,
    CLEAR_ERROR
} from '../constants/reviewConstants'


// import {URL} from '../../Url';


// product review by user
export const createReviewAction = (formData) => async (dispatch) => {
    try {

        dispatch({
            type: CREATE_REVIEW_REQUEST
        })
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        const {
            data
        } = await axios.post(`/api/products/reviews`, formData, config)
        dispatch({
            type: CREATE_REVIEW_SUCCESS,
            payload: data.success
        })
    } catch (error) {
        dispatch({
            type: CREATE_REVIEW_FAIL,
            payload: error.response.data.msg
        })
    }


}






// clear the error 


export const clearErrorAction = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERROR
    })
}