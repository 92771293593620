import React, { Fragment } from 'react'

import { useSelector } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'

function ProtectedRoute({ isAdmin, component: Component, ...rest }) {
    const { user, loading, isAuthenticated } = useSelector(state => state.user)
    return (
        <Fragment> 
            {loading === false && (
                <Route
                    {...rest}
                    render={(props) => {
                        if (!isAuthenticated) {
                            return <Redirect to='/Login' />
                        }

                        if (isAdmin === true && user.role !== 'admin') {
                            return <Redirect to='/Login' />
                        }

                        return <Component {...props} />

                    }}
                />


            )}

        </Fragment>
    );
}

export default ProtectedRoute
