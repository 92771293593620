
// FOR SAVING DELIVERY DATA FOR DELIVERY
export const CREATE_ORDER_REQUEST ='CREATE_ORDER_REQUEST';
export const CREATE_ORDER_SUCCESS ='CREATE_ORDER_SUCCESS';
export const CREATE_ORDER_FAIL ='CREATE_ORDER_FAIL';

// FOR GETTING ALL BUY ITEMS BY USER
export const ORDER_DETAIL_REQUEST ='ORDER_DETAIL_REQUEST';
export const ORDER_DETAIL_SUCCESS ='ORDER_DETAIL_SUCCESS';
export const ORDER_DETAIL_FAIL ='ORDER_DETAIL_FAIL';


// FOR GETTING All ORDERED ITEMS BY ADMIN
export const ALL_ORDER_REQUEST ='ALL_ORDER_REQUEST';
export const ALL_ORDER_SUCCESS ='ALL_ORDER_SUCCESS';
export const ALL_ORDER_FAIL ='ALL_ORDER_FAIL';


// FOR GETTING UPDATE ORDER BY ADMIN
export const UPDATE_ORDER_REQUEST ='UPDATE_ORDER_REQUEST';
export const UPDATE_ORDER_SUCCESS ='UPDATE_ORDER_SUCCESS';
export const UPDATE_ORDER_FAIL ='UPDATE_ORDER_FAIL';
export const UPDATE_ORDER_REST ='UPDATE_ORDER_REST';

// FOR GETTING SINGEL BUY ITEM DETAILS
export const MY_ORDER_REQUEST ='MY_ORDER_REQUEST';
export const MY_ORDER_SUCCESS ='MY_ORDER_SUCCESS';
export const MY_ORDER_FAIL ='MY_ORDER_FAIL';


// FOR CASH ON DELIVERY DATA SAVE
export const CASH_ON_DELIVERY_DATA_SAVE_REQUEST ='CASH_ON_DELIVERY_DATA_SAVE_REQUEST';
export const CASH_ON_DELIVERY_DATA_SAVE_SUCCESS ='CASH_ON_DELIVERY_DATA_SAVE_SUCCESS';
export const CASH_ON_DELIVERY_DATA_SAVE_FAIL ='CASH_ON_DELIVERY_DATA_SAVE_FAIL';


// FOR CASH ON DELIVERY CONFIRMATION OTP REQUEST
export const CASH_ON_DELIVERY_OTP_REQUEST ='CASH_ON_DELIVERY_OTP_REQUEST';
export const CASH_ON_DELIVERY_OTP_SUCCESS ='CASH_ON_DELIVERY_OTP_SUCCESS';
export const CASH_ON_DELIVERY_OTP_FAIL ='CASH_ON_DELIVERY_OTP_FAIL';


// FOR CASH ON DELIVERY CONFIRMATION 
export const CASH_ON_DELIVERY_OTP_VEREFIY_REQUEST ='CASH_ON_DELIVERY_OTP_VEREFIY_REQUEST';
export const CASH_ON_DELIVERY_OTP_VEREFIY_SUCCESS ='CASH_ON_DELIVERY_OTP_VEREFIY_SUCCESS';
export const CASH_ON_DELIVERY_OTP_VEREFIY_FAIL ='CASH_ON_DELIVERY_OTP_VEREFIY_FAIL';



// FOR GETTING INVOICE OF ORDER PRODUCT BY USER
export const INVOICE_CREATE_REQUEST ='INVOICE_CREATE_REQUEST';
export const INVOICE_CREATE_SUCCESS='INVOICE_CREATE_SUCCESS';
export const INVOICE_CREATE_FAIL ='INVOICE_CREATE_FAIL';


// TARCKING ORDER REQUEST BY USER
export const TRACKING_ORDER_REQUEST ='TRACKING_ORDER_REQUEST';
export const TRACKING_ORDER_SUCCESS='TRACKING_ORDER_SUCCESS';
export const TRACKING_ORDER_FAIL ='TRACKING_ORDER_FAIL';


// RETURN ORDER BY USER
export const RETURN_ORDER_REQUEST ='RETURN_ORDER_REQUEST';
export const RETURN_ORDER_SUCCESS='RETURN_ORDER_SUCCESS';
export const RETURN_ORDER_FAIL ='RETURN_ORDER_FAIL';

// GET SINGEL RETURN ORDER BY ADMIN 
export const GET_SINGAL_RETURN_ORDER_REQUEST ='GET_SINGAL_RETURN_ORDER_REQUEST';
export const GET_SINGAL_RETURN_ORDER_SUCCESS='GET_SINGAL_RETURN_ORDER_SUCCESS';
export const GET_SINGAL_RETURN_ORDER_FAIL ='GET_SINGAL_RETURN_ORDER_FAIL';

// ALL RETURNS ORDERS BY USER
export const ALL_RETURN_ORDER_REQUEST ='ALL_RETURN_ORDER_REQUEST';
export const ALL_RETURN_ORDER_SUCCESS='ALL_RETURN_ORDER_SUCCESS';
export const ALL_RETURN_ORDER_FAIL ='ALL_RETURN_ORDER_FAIL';
export const ALL_RETURN_ORDER_RESET ='ALL_RETURN_ORDER_RESET';

// ALL RETURNS ORDERS BY ADMIN
export const ALL_RETURN_ORDER_REQUEST_BY_ADMIN ='ALL_RETURN_ORDER_REQUEST_BY_ADMIN';
export const ALL_RETURN_ORDER_SUCCESS_BY_ADMIN='ALL_RETURN_ORDER_SUCCESS_BY_ADMIN';
export const ALL_RETURN_ORDER_FAIL_BY_ADMIN ='ALL_RETURN_ORDER_FAIL_BY_ADMIN';
export const ALL_RETURN_ORDER_RESET_BY_ADMIN ='ALL_RETURN_ORDER_RESET_BY_ADMIN';

// ORDER STATUS UPDATE 
export const RETURN_ORDER_STATUS_UPDATE_REQUEST ='ALL_RETURN_ORDER_STATUS_UPDATE_REQUEST';
export const RETURN_ORDER_STATUS_UPDATE_SUCCESS='ALL_RETURN_ORDER_STATUS_UPDATE_SUCCESS';
export const RETURN_ORDER_STATUS_UPDATE_FAIL ='ALL_RETURN_STATUS_UPDATE_ORDER_FAIL';
export const RETURN_ORDER_STATUS_UPDATE_RESET ='ALL_RETURN_STATUS_UPDATE_ORDER_RESET';



// ORDER CANCEL REQUEST BY USER
export const ORDER_CANCEL_REQUEST ='ORDER_CANCEL_REQUEST';
export const ORDER_CANCEL_SUCCESS ='ORDER_CANCEL_SUCCESS';
export const ORDER_CANCEL_REQUEST_FAIL ='ORDER_CANCEL_REQUEST_FAIL';
export const ORDER_CANCEL_REQUEST_RESET ='ORDER_CANCEL_REQUEST_RESET';


// GET SINGEL CANCEL ORDER BY ADMIN 
export const GET_SINGAL_CANCEL_ORDER_REQUEST ='GET_SINGAL_CANCEL_ORDER_REQUEST';
export const GET_SINGAL_CANCEL_ORDER_SUCCESS='GET_SINGAL_CANCEL_ORDER_SUCCESS';
export const GET_SINGAL_CANCEL_ORDER_FAIL ='GET_SINGAL_CANCEL_ORDER_FAIL';


// ALL ORDER REQUEST BY USER
export const ALL_ORDER_CANCEL_REQUEST ='ORDER_CANCEL_REQUEST';
export const ALL_ORDER_CANCEL_SUCCESS ='ORDER_CANCEL_SUCCESS';
export const ALL_ORDER_CANCEL_REQUEST_FAIL ='ORDER_CANCEL_REQUEST_FAIL';
export const ALL_ORDER_CANCEL_REQUEST_RESET ='ORDER_CANCEL_REQUEST_RESET';


// ALL ORDER REQUEST BY ADMIN
export const ALL_ORDER_CANCEL_REQUEST_BY_ADMIN ='ORDER_CANCEL_REQUESTT_BY_ADMIN';
export const ALL_ORDER_CANCEL_SUCCESST_BY_ADMIN ='ORDER_CANCEL_SUCCESST_BY_ADMIN';
export const ALL_ORDER_CANCEL_REQUEST_FAIT_BY_ADMIN ='ORDER_CANCEL_REQUEST_FAIL_BY_ADMIN';
export const ALL_ORDER_CANCEL_REQUEST_RESET_BY_ADMIN ='ORDER_CANCEL_REQUEST_RESET_BY_ADMIN';


// CANCEL ORDER STATUS UPDATE
export const ORDER_CANCEL_UPDATE_STATUS_REQUEST ='ORDER_CANCEL_UPDATE_STATUS_REQUEST';
export const ORDER_CANCEL_UPDATE_STATUS_SUCCESS ='ORDER_CANCEL_UPDATE_STATUS_SUCCESS';
export const ORDER_CANCEL_UPDATE_STATUS_FAIL ='ORDER_CANCEL_UPDATE_STATUS_FAIL';
export const ORDER_CANCEL_UPDATE_STATUS_RESET ='ORDER_CANCEL_UPDATE_STATUS_RESET';



// ORDERED STATUS UPDATE BY ADMIN
export const ORDER_UPDATE_STATUS_REQUEST ='ORDER_UPDATE_STATUS_REQUEST';
export const ORDER_UPDATE_STATUS_SUCCESS ='ORDER_UPDATE_STATUS_SUCCESS';
export const ORDER_UPDATE_STATUS_FAIL ='ORDER_UPDATE_STATUS_FAIL';
export const ORDER_UPDATE_STATUS_RESET ='ORDER_UPDATE_STATUS_RESET';



export const CLEAR_ERROR ='CLEAR_ERROR';

