import React, { Fragment } from 'react'
import {Link} from 'react-router-dom'

import EmpteyFrom from '../../images/undraw_Empty_re_opql.png';

function EmptyOrder() {
    return (
        <Fragment>
            <div className="if-not-order-div">
                <div className="image-of-not-div">
                    <img src={EmpteyFrom} alt="www.imonline.shop" />
                </div>
                <div className="paragraph-of-not-div">
                    <p>yet not cancel product !</p>
                   <Link to={'/Product'}> <button>back</button> </Link>
                </div>
            </div>
        </Fragment>
    )
}

export default EmptyOrder