import React, { Fragment } from 'react'
import { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import HomeIcon from '@mui/icons-material/Home';
import { userUpdatePasswordAction, clearErrorAction, userLoadingAction } from '../../redux/actions/userAction'
import swal from 'sweetalert';
import Loader from '../../layout/loader';
import './updatePassword.css'
import { UPDATE_USER_PASSWORD_RESET } from '../../redux/constants/userConstants'
import updatePasswordImage from '../../images/undraw_Vault_re_s4my.png'
import MetaTag from '../../layout/MetaTag'

function UpdatePassword() {
    const history = useHistory();
    const dispatch = useDispatch();

    const { error, loading } = useSelector(state => state.user)
    const { error: updateError, loading: updateLoading, isUpdated } = useSelector(state => state.profileUpdateByUser)


    const [oldPassword, setOldPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [newConfirmPassword, setNewConfirmPassword] = useState('')

    // on submit fuction 

    const submitHandler = async (e) => {
        e.preventDefault();
        if (newPassword !== newConfirmPassword) {
           return swal({
                title: "Validation Error",
                text: "both password and confirm password should be same...!",
                icon: "info",
            })
        }
        dispatch(userUpdatePasswordAction(oldPassword, newPassword, newConfirmPassword))
    }

    useEffect(() => {
        if (error) {
            swal({
                title: "Error",
                text: error,
                icon: "error",
            })
            dispatch(clearErrorAction)
        }

        if (updateError) {
            swal({
                title: "Error",
                text: updateError,
                icon: "error",
            })
            dispatch(clearErrorAction)
        }


        if (isUpdated) {
            swal({
                title: "Updated",
                text: "Your Passwords has updated Successfully !!",
                icon: "success",
            })
            dispatch(userLoadingAction())
            history.push('/userprofile')
            dispatch({ type: UPDATE_USER_PASSWORD_RESET })
        }


    }, [dispatch, error, history, isUpdated, updateError])

    return (
        <Fragment>
             <MetaTag title={'IM Online Shopping Update Password To Stay Proctected !'}   />
            {updateLoading ? <Loader /> : (<Fragment>
                {loading ? <Loader /> : <Fragment>
                    <div className='profile-update-div-header'  >
                        <Link to='/'>  <h4 className='home-heading-header'>  <HomeIcon />  </h4></Link>
                    </div>
                    <div className='update-profile-heading-second'  >
                        <img src={updatePasswordImage} alt='www.imonline.shop' />
                        <h6>Update Password</h6>
                    </div>

                    <form onSubmit={submitHandler}  >
                        <div className='profile-update-div-form'  >
                            <div className='user-password-update-div'>
                                <div className="password-div">
                                    <input type="password" placeholder='*type old password*'
                                        className='old-password-field' required
                                        value={oldPassword}
                                        onChange={(e) => setOldPassword(e.target.value)}
                                    />
                                </div>


                                <div className="password-div">
                                    <input type="password" placeholder='*type new password*'
                                        className='old-password-field'
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                        required />
                                </div>


                                <div className="confirm-password-div">
                                    <input type="password" placeholder='*type confirm password*'
                                        className='old-password-field'
                                        value={newConfirmPassword}
                                        onChange={(e) => setNewConfirmPassword(e.target.value)}
                                        required />
                                </div>
                            </div>
                        </div>
                        <div className='submit-btn-edit-form'>
                            <button > sumbit </button>
                        </div>
                    </form>

                </Fragment>}

            </Fragment>)}


        </Fragment >
    )
}

export default UpdatePassword
