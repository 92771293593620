import React, { Fragment, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { clearErrorAction, applyCoupanAction } from '../../../redux/actions/coupanAction'
import Loader from '../../../layout/loader'
import swal from 'sweetalert';
import CheckoutStep from '../CheckoutStep'
import MetaTag from '../../../layout/MetaTag';


import '../confirmOrder.css'


function ConfirmOrdersForBuyDirect() {
    const dispatch = useDispatch();
    const history = useHistory();


    const { shippingInfo } = useSelector((state) => state.shippingInfo)

    console.log(shippingInfo)

    // Getting data from localStorage to store it.
    const productBuy = JSON.parse(localStorage.getItem('productBuy')) 

    const { error, loading, success, coupanCode, coupanValue } = useSelector((state) => state.coupanApply)

    // apply coupan 
    const [applyCoupan, setApplyCoupan] = useState('');


    const subTotalAmount = {
        productQuantity: productBuy && productBuy.quantity,
        productPrice: productBuy && productBuy.price,
        selectBox: productBuy && productBuy.selectBox
    }

    // subtotal
    let subTotal = Number(subTotalAmount.productQuantity) * Number(subTotalAmount.productPrice)

    // shipping charges
    const shippingCharges =  productBuy.productShippingCharges !== undefined ?   Number(subTotalAmount.productQuantity) * productBuy.productShippingCharges :  Number(subTotalAmount.productQuantity) *   100;

    // item Price
    const itemPrice = Number(subTotalAmount.productPrice);

    // box charges
    const boxCharges = Number(subTotalAmount.productQuantity) * subTotalAmount.selectBox;


    // round off tax
    const tax = subTotal * 0.05;

    // round off total 
    const roundOffTotalTax = Math.round(tax)

    // const productSubTotal = subTotal + roundOffTotalTax;
    const totalPrice = subTotal + roundOffTotalTax + shippingCharges + boxCharges;


    const address = `${shippingInfo.address}
  ,${shippingInfo.landMark}
  ,${shippingInfo.city}
  ,${shippingInfo.state}
  ,${shippingInfo.district}
  ,${shippingInfo.pincode}
  ,${shippingInfo.phoneNo}`

    const proccedToPayments = () => {
        const data = {
            subTotal,
            itemPrice,
            shippingCharges,
            roundOffTotalTax,
            totalPrice,
            coupanCode,
            coupanValue,
            boxCharges
        }

        sessionStorage.setItem('orderInfo', JSON.stringify(data))
        history.push('/confirm/payments/directbuy')
    }

    const coupanApplySubmitHandler = async (e) => {
        e.preventDefault()
        if (!applyCoupan) {
            return swal({
                title: "Sorry",
                text: "Please Type Coupon..!",
                icon: "info",
            })

        }
        dispatch(applyCoupanAction(applyCoupan, totalPrice))
    }


    useEffect(() => {
        if (error) {
            swal({
                title: 'Error',
                text: error,
                icon: 'error'
            })
            dispatch(clearErrorAction())
        }
        if (success) {
            swal({
                title: 'Success',
                text: success,
                icon: 'success'
            })
        }




    }, [dispatch, error, success])


    return (
        <Fragment>
            {loading ? <Loader /> : (<Fragment>
                <MetaTag title={'IM Online Shopping Confirm Product Details Page'} />
                <CheckoutStep activeStep={1} />
                <div className="confirm-order-page">
                    <div>
                        <div className="confirm-shipping-area">
                            <Typography>Shipping Info       </Typography>
                            <div className="confirm-shipping-area-box">
                                <div>
                                    <p>Name:</p>
                                    <span>{shippingInfo.name}</span>
                                </div>
                                <div>
                                    <p>Email:</p>
                                    <span>{shippingInfo.email}</span>
                                </div>
                                <div>
                                    <p>Phone:</p>
                                    <span>{shippingInfo.phoneNo}</span>
                                </div>
                                <div>
                                    <p>Address:</p>
                                    <span>{address}</span>
                                </div>

                            </div>
                        </div>
                        <div className="confirm-cart-item">
                            <Typography>Your Cart Items :  </Typography>
                            <div className="confirm-cartItems-container">
                                <div>
                                    <img src={productBuy.image} alt="imonline.shop" />
                                    <Link to={`/ProductDetail/${productBuy.products}`}>
                                        {productBuy.name}
                                    </Link>

                                    <span>
                                        {productBuy.quantity} <CloseIcon /> <span></span>{productBuy.price} =
                                        <b> <CurrencyRupeeIcon />{productBuy.quantity * productBuy.price} </b>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*    */}

                    <div>
                        <div className="order-summery">
                            <Typography>Order Summery  </Typography>
                            <div>
                                <div>
                                    <p>Subtotal:</p>
                                    <span>  {subTotal} <CurrencyRupeeIcon /> </span>
                                </div>

                                <div>
                                    <p>Shipping Charges:</p>
                                    <span> {shippingCharges} <CurrencyRupeeIcon />  </span>
                                </div>
                                <div>
                                    <p>Box Charges:</p>
                                    <span> {boxCharges} <CurrencyRupeeIcon />  </span>
                                </div>

                                <div>
                                    <p>GST:</p>
                                    <span> {roundOffTotalTax} <CurrencyRupeeIcon /> </span>
                                </div>
                            </div>
                            <div className="order-summery-total">
                                <p>
                                    <b>Total :</b>
                                </p>
                                <span>{totalPrice} <CurrencyRupeeIcon /> </span>
                            </div>
                            {success ? (<Fragment>
                                <div className="coupan-div">
                                    <input type="text" className='apply-coupan-text-input' value={applyCoupan} onChange={(e) => setApplyCoupan(e.target.value)} required />
                                    <button className='apply-coupan-div' onClick={coupanApplySubmitHandler}>apply coupan</button>
                                    <div className="coupan-apply-details-">
                                        <div>
                                            <p  >
                                                <b>Discount : {coupanValue}</b>
                                            </p>
                                        </div>
                                        <div>
                                            <p >
                                                <b>Coupan Code :{coupanCode}</b>
                                            </p>
                                        </div>

                                    </div>
                                    <p  >please do not the refresh page !!</p>
                                </div>
                            </Fragment>) : (<Fragment>
                                <div className="coupan-div">
                                    <input type="text" className='apply-coupan-text-input' value={applyCoupan} onChange={(e) => setApplyCoupan(e.target.value)} required />
                                    <button className='apply-coupan-div' onClick={coupanApplySubmitHandler}>apply coupan</button>
                                </div>
                            </Fragment>)}

                            <button className='procced-to-payment' onClick={proccedToPayments} > Procced To Payment  </button>
                        </div>
                    </div>
                </div>
            </Fragment>)
            }
        </Fragment >

    )
}

export default ConfirmOrdersForBuyDirect