import {
    CREATE_ORDER_REQUEST,
    CREATE_ORDER_SUCCESS,
    CREATE_ORDER_FAIL,
    MY_ORDER_REQUEST,
    MY_ORDER_SUCCESS,
    MY_ORDER_FAIL,
    ORDER_DETAIL_REQUEST,
    ORDER_DETAIL_SUCCESS,
    ORDER_DETAIL_FAIL,
    GET_SINGAL_CANCEL_ORDER_REQUEST,
    GET_SINGAL_CANCEL_ORDER_SUCCESS,
    GET_SINGAL_CANCEL_ORDER_FAIL,
    ALL_ORDER_CANCEL_REQUEST,
    ALL_ORDER_CANCEL_SUCCESS,
    ALL_ORDER_CANCEL_REQUEST_FAIL,
    ALL_ORDER_CANCEL_REQUEST_BY_ADMIN,
    ALL_ORDER_CANCEL_SUCCESST_BY_ADMIN,
    ALL_ORDER_CANCEL_REQUEST_FAIT_BY_ADMIN,
    ORDER_CANCEL_UPDATE_STATUS_REQUEST,
    ORDER_CANCEL_UPDATE_STATUS_SUCCESS,
    ORDER_CANCEL_UPDATE_STATUS_FAIL,
    ALL_ORDER_REQUEST,
    ALL_ORDER_SUCCESS,
    ALL_ORDER_FAIL,
    UPDATE_ORDER_REQUEST,
    UPDATE_ORDER_SUCCESS,
    UPDATE_ORDER_FAIL,
    ORDER_CANCEL_REQUEST,
    ORDER_CANCEL_SUCCESS,
    ORDER_CANCEL_REQUEST_FAIL,
    INVOICE_CREATE_REQUEST,
    INVOICE_CREATE_SUCCESS,
    INVOICE_CREATE_FAIL,
    TRACKING_ORDER_REQUEST,
    TRACKING_ORDER_SUCCESS,
    TRACKING_ORDER_FAIL,
    RETURN_ORDER_REQUEST,
    RETURN_ORDER_SUCCESS,
    RETURN_ORDER_FAIL,
    GET_SINGAL_RETURN_ORDER_REQUEST,
    GET_SINGAL_RETURN_ORDER_SUCCESS,
    GET_SINGAL_RETURN_ORDER_FAIL,
    ALL_RETURN_ORDER_REQUEST,
    ALL_RETURN_ORDER_SUCCESS,
    ALL_RETURN_ORDER_FAIL,
    ALL_RETURN_ORDER_REQUEST_BY_ADMIN,
    ALL_RETURN_ORDER_SUCCESS_BY_ADMIN,
    ALL_RETURN_ORDER_FAIL_BY_ADMIN,
    RETURN_ORDER_STATUS_UPDATE_REQUEST,
    RETURN_ORDER_STATUS_UPDATE_SUCCESS,
    RETURN_ORDER_STATUS_UPDATE_FAIL,
    ORDER_UPDATE_STATUS_REQUEST,
    ORDER_UPDATE_STATUS_SUCCESS,
    ORDER_UPDATE_STATUS_FAIL,
    CLEAR_ERROR
} from '../constants/orderConstants'

import axios from 'axios'

// import {URL} from '../../Url';



// sending {order} delivery data to backend 
export const createOrderAction = (order) => async (dispatch) => {
    try {

        dispatch({
            type: CREATE_ORDER_REQUEST
        })
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        const {
            data
        } = await axios.post(`/api/newOrder`, order, config)
        dispatch({
            type: CREATE_ORDER_SUCCESS,
            payload: data.msg
        })
    } catch (error) {
        dispatch({
            type: CREATE_ORDER_FAIL,
            payload: error.response.data.msg
        })
    }


}



// create invoice by user
export const createInvoiceByUserAction = (inVoiceId) => async (dispatch) => {
    try {

        dispatch({
            type: INVOICE_CREATE_REQUEST
        })
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        const {
            data
        } = await axios.post(`/api/shipped/order-user/invoice/${inVoiceId}`, config)

        dispatch({
            type: INVOICE_CREATE_SUCCESS,
            payload: data.data
        })
    } catch (error) {
        dispatch({
            type: INVOICE_CREATE_FAIL,
            payload: error.response.data.msg
        })
    }

}



// FOR TRACKING ORDER
export const trackingOrderByUserAction = (id) => async (dispatch) => {
    try {

        dispatch({
            type: TRACKING_ORDER_REQUEST
        })
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        const {
            data
        } = await axios.get(`/api/shipped/order/tracking-by-shippiment/${id}`, config)
        dispatch({
            type: TRACKING_ORDER_SUCCESS,
            payload: data.msg
        })
    } catch (error) {
        dispatch({
            type: TRACKING_ORDER_FAIL,
            payload: error.response.data.msg
        })
    }


}


// FOR RETURN ORDER ACTION BY USER
export const returnOrderByUserAction = (formData) => async (dispatch) => {
    try {

        dispatch({
            type: RETURN_ORDER_REQUEST
        })
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };

        const {
            data
        } = await axios.post(`/api/sumbit-return-request`, formData, config)
        dispatch({
            type: RETURN_ORDER_SUCCESS,
            payload: data.msg
        })
    } catch (error) {
        dispatch({
            type: RETURN_ORDER_FAIL,
            payload: error.response.data.msg
        })
    }


}






// GET ALL RETURN ORDER DETAILS BY USER
export const allReturnOrderByUserAction = () => async (dispatch) => {
    try {

        dispatch({
            type: ALL_RETURN_ORDER_REQUEST
        })
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        const {
            data
        } = await axios.get(`/api/order-returns/user`, config)
        dispatch({
            type: ALL_RETURN_ORDER_SUCCESS,
            payload: data.getAllReturnShoes
        })
    } catch (error) {
        dispatch({
            type: ALL_RETURN_ORDER_FAIL,
            payload: error.response.data.msg
        })
    }


}




// GET SINGEL RETURN ORDER DETAILS BY ADMIN 
export const singelReturnOrderByAdminAction = (order_Id) => async (dispatch) => {
    try {

        dispatch({
            type: GET_SINGAL_RETURN_ORDER_REQUEST
        })
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        const {
            data
        } = await axios.get(`/api/order-returns/user/${order_Id}`, config)
        console.log(data)
        dispatch({
            type: GET_SINGAL_RETURN_ORDER_SUCCESS,
            payload: data.isReturnOrderMatch
        })
    } catch (error) {
        dispatch({
            type: GET_SINGAL_RETURN_ORDER_FAIL,
            payload: error.response.data.msg
        })
    }


}





// GET ALL RETURN ORDER DETAILS BY ADMIN 
export const allReturnOrderByAdminAction = () => async (dispatch) => {
    try {

        dispatch({
            type: ALL_RETURN_ORDER_REQUEST_BY_ADMIN
        })
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        const {
            data
        } = await axios.get(`/api/order-returns/admin`, config)

        dispatch({
            type: ALL_RETURN_ORDER_SUCCESS_BY_ADMIN,
            payload: data.getAllReturnShoes
        })
    } catch (error) {
        dispatch({
            type: ALL_RETURN_ORDER_FAIL_BY_ADMIN,
            payload: error.response.data.msg
        })
    }


}





// UPDATE RETURN ORDER STATUS BY ADMIN  
export const updateReturnOrderStatusByAdminAction = (id, updateReturnStatus) => async (dispatch) => {
    try {

        dispatch({
            type: RETURN_ORDER_STATUS_UPDATE_REQUEST
        })
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        const {
            data
        } = await axios.put(`/api/order-returns/update/Status/${id}`, {
            updateReturnStatus
        }, config)
        dispatch({
            type: RETURN_ORDER_STATUS_UPDATE_SUCCESS,
            payload: data.msg
        })
    } catch (error) {
        dispatch({
            type: RETURN_ORDER_STATUS_UPDATE_FAIL,
            payload: error.response.data.msg
        })
    }


}




// GET SINGEL CANCEL ORDER BY ADMIN

export const getSingelCancelOrderByAdmin = (id) => async (dispatch) => {
    try {

        dispatch({
            type: GET_SINGAL_CANCEL_ORDER_REQUEST
        })
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        const {
            data
        } = await axios.get(`/api/cancelorder/Request/${id}`, config)
        dispatch({
            type: GET_SINGAL_CANCEL_ORDER_SUCCESS,
            payload: data.cancelOrder
        })
    } catch (error) {
        dispatch({
            type: GET_SINGAL_CANCEL_ORDER_FAIL,
            payload: error.response.data.msg
        })
    }


}




// GET ALL CANCEL ORDER BY USER

export const allCancelOrderByUserAction = () => async (dispatch) => {
    try {

        dispatch({
            type: ALL_ORDER_CANCEL_REQUEST
        })
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };

        const {
            data
        } = await axios.get(`/api/all/cancelorder/user`, config)
        dispatch({
            type: ALL_ORDER_CANCEL_SUCCESS,
            payload: data.cancelOrders
        })
    } catch (error) {
        dispatch({
            type: ALL_ORDER_CANCEL_REQUEST_FAIL,
            payload: error.response.data.msg
        })
    }


}





// GET ALL CANCEL ORDER BY ADMIN

export const allCancelOrderByAdminAction = () => async (dispatch) => {
    try {

        dispatch({
            type: ALL_ORDER_CANCEL_REQUEST_BY_ADMIN
        })
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        const {
            data
        } = await axios.get(`/api/all/cancelorder/Request`, config)
        dispatch({
            type: ALL_ORDER_CANCEL_SUCCESST_BY_ADMIN,
            payload: data.cancelOrders
        })
    } catch (error) {
        dispatch({
            type: ALL_ORDER_CANCEL_REQUEST_FAIT_BY_ADMIN,
            payload: error.response.data.msg
        })
    }


}





// UPDATE CANCEL ORDER STATUS BY ADMIN

export const updateCancelOrderStatusByAdmin = (id, OrderStatus,phone) => async (dispatch) => {
    try {

        dispatch({
            type: ORDER_CANCEL_UPDATE_STATUS_REQUEST
        })
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        const {
            data
        } = await axios.put(`/api/cancelorder/update/${id}`, {
            OrderStatus,phone
        }, config)

        dispatch({
            type: ORDER_CANCEL_UPDATE_STATUS_SUCCESS,
            payload: data.msg
        })
    } catch (error) {
        dispatch({
            type: ORDER_CANCEL_UPDATE_STATUS_FAIL,
            payload: error.response.data.msg
        })
    }


}



// ALL ORDERS BY USER
export const myOrderAction = () => async (dispatch) => {

    try {

        dispatch({
            type: MY_ORDER_REQUEST
        })

        const {
            data
        } = await axios.get(`/api/orders/me`)
        dispatch({
            type: MY_ORDER_SUCCESS,
            payload: data.orders
        })
    } catch (error) {
        dispatch({
            type: MY_ORDER_FAIL,
            payload: error.response.data.msg
        })
    }


}

// FOR ALL ORDERS REQUEST BY ADMIN
export const allOrderAction = () => async (dispatch) => {

    try {

        dispatch({
            type: ALL_ORDER_REQUEST
        })

        const {
            data
        } = await axios.get(`/api/Admin/order`)
        dispatch({
            type: ALL_ORDER_SUCCESS,
            payload: data.allOrders
        })
    } catch (error) {
        dispatch({
            type: ALL_ORDER_FAIL,
            payload: error.response.data.msg
        })
    }


}





// FOR UPDATING ORDER BY ADMIN  
export const updateOrderAction = (id, status,email, orderHeigth, orderWeight, orderLength, orderBreath) => async (dispatch) => {

    try {

        dispatch({
            type: UPDATE_ORDER_REQUEST
        })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };

        const {
            data
        } = await axios.put(`/api/shipped/order-in-admin/${id}`, {
            status,
            email,
            orderHeigth,
            orderWeight,
            orderLength,
            orderBreath
        }, config)
        dispatch({
            type: UPDATE_ORDER_SUCCESS,
            payload: data.msg
        })
    } catch (error) {
        dispatch({
            type: UPDATE_ORDER_FAIL,
            payload: error.response.data.msg
        })
    }


}




// FOR UPDATING ORDER STATUS BY ADMIN  
export const updateOrderStatusAction = (id, status,email,name) => async (dispatch) => {

    try {

        dispatch({
            type: ORDER_UPDATE_STATUS_REQUEST
        })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };

        const {
            data
        } = await axios.put(`/api/admin/updateOrder/${id}`, {
            status,
            email,
            name
        }, config)
        dispatch({
            type: ORDER_UPDATE_STATUS_SUCCESS,
            payload: data.msg
        })
    } catch (error) {
        dispatch({
            type: ORDER_UPDATE_STATUS_FAIL,
            payload: error.response.data.msg
        })
    }


}

// ORDER CANCEL ACTION BY USER  
export const orderCancelAction = (userName, email, phoneNumber, id, reasonForCancel) => async (dispatch) => {

    try {

        dispatch({
            type: ORDER_CANCEL_REQUEST
        })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        const {
            data
        } = await axios.post(`/api/Order/cancelRequest/${id}`, {
            userName,
            email,
            phoneNumber,
            reasonForCancel
        }, config)

        dispatch({
            type: ORDER_CANCEL_SUCCESS,
            payload: data.msg
        })
    } catch (error) {
        dispatch({
            type: ORDER_CANCEL_REQUEST_FAIL,
            payload: error.response.data.msg
        })
    }


}



// FOR SINGEL ORDERED DETAILS BY ADMIN
export const OrderDetailAction = (id) => async (dispatch) => {

    try {

        dispatch({
            type: ORDER_DETAIL_REQUEST
        })

        const {
            data
        } = await axios.get(`/api/order/${id}`)

        dispatch({
            type: ORDER_DETAIL_SUCCESS,
            payload: data.order
        })
    } catch (error) {
        dispatch({
            type: ORDER_DETAIL_FAIL,
            payload: error.response.data.msg
        })
    }


}

// clear the error 


export const clearErrorAction = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERROR
    })
}