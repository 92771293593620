import {
    ADD_PRODUCT_REQUEST,
    ADD_PRODUCT_REQUEST_SUCCESS,
    ADD_PRODUCT_REQUEST_FAIL,
    ADD_PRODUCT_REQUEST_RESET,
    REMOVE_PRODUCT_REQUEST,
    REMOVE_PRODUCT_REQUEST_SUCCESS,
    REMOVE_PRODUCT_REQUEST_FAIL,
    REMOVE_PRODUCT_REQUEST_RESET,
    ALL_WISHLIST_PRODUCT_REQUEST,
    ALL_WISHLIST_PRODUCT_REQUEST_SUCCESS,
    ALL_WISHLIST_PRODUCT_REQUEST_FAIL,
    ALL_WISHLIST_PRODUCT_REQUEST_ADMIN,
    ALL_WISHLIST_PRODUCT_REQUEST_ADMIN_SUCCESS,
    ALL_WISHLIST_PRODUCT_REQUEST_ADMIN_FAIL,
    CLEAR_ERROR
} from '../constants/wishListConstant';



// add product in wishlist by user
export const addProductToWishListReducer = (state = {}, action) => {
    switch (action.type) {

        case ADD_PRODUCT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case ADD_PRODUCT_REQUEST_SUCCESS:
            return {
                loading: false,
                isProductAdd: action.payload,
            };
        case ADD_PRODUCT_REQUEST_FAIL:
            return {
                loading: false,
                error: action.payload,
            };
        case ADD_PRODUCT_REQUEST_RESET:
            return {
                ...state,
                isProductAdd: false,
            };
        case CLEAR_ERROR:
            return {
                ...state,
                error: null
            }
        default:
            return state;
    };


}



// remove product in wishlist by user
export const removeProductToWishListReducer = (state = {}, action) => {
    switch (action.type) {

        case REMOVE_PRODUCT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case REMOVE_PRODUCT_REQUEST_SUCCESS:
            return {
                loading: false,
                isProductRemove: action.payload,
            };
        case REMOVE_PRODUCT_REQUEST_FAIL:
            return {
                loading: false,
                error: action.payload,
            };
        case REMOVE_PRODUCT_REQUEST_RESET:
            return {
                ...state,
                isProductRemove: false,
            };
        case CLEAR_ERROR:
            return {
                ...state,
                error: null
            }
        default:
            return state;
    };


}




// all product in wishlist by user
export const allProductToWishListReducer = (state = {}, action) => {
    switch (action.type) {

        case ALL_WISHLIST_PRODUCT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case ALL_WISHLIST_PRODUCT_REQUEST_SUCCESS:
            return {
                loading: false,
                wishListProducts: action.payload,
            };
        case ALL_WISHLIST_PRODUCT_REQUEST_FAIL:
            return {
                loading: false,
                error: action.payload,
            };
        case CLEAR_ERROR:
            return {
                ...state,
                error: null
            }
        default:
            return state;
    };


}





// all product in wishlist by ADMIN
export const allProductToWishListAdminReducer = (state = {}, action) => {
    switch (action.type) {

        case ALL_WISHLIST_PRODUCT_REQUEST_ADMIN:
            return {
                ...state,
                loading: true,
            };
        case ALL_WISHLIST_PRODUCT_REQUEST_ADMIN_SUCCESS:
            return {
                loading: false,
                wishListProducts: action.payload,
            };
        case ALL_WISHLIST_PRODUCT_REQUEST_ADMIN_FAIL:
            return {
                loading: false,
                error: action.payload,
            };
        case CLEAR_ERROR:
            return {
                ...state,
                error: null
            }
        default:
            return state;
    };


}