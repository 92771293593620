import {
    CLEAR_ERROR,
    USER_LOGIN_REQUEST,
    USER_LOGIN_REQUEST_SUCCESS,
    USER_LOGIN_REQUEST_FAIL,
    LOGOUT_USER_PROFILE_SUCCESS,
    LOGOUT_USER_PROFILE_FAIL,
    USER_SIGNIN_REQUEST,
    USER_SIGNIN_REQUEST_SUCCESS,
    USER_SIGNIN_REQUEST_FAIL,
    LOADING_USER_REQUEST,
    LOADING_USER_REQUEST_SUCCESS,
    LOADING_USER_REQUEST_FAIL,
    UPDATE_PROFILE_BY_USER_REQUEST,
    UPDATE_PROFILE_BY_USER_SUCCESS,
    UPDATE_PROFILE_BY_USER_FAIL,
    UPDATE_PROFILE_BY_USER_RESET,
    UPDATE_USER_PASSWORD_REQUEST,
    UPDATE_USER_PASSWORD_SUCCESS,
    UPDATE_USER_PASSWORD_FAIL,
    UPDATE_USER_PASSWORD_RESET,
    UPDATE_USER_DETAIL_REQUEST,
    UPDATE_USER_DETAIL_FAIL,
    UPDATE_USER_DETAIL_SUCCESS,
    ALL_USER_REQUEST_BY_ADMIN,
    ALL_USER_SUCCESS_BY_ADMIN,
    ALL_USER_FAIL_BY_ADMIN,
    USER_DETAIL_REQUEST_BY_ADMIN,
    USER_DETAIL_SUCCESS_BY_ADMIN,
    USER_DETAIL_FAIL_BY_ADMIN,
    DELETE_USER_DETAIL_SUCCESS,
    DELETE_USER_DETAIL_RESET,
    DELETE_USER_DETAIL_REQUEST,
    DELETE_USER_DETAIL_FAIL,
    RESET_PASSWORD_BY_EMAIL_REQUEST,
    RESET_PASSWORD_BY_EMAIL_SUCCESS,
    RESET_PASSWORD_BY_EMAIL_FAIL,
    PASSWORD_RESET_REQUEST,
    PASSWORD_RESET_SUCCESS,
    PASSWORD_RESET_FAIL



} from "../constants/userConstants"

//  user reducer

export const userReducer = ((state = {
    user: {}
}, action) => {
    switch (action.type) {
        case USER_LOGIN_REQUEST:
        case USER_SIGNIN_REQUEST:
        case LOADING_USER_REQUEST:
            return {
                loading: true,
                isAuthenticated: false
            }
        case LOGOUT_USER_PROFILE_SUCCESS:
            return {
                loading: false,
                isAuthenticated: false,
                user: null,
            }

        case USER_LOGIN_REQUEST_SUCCESS:
        case USER_SIGNIN_REQUEST_SUCCESS:
        case LOADING_USER_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                isAuthenticated: true,
                user: action.payload,
            }
        case USER_LOGIN_REQUEST_FAIL:
        case USER_SIGNIN_REQUEST_FAIL:
            return {
                ...state,
                loading: false,
                user: null,
                isAuthenticated: false,
                error: action.payload,
            }
        case LOADING_USER_REQUEST_FAIL:
            return {
                loading: false,
                user: null,
                isAuthenticated: false,
                error: action.payload,
            }
        case LOGOUT_USER_PROFILE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case CLEAR_ERROR:
            return {
                ...state,
                error: null
            }

        default:
            return state;

    }


})




// user update by user ,deleted by admin,password update by user

export const profileUpdateByUserReducer = ((state = {}, action) => {
    switch (action.type) {
        case UPDATE_PROFILE_BY_USER_REQUEST:
        case UPDATE_USER_PASSWORD_REQUEST:
        case DELETE_USER_DETAIL_REQUEST:
            return {
                ...state,
                loading: true,

            }
        case UPDATE_PROFILE_BY_USER_SUCCESS:
        case UPDATE_USER_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
                isUpdated: action.payload,
            }
        case DELETE_USER_DETAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                isDeleted: action.payload
            }
        case UPDATE_PROFILE_BY_USER_FAIL:
        case UPDATE_USER_PASSWORD_FAIL:
        case DELETE_USER_DETAIL_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }

        case UPDATE_PROFILE_BY_USER_RESET:
        case UPDATE_USER_PASSWORD_RESET:
            return {
                ...state,
                isUpdated: false

            }
        case DELETE_USER_DETAIL_RESET:
            return {
                ...state,
                isDeleted: false

            }
        case CLEAR_ERROR:
            return {
                ...state,
                error: null
            }
        default:
            return state;

    }

})



// GET ALL USERS BY ADMIN 

export const allUserByAdminReducer = ((state = {
    users: []
}, action) => {
    switch (action.type) {
        case ALL_USER_REQUEST_BY_ADMIN:
            return {
                ...state,
                loading: true,

            }
        case ALL_USER_SUCCESS_BY_ADMIN:
            return {
                ...state,
                loading: false,
                users: action.payload,
            }
        case ALL_USER_FAIL_BY_ADMIN:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case CLEAR_ERROR:
            return {
                ...state,
                error: null
            }

        default:
            return state;

    }


})




// GET SINGEL USER DETAIL BY ADMIN
export const userDetailByAdminReducer = ((state = {
    user: []
}, action) => {
    switch (action.type) {
        case USER_DETAIL_REQUEST_BY_ADMIN:
            return {
                ...state,
                loading: true,

            }
        case USER_DETAIL_SUCCESS_BY_ADMIN:
            return {
                ...state,
                loading: false,
                user: action.payload,
            }
        case USER_DETAIL_FAIL_BY_ADMIN:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case CLEAR_ERROR:
            return {
                ...state,
                error: null
            }

        default:
            return state;

    }


})




// UPDATE USER ROLE BY ADMIN
export const updateUserRoleAdminReducer = ((state = {}, action) => {
    switch (action.type) {
        case UPDATE_USER_DETAIL_REQUEST:
            return {
                ...state,
                loading: true,

            }
        case UPDATE_USER_DETAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                isUpdated: action.payload,
            }
        case UPDATE_USER_DETAIL_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case CLEAR_ERROR:
            return {
                ...state,
                error: null
            }

        default:
            return state;

    }


})




// PASSWORD RESET REQUEST BY USER
export const passwordResetRequestByEmail = ((state = {}, action) => {
    switch (action.type) {
        case RESET_PASSWORD_BY_EMAIL_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            }
        case RESET_PASSWORD_BY_EMAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                message: action.payload,
            }
        case RESET_PASSWORD_BY_EMAIL_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case CLEAR_ERROR:
            return {
                ...state,
                error: null
            }

        default:
            return state;

    }


})



// PASSWORD RESET BY TOKEN
export const passwordResetByToken = ((state = {}, action) => {
    switch (action.type) {
        case PASSWORD_RESET_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            }
        case PASSWORD_RESET_SUCCESS:
            return {
                ...state,
                loading: false,
                message: action.payload,
            }
        case PASSWORD_RESET_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case CLEAR_ERROR:
            return {
                ...state,
                error: null
            }

        default:
            return state;

    }


})