import {
    CLEAR_ERROR,
    CREATE_SEARCH_REQUEST,
    CREATE_SEARCH_SUCCESS,
    CREATE_SEARCH_FAIL,
    ALL_SEARCHED_REQUEST,
    ALL_SEARCHED_SUCCESS,
    ALL_SEARCHED_FAIL,
} from '../constants/searchConstants'

import axios from 'axios';

// create search add function


export const createSearchedKeywordAction = (keywords) => async (dispatch) => {

    try {

        dispatch({
            type: CREATE_SEARCH_REQUEST
        })
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };

        const {
            data
        } = await axios.post(`/api/search/keywords/save`, {
            keywords
        }, config)
        dispatch({
            type: CREATE_SEARCH_SUCCESS,
            payload: data.msg
        })
    } catch (error) {
        dispatch({
            type: CREATE_SEARCH_FAIL,
            payload: error.response.data.msg
        })

    }

}

// getting all keywords by admin

export const allSearchedKeywordAction = () => async (dispatch) => {

    try {

        dispatch({
            type: ALL_SEARCHED_REQUEST
        })
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        const {
            data
        } = await axios.get(`/api/search/find/allkeywords`, config)
        dispatch({
            type: ALL_SEARCHED_SUCCESS,
            payload: data.msg
        })
    } catch (error) {
        dispatch({
            type: ALL_SEARCHED_FAIL,
            payload: error.response.data.msg
        })

    }

}




// clear the error 

export const clearErrorAction = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERROR
    })
}