import React, { Fragment, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Footer from "../HomeScreen/bottomsection/Footer";
import Loader from '../../layout/loader';

import "./cartpage.css";
// import CartDetails from "./CartDetails";
import { textRapForNameSearch } from '../../utils/textRapUtils'
import {
  cartRemoveAction,
  clearErrorAction,
  allcartItemAction,
} from "../../redux/actions/cartAction";

import { REMOVE_FROM_CART_RESET } from '../../redux/constants/cartConstants'

// import MetaTag from '../../layout/MetaTag';
import swal from "sweetalert";
// import AuthenticatedCartPage from "./AuthenticatedCartPage";
import { Helmet } from "react-helmet";

// Material icons
// icons
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import RemoveShoppingCartIcon from "@material-ui/icons/RemoveShoppingCart";
import DeleteIcon from '@mui/icons-material/Delete';
import { Typography } from "@material-ui/core";

function Cartpage() {
  const dispatch = useDispatch();
  const history = useHistory();

  // user redux functions
  const { isAuthenticated } = useSelector((state) => state.user);

  // cart reducers
  const { carts, loading } = useSelector((state) => state.carts);

  // remove item from cart 
  const { Removed, loading: cartRemoveLoading, error: itemRemoveFromCartError } = useSelector((state) => state.removeCart);


  // use state in radio button
  // const [isValueChecked, setIsValuedChecked] = useState(false)



  // remove from cart function
  const removeCartItems = (id) => {
    dispatch(cartRemoveAction(id));
  };



  useEffect(() => {
    // if (error) {
    //   swal({
    //     title: "Cart Error",
    //     text: error,
    //     icon: "error",
    //     button: "OK",
    //   });
    //   dispatch(clearErrorAction())
    // }

    if (itemRemoveFromCartError) {
      swal({
        title: "Remove Error",
        text: itemRemoveFromCartError,
        icon: "error",
        button: "OK",
      });
      dispatch(clearErrorAction())
    }
    if (cartRemoveLoading) {
      swal("Please Wait....", {
        button: false,
      });
    }
    if (Removed) {
      swal({
        title: 'Removed',
        text: Removed,
        icon: 'success'
      })
      dispatch({ type: REMOVE_FROM_CART_RESET })
    }

    dispatch(allcartItemAction())

  }, [dispatch, Removed, cartRemoveLoading, itemRemoveFromCartError])


  // checkout button function to proceed for payment

  const checkoutHandler = () => {
    if (!isAuthenticated) {
      return swal({
        title: "Validation Error",
        text: "Please Login for buy the product !!",
        icon: "info",
        button: "OK",
      });
    }
    history.push("/Login?redirect=Checkout");
  };


// const getInputValue = async({id }) => {
    
//     setIsValuedChecked(true)

// }  



  return (
    <Fragment>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=2.0"
        />
        <meta
          name="description"
          content={` Welcome to im online shopping cart page track your bag.   `}
        />
        <meta
          name="keywords"
          content={` cart page || imonline.shop cart page || im online cart page || cart page || im shopping cart page || cart page  || shopping || im || online || im online shopping comp || cart page || shopping cart page || im shopping cart page `}
        />
        <meta name="robots" content="index, follow " />
        <meta
          property="og:title"
          content={` Welcome to im online shopping cart page track your shopping bag.   `}
        />
        <meta
          property="og:description"
          content={` IM Online Shopping Cart Details Page `}
        />
        <meta property="og:type" content="website" />
        {/* <meta property="og:url" content={items.imageurl}   key={items.public_id}/>
            <meta property="og:image" content={items.imageurl}  key={items.public_id}/> */}
        <meta property="og:locale" content="en-GB_IN" />
        <meta property="og:site_name" content="IMONLINE.SHOP" />
        {/* <meta property="og:price:currency" content="INR" />
            <meta property="og:price:amount" content={product.price} /> */}
      </Helmet>
      {isAuthenticated === true ? (
        <Fragment>
          {carts && carts.length === 0 ? (
            <div className="cart-empty-div">
              <RemoveShoppingCartIcon />
              <Typography>No Product's in Your Cart !! </Typography>
              <Link to="/Product"> Buy now </Link>
            </div>
          ) : (
            <Fragment>
              {loading ? <Loader /> : (
                <Fragment>
                  <div className="cart-Page">
                    <div className="cart-Header">
                      <p>Product</p>
                      <p>Quantity</p>
                      <p>Subtotal</p>
                    </div>

                    {carts && carts.map((item) => (
                      <div className="cart-Container" key={item._id}>
                        <div className='cart-Details'>
                          {/* <input type='radio' value={item._id } onChange={(e)=>getInputValue(e.target.value)}  /> */}
                          <img src={item.image} alt='www.imonline.shop' />
                          <div>
                            <Link to={`/ProductDetail/${item.product}`}> {textRapForNameSearch(item.name)} </Link>
                            <span> {`Price : ${item.price}`}    </span>
                            <span> {`Size : ${item.size}`}     </span>
                          </div>
                          <p onClick={() => removeCartItems(item.product)}> <DeleteIcon /></p>
                        </div>
                        {/* <CartDetails
                          item={item}
                          removeCartItem={()=>removeCartItems(item.product)}
                        /> */}
                        <div className="cart-container-input">
                          {/* <button onClick={() => increaseQuantity(item.product, item.quantity, item.productStock, item.size, item.colour)}>+</button> */}
                          <input type="number" name={item._id} value={item.quantity} readOnly />
                          {/* <button onClick={() => decreaseQuantity(item.product, item.quantity, item.size, item.colour)} >-</button> */}
                        </div>
                        <p className="subtotal-container">
                          {`${item.price * item.quantity
                            }`} <CurrencyRupeeIcon />{" "}
                        </p>
                      </div>
                    ))}

                    <div className="cart-gross-total">
                      <div></div>
                      <div className="cart-gross-total-box">
                        <p>Gross Total </p>
                        <p>
                          {`${carts && carts.reduce(
                            (acc, item) => acc + item.quantity * item.price,
                            0
                          )}`}
                          <CurrencyRupeeIcon />
                        </p>
                      </div>
                      <div></div>
                      <div className="cart-cheakout-div">
                        <button onClick={checkoutHandler}>
                          Checkout <ArrowRightAltIcon />
                        </button>
                      </div>
                    </div>
                  </div>
                </Fragment>
              )}
            </Fragment>
          )}
        </Fragment>
      ) : (
        <Fragment>
          <div className="authecation-div-cart">
            <div className="text-of-authecation-cart-div">
              <h2>SHOPPING BAG</h2>
              <h6>Your bag is empty <Link to={'/Signin'}>SignIn or register</Link> to sync your bag to any device</h6>
            </div>
            <div className="button-of-authentication-cart-div">
              <Link to={'/Product'}  > <button>Shop Now</button></Link>
            </div>
          </div>
          {/* <AuthenticatedCartPage /> */}
        </Fragment>
      )}
      <Footer />
    </Fragment>
  );
}

export default Cartpage;
