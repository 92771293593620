import React, { Fragment } from 'react'

function TermsOfUsesPart2() {
    return (
        <Fragment>
            <div className="terms-and-conditions-uses">
                <ul className='DEFINITIONS'>
                    DEFINITIONS
                    <li>
                        Under these Conditions, the following terms shall have the respective meanings
                        specified below unless the context otherwise requires:

                        <ul>
                            <li>
                                <b> Buyer </b>means the person or legal entity identified in the Order as the purchaser of the Products.
                            </li>
                            <li><b>IM</b>  means IM ONLINE SHOPPING COMP.</li>
                            <li><b>IM Product</b>  means a Product bearing the IM trade mark for which IM
                                invites Orders in accordance with these Conditions.</li>
                            <li> <b>Contract</b> means a contract for sale between IM and the Buyer of the Products
                                formed in accordance with Clause 3(iv).</li>
                            <li><b>Defective</b>  Product is defined in Clause 7(ii).</li>
                            <li><b>Force Majeure Event</b>  means any event or circumstance that IM could not
                                reasonably prevent or avoid, including

                                <ul>
                                    <li>explosion, fire, flood, war, earthquake, storm or other natural disasters</li>
                                    <li>war, declared or undeclared, sabotage, insurrection, terrorist or criminal acts
                                        whether online or offline, riot or civil disturbance</li>
                                    <li>import or export regulations or embargo or requisition restrictions regulations bye-laws prohibition or any acts or measures or any intervention
                                        of any governmental or regulatory authority</li>
                                    <li>epidemic, quarantine restrictions, outbreak of disease or any travel restrictions or bans (including bans on non-essential travel) issued
                                        by the World Health Organisation or any governmental authority</li>

                                    <li>strike, lock-out, work stoppage or other industrial action or trade dispute
                                        (whether involving IM employees or any other person)</li>
                                    <li>telecommunication, network or Internet disruptions or interruptions</li>
                                    <li>we collect it by other lawful means</li>
                                </ul>
                            </li>
                            <li><b>Online Payment</b>  means payment by any one or more of the following payment modes as may be designated by
                                IM from time to time. These include credit card, debit card, charge card, OR any other online payment methods
                                eg:(google-pay,phonepe and many more), online banking,online store credit, and other designated payment modes.
                            </li>
                            <li><b>Online Store</b> means the IM online store currently accessible at https://www.imonline.shop.</li>
                            <li><b>Order</b>means an online order placed via the Online Store by
                                a IM Buyer for the Products in accordance with these Conditions.</li>

                            <li><b>Parties</b>mean IM and the Buyer. "Party" means any one of them.</li>
                            <li><b>Payment Processing Company</b>means the applicable payment or card processing entity for the
                                relevant Online Payment mode.</li>
                            <li><b>Product</b> means a IM Product or Third Party Product listed at the Online Store for which
                                IM invites Orders in accordance with these Conditions.</li>


                            <li><b>Substitute Product </b>is defined in Clause 6(iv).</li>

                            <li> <b>Third Party Product </b> means a Product which is not a IM
                                Product for which IM invites Orders in accordance with these Conditions.</li>
                        </ul>

                    </li>
                    <li>Words using the singular or plural number also include the plural or singular number.
                    </li>
                    <li>Any reference to a "person" or "entity" includes a reference to an individual, a sole-proprietor,
                        a partnership, an unincorporated association and a company.</li>
                    <li>Any reference to a "Clause" is to a clause of these Conditions.</li>
                    <li>The headings in these Conditions are inserted for convenience only and shall
                        be ignored in construing these Conditions.</li>
                    <li>A reference to the word "include" or "including" shall not be construed as having any limiting effect.</li>
                </ul>
                <ul className='APPLICATION-AND-EFFECT'>

                    APPLICATION AND EFFECT

                    <li>These Conditions shall govern the sale of the Products listed at the Online Store by IM to the Buyer,
                        save where Buyer has signed a separate purchase agreement with
                        IM, in which case the terms and conditions of the separate agreement shall govern.</li>
                    <li>By ordering the Products or accepting delivery of the Products described on the invoice, the Buyer agrees to be bound by
                        and is deemed to have accepted these Conditions.</li>

                </ul>

                <ul className='ORDERS-PRICE-AND-PAYMENT'>
                    ORDERS, PRICE AND PAYMENT
                    <li>IM will process Orders for delivery in India AND SOON we will start delivering in the following territories:
                        Australia, Bahrain, Belgium*, Cambodia, Canada, China*, Denmark*, Finland*, France*, Germany*,
                        Hong Kong SAR, Indonesia, Israel, Italy*, Japan*, Kuwait, Macau SAR, Malaysia, Mexico,
                        Netherlands*, New Zealand, Norway*, Oman, Panama, Qatar, Philippines, Portugal*, Singapore,
                        South Africa, South Korea, Spain*, Sri Lanka, Sweden*, Switzerland*,
                        Taiwan, Thailand, United Kingdom*, United Arab Emirates, United States of America, Vietnam.</li>


                    <li>Buyer shall indicate the Products they wish to order and the quantity required, at the price and in the INR currency
                        specified by IM at the Online Store. This can be done by placing an Order on the designated online
                        form at the Online Store and providing to IM all necessary information as may
                        be required by IM at the Online Store.</li>

                    <li>All Orders made by the Buyer for one or more Product(s) shall be deemed to be an offer made by the Buyer to purchase
                        such Product(s) upon the terms of these Conditions and for the selected Products and quantities set out in the Order.
                        All Orders shall be subject to acceptance by IM.</li>


                    <li>IM may accept an Order by:
                        <ul>

                            <li>
                                contacting the Buyer by telephone, email or other mode of communication within a
                                reasonable time after Buyer has made the Order, to either

                                <ul>

                                    <li>
                                        accept and confirm the price, quantity, and delivery date and time of the Product(s)
                                        ordered by the Buyer
                                    </li>
                                    <li>
                                        in the case of Orders exceeding a certain quantity or value, request that the Buyer
                                        submit a written bulk purchase order form for IM's written acceptance

                                    </li>

                                </ul>



                            </li>

                            <li>delivering the Product(s) ordered to the Buyer
                            </li>
                            <li>
                                Upon such an acceptance, a binding Contract shall be formed upon the terms set out in these Conditions, the terms of the accepted
                                Order (excluding any terms which are not accepted by IM), and other terms and conditions as IM may impose
                                as a condition of its acceptance. For the avoidance of doubt, no Contract shall come into existence unless and until the Order has
                                been accepted by IM in the manner set out in Clause 3(iv). Processing or acceptance of Online Payment for an Order
                                shall not in itself constitute acceptance of the Order by IM, provided that where an Order is rejected by
                                IM, any payment made for such an Order shall be reversed or refunded
                                by IM, or an online store credit shall be given to the Buyer.
                            </li>




                        </ul>



                    </li>
                    <li>IM shall be entitled to

                        <ul>
                            <li>decline to accept or reject the Buyer’s Order in whole or in part without assigning any reason</li>
                            <li>delay or defer delivery of any Order in whole or in part due to Product unavailability, low inventory levels,
                                or for any other reason</li>
                        </ul>


                    </li>
                    <li>IM shall not be liable for any errors in the pricing or specification of Products ordered by the
                        Buyer that may appear at the Online Store. The price to be paid by the Buyer for all Products shall be
                        IM's current selling price on the date of Order acceptance by IM, which may or
                        may not be correctly reflected at the Online Store.
                    </li>
                    <li>No promotion, offer, voucher, or online store credit will be applied retroactively to any standing or previously placed Order.
                        Any applicable promotions, offers, vouchers, or online store credit must be stated before checkout as these cannot be claimed
                        or applied after an Order have been made. No promotion, offer, voucher, or online store credit may be exchanged for cash.
                    </li>
                    <li>Unless otherwise agreed by IM in writing, payment for the Products shall be made by the Buyer
                        in full by valid Online Payment before physical delivery of Products.</li>

                    <li>
                        The Buyer warrants and agrees that it is ordering Products for his/her own use only and not for re-sale, distribution or export.
                        The Buyer shall indemnify IM and hold IM harmless against all losses, damages, liabilities,
                        expenses and costs arising from any breach of this Clause 3(ix).
                    </li>
                    <li>For economical and standard delivery, the Buyer shall bear all shipping and handling charges
                        (where applicable), as well as all applicable duties, tariffs and taxes (including, but not limited to, goods and services tax)
                        at the prevailing rates, which are not determined at checkout but will be determined by your local customs officials once the
                        Products arrive in your state (where applicable). Buyer shall not be entitled to reject any Products, withdraw any Order,
                        or claim any refund on the grounds that the Buyer does not agree with any such duties, tariffs and taxes.
                        For express delivery, the Buyer shall bear all shipping and handling charges (where applicable). Standard duties,
                        tariffs and taxes imposed by the delivery destination shall be borne
                        by IMONLINE.SHOP for orders shipped under express delivery.</li>

                    <li>All Online Payments are subject to processing by IM's payment service provider, the approval of the Payment
                        Processing Company and the relevant issuing bank.
                        IM shall not be liable in any way if the payment service provider, Payment Processing Company or the
                        issuing bank refuses to process or accept any Online Payment particulars for any reason.</li>

                    <li>The Buyer agrees to submit to IM, and its payment service provider, Online Payment or card information
                        and other personal and delivery information that may be reasonably requested by IM or
                        its payment service provider to process the Order, payment for the Order, and to arrange for delivery and invoicing.
                        All information submitted shall be subject to IM's Privacy Policy.IM shall require its
                        payment service provider to keep such information confidential and not use the same for any purpose other than to
                        carry out its service. The Buyer agrees that IM shall not be liable for any loss,
                        damage, or liability in the event of any unauthorised disclosure of such information by its payment service provider.</li>


                    <li>IM reserves the right to exercise its lawful remedies if a dispute or issue arises over Online Payments,
                        or if it does not receive full payment for an Order. In particular, but without limitation to any other remedies,
                        if the Payment Processing Company or the issuing bank rejects or reverses payment for an Order,
                        IM may in its discretion:

                        <ul>
                            <li>cancel the Order and/or</li>
                            <li>refuse to accept future Orders from you.</li>
                        </ul>
                    </li>
                </ul>
                <ul className='PRODUCT-AVAILABILITY-AND-DESCRIPTIONS
 '>
                    PRODUCT AVAILABILITY AND DESCRIPTIONS
                    <li>Products displayed on the Online Store are subject to availability.In some cases, Products displayed for
                        sale on the Online Store may not be available in IM stores or vice versa.</li>

                    <li>IM has made every effort to display and describe as accurately as possible the colours, sizes,
                        dimensions, information and other attributes of Products (“Attributes”). However, due to the inherent limitations
                        of electronic displays and the characteristics of certain materials, actual Attributes of individual Products may vary.
                        Slight marks and colour/print variations should not be considered as defects or render the Product non-compliant, but are
                        inherent characteristics of the Product.
                        All Attributes given on the Online Store are approximate only.</li>

                    <li>All Products displayed for sale on this Site are constructed of man-made materials unless otherwise stated.
                    </li>

                </ul>

                <ul className='TITLE-AND-RISK' >
                    TITLE AND RISK
                    <li>Risk in the Products shall pass to the Buyer upon our delivery of the Products to the carrier.
                    </li>
                    <li>Title in the Products shall not pass to Buyer until receipt by IM of full
                        and final payment for the Products and delivery of the Products to Buyer.</li>
                </ul>


                <ul className='ORDER-DELIVERY-AND-CANCELLATION'>
                    ORDER DELIVERY AND CANCELLATION
                    <li>The Buyer shall state in the Order the Buyer’s preferred delivery option or if available, collection options,
                        and where the delivery option is selected, the address for delivery of the Products.</li>
                    <li>Subject always to IM acceptance of the Order and to Clause 3(i)
                        <ul>
                            Where the delivery option is selected:
                            <li>IM shall deliver the Products to the place of delivery as may be stated by the Buyer and agreed
                                to by IM. If no one is available at the delivery address to receive the Products, IM's delivery
                                agent will leave an "unable to deliver" card at the address and the Buyer should
                                follow the directions on that card to obtain delivery of the Products</li>
                            <li>he Buyer shall bear and pay to IM:
                                <ul>
                                    <li>the Delivery and Shipment Charges</li>
                                    <li>the delivery, re-delivery, shipment, return, forwarding, re-shipment, and administrative costs, tax, duties,
                                        storage and disposal charges and costs for any Product(s), which is/are rejected by the Buyer where such rejection
                                        is not made pursuant to an express right of the Buyer under these Conditions,
                                        or where any delivery to the Buyer is unsuccessful for any reason.</li>
                                </ul>
                                <li>Please read our latest Delivery Terms for local and national deliveries,
                                    which shall apply to the Contract</li>
                            </li>

                            <li>Where the option is made available by IM, and the Buyer opts for self-collection of the Products,
                                the Buyer shall collect the Products at an address designated by IM.</li>
                            <li>IM is unable to ship to any PO Box, military, protected area or location. Shipping and other charges for
                                failure of attempted delivery to the restricted
                                areas will be borne by Buyer or the recipient of the Products.</li>

                            <li>There may be an administrative fee payable by the Buyer for any changes in delivery address for each Order.
                            </li>
                            <li>The Buyer is aware that the courier company or customs authority may in their sole discretion, open and inspect for any
                                reason the contents of the Product package.IM shall not be liable for any loss or damage thereby caused.</li>

                            <li>All custom declarations will be completed in English.</li>
                        </ul>

                    </li>

                    <li>Any shipment, delivery or collection dates provided by IM are estimates only and shall not form part of the Contract.
                        IM shall not be liable for any loss, damage, cost or expense for any failure to meet any given shipment,
                        delivery or collection date, howsoever caused.
                        If the Buyer's Order has not been delivered within a reasonable time, the Buyer should contact IM at:
                        <ul>
                            <li>
                                IM ONLINE SHOPPING COMP.
                                MRCG+87X, Chhanna Rd,Dehliz Kalan,Ahmedgarh,punjab,148021,india
                                Tel: +91 7658007326
                                Email: im@imonline.shop
                                Operation Hours: 9am – 6pm (GMT +8)
                            </li>
                        </ul>
                    </li>
                    <li>IM reserves the right from time to time, without liability
                        or prior notice, to withdraw or cease to make available any or all Products from the Online Store, or to

                        <ul>
                            <li>change its prices or specifications of any Product</li>
                            <li>deliver a Product which is similar to the Product ordered with minor differences</li>
                            <li>(each a "Substitute Product"). The Buyer shall be deemed to have accepted such changes
                                and differences if the Buyer accepts delivery of a Substitute Product.</li>
                        </ul>
                    </li>
                    <li>An Order may be cancelled by the Buyer prior to payment for the Products by Buyer,
                        provided always that
                        <ul>

                            <li>the Buyer must contact IM to request IM for cancellation immediately</li>
                            <li>IM will endeavour to accommodate the Buyer's request, if the Order has not been processed</li>
                            <li>the Buyer shall pay any cancellation fees which may be imposed by IM</li>

                        </ul>
                    </li>

                    <li>If an Order has already been processed, no cancellation or refund will be permitted.</li>

                    <li>The Buyer acknowledges that IM's liability to deliver the Products to the Buyer pursuant to the Contract
                        is subject to the availability of the Products. The Buyer further acknowledges that IM retains absolute discretion
                        as to the order of priorities in which any Products are delivered to IM's customers.</li>

                    <li>IM may deliver the Products in an Order by instalments, provided that full payment for the entire
                        Order shall be made on or before the delivery of the first instalment.</li>

                    <li>IM reserves the right to make only partial delivery of an Order. If only part of the Order can be shipped,
                        IM will use reasonable efforts to contact the Buyer to select replacements.
                        If the Buyer cannot be contacted, IM will reject the entire Order.</li>


                </ul>
                <ul className='ACCEPTANCE-OF-PRODUCTS'>ACCEPTANCE OF PRODUCTS

                    <li>Unless the Buyer notifies IM in writing to the contrary within thirty (30) days of the date of delivery,
                        the Products shall be deemed to have been accepted by the Buyer as being in good condition and in accordance with the Contract.
                    </li>

                    <li>A Product shall only be eligible for replacement if upon delivery:
                        <ul>
                            <li>it is damaged</li>
                            <li>the Product supplied is materially different from the Product specified in the Order,
                                (such Product being a "Defective Product").
                            </li>
                        </ul>
                    </li>
                    <li>The Buyer must report any Defective Product to IM by submitting a “return request” through
                        My Account online and describing the reasons. The Defective Product has to be returned within thirty (30) days of its delivery,
                        failing which the Buyer shall not be entitled to a replacement Product.</li>

                    <li>If IM considers the Product to be a Defective Product, the procedure for returns set out in IM's Policy will apply.
                        The Buyer must produce the tax receipt together with the Defective Product in its original delivered condition and packaging.</li>


                    <li>IM reserves the right to refuse any return or replacement of a Defective Product if:
                        <ul>
                            <li>The Buyer is unable to produce the Product details or tax receipt</li>
                            <li>The Defective Product is a Third Party Product and IM is
                                not authorised by the supplier to process returns or replacements</li>
                            <li>The defect is the result of:
                                <ul>
                                    <li>
                                        unique, accidental, or random damage that is the result of use by the Buyer, or wear and tear.
                                    </li>
                                    <li>accidental nicks, scratches, or minor damage</li>
                                    <li>improper use or mismanagement by the Buyer</li>
                                    <li>modification of the Product not authorised by IM</li>
                                    <li>unusual or unrecommended physical, environmental or electrical stress by the Buyer</li>
                                    <li>use of Product by a person other than the Buyer</li>
                                    <li>Buyer’s failure to comply with any terms of these Conditions</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>Please see IM's Returns Policy for additional terms governing the return of Products.</li>
                </ul>

                <ul className='CONTROLS-AND-RESTRICTIONS'>
                    CONTROLS AND RESTRICTIONS
                    <li>Certain territories impose censorship, customs, import, export and/or other regulatory requirements
                        and restrictions on Products.The Buyer shall be responsible for ensuring all Products in the Order
                        are and will be compliant with such requirements at their own cost. If requested,
                        IM will use reasonable commercial efforts to attempt to deliver
                        the Order to the Buyer by providing supporting documents to the relevant authorities.
                        IM shall not be liable for any non-delivery or delay in delivery of any
                        Product which fails to meet such requirements, or which is intercepted by any government
                        or regulatory authorities.
                    </li>
                    <li>The Buyer shall be responsible for all (i) shipping charges for both the delivery and the return of the Order,
                        (ii) charges, duties, taxes or penalties imposed upon IM by the authorities in either the delivery or
                        shipping destination,(iii) reasonable administration fee for all Orders which are unable to be delivered due
                        to any regulatory requirements and restrictions on Products not being met after the reasonable
                        commercial efforts of IM.

                    </li>
                </ul>

                <ul className='NO-WARRANTIES'>NO WARRANTIES

                    <li>IM makes no warranty in respect to any IM Product, expressed or implied,
                        including any implied warranties of merchantability, quality, compliance with description and fitness
                        for a particular purpose. IM's  sole liability for any defects in any IM
                        Product is set out in Clause 7.
                    </li>

                    <li>IM makes no representations or warranties of any kind in respect of any Third Party Products,
                        and all warranties expressed or implied in respect to Third Party Products, including any implied warranties
                        of merchantability, quality, compliance with description and fitness for a particular purpose,
                        are hereby excluded. Third Party Products including software, hardware, peripherals and accessories
                        are covered by the warranties (if any) provided by the original manufacturer, licensor or publisher only.
                    </li>
                </ul>

                <ul className='EXCLUSION-LIMITATION-OF-LIABILITY'>
                    EXCLUSION & LIMITATION OF LIABILITY

                    <li>In no event shall IM be liable to the Buyer or any third party for any indirect,
                        special, consequential, exemplary or punitive loss or damage for any breach of these Conditions,
                        including but not limited to loss of profits, loss of business or goodwill, loss of use, or any claim by any third party,
                        even if IM has been advised of the possibility of such loss or damage.
                    </li>

                    <li>If any service (including but not limited to installation of any Product)
                        is performed by any third party, IM shall not be liable for any act, neglect,
                        omission or wilful default of such third party,
                        regardless of whether such third party is authorised by IM.
                    </li>

                    <li>The total liability of IM to the Buyer for any and all claims whether by the Buyer or by any other
                        person and howsoever arising in connection with or arising out of the sale, supply, delivery possession,
                        replacement or use of a Product or resulting from the breach of the Contract and/or
                        these Conditions by IM shall not in any event exceed the price
                        of the Product giving rise to such claims.

                    </li>

                    <li>Nothing in these Conditions shall limit or exclude IM's
                        liability for death or personal injury caused by IM's negligence.
                    </li>

                </ul>

                <ul className='FORCE-MAJEURE'>
                    FORCE MAJEURE
                    <li>IM shall not be liable for or be considered to be in breach of the Contract and/or
                        these Conditions by reason of any failure in performing any of its obligations under the Contract and/or
                        these Conditions during any period in which performance is delayed by a Force Majeure Event.
                    </li>

                    <li>IM shall notify the Buyer of any delay or failure arising through any Force Majeure
                        Event and provide a revised delivery date as soon as practicable.
                        The Buyer may not terminate the Contract by reason of any such delay or failure.
                    </li>

                    <li>In the event that any Force Majeure Event results in a shortage of Products,IM
                        shall be entitled to allocate its available stock of
                        the Products among its customers in such a manner that IM
                        may consider equitable and may make partial deliveries of any Products to the Buyer.
                    </li>
                </ul>
                <ul className='AMENDMENT' >AMENDMENT

                    <li>IM reserves the right to amend these Conditions without prior written notice,
                        at IM's sole discretion,
                        by posting a copy of the amended Conditions at the Online Store.
                    </li>
                    <li>Any attempt to modify, supplement or amend
                        these Conditions by the Buyer will be null and void, unless expressly
                        agreed to in writing by IM.
                    </li>
                </ul>
                <ul className='ASSIGNMENT'>
                    ASSIGNMENT
                    <li>The Buyer shall not assign or otherwise transfer the Contract or any of its
                        rights and obligations hereunder, whether in whole or in part without the prior written consent
                        of IM. Any such unauthorised assignment shall be deemed null and void.
                    </li>
                </ul>

                <ul className='NO-WAIVER'>
                    NO WAIVER
                    <li>No failure on the part of IM to exercise, and no delay on its part in exercising,
                        any right or remedy under the Contract will operate as a waiver thereof. Nor will any single or
                        partial exercise of any right or remedy preclude any other or further exercise thereof or the
                        exercise of any other right or remedy.
                    </li>
                    <li>Any term or condition of the Buyer’s Order which is any way inconsistent with or in addition to these
                        Conditions shall not be applicable or binding on IM, unless otherwise agreed in a written agreement
                        signed by Buyer and IM. Any failure by IM to object
                        shall not be considered a waiver of these Conditions.
                    </li>
                </ul>
                <ul>
                    ILLEGALITY
                    <li>
                        If any provision of these Conditions is held by any competent authority to be invalid or unenforceable in whole or in part,
                        the validity of the other provisions of these Conditions and the remainder of the provisions
                        in question shall not be affected thereby.
                    </li>
                </ul>
                <ul>
                    RIGHTS OF THIRD PARTIES
                    <li>
                        A person or entity who is not a Party to the Contract shall have no right under the Contracts
                        (Rights of Third Parties) Act (Cap. 53B of Singapore) to enforce any term of the Contract, regardless
                        of whether such person or entity has been identified by name, as a member of a class or as answering a particular description.
                        For the avoidance of doubt,nothing in this Clause shall affect the rights of any permitted assignee or transferee of the Contract.
                    </li>
                </ul>
                
                <ul>
                    GOVERNING LAW AND JURISDICTION
                    <li>These Conditions shall be construed in accordance with, and governed by the
                        laws of India, without giving effect to any principles of conflicts of law.</li>

                    <li>
                        The Parties hereby submit to the exclusive jurisdiction of the India courts.
                    </li>
                </ul>





            </div>
        </Fragment>
    )
}

export default TermsOfUsesPart2