import React, { Fragment, useEffect } from "react";

// import { Link } from "react-router-dom";
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import { useSelector, useDispatch } from "react-redux";
import swal from "sweetalert";
import Navbar from "../HomeScreen/Navbar";
import {
  clearErrorAction,
  getAllNotificationaActionByUser,
  readNotificationaActionByUser,
} from "../../redux/actions/notificationAction";

import './notification.css';
import Footer from "../HomeScreen/bottomsection/Footer";

function NotificationMainPage() {
  const dispatch = useDispatch();

  const { error, notification } = useSelector(
    (state) => state.userNotification
  );

  useEffect(() => {
    if (error) {
      swal({
        title: "Notification Error",
        text: error,
        icon: "error",
      });
      dispatch(clearErrorAction());
    }

    dispatch(getAllNotificationaActionByUser());
  }, [dispatch, error]);



  const getClickHandler = (id) => {
    dispatch(readNotificationaActionByUser(id))
  }

  return (
    <Fragment>
      <Navbar />
      <div className="notification-page-style-main-div">
        {notification.length === 0 ? (
          <Fragment>
            <div className='empty-div-main-notification-page-container'>
              <p className='empty-div-main-notification-page-container-paragarph'>
                Oohu !! Look Likes You Have'nt have Any Notification.
                <NotificationImportantIcon/>
              </p>
            </div>
          </Fragment>
        ) : (
          <Fragment>
            {notification.map((data) => (
              <div className={data.notifactionRead === true ? 'notification-message-read' : 'notification-message-unread'} onClick={() => getClickHandler(data._id)} >
                {data.message}
              </div>
            ))}
          </Fragment>
        )}

      </div>
      <Footer />
    </Fragment>
  );
}

export default NotificationMainPage;
