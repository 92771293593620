import axios from 'axios';


import {
    CLEAR_ERROR,
    PRODUCT_REQUEST,
    PRODUCT_REQUEST_FAIL,
    PRODUCT_REQUEST_SUCCESS,
    PRODUCT_DETAIL_REQUEST,
    PRODUCT_DETAIL_REQUEST_SUCCESS,
    PRODUCT_DETAIL_REQUEST_FAIL,
    ADMIN_PRODUCT_REQUEST,
    ADMIN_PRODUCT_REQUEST_SUCCESS,
    ADMIN_PRODUCT_REQUEST_FAIL,
    NEW_PRODUCT_REQUEST,
    NEW_PRODUCT_REQUEST_SUCCESS,
    NEW_PRODUCT_FAIL,
    DELETE_PRODUCT_REQUEST,
    DELETE_PRODUCT_REQUEST_SUCCESS,
    DELETE_PRODUCT_FAIL,
    UPDATE_PRODUCT_REQUEST,
    UPDATE_PRODUCT_REQUEST_SUCCESS,
    UPDATE_PRODUCT_FAIL,
    SEARCH_PRODUCT_REQUEST,
    SEARCH_PRODUCT_REQUEST_SUCCESS,
    SEARCH_PRODUCT_REQUEST_FAIL,
    SEARCH_PRODUCT_WITH_FILTERS_REQUEST,
    SEARCH_PRODUCT_WITH_FILTERS_REQUEST_SUCCESS,
    SEARCH_PRODUCT_WITH_FILTERS_REQUEST_FAIL,
    GET_PRODUCT_BY_TAG_REQUEST,
    GET_PRODUCT_BY_TAG_SUCCESS,
    GET_PRODUCT_BY_TAG_FAIL
} from "../constants/productConstants"

// import {
//     URL
// } from '../../Url';

// product action



export const getProductByTagAction = (category) => async (dispatch) => {
    try {
        dispatch({
            type: GET_PRODUCT_BY_TAG_REQUEST
        })

        const config = {
            headers: {
                'Content-Type': 'application/json',
            }
        };
        const {
            data
        } = await axios.get(`/api/getProducts/shoes?category=${category}`, config);

        dispatch({
            type: GET_PRODUCT_BY_TAG_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: GET_PRODUCT_BY_TAG_FAIL,
            payload: error.response.data.msg,
        })
    }
}





export const productAction = (keyword = '', category = '', tag = '', price = [0, 50000],) => async (dispatch) => {

    var link;
    try {
        dispatch({
            type: PRODUCT_REQUEST
        })

        const config = {
            headers: {
                'Content-Type': 'application/json',
            }
        };

        link = `/api/getProducts/shoes?keyword=${keyword}&price[gte]=${price[0]}&price[lte]=${price[1]}`



        if (category !== '' && tag !== '') {
            link = `/api/getProducts/shoes?keyword=${keyword}&price[gte]=${price[0]}&price[lte]=${price[1]}&category=${category}&tag=${tag}`
        }else if(tag !== ''){
            link = `/api/getProducts/shoes?keyword=${keyword}&price[gte]=${price[0]}&price[lte]=${price[1]}&tag=${tag}`
        }

        const {
            data
        } = await axios.get(link, config);

        dispatch({
            type: PRODUCT_REQUEST_SUCCESS,
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: PRODUCT_REQUEST_FAIL,
            payload: error.response.data.msg,
        })
    }


}


// auto complete search action 

export const autoCompleteSearchProductAction = (shoesName) => async (dispatch) => {
    try {
        dispatch({
            type: SEARCH_PRODUCT_REQUEST
        })


        const {
            data
        } = await axios.get(`/api/search/find/shoesName=/name?shoesName=${shoesName}`);
        dispatch({
            type: SEARCH_PRODUCT_REQUEST_SUCCESS,
            payload: data.msg,
        })

    } catch (error) {
        dispatch({
            type: SEARCH_PRODUCT_REQUEST_FAIL,
            payload: error.response.data.msg,
        })
    }


}



// searched data for filters 

export const searchDataForFiltersAction = (shoesName) => async (dispatch) => {
    try {
        dispatch({
            type: SEARCH_PRODUCT_WITH_FILTERS_REQUEST
        })
        const {
            data
        } = await axios.get(`/api/search/find/shoesName=name/catogery/brand?shoesName=${shoesName}`);
        dispatch({
            type: SEARCH_PRODUCT_WITH_FILTERS_REQUEST_SUCCESS,
            payload: data.msg,
        })

    } catch (error) {
        dispatch({
            type: SEARCH_PRODUCT_WITH_FILTERS_REQUEST_FAIL,
            payload: error.response.data.msg,
        })
    }


}




// product delete action by admin

export const productDeleteAction = (id) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_PRODUCT_REQUEST
        })
        const {
            data
        } = await axios.delete(`/api/product/remove/${id}`);
        dispatch({
            type: DELETE_PRODUCT_REQUEST_SUCCESS,
            payload: data.msg,
        })

    } catch (error) {
        dispatch({
            type: DELETE_PRODUCT_FAIL,
            payload: error.response.data.msg,
        })
    }

}



// product detail action

export const productDetailAction = (id) => async (dispatch) => {
    try {
        dispatch({
            type: PRODUCT_DETAIL_REQUEST
        })
        const {
            data
        } = await axios.get(`/api/product/${id}`);
        dispatch({
            type: PRODUCT_DETAIL_REQUEST_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: PRODUCT_DETAIL_REQUEST_FAIL,
            payload: error.response.data.msg,
        })
    }


}

// admin product request 

export const adminProductRequestAction = () => async (dispatch) => {

    try {
        dispatch({
            type: ADMIN_PRODUCT_REQUEST
        })
        const {
            data
        } = await axios.get(`/api/admin/getProducts/shoes`);
        dispatch({
            type: ADMIN_PRODUCT_REQUEST_SUCCESS,
            payload: data.products,
        })

    } catch (error) {
        dispatch({
            type: ADMIN_PRODUCT_REQUEST_FAIL,
            payload: error.response.data.msg,
        })
    }

}

// update product by admin action
export const updateProductAction = (id, productData) => async (dispatch) => {
    try {

        dispatch({
            type: UPDATE_PRODUCT_REQUEST
        })
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        const {
            data
        } = await axios.put(`/api/product/edit/${id}`, productData, config)
        dispatch({
            type: UPDATE_PRODUCT_REQUEST_SUCCESS,
            payload: data.msg
        })
    } catch (error) {
        dispatch({
            type: UPDATE_PRODUCT_FAIL,
            payload: error.response.data.msg
        })
    }


}



// create product by admin action
export const createProductAction = (formData) => async (dispatch) => {
    try {

        dispatch({
            type: NEW_PRODUCT_REQUEST
        })
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        const {
            data
        } = await axios.post(`/api/CreateProducts/shoes`, formData, config)
        dispatch({
            type: NEW_PRODUCT_REQUEST_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: NEW_PRODUCT_FAIL,
            payload: error.response.data.msg
        })
    }


}

// clear the error 


export const clearErrorAction = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERROR
    })
}