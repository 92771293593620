import React, { Fragment, useEffect } from 'react'
import { DataGrid } from '@material-ui/data-grid'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { clearErrorAction, myOrderAction } from '../../redux/actions/orderAction'
import Loader from '../../layout/loader'
import Typography from '@material-ui/core/Typography'
import LaunchIcon from '@mui/icons-material/Launch';
import swal from 'sweetalert';
import MetaTag from '../../layout/MetaTag'
import './delivery.css'

function DeliveryPage() {
  const dispatch = useDispatch();

  const { error, loading, orders } = useSelector((state) => state.myOrders)
  const { user } = useSelector((state) => state.user)

  const columns = [
    { field: 'id', headerName: 'OrderId', minWidth: 300, flex: 1 },


    {
      field: 'status',
      headerName: 'Status',
      minWidth: 150,
      flex: 0.5,
      cellClassName: (params) => {
        return params.getValue(params.id, 'status') === 'Delivered' ?
          'greenColor' : 'redColor';
      }
    },
    {
      field: 'itemQty',
      headerName: 'Item-Quantity',
      minWidth: 150,
      type: "number",
      flex: 0.2
    },
    {
      field: 'amount',
      headerName: 'Amount',
      minWidth: 150,
      type: "number",
      flex: 0.5
    },
    {
      field: 'actions',
      headerName: 'Actions',
      minWidth: 150,
      type: "number",
      flex: 0.3,
      sortable: false,
      renderCell: (params) => {
        return (
          <Link to={`/order/${params.getValue(params.id, 'id')}`}  >

            <LaunchIcon />
          </Link>
        )
      }
    }


  ];
  const rows = [];

  orders &&
    orders.forEach((item, index) => {
      rows.push({
        itemQty: item.orderItems.length,
        id: item._id,
        status: item.orderStatus,
        amount: item.totalPrice
      });
    });

  useEffect(() => {

    if (error) {
      swal({
        title: "Error",
        text: error,
        icon: 'error',
      });
      dispatch(clearErrorAction())
    }

    dispatch(myOrderAction())

  }, [dispatch, error])





  return (

    <Fragment>
      <MetaTag title={`IM Online Shopping ${user.firstName} All Orders Details Page`} />
      {loading ? (<Loader />) : (

        <div className="myOrdersPage">
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={10}
            disableSelectionOnClick
            className='myOrdersTable'
            autoHeight
          />


          <Typography id='myOrdersHeading'>{user.firstName}'s orders </Typography>

        </div>



      )}


    </Fragment>
  )
}

export default DeliveryPage