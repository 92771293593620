import { configureStore } from "@reduxjs/toolkit";
import {
  getProductByTagReducer,
  productCreateReducer,
  productDeleteReducer,
  productDetailReducer,
  productReducer,
  productUpdateReducer,
  autoCompleteSearchProductReducer,
  searchDataForFiltersReducer,
} from "./reducers/productReducer";
import {
  allUserByAdminReducer,
  profileUpdateByUserReducer,
  userDetailByAdminReducer,
  passwordResetRequestByEmail,
  passwordResetByToken,
  userReducer,
  updateUserRoleAdminReducer,
} from "./reducers/userReducer";
import {
  saveShippingInfoReducer,
  addToCartReducers,
  removeFromCartReducers,
  allCartItemsByUserReducer,
} from "./reducers/cartReducer";

import {
  createComplainReducer,
  allComplainReducerByAdmin,
  singelComplainReducerByAdmin,
  updateComplainReducerByAdmin,
  deleteComplainReducerByAdmin,
} from "./reducers/complainReducer";

import {
  allOrderReducer,
  createOrderReducer,
  myOrderReducer,
  OrderDetailReducer,
  updateOrderReducer,
  cancelOrderReducer,
  createInvoiceByUserReducer,
  trackingOrderByUserReducer,
  returnOrderByUserReducer,
  singelReturnOrderByAdminReducer,
  allReturnOrderByUserReducer,
  allReturnOrderByAdminReducer,
  returnOrderStatusByAdminReducer,
  singelCancelOrderByAdminReducer,
  allCancelOrderByUserReducer,
  allCancelOrderByAdminReducer,
  cancelOrderStatusByAdminReducer,
  updateOrderStatusByAdminReducer,
} from "./reducers/orderReducer";
import { directBuyReducer } from "./reducers/directBuyReducer";
import { reviewCreateReducer } from "./reducers/reviewReducer";

import {
  allProductAdsDetailReducer,
  productAdsCreateReducer,
  productAdsDetailReducer,
  productAdsUpdateReducer,
} from "./reducers/productAdsReducer";

import {
  createCoupanReducer,
  editCoupanReducer,
  getAllCoupanReducer,
  singelCoupanReducer,
  deleteCoupanReducer,
  applyCoupanByUserReducer,
} from "./reducers/coupanReducer";

// wishllist reducer

import {
  addProductToWishListReducer,
  removeProductToWishListReducer,
  allProductToWishListReducer,
  allProductToWishListAdminReducer,
} from "./reducers/wishListReducer";

// keyword searched

import {
  searchKeywordReducer,
  allSearchKeywordReducer,
} from "./reducers/searchReducer";
import {
  deleteNotificationReducersByAdmin,
  getAllNotificationReducersByAdmin,
  getAllUserNotificationReducers,
  getSingelNotificationReducersByAdmin,
  readNotificationFunctionReducers,
  sendNotificationFunctionReducers,
  updateNotificationReducersByAdmin,
} from "./reducers/notificationReducers";
import {
  createFormReducers,
  deleteFormReducers,
  getAllFormReducers,
  getSingelFormReducers,
} from "./reducers/imFormReducer";

// save info reducers

import {
  allSaveInfoReducersByAdmin,
  deleteSaveInfoByAdmin,
  gettingSaveInfoReducers,
  saveAddressInfo,
  singelSaveInfoByAdminReducers,
} from "./reducers/saveAddressReducer";

// refund reducres
import {
  getAllsaveBankAccountDetailsReducers,
  getsaveBankAccountDetailsReducers,
  saveBankAccountDetailsReducers,
} from "./reducers/refundReducer";

// reducer
const store = configureStore({
  reducer: {
    categoryProduct: getProductByTagReducer,
    products: productReducer,
    autoCompleteSearch: autoCompleteSearchProductReducer,
    searchData: searchDataForFiltersReducer,
    productDetail: productDetailReducer,
    productUpdated: productUpdateReducer,
    newProductCreate: productCreateReducer,
    deleteProduct: productDeleteReducer,
    user: userReducer,
    profileUpdateByUser: profileUpdateByUserReducer,
    cart: addToCartReducers,
    removeCart: removeFromCartReducers,
    carts: allCartItemsByUserReducer,
    shippingInfo: saveShippingInfoReducer,
    newOrder: createOrderReducer,
    directBuy: directBuyReducer,
    myOrders: myOrderReducer,
    orderDetail: OrderDetailReducer,
    allOrders: allOrderReducer,
    updateOrder: updateOrderReducer,
    updateOrderByAdmin: updateOrderStatusByAdminReducer,
    orderCancel: cancelOrderReducer,
    allUsers: allUserByAdminReducer,
    userDetailAdmin: userDetailByAdminReducer,
    userUpdateByAdmin: updateUserRoleAdminReducer,
    reviewCreate: reviewCreateReducer,
    productAdsCreate: productAdsCreateReducer,
    productAdsUpdate: productAdsUpdateReducer,
    getProductAds: productAdsDetailReducer,
    getAllProductAds: allProductAdsDetailReducer,
    passwordResetByEmail: passwordResetRequestByEmail,
    passwordByToken: passwordResetByToken,
    coupanCreate: createCoupanReducer,
    coupanUpdate: editCoupanReducer,
    coupans: getAllCoupanReducer,
    coupan: singelCoupanReducer,
    coupanDelete: deleteCoupanReducer,
    coupanApply: applyCoupanByUserReducer,
    wishListAdd: addProductToWishListReducer,
    wishListRemove: removeProductToWishListReducer,
    wishListAll: allProductToWishListReducer,
    wishListAllAdmin: allProductToWishListAdminReducer,
    createInvoice: createInvoiceByUserReducer,
    trackingOrder: trackingOrderByUserReducer,
    returnOrder: returnOrderByUserReducer,
    singelReturnOrder: singelReturnOrderByAdminReducer,
    allReturnOrderByAdmin: allReturnOrderByAdminReducer,
    allReturnOrderByUser: allReturnOrderByUserReducer,
    returnStatusUpdateByAdmin: returnOrderStatusByAdminReducer,
    singelCancelOrder: singelCancelOrderByAdminReducer,
    allCancelOrderByUser: allCancelOrderByUserReducer,
    allCancelOrderByAdmin: allCancelOrderByAdminReducer,
    cancelOrderStatusUpdateByAdmin: cancelOrderStatusByAdminReducer,
    complainCreate: createComplainReducer,
    allComplain: allComplainReducerByAdmin,
    singelComplain: singelComplainReducerByAdmin,
    updateComplain: updateComplainReducerByAdmin,
    deleteComplain: deleteComplainReducerByAdmin,
    searchedKeywords: searchKeywordReducer,
    allSearchedKeyword: allSearchKeywordReducer,
    // notification
    userNotification: getAllUserNotificationReducers,
    readNotification: readNotificationFunctionReducers,
    sendNotification: sendNotificationFunctionReducers,
    allNotification: getAllNotificationReducersByAdmin,
    singelNotification: getSingelNotificationReducersByAdmin,
    updateNotification: updateNotificationReducersByAdmin,
    deleteNotification: deleteNotificationReducersByAdmin,

    // FORM DATA
    createForm: createFormReducers,
    getallForm: getAllFormReducers,
    singelForm: getSingelFormReducers,
    deleteForm: deleteFormReducers,

    //  save info
    saveInfo: saveAddressInfo,
    info: gettingSaveInfoReducers,

    // save info by admin reducers
    allInfo: allSaveInfoReducersByAdmin,
    singelInfo: singelSaveInfoByAdminReducers,
    deleteInfo: deleteSaveInfoByAdmin,

    // Saving bank account details
    saveAccount: saveBankAccountDetailsReducers,
    getSavingAccount: getsaveBankAccountDetailsReducers,
    allBankAccount: getAllsaveBankAccountDetailsReducers,
  },
});

// initial state
// let initialState = {
//   cart: {
//     cartItems: localStorage.getItem("cartItems")
//       ? JSON.parse(localStorage.getItem("cartItems"))
//       : [],
//     shippingInfo: localStorage.getItem("shippingInfo")
//       ? JSON.parse(localStorage.getItem("shippingInfo"))
//       : {},
//   },
// };



export default store;
