import React, { Fragment, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, Link } from 'react-router-dom'
import { clearErrorAction, OrderDetailAction, updateOrderAction, updateOrderStatusAction } from '../redux/actions/orderAction'
import { Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import { Button } from '@material-ui/core'
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import Sidebar from './Sidebar'
import Loader from '../layout/loader'
import { useAlert } from 'react-alert'
import swal from 'sweetalert';
import axios from 'axios'
import './editOrderByAdmin.css'
import { UPDATE_ORDER_REST, ORDER_UPDATE_STATUS_RESET } from '../redux/constants/orderConstants'

function EditOrderByAdmin(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const alert = useAlert();
    const { loading, order, error } = useSelector((state) => state.orderDetail)
    const { error: updateError, loading: updateLoading, isUpdated } = useSelector((state) => state.updateOrder)
    const { error: updateStatusError, loading: updateStatusLoading, isUpdated: isStatuesUpdated } = useSelector((state) => state.updateOrderByAdmin)

    const [status, setStatus] = useState('')

    let id = props.match.params.id;

    const orderEditSubmitHandler = async (e) => {
        e.preventDefault();
        if (!status) {
            return swal({
                title: "Validation Error",
                text: "Please Fill Status",
                icon: "info",
            })
        }

        dispatch(updateOrderAction(id, status, order.shippingInfo && order.shippingInfo.email));

    }

    // only for order status updation handler 
    const orderUpdateStatusSubmitHandler = async (e) => {
        e.preventDefault();
        if (!status) {
            return swal({
                title: "Validation Error",
                text: "Please Fill Status",
                icon: "info",
            })
        }

        dispatch(updateOrderStatusAction(id, status, order.shippingInfo && order.shippingInfo.email, order.shippingInfo && order.shippingInfo.name))

    }


    const orderConfirmationRequest = async (e) => {
        try {
            e.preventDefault();
            if (!status) {
                return swal({
                    title: "Validation Error",
                    text: "Please Fill Status",
                    icon: "info",
                })
            }
            alert.info('Please wait....!!')
            
            const { data } = await axios.put(`/api/admin/Order-confirmation-request/${id}`, { status })

            if (data.msg === 'Order Confirmation sent!!') {
                return swal({
                    title: "Request Sent !!",
                    text: data.msg,
                    icon: "success",
                })
            } else {
                return swal({
                    title: "Error",
                    text: 'Something Wrong !!',
                    icon: "error",
                })
            }

        } catch (error) {
            return swal({
                title: "Error",
                text: error,
                icon: "error",
            })
        }
    }


    useEffect(() => {
        if (error) {
            swal({
                title: "Error",
                text: error,
                icon: "error",
            })
            dispatch(clearErrorAction())
        }
        if (updateError) {
            swal({
                title: "Error",
                text: updateError,
                icon: "error",
            })
            dispatch(clearErrorAction())
        }

        if (updateStatusError) {
            swal({
                title: "Error",
                text: updateStatusError,
                icon: "error",
            })
            dispatch(clearErrorAction())
        }

        if (isStatuesUpdated) {
            swal({
                title: "Order Updated.",
                text: isStatuesUpdated,
                icon: "success",
            })
            dispatch({ type: ORDER_UPDATE_STATUS_RESET })
        }




        if (isUpdated) {
            swal({
                title: "Order Updated.",
                text: isUpdated,
                icon: "success",
            })
            dispatch({ type: UPDATE_ORDER_REST })
        }

        dispatch(OrderDetailAction(id))


    }, [id, dispatch, error, history, isUpdated, updateError, updateStatusError, isStatuesUpdated])


    return (
        <Fragment>
            {updateLoading ? <Loader /> : (
                <Fragment>
                    {loading ? <Loader /> : (
                        <Fragment>
                            <div className="dashboard-container">
                                <Sidebar />
                                <div className="edit-order-confirm-order-page">
                                    <div>
                                        <div className="edit-order-confirm-shipping-area">
                                            <Typography>Shipping Info       </Typography>
                                            <div className="edit-order-confirm-shipping-area-box">
                                                <div>
                                                    <p>User Id:</p>
                                                    <span>{order && order.user}</span>
                                                </div>
                                                <div>
                                                    <p>Name:</p>
                                                    <span>{order.shippingInfo && order.shippingInfo.name}</span>
                                                </div>
                                                <div>
                                                    <p>Email:</p>
                                                    <span>{order.shippingInfo && order.shippingInfo.email}</span>
                                                </div>
                                                <div>
                                                    <p>Phone:</p>
                                                    <span>{order.shippingInfo && order.shippingInfo.phoneNo}</span>
                                                </div>
                                                <div>
                                                    <p>Address:</p>
                                                    <span>{order.shippingInfo && order.shippingInfo.address},
                                                        {order.shippingInfo && order.shippingInfo.city},
                                                        {order.shippingInfo && order.shippingInfo.pincode},
                                                        {order.shippingInfo && order.shippingInfo.state}
                                                    </span>
                                                </div>

                                                <div>
                                                    <p>Ordered Date:</p>
                                                    <span>{order.createdAt}
                                                    </span>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="edit-order-confirm-shipping-area">
                                            <Typography>Payment Info       </Typography>
                                            <div className="edit-order-confirm-shipping-area-box">
                                                <div>
                                                    <p>Payment: </p>  <span><p className={order.paymentInfo && order.paymentInfo.status === 'Transaction Successfull' ? 'greenColor' : 'redColor'} >
                                                        {order.paymentInfo && order.paymentInfo.status === 'Transaction Successfull' ? 'Paid' : 'Un-Paid'}
                                                    </p></span>
                                                </div>
                                                <div>
                                                    <p>Total Amount:</p>
                                                    <span>{order.totalPrice && order.totalPrice}</span>
                                                </div>
                                                {order.coupanInfo && order.coupanInfo.coupanName === "null" ? (<Fragment>
                                                    <div>
                                                        <p>Coupon:</p>
                                                        <span>Not use</span>
                                                    </div>
                                                </Fragment>) : (<Fragment> <div>
                                                    <p>Coupon:</p>
                                                    <span>Name : {order.coupanInfo && order.coupanInfo.coupanName}</span>
                                                    <span>Value : {order.coupanInfo && order.coupanInfo.coupanValue}</span>
                                                </div></Fragment>)}

                                                <div>
                                                    <p>Item Price:</p>
                                                    <span>{order.itemPrice && order.itemPrice}</span>
                                                </div>
                                                <div>
                                                    <p>Tax charges:</p>
                                                    <span>{order.taxPrice && order.taxPrice}</span>
                                                </div>

                                                <div>
                                                    <p>Shipping charges:</p>
                                                    <span>{order.shippingPrice && order.shippingPrice}</span>
                                                </div>
                                                <div>
                                                    <p>Packing Details:</p>
                                                    <span>{order.shoesPackingDetails && order.shoesPackingDetails}</span>
                                                </div>

                                                <div>
                                                    <p>Advance Payment info:</p>
                                                    <span>id : {order.adavncePayment && order.adavncePayment.id}</span>
                                                    <span>Status : {order.adavncePayment && order.adavncePayment.status}</span>
                                                    <span>Advance Payment Percentage : {order.adavncePayment && order.adavncePayment.advanceAmountPercentage}</span>
                                                    <span>Amount : {order.adavncePayment && order.adavncePayment.amount}</span>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="edit-order-confirm-shipping-area">
                                            <Typography>Order Status       </Typography>
                                            <div className="edit-order-confirm-shipping-area-box">
                                                <div>
                                                    <p>Order:</p>
                                                    <span> <p className={order.orderStatus && order.orderStatus === 'Delivered' ? 'greenColor' : 'redColor'} >
                                                        <span>{order.orderStatus && order.orderStatus}</span>
                                                    </p></span>

                                                </div>
                                                <div>
                                                    <p>Order information:</p>
                                                    <span> <p>
                                                        {order.orderItems && order.orderItems.map((item, index) => (
                                                            <div key={index}>
                                                                <span>
                                                                    Name : {item.name}
                                                                </span> ,
                                                                <span>
                                                                    Colors : {item.colour}
                                                                </span> ,
                                                                <span>
                                                                    Sizes : {item.size}
                                                                </span> ,
                                                                <span>
                                                                    Quantity : {item.quantity}
                                                                </span> ,
                                                                <span>
                                                                    Price : {item.price}
                                                                </span>
                                                            </div>
                                                        ))}

                                                    </p></span>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="edit-order-confirm-cart-item">
                                            <Typography>Your Cart Items :  </Typography>
                                            <div className="edit-order-confirm-cartItems-container">
                                                {order.orderItems && order.orderItems.map((item) => (
                                                    <div key={item.product}  >
                                                        <img src={item.image} alt="imonline.shop" />
                                                        <Link to={`/ProductDetail/${item.product}`}>
                                                            {item.name}
                                                        </Link>

                                                        <span>
                                                            {item.quantity} <CloseIcon /> <span></span>{item.price} =
                                                            <b> <CurrencyRupeeIcon />{item.quantity * item.price}     </b>
                                                        </span>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    {order.orderStatus && order.orderStatus === 'Cancel' ? (null) : (<Fragment>

                                        {/*  ORDER STATUS UPDATE SECTION  */}
                                        <div style={{ display: order.orderStatus === 'Delivered' ? 'none' : 'block' }}>
                                            <div className="edit-order-order-summery">
                                                <Typography> Processing Order </Typography>
                                                {order.orderStatus === 'Confirmation-Request' ? (<Fragment>
                                                    <div >
                                                        <div className='order-update-form'>
                                                            <select onChange={(e) => setStatus(e.target.value)} >
                                                                <option>Choose Status</option>
                                                                {order.orderStatus === 'Confirmation-Request' && (
                                                                    <>
                                                                        <option value='Confirm'>Confirm</option>
                                                                        <option value='Cancel'>Cancel</option>
                                                                        <option value='No Response'>No Response</option>
                                                                    </>
                                                                )}
                                                            </select>
                                                        </div>
                                                        <Button
                                                            id='createProductBtn'
                                                            onClick={orderEditSubmitHandler}
                                                            disabled={loading ? true : false}
                                                        >
                                                            Status Confirm
                                                        </Button>
                                                    </div>

                                                </Fragment>) :


                                                    (<Fragment>
                                                        <div className='order-update-form'>
                                                            <select onChange={(e) => setStatus(e.target.value)} >
                                                                <option >Choose Status</option>
                                                                {order.orderStatus === 'Confirm' && (
                                                                    <>
                                                                        <option value='Shipped'>Shipped</option>
                                                                    </>
                                                                )}

                                                                {order.orderStatus === 'Shipped' && (
                                                                    <>
                                                                        <option value='Delivered'>Delivered</option>
                                                                        <option value='Un-Delivered'>Un-Delivered</option>
                                                                        <option value='Returned'>Returned</option>
                                                                    </>
                                                                )}
                                                            </select>
                                                            <div>
                                                            </div>
                                                        </div>
                                                        <Button
                                                            id='createProductBtn'
                                                            onClick={orderUpdateStatusSubmitHandler}
                                                            disabled={updateStatusLoading ? true : false}
                                                        >
                                                            Status Confirm
                                                        </Button>

                                                    </Fragment>)}

                                                {order.orderStatus === 'Processing' ? (
                                                    <>
                                                        <div className='order-update-form'>
                                                            <div>
                                                            </div>
                                                            <select onChange={(e) => setStatus(e.target.value)} >
                                                                <option>Choose Status</option>
                                                                {order.orderStatus === 'Processing' && (
                                                                    <>
                                                                        <option value='Confirmation-Request'>Confirmation Request</option>
                                                                        <option value='Cancel'>Cancel</option>
                                                                    </>
                                                                )}
                                                            </select>
                                                            <div>
                                                            </div>
                                                            <Button
                                                                id='createProductBtn'
                                                                onClick={orderConfirmationRequest}
                                                            >
                                                                Confirm Request
                                                            </Button>

                                                        </div>
                                                    </>
                                                ) : (null)}

                                            </div>
                                        </div>
                                    </Fragment>)}
                                </div>
                            </div>
                        </Fragment>
                    )}

                </Fragment>
            )}

        </Fragment>

    )
}

export default EditOrderByAdmin