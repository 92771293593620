import React, { Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { useHistory } from "react-router-dom";
import {
    clearErrorAction,
    adminAdsRequestAction,
} from "../../../redux/actions/productAdsActions";
// import { Carousel } from 'react-bootstrap';
import swal from "sweetalert";
import LatestProductCard from "./LatestProductCard";
import TopProductCatogery from "./TopProductCatogery";
import HighlighPage from "./HighlighPage";
// import bannerImage1 from "../../../images/IMG_1283.png";
// import Slider from "react-slick";
// css
import "../../../css/overviewproducts.css";

function LatestProducts() {
    const dispatch = useDispatch();
    // const history = useHistory();

    const { loading, error, productDetails } = useSelector(
        (state) => state.getAllProductAds
    );
    const { isAuthenticated } = useSelector((state) => state.user);
    const { wishListProducts } = useSelector((state) => state.wishListAll);

    useEffect(() => {
        if (error) {
            swal({
                title: "Validation Error",
                text: error,
                icon: "error",
            });
            dispatch(clearErrorAction());
        }

        dispatch(adminAdsRequestAction());
    }, [dispatch, error]);

    // const settings = {
    //     dots: false,
    //     infinite: true,
    //     autoplay: true,
    //     speed: 5000,
    //     autoplaySpeed: 5000,
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    // };

    // const offerSubmitHandler = () => {
    //     if (!isAuthenticated) {
    //         return swal({
    //             title: "Account Not Created..!",
    //             text: "Please Create an Account Then Click here... !!",
    //             icon: "info",
    //             button: 'OK'
    //         });
    //     }
    //     history.push("/Login?redirect=user/fill/form/data");
    // };

    return (
        <Fragment>
            {/* <div className="banner-section">
                <Slider {...settings}>
                    <div className="banner-image">
                        <div className="container">
                            <div className="row">
                                <div className="col-6">
                                    <div className="banner-left-side-image-container">
                                        <img src={bannerImage1} alt="banner-area-image2" />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="banner-right-side-main-container">
                                        <h2>
                                            special offer from
                                            <span className="name-in-banner-style">
                                                im online shopping co.
                                            </span>
                                        </h2>
                                        <h4>
                                            create account with us and get one silde free off cost.
                                        </h4>
                                        <p> **this offer is only valid for new users** </p>
                                        <button onClick={offerSubmitHandler}> get now </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Slider>
            </div> */}

            <div className="our-catogery-highlight-main-container">
                <div className="products-details-heading-div">
                    <h4 className="product-details-heading">our categories</h4>
                </div>
                <div className="top-product-catogery-section-div">
                    <TopProductCatogery />
                </div>
                <div className="latest-product-div">
                    <div className="top-categories-heading">
                        <h1> Today Highlight's </h1>
                    </div>
                    <LatestProductCard
                        productDetails={productDetails}
                        loading={loading}
                    />
                </div>
            </div>
            {isAuthenticated === true ? (
                <Fragment>
                    {wishListProducts && wishListProducts.length < 10 ? null : (
                        <Fragment>
                            <div className="heading-for-wishlist-products-container">
                                <p>Buy Now Your Favorite Items</p>
                            </div>
                            <div className="highligh-container-div">
                                <HighlighPage />
                            </div>
                        </Fragment>
                    )}
                </Fragment>
            ) : null}
        </Fragment>
    );
}

export default LatestProducts;
