import React, { Fragment } from 'react'

import Navbar from '../../HomeScreen/Navbar';

import Footer from '../../HomeScreen/bottomsection/Footer';
import MetaTag from '../../../layout/MetaTag';

import './myAccountPrivilage.css'


function BrandProfile() {
    return (
        <Fragment>
            <MetaTag title={'IM Online Shopping Brand Profile'} />
            <Navbar />
            <div className="brand-profile-div">
                <div className="brand-profile-heading-div">
                    <h5>BRAND PROFILE</h5>
                </div>
                <ul className="brand-profile-div-ul">
                    <li>
                        IM was founded with a vision to empower man all over
                        india to express themselves freely through fashion. The brand pushes the
                        boundaries of modern footwear
                        and accessories by constantly reinventing fashion with its curated collections.
                    </li>
                    <li>
                        Since its establishment in 2021, the brand has expanded its reach beyond brick-and-mortar
                        stores by offering online shopping on its website IMONLINE.SHOP. Its stylish
                        and trend-focused designs are for now available in india.
                    </li>
                </ul>

                <ul>
                    <h4> Our Product Quality</h4>
                    <p>
                        Shoes that you see on our website whether branded or non-branded.
                        We sell shoes, not for the sole purpose of money or profits or any kind of intention.
                        because we believe in the first trust over the Product which is why we are here.
                        We mainly deal in four types of quality shoes for mans
                    </p>
                    <li>Branded or Original</li>
                    <li>First Copy or Second Copy both are same.</li>
                    <li>Imported Shoes</li>
                    <li>Indian Shoes</li>

                </ul>
                <ul className='branded-original-ul-tag'>
                    <p>BRANDED OR ORIGINAL</p>
                    <li>
                        Branded or Original Shoes are the company's original shoes with a bar code with the SKU name and
                        with the original box of the company which comes with the shoe including
                        all accessories that belong to the shoe. however, in any case, we sell you
                        duplicate shoes with the name of the original shoe.
                        you can file the case under (Section 420, 468 of IPC) for selling duplicate.
                        whatever the quality is we will tell you first then we offer you to buy our Product.
                        @For example: Nike Shoe, we tell you in the product description that what kind of product it is.
                    </li>
                </ul>
                <ul className='first-copy-second-copy-ul-tag'>
                    <p >FIRST COPY OR SECOND COPY</p>
                    <li>
                        The first and second copies are both the same quality because it is a copy of the original shoe that's
                        why the price is going down up to 80 to 90 percent as compared to the original shoe price.
                        on the other hand, the original shoe is much more expansive but the first copy is the same as is original with
                        no different accept price and tag on shoe inside the shoe because the first copy is
                        made in Vietnam and imported from another country, however, why we tell you because
                        we do not want that our customers fill the pain that other shop keepers give you that
                        why we are here only to serve our customers.
                        for @example:
                    </li>
                    <div className="container">
                        <div className="row   shoes-container-of-original-firstcopy">
                            <div className="col-6  ">
                                <b>  Air Jordan 4 Alternate Original </b>
                                <img className='container-original-shoes-img' src={'https://res.cloudinary.com/im-online-shop/image/upload/v1671530022/ads%20images/13157810_14625120_1000_vafvkn.jpg'} alt="www.imonline.shop" />
                            </div>
                            <div className="col-6">
                                <b>  Air Jordan 4 Alternate First Copy </b>
                                <img className='firstcopy-original-shoes-img' src={'https://res.cloudinary.com/im-online-shop/image/upload/v1671531074/ads%20images/video-www_instagram_com-162271422653861_prev_ui_hzlxw4.png'} alt="www.imonline.shop" />
                            </div>
                        </div>
                    </div>

                </ul>
                <ul className='first-copy-second-copy-ul-tag' >
                    <p >IMPORTED SHOE</p>
                    <li>
                        These kinds of Shoe are IMPORTED from other countries like China, Thailand, Malaysia, Indonesia, Dubai, and others Countries. This kind of shoe comes in a vast variety of design and color, they mostly copy the original shoe with a minor change, for example, making copies of one original shoe with sool and laces and their brand shoe Style Logo always change they are comparable far less expensive then Original shoes and First-copy shoes, Last but not least is we are here to serve you as best as we can with price or quality everything.

                    </li>
                </ul>
                <ul className='first-copy-second-copy-ul-tag'>
                    <p >INDIAN SHOES</p>
                    <li>
                    Indian shoes are made in India. so they are less expensive than other popular brands. They are an unknown local brand so start from  ruppe 400 and go up to ruppe 1000.
                    </li>

                </ul>



            </div>
            <Footer />
        </Fragment>
    )
}

export default BrandProfile