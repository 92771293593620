import React from 'react'
import { Link } from "react-router-dom"

import img1 from "../../../images/download_mastercard2-removebg-preview.png";
import img2 from "../../../images/download_netbanking-removebg-preview.png";
import img3 from "../../../images/download_paytm-removebg-preview.png";
import img4 from "../../../images/PhonePe-Logo.wine-removebg-preview.png";
import img5 from "../../../images/download_rupay-removebg-preview.png";
import img6 from "../../../images/download_visa-removebg-preview.png";
import img7 from "../../../images/google-pay-logo-400W-200x200-removebg-preview.png";
import img8 from "../../../images/Cash-on-Delivery033_large-1-removebg-preview.png";
import img9 from "../../../images/360-3606562_bhim-logo-bhim-upi-removebg-preview.png";
import "./footer.css"

function Footer() {
    return (
     <div>
       <div className="container footer-container">
          <div className="row">
             <div className="col-md-3  footer-first-colum">
                <h4 className="first-row-heading"> PAYMENT GATEWAYS </h4>
                <div className="row">
                  <div className="col-md-4  ">
                <div className="images-of-payments">
                <div className="img-one">
                  <img src={img1} alt="www.im.com" />
                </div>
                <div className="img-two">
                  <img src={img2} alt="www.im.com" />
                </div>
                <div className="img-three">
                  <img src={img3} alt="www.im.com" />
                </div>
                </div>
                <div className="col-md-4 payments-images-col-row-2 ">
              
                <div className="img-five">
                  <img src={img5} alt="www.im.com" />
                </div>
                <div className="img-six">
                  <img src={img6} alt="www.im.com" />
                </div>
                <div className="img-four">
                  <img src={img4} alt="www.im.com" />
                </div>
                </div>
                <div className="col-md-4  payments-images-col-row-2">
                <div className="img-seven">
                  <img src={img7} alt="www.im.com" />
                </div>
                <div className="img-eight">
                  <img src={img8} alt="www.im.com" />
                </div>
                <div className="img-nine">
                  <img src={img9} alt="www.im.com" />
                </div>
                </div>
               </div>
                </div>
             </div>
             <div className="col-md-3  second-row-menu-section">
                 <h3 className="second-row-menu-heading">SHOPPING WITH US  </h3>
                  <ul>
                     <Link to="/userprofile" >  <li>My Account</li></Link>
                     <Link to="/about/Shipping-Tracking" >  <li>Shipping & Tracking</li></Link>
                     <Link to="/about/return-and-exchange"  > <li>Returns & Exchanges</li></Link>
                     <Link to="/promotions"  >  <li>Promotions</li></Link>
                     <Link to="/about/Product-And-Pricing"  >  <li>Size Guide</li></Link>
                     <Link to="/about/GiftBox"  >  <li>Gift Boxes</li></Link>
                 </ul>
               </div>
             <div className="col-md-3 third-row-account-section">
            <h3 className="third-row-account-heading">  CUSTOMER CARE  </h3>
            <ul>
            <Link to={'/terms-and-conditions'}> <li>Order Status</li></Link>
            <Link to={'/how-to-shop'}> <li> FAQ </li></Link>
            <Link to={'/terms-and-conditions'}>   <li> Contact Us </li></Link>
            <Link to={'/user/complain/request'}>  <li>Get in Touch </li></Link>
            </ul>
                
             </div>

            <div className="col-md-3  fourth-row-products-section">
               <h3  className="fourth-row-product-heading"> ABOUT US  </h3>
               <ul>
               <Link to="/terms-and-conditions"> <li> Sustainability   </li></Link>
               <Link to="/about/BrandedProfile"> <li>Brand Profile</li></Link>
               <Link to="/terms-and-conditions"><li>Terms of Use</li></Link>
               <Link to="/Cookies-Policy"> <li>Privacy Policy</li></Link>
               <Link to="/Cookies-Policy" > <li>Cookies Policy</li></Link>
                </ul>
            </div>
         </div>
      </div>
      <footer  className="footer-part">
      <div className="container  copy-right-section">
        <div className="row">
          <div className="col-md-12">
          <div class="bottom-copyright-part">
     <p class="rights"><span>©  </span><span class="copyright-year">2021 to 2023</span><span> </span><span>www.imonline.shop</span><span></span><span> All CopyRight Reserved.</span></p>
     </div>
          </div>
        </div>
      </div>
      </footer>  
      
    </div>
    )
}

export default Footer
