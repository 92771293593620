import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import './wishList.css'
function AuthenticatedPage() {
    return (
        <Fragment>
            <div className="authecation-div">
                <div className="text-of-authecation-div">
                    <h2>WISHLIST</h2>
                    <h6>Looking for wishlist? Sign in to pick up where you left off</h6>
                </div>
                <div className="button-of-authentication">
                    <Link to={'/Login'}  >  <button>Login</button></Link>
                </div>
            </div>

        </Fragment>
    )
}

export default AuthenticatedPage