import React, { Fragment, useState } from 'react'
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import swal from 'sweetalert';
import MetaTag from '../layout/MetaTag';
import "./sports.css";
import { addEllipsis } from '../utils/textRapUtils'
import ReactStars from "react-rating-stars-component";
// icons 
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
// import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import CloseIcon from '@mui/icons-material/Close';

// import Logo1 from '../images/logo1.png'
import Logo2 from '../images/not_found_product.png'

// redux costant

// import { ADD_PRODUCT_REQUEST_RESET } from '../redux/constants/wishListConstant'

// import { addProductToWishListAction, clearErrorAction } from '../redux/actions/wishListAction'
import { directBuyAction } from '../redux/actions/directBuyAction'


function Sportspage({ currentItems }) {
   const history = useHistory();
   const dispatch = useDispatch();
   // const { error: wishListError, loading: wishListLoading, isProductAdd } = useSelector(state => state.wishListAdd)

   const { isAuthenticated } = useSelector(state => state.user)

   const [currentImage, setCurrentImage] = useState('')
   const [productId, setProductId] = useState('')

   // buy now 
   const [buyNowButtonClick, setBuyNowButtonClick] = useState(false)
   const [seletedProductId, setSelectedProductId] = useState()
   const [selectBox, setSelectBox] = useState('')
   const [colour, setColour] = useState('')
   const [size, setSize] = useState('')


   // useEffect(() => {
      // if (wishListError) {
      //    swal({
      //       title: "Error",
      //       text: wishListError,
      //       icon: "error",
      //    })
      //    dispatch(clearErrorAction())
      // }
      // if (wishListLoading) {
      //    swal("Please Wait....", {
      //       button: false,
      //    });
      // }
      // if (isProductAdd) {
      //    swal({
      //       title: "Success",
      //       text: "Added to Wishlist.",
      //       icon: "success",
      //    })
      //    dispatch({ type: ADD_PRODUCT_REQUEST_RESET })
      // }


   // }, [])

   // const addToWishListHandler = (id) => {
   //    if (!isAuthenticated) {
   //       return swal({
   //          title: "Validation Error",
   //          text: 'Please Login..!!',
   //          icon: "info",
   //       })

   //    }
   //    dispatch(addProductToWishListAction(id))
   // }


   const productHandler = (id) => {
      history.push(`/ProductDetail/${id}`);
   }

   const imageSelectHandler = (productUrl, productId) => {
      setCurrentImage(productUrl)
      setProductId(productId)
   }



   const buyNowHandler = (id) => {
      if (!id) {
         return swal({
            title: "Validation Info",
            text: 'Invalid..!!',
            icon: "info",
         })
      }

      setSelectedProductId(id)
      setBuyNowButtonClick(true)
   }


   // buy button handler

   const bestSellerBuyNowHanlder = (e) => {
      e.preventDefault();

      if (!size || !colour) {
         return swal({
            title: "Validation Error",
            text: 'Please Select Colour and Size..!!',
            icon: "info",
         })

      }

      if (!selectBox) {
         return swal({
            title: "Validation Error",
            text: 'Please Select Box !!',
            icon: "info",
         })
      }
      // by defalut it will be one
      const quantity = 1;

      dispatch(directBuyAction(seletedProductId, quantity, size, colour, selectBox));


      if (!isAuthenticated) {
         swal({
            title: "Validation Error",
            text: 'Please Login..!!',
            icon: "info",
         })
      }

      history.push('/Login?redirect=/Checkout/directbuy')
   }

   //    button close function

   const closeBestSellerBuyNowButton = () => {
      setBuyNowButtonClick(false)
      setSelectedProductId('')
   }




   return (
      <Fragment>
         {currentItems.length === 0 ? (<Fragment>
            <MetaTag title={'Sorry We Didnt found any Shoes !!'} />
            <div className="not-found-container">
               <img src={Logo2} alt="www.imonline.shop" />
               <h4>Sorry we did'nt found !!</h4>
            </div>
         </Fragment>) : (
            <Fragment>
               {currentItems && currentItems.map((product, index) => (
                  <div className="Sports-prodcuts-div" key={index}>
                     <div className="Sports-images-div">
                        {currentImage === '' ? (<img src={product.mainImage[0].imageurl} onClick={() => productHandler(product._id)} alt={product.name} />) : (product._id === productId ? (<img src={currentImage} onClick={() => productHandler(product._id)} alt={product.name} />) : (<img src={product.mainImage[0].imageurl} onClick={() => productHandler(product._id)} alt={product.name} />))}
                     </div>
                     <div className='sports-product-slide-div'   >
                        {product.image.map((newImage, index) => (
                           <img src={newImage.imageurl} onClick={() => imageSelectHandler(newImage.imageurl, product._id)} alt={product.name} key={index} />
                        ))}
                     </div>
                     {
                        product.discountActive === true ? (
                           <>
                              <p className="sports-product-discount">{Math.round((product.discountPrice) / (product.price + product.discountPrice) * 100)} % off </p>
                           </>
                        ) : (
                           null
                        )
                     }
                     <div className="Sports-Product-title">
                        <h6><Link to={`/ProductDetail/${product._id}`}   >  {addEllipsis(product.name)} </Link>  </h6> <hr />
                     </div>

              
                     <div className="sports-product-main-price-container">
                        {product.discountActive === true ? (<>
                           <div className="Sports-product-price">
                              <p ><s className='sports-product-price-cross'> price : <span> {product.price} </span> <span><CurrencyRupeeIcon /></span>  </s> </p>    <p className='sports-product-price-after-discount'>  price : <span> {(product.price) - (product.discountPrice)} </span> <span><CurrencyRupeeIcon /></span>  </p>
                           </div>

                        </>) : (<>
                           <div className="Sports-product-price">
                              <p> price : <span> {product.price} </span><span><CurrencyRupeeIcon /></span>  </p>
                           </div>
                        </>)}
                     </div>

                     <div className="bestSeller-Star">
                        <p>
                           <ReactStars
                              padding='5px'
                              edit={false}
                              color='gray'
                              activeColor='#ffd700'
                              size={window.innerWidth < 600 ? 25 : 30}
                              value={product.ratings}
                              isHalf={true}
                           />
                        </p>
                     </div>
                     <div className="Sports-product-btns">
                        {/* <button className='addtowishbutttonstyle' onClick={() => addToWishListHandler(product._id)}><FavoriteBorderIcon /></button> */}
                        <button onClick={() => buyNowHandler(product._id)} className="Sports-product-buy-btn"><ShoppingCartIcon /></button>
                     </div>

                     {buyNowButtonClick !== false && seletedProductId === product._id ? (
                        <>
                           <div className="buy-now-main-container">
                              <div className="buy-now-second-conatiner-close-div">
                                 <button onClick={closeBestSellerBuyNowButton}> <CloseIcon /> </button>
                              </div>
                              <div className="buy-now-second-container">
                                 <div className="buy-now-third-container">
                                    <h2 className="heading-for-buy-now">
                                       Select Options
                                    </h2>
                                 </div>
                                 <form onSubmit={bestSellerBuyNowHanlder}>
                                    { /**SIZE BUTTON */}
                                    <div className="buy-now-size-detail-div">
                                       <span><label className="">Sizes</label>
                                          <select onChange={(e) => setSize(e.target.value)}>
                                             <option value=''>Select Size</option>
                                             {product.sizeOfShoes && product.sizeOfShoes.map((sizes, index) => (
                                                <option value={sizes} key={index} >{sizes}</option>
                                             ))}
                                          </select>
                                       </span>
                                    </div>
                                    {/**COLOUR BUTTON */}
                                    <div className="buy-now-colour-detail-div" >
                                       <span><label className="">Colours </label>
                                          <select onChange={(e) => setColour(e.target.value)} >
                                             <option value=''>Select Colour</option>
                                             {product.colorOfShoes && product.colorOfShoes.map((catagery, index) => (
                                                <option value={catagery} key={index} >{catagery}</option>
                                             ))}
                                          </select>
                                       </span>

                                    </div>


                                    {/**Box BUTTON */}
                                    <div className="buy-now-colour-detail-div" >
                                       <span><label className="">Box Type </label>
                                          <select onChange={(e) => setSelectBox(e.target.value)} >
                                             <option value=''>Select Packaging Type</option>
                                             <option value='0'> Default-Packing</option>
                                             <option value='50'>Simple-Box  50₹</option>
                                             <option value='150' >Original-Box  150₹</option>
                                          </select>
                                       </span>
                                    </div>
                                    <div className="buy-now-container-btn-div">
                                       <input type='submit' value="Buy Now" />
                                    </div>

                                 </form>
                              </div>

                           </div>
                        </>
                     ) : (null)}




                  </div>

                  // buy button






               ))}
            </Fragment>
         )}
      </Fragment>
   )
}

export default Sportspage
