import React, { Fragment } from 'react'

import { Link } from 'react-router-dom'

import Navbar from '../../HomeScreen/Navbar';
import MetaTag from '../../../layout/MetaTag';
import Footer from '../../HomeScreen/bottomsection/Footer';
import {Helmet} from 'react-helmet'

import SideBarAccount from './SideBarAccount'
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import InventoryIcon from '@mui/icons-material/Inventory';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import LocalPostOfficeIcon from '@mui/icons-material/LocalPostOffice';

import NearMeIcon from '@mui/icons-material/NearMe';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';





function ReturnAndExchange() {
    return (
        <Fragment>
            <Helmet  >
                <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=2.0" />
                <meta name="description" content={`   How do I make a return? || How do I return an order that was purchased online? ||   Our return policy is only applicable to any item(s)  purchased online and delivered in India, within 30 days of receiving your order.    ||     IMONLINE.SHOP (RETURNS DEPT)||   IM Group Headquarters  Chhanna Rd,Dehliz Kalan,Ahmedgarh  || Punjab 148021,INDIA.`} />
                <meta name="keywords" content={`RETURNS & EXCHANGES ||   USING PHYSICAL RETURN & EXCHANGE FORM ||  When will I receive my refund? ||   * Please note that your refund will take up to 21 working days if you have made your purchase during our online sales period.   ||     What are the terms and conditions for promotions? ||     How do I make an exchange? ||  Please note that an exchange is subject to stock availability and we reserve the right to do an exchange by way of an item(s) of a similar value, giving you a refund via the original payment method, or any other methods at the discretion of IMONLINE.SHOP. `} />
                <meta name="robots" content="index, follow " />
                <meta property="og:title" content={` RETURNS & EXCHANGES || What promotions does IM run? ||  Can I use more than one promo code on my order? ||  My promo code is not working, what should I do?    ||     What are the terms and conditions for promotions? ||   What happens if the promotion I’m interested in has expired? || `} />
                <meta property="og:description" content={`  What promotions does IM run? ||  Can I use more than one promo code on my order? ||  My promo code is not working, what should I do?    ||     What are the terms and conditions for promotions? ||   What happens if the promotion I’m interested in has expired? || `} />
                <meta property="og:type" content="website" />
                {/* <meta property="og:url" content={items.imageurl}   key={items.public_id}/>
            <meta property="og:image" content={items.imageurl}  key={items.public_id}/> */}
                <meta property="og:locale" content="en-GB_IN" />
                <meta property="og:site_name" content="IMONLINE.SHOP" />
                {/* <meta property="og:price:currency" content="INR" />
            <meta property="og:price:amount" content={product.price} /> */}
            </Helmet>

            <MetaTag title={'IM Online Shopping Return And Exchange Realted Rules And Terms Details Page'} />
            <Navbar />
            <div className="container">
                <div className="row">
                    <div className="col-md-3">
                        <SideBarAccount />
                    </div>
                    <div className="col-md-9">
                        <div className="right-side-paragraph">
                            <div className="h2-heading">
                                <h2> RETURNS & EXCHANGES </h2>
                            </div>
                            <div className="details-of-account-privileges">

                                <ul>
                                    How do I make a return?
                                    <li>
                                        <ul>
                                            Our return policy is only applicable to any item(s)
                                            purchased online and delivered in India, within 30 days of receiving your order.
                                            <li>
                                                Please make sure the item(s) is in original condition, with the original packaging, original tags, and tickets attached
                                            </li>
                                            <li>
                                                Returns are free if the product you received is wrong or defective, otherwise shipping charges will be borne by you.
                                            </li>
                                            <li>
                                                In the interest of hygiene, we do not accept returns or refunds on earrings unless they are defective or the wrong item was sent
                                            </li>
                                            <li>
                                                A final inspection will be done to ensure all items are in their original condition before processing your returns
                                            </li>
                                            <li>
                                                Returns that do not meet our policy will not be accepted and will be returned to you at your cost
                                            </li>
                                            <li>
                                                If you are returning an item from an order that came with a free Gift With Purchase (GWP)
                                                and the value of the order falls below the GWP qualifying amount after the return,
                                                the redeemed GWP must be returned as well.
                                                If the GWP is not returned, you will incur charges based on the market value of the GWP
                                            </li>
                                        </ul>

                                    </li>
                                </ul>

                                <ul>
                                    How do I return an order that was purchased online?
                                    <li>
                                        Our return policy is only applicable to any item(s)
                                        purchased online and delivered in India, within 30 days of receiving your order.
                                    </li>
                                    <li>
                                        <p>
                                            IMONLINE.SHOP (RETURNS DEPT)
                                            IM Group Headquarters
                                            Chhanna Rd,Dehliz Kalan,Ahmedgarh
                                            Punjab 148021,INDIA.
                                        </p>
                                    </li>
                                    <li>
                                        We recommend you opt for a shipment method that enables tracking.
                                        IM will not be responsible for the loss of a returned parcel.
                                    </li>
                                    <li>
                                        If your item(s) is defective or wrong, email us at information@imonline.shop with the subject title:
                                        Return Request – [Your Order Number]. Our customer service team will assist you with the return process.
                                    </li>
                                    <ul>
                                        <div className="return-physcial-exhange-div">


                                            <h3 className='option-for-return'>
                                                OPTION 1
                                            </h3>
                                            <div className='using-physcial-return-heading'>
                                                <h6 >USING PHYSICAL RETURN & EXCHANGE FORM</h6>
                                                <p>
                                                    Shipping charges will be borne by you
                                                </p>
                                            </div>
                                            <div className="div-of-detail-to-return">
                                                <p className="div-of-detail-to-return-para">Complete the physical Return & Exchange form that came
                                                    with your parcel and return it together with the parcel.</p>
                                                <div className="first-div-section">

                                                    <div className="first-div">
                                                        <div className="icon-div">
                                                            <NoteAltIcon />
                                                        </div>
                                                        <div className="paragraph">
                                                            <h6>STEP 1</h6>
                                                            <p>
                                                                Log in to IMONLINE.SHOP and view your order. Under My Orders, select the item(s) you wish to return/exchange and state the reason.
                                                                You will receive an email within 1 to 2 days on your request status and given further instructions.
                                                                Retrieve the Return & Exchange form from the Order Status page if you did not receive it.
                                                            </p>
                                                        </div>

                                                    </div>

                                                    <div className="second-div">
                                                        <div className="icon-div">
                                                            <InventoryIcon />
                                                        </div>
                                                        <div className="paragraph">
                                                            <h6>STEP 2</h6>
                                                            <p>
                                                                Pack the item(s) and the Return & Exchange form into the original
                                                                packaging and paste the Return Address sticker (provided in the Returns & Exchanges guide) onto your parcel.
                                                            </p>
                                                        </div>

                                                    </div>
                                                    <div className="third-div">
                                                        <div className="icon-div">
                                                            <LocalShippingIcon />
                                                        </div>
                                                        <div className="paragraph">
                                                            <h6>STEP 3</h6>
                                                            <p>
                                                                Post the item(s) to us via your preferred shipment method.
                                                            </p>
                                                        </div>

                                                    </div>

                                                    <div className="fourth-div">
                                                        <div className="icon-div">
                                                            <LocalPostOfficeIcon />
                                                        </div>
                                                        <div className="paragraph">
                                                            <h6>STEP 4</h6>
                                                            <p>
                                                                You will be notified via email once your parcel reaches us.
                                                                Further instructions will be provided in the email.
                                                            </p>
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </ul>

                                    <ul>
                                        <div className="return-physcial-exhange-div">


                                            <h3 className='option-for-return'>
                                                OPTION 2
                                            </h3>
                                            <div className='using-physcial-return-heading'>
                                                <h6 >USING ONLINE RETURN & EXCHANGE FORM</h6>
                                                <p>
                                                    Shipping charges will be borne by you
                                                </p>
                                            </div>
                                            <div className="div-of-detail-to-return">
                                                <p className="div-of-detail-to-return-para">Return your item via your IM account.</p>
                                                <div className="first-div-section">

                                                    <div className="first-div">
                                                        <div className="icon-div">
                                                            <NearMeIcon />
                                                        </div>
                                                        <div className="paragraph">
                                                            <h6>STEP 1</h6>
                                                            <p>
                                                                Log in to IMONLINE.SHOP and view your order. Under My Purchases and Returns, select the item(s) you wish to return/exchange and state the reason.
                                                                You will receive an email within 1 to 2 days on your request status and given further instructions.
                                                            </p>
                                                        </div>

                                                    </div>

                                                    <div className="second-div">
                                                        <div className="icon-div">
                                                            <AlternateEmailIcon />
                                                        </div>
                                                        <div className="paragraph">
                                                            <h6>STEP 2</h6>
                                                            <p>
                                                                Once your return has been authorised, pack the item(s)
                                                                into the original packaging and paste the Return Address sticker
                                                                (provided in the Returns & Exchange guide) onto your parcel.
                                                            </p>
                                                        </div>

                                                    </div>
                                                    <div className="third-div">
                                                        <div className="icon-div">
                                                            <InventoryIcon />
                                                        </div>
                                                        <div className="paragraph">
                                                            <h6>STEP 3</h6>
                                                            <p>
                                                                Post the item(s) to us with your Return number via your preferred shipment method.
                                                            </p>
                                                        </div>

                                                    </div>

                                                    <div className="fourth-div">
                                                        <div className="icon-div">
                                                            <LocalPostOfficeIcon />
                                                        </div>
                                                        <div className="paragraph">
                                                            <h6>STEP 4</h6>
                                                            <p>
                                                                You will be notified via email once your parcel reaches us. Further instructions will be provided in the email.
                                                            </p>
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </ul>
                                    <ul>
                                        <div className="return-physcial-exhange-div">


                                            <h3 className='option-for-return'>
                                                OPTION 3
                                            </h3>
                                            <div className='using-physcial-return-heading'>
                                                <h6 >SEND US AN EMAIL</h6>
                                            </div>
                                            <div className="send-us-email-option-return">
                                                If you have checked out as a guest, kindly contact us at information@imonline.shop with the email subject:
                                                ’Return Request – Your Order Number’, to process your returns or exchanges.
                                                Our customer service team will assist you with the return process.

                                            </div>
                                        </div>
                                    </ul>

                                </ul>
                                <ul>
                                    How do I check on my return status?
                                    <li>
                                        To check on your return status,<Link to={'/Signin'}> <span>sign in</span></Link>  to your account, head to My Account and select My Purchases & Returns.
                                        Your return status is reflected next to your Returns Request number.

                                        <table className='status-cheack-return-table'  >
                                            <tr>
                                                <td>Created</td>
                                                <td>Your returns request has been received</td>
                                            </tr>
                                            <tr>
                                                <td>Pending</td>
                                                <td>Your returned item(s) are currently being processed</td>
                                            </tr>
                                            <tr>
                                                <td>Completed</td>
                                                <td>You have received a refund for your returns request</td>
                                            </tr>
                                            <tr>
                                                <td>Rejected</td>
                                                <td>Your returns request has been denied</td>
                                            </tr>
                                        </table>


                                    </li>
                                </ul>

                                <ul>
                                    When will I receive my refund?

                                    <li>
                                        Once we receive your return item(s), it will go through a quality check to ensure
                                        it’s in good condition and complies with our refund policy. This can take up to 2 working days.
                                    </li>

                                    <li>
                                        After the return has been approved, it takes up to 14 working days for the refund to be made to your payment services provider.
                                        Once we have processed your refund, you will receive a confirmation email from us.
                                    </li>
                                    <li style={{ color: 'red' }}>
                                        * Please note that your refund will take up to 21 working days if you have made your purchase during our online sales period.
                                    </li>
                                    <li>
                                        Please allow up to 7 working days for your account to be credited. If you do not receive your refund after 7 working days,
                                        please contact your payment processor directly for further information.
                                    </li>

                                    <li>
                                        Refunds will be based on the value of the merchandise as reflected on your invoice.
                                        Shipping and handling charges from your initial order will only be refunded if the return is due to a defective or wrong item.
                                        The refund will be issued by IMONLINE.SHOP to your original payment method.
                                    </li>

                                    <li>
                                        We seek your understanding with delays to your refund caused by special events (e.g. online sales).
                                    </li>
                                </ul>

                                <ul>
                                    How do I make an exchange?
                                    <li>
                                        If your item(s) is defective or wrong, email us at information@imonline.shop with the subject title:
                                        Return Request – [Your Order Number]. Our customer service team will assist you with the return process.
                                    </li>
                                    <li>
                                        Beyond these reasons, if you wish to change your item(s):
                                        <ul>
                                            <li>
                                                Submit a return request within 30 days of purchase via the returns procedure stated above
                                            </li>
                                            <li>
                                                Make a new order
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        Please note that an exchange is subject to stock availability
                                        and we reserve the right to do an exchange by way of an item(s) of a similar value,
                                        giving you a refund via the original payment method, or any other methods at the discretion of IMONLINE.SHOP.
                                    </li>
                                </ul>

                                <ul style={{ listStyle: 'upper-roman' }}>
                                    Terms & Conditions

                                    <li>
                                        IM reserves all rights to inspect the condition of the item(s) before accepting any returns, refunds or exchanges (“Returns”).
                                        You shall bear the shipping and handling charges of returning any rejected item(s) to you.
                                    </li>
                                    <li>
                                        All risks in shipping the item(s) to IM shall be borne by you.
                                        IM shall not be responsible for any loss or damage to the item(s) which is being returned to us.
                                    </li>

                                    <li>
                                        Should your preferred refund method not be indicated during the Returns process,
                                        you shall be refunded by your original payment method.
                                    </li>

                                    <li>
                                        Should there be any dispute on the refund amount for any Returns,
                                        your refund will be based on the merchandise value on the original purchase invoice.
                                    </li>

                                    <li>
                                        IM shall not be responsible for damage to any item(s) resulting from unique, accidental,
                                        or random damage that is the result of use by you or wear and tear. Our Returns policies do not cover such damage.
                                    </li>

                                    <li>
                                        Our Returns policies does not cover item(s) used in a manner or for a purpose not reasonably contemplated by IM,
                                        or if the item(s) has been modified in a manner not authorised by IM
                                    </li>
                                    <li>
                                        IM makes no warranty in respect of any product, express or implied,
                                        including any implied warranties of merchantability, quality,
                                        compliance with description and fitness for a particular purpose.

                                    </li>
                                    <li>
                                        All Returns are at all times governed by Indian law. IM
                                        and you agree to submit to the non-exclusive jurisdiction of the Indian courts.
                                    </li>
                                    <li>
                                        IM's decisions on Returns are final.
                                    </li>
                                </ul>



                            </div>
                        </div>
                    </div>
                </div>
            </div>







            <Footer />
        </Fragment>
    )
}

export default ReturnAndExchange