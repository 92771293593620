import React, { Fragment, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux'
import SearchIcon from '@mui/icons-material/Search';
import { textRapForNameSearch } from '../../utils/textRapUtils'

import { createSearchedKeywordAction } from '../../redux/actions/searchAction'

import axios from 'axios';

function SearchItems() {
    const history = useHistory()
    const dispatch = useDispatch()

    const [shoesName, setShoesName] = useState('')
    const [autoCompleteSearchProduct, setautoCompleteSearchProduct] = useState([])


    useEffect(() => {


        if (!shoesName) {
            return setShoesName([])

        }

        (async () => {
            const url = `/api/search/find/shoesName=/name`
            const { data } = await axios.get(url, {
                
                params: {
                    shoesName: shoesName
                },
            })
            setautoCompleteSearchProduct(data.msg)
        })()


    }, [dispatch, shoesName])

    const selectHandler = (text) => {
        setShoesName(text)
      
    }

    const searchedDataHandler = (e) => {
        setShoesName(e.target.value)

    }


    const showSearchHandler = (e) => {
        e.preventDefault()
        history.push(`/searched/${shoesName}`)
        dispatch(createSearchedKeywordAction(shoesName))
    }




    return (
        <Fragment>
            <form onSubmit={showSearchHandler} >
                <div className='autosearch-form-main-div'>
                <input className='search-input-form' type="text" placeholder='Type Shoes Name...' value={shoesName} onChange={searchedDataHandler} required />
                <button className='search-btn-icons-style'> <SearchIcon /> </button>
                </div>
                {shoesName.length !== 0 ? (
                    <div className='searched-data-show-container'>
                        {autoCompleteSearchProduct.map((hey, index) => (

                            <div className="searched-item-div" key={index}>
                                <img style={{ height: '40px',width: 'auto' }} src={hey.mainImage[0].imageurl} alt={hey.name} />
                                <div>
                                    <p onClick={() => selectHandler(hey.name)}> {textRapForNameSearch(hey.name)}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : null}
                
            </form>
        </Fragment>

    )
}

export default SearchItems