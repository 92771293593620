import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, Link } from 'react-router-dom'
import Navbar from '../HomeScreen/Navbar';
import swal from 'sweetalert';
import {useParams} from "react-router-dom";
import MetaTag from '../../layout/MetaTag';
import ReturnOrderImage from '../../images/undraw_delivery_truck_vt6p.png';
import './returnOrder.css';

import Loader from '../../layout/loader';

import { returnOrderByUserAction, clearErrorAction } from '../../redux/actions/orderAction';



function ReturnOrderByUser(props) {

    const history = useHistory()
    const dispatch = useDispatch()

const {returnOrderData} = useParams;
console.log(returnOrderData)
    
    // user redux functions
    const { loading, returnOrder, error } = useSelector(state => state.returnOrder)

    const { user } = useSelector(state => state.user)


    const [returnReason, setReturnReason] = useState('');
    const [returnType, setReturnType] = useState('');
    const [isChacked, setIsChacked] = useState(false);
    const [accept, setAccept] = useState(false);
    const [image, setImage] = useState('');


    useEffect(() => {
        if (error) {
            swal({
                title: "Error",
                text: error,
                icon: 'error',
            });
            dispatch(clearErrorAction())
        }
        if (returnOrder) {
            swal({
                title: "Order Return",
                text: returnOrder,
                icon: 'success',
            });
            history.push('/userprofile')
        }


    }, [dispatch, error, history, returnOrder])





    const orderId = props.match.params.orderId;

    const returnSubmitHandler = (e) => {
        e.preventDefault();
        if (isChacked === false) {
            return swal({
                title: "Validation Error",
                text: 'please accpet all terms..!',
                icon: 'info',
            });
        }
        if (accept === false) {
            return swal({
                title: "Validation Error",
                text: 'please accpet all terms..!',
                icon: 'info',
            });
        }
        if (!returnReason) {
            return swal({
                title: "Validation Error",
                text: 'please type valid reason..!',
                icon: 'info',
            });
        }



        let formData = new FormData();
        formData.append('name',user.firstName)
        formData.append('email',user.email)
        formData.append('phoneNo',user.number)
        formData.append('returnOrderName',user.firstName)
        formData.append('returnOrderSize',user.firstName)
        formData.append('ReturnOrderColor',user.firstName)
        formData.append('returnType',returnType)
        formData.append('returnReason',returnReason)
        formData.append('orderId',orderId)

        for (let index = 0; index < image.length; index++) {
            formData.append('image', image[index]);
        }


        dispatch(returnOrderByUserAction( formData))
    }

    const returnAgreeHandler = (e) => {
        e.preventDefault();
        setIsChacked(true)
    }

    const returnPolicyAgreeHandler = (e) => {
        e.preventDefault();
        setAccept(true)

    }

    const createProductImageChange = (e) => {
        setImage(e.target.files);
    }

    return (
        <Fragment>
            <MetaTag title={'IM Online Shopping Order Return Request Page'} />
            <Navbar />
            {loading ? <Loader /> : (<Fragment>

                <div className="return-order-heading">
                    <h6>Return Order Request</h6>
                </div>
                <div className="return-form-div-main-container">
                    <div className="return-order-image">
                        <img src={ReturnOrderImage} alt="www.imonline.shop" />
                    </div>
                    <form className='return-form-div' onSubmit={returnSubmitHandler} >
                        <div className="cheack-box-div">
                            <input type="checkbox" readOnly required onChange={returnAgreeHandler} />Do you really want to return your order
                        </div>
                        <div className="cheack-box-div">
                            <input type="checkbox" readOnly required onChange={returnPolicyAgreeHandler} /> <span>I agree to IM ONLINE SHOPPING Co.<Link to='/terms-and-conditions'> <span> Terms of Use</span> </Link>  and <Link to='/about/return-and-exchange'><span>Return Policy</span> </Link>  </span>
                        </div>
                        <div className='return-type-option-div'>
                            <select  onChange={(e)=>setReturnType(e.target.value)}   >
                                <option >Select Type</option>
                                <option value="Return">I get damage items </option>
                                <option value="Exchange">I want Exchange </option>
                                <option value="Exchange">Wrong product shipped </option>
                                <option value="Exchange">Order arrived too late </option>
                                <option value="Exchange">Incorrect product size </option>
                                <option value="Exchange">The product did not match the description </option> 
                                <option value="Exchange">The product did not meet the customer’s expectations </option>
                            </select>
                        </div>
                        <div className="text-area-div">
                            <textarea placeholder='Type Valid Reason in detail...@example order item size or color is mis-match..... etc.' cols={'50'} rows={'8'} value={returnReason} onChange={(e) => setReturnReason(e.target.value)} required> </textarea>
                        </div>
                        <input type="file"
                                    name='image'
                                    accept='image/*'
                                    multiple
                                    onChange={createProductImageChange}
                                />

                        <button className='return-submit-btn'>submit</button>
                    </form>
                    <p className='return-paragraph'>You will get update on email.</p>
                </div>

            </Fragment>)}
        </Fragment>
    )
}

export default ReturnOrderByUser