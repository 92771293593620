import React, { Fragment } from 'react'
import { useHistory } from 'react-router-dom'
import firstImage from '../../../images/spy-first-image.jpg'
import highNeck from '../../../images/highneck.jpg'
import loafers from '../../../images/18680837_40614989_1000.jpg'
import chalsia from '../../../images/chalsa.jpg'
import boatShoes from '../../../images/boatshoes.jpg'
import shortFlatShoes from '../../../images/shortflatshoes.jpg'
import classicShoes from '../../../images/classicShoes.jpg'
import sleepers from '../../../images/sleepers.jpg'
import flatLoafers from '../../../images/simpleloafers.jpg'

import './topProductCatogery.css'

function TopProductCatogery() {

    const history = useHistory();

    const productSearchHandler = (category) => {
        history.push(`/catogery/${category}`)
    }


    return (
        <Fragment>
            <div className="shoes-catogery-main-container">
                <div className="first-container-div-of-catogery">
                    <div className="heading-of-first-container">
                        <div className="image-of-first-container">
                            <div onClick={()=>productSearchHandler('Sports')}>  <img src={firstImage} alt="www.imonline.shop" /> </div>
                        </div>
                        <div onClick={()=>productSearchHandler('Sports')} > <h6  >Sports</h6> </div>
                    </div>
                </div>
                <div className="first-container-div-of-catogery">
                    <div className="heading-of-first-container">
                        <div className="image-of-first-container">
                        <div onClick={()=>productSearchHandler('High Neck')} >
                             <img src={highNeck} alt="www.imonline.shop" />
                             </div>
                        </div>
                        <div onClick={()=>productSearchHandler('High Neck')} >  <h6>High-Neck</h6>  </div>
                    </div>
                </div>
                <div className="first-container-div-of-catogery">
                    <div className="heading-of-first-container">
                        <div className="image-of-first-container">
                        <div onClick={()=>productSearchHandler('Loafer')} >   <img src={loafers} alt="www.imonline.shop" /> </div>
                        </div>
                        <div onClick={()=>productSearchHandler('Loafer')} >  <h6>High-Heel Loafer's</h6> </div>
                    </div>
                </div>

                <div className="first-container-div-of-catogery">
                    <div className="heading-of-first-container">
                        <div className="image-of-first-container">
                        <div onClick={()=>productSearchHandler('Classic')} >    <img src={chalsia} alt="www.imonline.shop" /></div>
                        </div>
                        <div onClick={()=>productSearchHandler('Classic')} >  <h6>Chelsea Boots</h6></div>
                    </div>
                </div>
                <div className="first-container-div-of-catogery">
                    <div className="heading-of-first-container">
                        <div className="image-of-first-container">
                        <div onClick={()=>productSearchHandler('Boat Shoes')} >  <img src={boatShoes} alt="www.imonline.shop" /></div>
                        </div>
                        <div onClick={()=>productSearchHandler('Boat Shoes')} >  <h6>Boat Shoes</h6> </div>
                    </div>
                </div>
                <div className="first-container-div-of-catogery">
                    <div className="heading-of-first-container">
                        <div className="image-of-first-container">
                        <div onClick={()=>productSearchHandler('Formal')} >    <img src={shortFlatShoes} alt="www.imonline.shop" /> </div>
                        </div>
                        <div onClick={()=>productSearchHandler('Formal')} >   <h6>Short Flat Shoes</h6> </div>
                    </div>
                </div>
                <div className="first-container-div-of-catogery">
                    <div className="heading-of-first-container">
                        <div className="image-of-first-container">
                        <div onClick={()=>productSearchHandler('Classic')} >  <img src={classicShoes} alt="www.imonline.shop" /> </div>
                        </div>
                        <div onClick={()=>productSearchHandler('Classic')} >  <h6>Classic Shoes</h6> </div>
                    </div>
                </div>

                <div className="first-container-div-of-catogery">
                    <div className="heading-of-first-container">
                        <div className="image-of-first-container">
                        <div onClick={()=>productSearchHandler('Sleepers')} >   <img src={sleepers} alt="www.imonline.shop" /> </div>
                        </div>
                        <div onClick={()=>productSearchHandler('Sleepers')} >   <h6>Slides </h6> </div>
                    </div>
                </div>

                <div className="first-container-div-of-catogery">
                    <div className="heading-of-first-container">
                        <div className="image-of-first-container">
                        <div onClick={()=>productSearchHandler('Loafer')} >  <img src={flatLoafers} alt="www.imonline.shop" /></div>
                        </div>
                        <div onClick={()=>productSearchHandler('Loafer')} >  <h6> Loafer's</h6></div>
                    </div>
                </div>     
            </div>
        </Fragment>
    )
}

export default TopProductCatogery