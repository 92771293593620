import React, { Fragment } from 'react'


import './CancelOrderDetailsPage.css'
// import Loader from '../layout/loader'


function CancelOrderDetailsPage2({ order, orderLoading }) {
    console.log(order)
    return (
        <Fragment>
            {orderLoading ? (<Fragment> <h1 className='Loading-text-design'>Loading...</h1></Fragment>) : (<Fragment>
                <div className="product-details-container-div">
                    {order.orderItems && order.orderItems.map((product, index) => (
                        <div className="order-product-data-main-container" key={index}>
                            <p> Product Id : {product.product}   </p>
                            <p> Name : {product.name}   </p>
                            <img src={product.image} alt="www.imonline.shop" />
                            <p>Price : {product.price}   </p>
                            <p>Quanitiy : {product.quantity}   </p>
                            <p>Colour :{product.colour}   </p>
                            <p>Size : {product.size}   </p>
                        </div>

                    ))}
                    {/* {order !==  ? */}
                    {/* (<Fragment> */}
                    {Object.keys(order).length !== 0 ? (<Fragment>

                        <div className="cancel-order-details-container-div">
                            <p>Order Status : {order && order.orderStatus} </p>
                            <p>Item Price : {order && order.itemPrice} </p>
                            <p>Tax : {order && order.taxPrice} </p>
                            <p>Shipping Cost : {order && order.shippingPrice} </p>
                            <p>Total Amount : {order && order.totalPrice} </p>
                            <p>Payment Details : {order.paymentInfo && order.paymentInfo.status}  </p>
                            <p>Coupan Use Details : {order.coupanInfo && order.coupanInfo.coupanName} </p>
                            <p>Coupan Use Code :  {order.coupanInfo && order.coupanInfo.coupanValue}  </p>
                            <p> User Details :  {order && order.user} </p>
                        </div>

                        <div className="shippingInfo-div-container">
                            {order.shippingInfo && order.shippingInfo.name},{order.shippingInfo && order.shippingInfo.email},{order.shippingInfo && order.shippingInfo.address},{order.shippingInfo && order.shippingInfo.city}
                            {order.shippingInfo && order.shippingInfo.state},{order.shippingInfo && order.shippingInfo.pincode},{order.shippingInfo && order.shippingInfo.phoneNo},{order.shippingInfo && order.shippingInfo.district}
                        </div>
                    </Fragment>) : (null)}
                </div>

            </Fragment>)}

        </Fragment>
    )
}

export default CancelOrderDetailsPage2