
// GET ALL COMPLAIN BY ADMIN
export const ALL_COMPLAIN_REQUEST ='ALL_COMPLAIN_REQUEST';
export const ALL_COMPLAIN_REQUEST_SUCCESS ='ALL_COMPLAIN_REQUEST_SUCCESS';
export const ALL_COMPLAIN_REQUEST_FAIL ='ALL_COMPLAIN_REQUEST_FAIL';


// GET SINGEL COMPLAIN BY ADMIN
export const SINGEL_COMPLAIN_REQUEST ='SINGEL_COMPLAIN_REQUEST';
export const SINGEL_COMPLAIN_REQUEST_SUCCESS ='SINGEL_COMPLAIN_REQUEST_SUCCESS';
export const SINGEL_COMPLAIN_REQUEST_FAIL ='SINGEL_COMPLAIN_REQUEST_FAIL';
export const SINGEL_COMPLAIN_REQUEST_RESET ='SINGEL_COMPLAIN_REQUEST_RESET';

// DELETE COMPLAIN BY ADMIN
export const DELETE_COMPLAIN_REQUEST ='DELETE_COMPLAIN_REQUEST';
export const DELETE_COMPLAIN_REQUEST_SUCCESS ='DELETE_COMPLAIN_REQUEST_SUCCESS';
export const DELETE_COMPLAIN_REQUEST_FAIL ='DELETE_COMPLAIN_REQUEST_FAIL';
export const DELETE_COMPLAIN_REQUEST_RESET ='DELETE_COMPLAIN_REQUEST_RESET';

// DELETE COMPLAIN BY ADMIN
export const UPDATE_COMPLAIN_REQUEST ='UPDATE_COMPLAIN_REQUEST';
export const UPDATE_COMPLAIN_SUCCESS ='UPDATE_COMPLAIN_SUCCESS';
export const UPDATE_COMPLAIN_FAIL ='UPDATE_COMPLAIN_FAIL';
export const UPDATE_COMPLAIN_RESET ='UPDATE_COMPLAIN_RESET';


// CREATE COMPLAIN BY USER
export const COMPLAIN_REQUEST ='COMPLAIN_REQUEST';
export const COMPLAIN_REQUEST_SUCCESS ='COMPLAIN_REQUEST_SUCCESS';
export const COMPLAIN_REQUEST_FAIL ='COMPLAIN_REQUEST_FAIL';
export const COMPLAIN_REQUEST_RESET ='COMPLAIN_REQUEST_RESET';


export const CLEAR_ERROR ='CLEAR_ERROR';
