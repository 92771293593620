import {
  ACCOUNT_DETAILS_FAIL,
  ACCOUNT_DETAILS_REQUEST,
  ACCOUNT_DETAILS_SUCCESS,
  ALL_ACCOUNT_DETAILS_FAIL,
  ALL_ACCOUNT_DETAILS_REQUEST,
  ALL_ACCOUNT_DETAILS_SUCCESS,
  CLEAR_ERROR,
  SAVE_BANK_ACCOUNT_DETAILS_FAIL,
  SAVE_BANK_ACCOUNT_DETAILS_REQUEST,
  SAVE_BANK_ACCOUNT_DETAILS_RESET,
  SAVE_BANK_ACCOUNT_DETAILS_SUCCESS,
} from "../constants/refundConstant";

// saving bank account details
export const saveBankAccountDetailsReducers = (state = {}, action) => {
  switch (action.type) {
    case SAVE_BANK_ACCOUNT_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SAVE_BANK_ACCOUNT_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        saved: action.payload,
      };
    case SAVE_BANK_ACCOUNT_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SAVE_BANK_ACCOUNT_DETAILS_RESET:
      return {
        ...state,
        saved: null,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

// get save bank account details by user

export const getsaveBankAccountDetailsReducers = (
  state = { account: [] },
  action
) => {
  switch (action.type) {
    case ACCOUNT_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ACCOUNT_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        account: action.payload,
      };
    case ACCOUNT_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

// get all save bank account details by admin

export const getAllsaveBankAccountDetailsReducers = (
  state = { accounts: [] },
  action
) => {
  switch (action.type) {
    case ALL_ACCOUNT_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ALL_ACCOUNT_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        accounts: action.payload,
      };
    case ALL_ACCOUNT_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};
