
// CREATE FORM BY USER
export const CREATE_FORM_REQUEST='CREATE_FORM_REQUEST';
export const CREATE_FORM_SUCCESS='CREATE_FORM_SUCCESS';
export const CREATE_FORM_FAIL='CREATE_FORM_FAIL';
export const CREATE_FORM_RESET='CREATE_FORM_RESET';

// GET ALL FORM DATA BY ADMIN
export const GET_ALL_FORM_REQUEST='GET_ALL_FORM_REQUEST';
export const GET_ALL_FORM_SUCCESS='GET_ALL_FORM_SUCCESS';
export const GET_ALL_FORM_FAIL='GET_ALL_FORM_FAIL';


// GET SINGEL FORM DATA BY ADMIN
export const GET_FORM_REQUEST='GET_FORM_REQUEST';
export const GET_FORM_SUCCESS='GET_FORM_SUCCESS';
export const GET_FORM_FAIL='GET_FORM_FAIL';


// DELETE FORM DATA  BY ADMIN
export const DELETE_FORM_REQUEST='DELETE_FORM_REQUEST';
export const DELETE_FORM_SUCCESS='DELETE_FORM_SUCCESS';
export const DELETE_FORM_FAIL='DELETE_FORM_FAIL';
export const DELETE_FORM_RESET='DELETE_FORM_RESET';


// CLEAR ERROR 
export const CLEAR_ERROR='CLEAR_ERROR'