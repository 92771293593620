import React from 'react'
import Loginpage from './components/LoginScreen/Loginpage';


function Login() {
    return (
        <div>
           <Loginpage/> 
        </div>
    )
}

export default Login
