import React, { Fragment, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import swal from 'sweetalert';
import emailSendImages from '../../images/undraw_Share_link_re_54rx.png'
import { passwordResetRequestByEmail, clearErrorAction } from '../../redux/actions/userAction'
import Loader from '../../layout/loader';
import MetaTag from '../../layout/MetaTag';
import './forgotPassword.css'

function ForgotPasswordEmail() {

    const dispatch = useDispatch();
    const { error, loading, message } = useSelector((state) => state.passwordResetByEmail)


    const [email, setEmail] = useState('');


    useEffect(() => {
        if (error) {
            swal({
                title: "Validation Error",
                text: error,
                icon: "error",
            })
            dispatch(clearErrorAction())
        }
        if (message) {
            swal({
                title: "Success",
                text: message,
                icon: "success",
            })
        }

    }, [dispatch, error, message])


    const resetPasswordHandler = (e) => {
        e.preventDefault();
        if (!email) {
            return swal({
                title: "Validation Error..!!",
                text: "Please fill email..!",
                icon: "info",
            })

        }

        dispatch(passwordResetRequestByEmail(email))

    }

    return (
        <Fragment>
            <MetaTag title={'lost your password ? please enter your email address, to Update your Password.'} />
            {loading ? <Loader /> : (<Fragment>
                <div className="forgot-password-div-main">
                    <div className="forgot-password-image-div">
                        <img src={emailSendImages} alt="www.imonline.shop" />
                    </div>
                    <div className="forgot-password-heading">
                        <h6>reset your password</h6>
                        <div className="forgot-password-paragarph">
                            <p>
                                lost your password ? please enter your email address. you will receive a link
                                to create a new password via email.
                            </p>
                        </div>
                    </div>
                    <div className="form-div-for-email">
                        <form onSubmit={resetPasswordHandler} method='POST'>
                            <input type="email" placeholder='please enter your valid email' value={email} onChange={(e) => setEmail(e.target.value)} required />
                            <button className='reset-password-button'>Reset Password </button>
                        </form>
                    </div>
                </div>
            </Fragment>)}
        </Fragment>
    )
}

export default ForgotPasswordEmail