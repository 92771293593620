import React, { Fragment, useEffect } from 'react'
import {Link} from 'react-router-dom'

import Loader from '../../../layout/loader';

import { useSelector, useDispatch } from 'react-redux'
import { allProductToWishListAction } from '../../../redux/actions/wishListAction'
import ReactStars from "react-rating-stars-component";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { addEllipsis } from '../../../utils/textRapUtils'


function HighlighPage() {

    const dispatch = useDispatch();
    const { loading, wishListProducts } = useSelector(state => state.wishListAll)


    useEffect(() => {
        dispatch(allProductToWishListAction())
    }, [dispatch])



    return (
        <Fragment>
            {loading ? <Loader /> : <Fragment>

                {wishListProducts && wishListProducts.length < 10 ? (null) :
                    (
                        <Fragment>
                            {loading ? <Loader /> : (<Fragment>
                                {wishListProducts && wishListProducts.map((product, index) => (
                                        <div className="bestSeller-pro" key={index} >
                                            <Link to={`/ProductDetail/${product._id}`}> <img src={product.image} alt="www.im.com" /></Link>
                                            <div className="bestSeller-descripation">
                                                <h6>  <Link to={`/ProductDetail/${product._id}`}> {product.price}  </Link>    </h6>
                                                <div className="bestSeller-Star">
                                                    <p> <ReactStars

                                                        padding='5px'
                                                        edit={false}
                                                        color='gray'
                                                        activeColor='#ffd700'
                                                        size={window.innerWidth < 600 ? 20 : 30}
                                                        value={product.ratings}
                                                        isHalf={true} />             </p>
                                                </div>
                                                <h3 className='best-seller-product-name' >  <Link to={`/ProductDetail/${product._id}`}> {addEllipsis(product.name)}  </Link>    </h3>
                                            </div>
                                            <Link to={`/ProductDetail/${product._id}`}>  <button className="best-Seller-descripation-cart-div"> <ShoppingCartIcon /> </button></Link>
                                        </div>
                                ))}
                            </Fragment>)}
                        </Fragment>
                    )}
            </Fragment>}

        </Fragment>
    )
}

export default HighlighPage