import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import NotFoundImage from '../images/notfound.png'
import HomeIcon from '@mui/icons-material/Home';
import './notFound.css'

function NotFound() {
    return (
        <Fragment>
            <div className="not-found-div-style">
                <img src={NotFoundImage} alt="www.imonline.shop" />
                <div className="paragraph-of-not-find-div">
                    <p>Sorry Not Found !! <span>404</span></p>
                </div>
                <div className="back-btn-to-home">
                    <Link to={'/'}> <button><HomeIcon /></button></Link>
                    <span>Back to Home</span>
                </div>
            </div>
        </Fragment>
    )
}

export default NotFound