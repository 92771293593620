import {
    CREATE_PRODUCT_HEADING_REQUEST,
    CREATE_PRODUCT_HEADING_SUCCESS,
    CREATE_PRODUCT_HEADING_FAIL,
    CREATE_PRODUCT_HEADING_RESET,
    UPDATE_PRODUCT_HEADING_REQUEST,
    UPDATE_PRODUCT_HEADING_SUCCESS,
    UPDATE_PRODUCT_HEADING_FAIL,
    UPDATE_PRODUCT_HEADING_RESET,
    PRODUCT_HEADING_REQUEST_ID,
    PRODUCT_HEADING_REQUEST_ID_SUCCESS,
    PRODUCT_HEADING_REQUEST_ID_FAIL,
    PRODUCT_ALL_HEADING_REQUEST,
    PRODUCT_ALL_HEADING_SUCCESS,
    PRODUCT_ALL_HEADING_FAIL,
    CLEAR_ERROR,
} from '../constants/productAdsConstants'
// // PRODUCT CREATE REDUCER by admin

export const productAdsCreateReducer = ((state = {}, action) => {
    switch (action.type) {
        case CREATE_PRODUCT_HEADING_REQUEST:
            return {
                ...state,
                loading: true,

            }
        case CREATE_PRODUCT_HEADING_SUCCESS:
            return {
                loading: false,
                success: action.payload,

            }
        case CREATE_PRODUCT_HEADING_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case CREATE_PRODUCT_HEADING_RESET:
            return {
                ...state,
                success: false,
            }
        case CLEAR_ERROR:
            return {
                ...state,
                error: null
            }
        default:
            return state;

    }


})


// PRODUCT UPDATE REDUCER by admin

export const productAdsUpdateReducer = ((state = {}, action) => {
    switch (action.type) {
        case UPDATE_PRODUCT_HEADING_REQUEST:
            return {
                ...state,
                loading: true,

            }
        case UPDATE_PRODUCT_HEADING_SUCCESS:
            return {
                loading: false,
                isUpdated: action.payload,

            }
        case UPDATE_PRODUCT_HEADING_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case UPDATE_PRODUCT_HEADING_RESET:
            return {
                ...state,
                success: false,
            }
        case CLEAR_ERROR:
            return {
                ...state,
                error: null
            }
        default:
            return state;

    }


})


// PRODUCT HEADING ADS DETAIL REDUCER 

export const productAdsDetailReducer = ((state = {
    productAdsDetail: []
}, action) => {
    switch (action.type) {
        case PRODUCT_HEADING_REQUEST_ID:
            return {
                loading: true,
                ...state
            }
        case PRODUCT_HEADING_REQUEST_ID_SUCCESS:
            return {
                loading: false,
                productAdsDetail: action.payload,
            }
        case PRODUCT_HEADING_REQUEST_ID_FAIL:
            return {
                loading: false,
                error: action.payload,
            }
        case CLEAR_ERROR:
            return {
                ...state,
                error: null
            }
        default:
            return state;

    }

})




// //ALL  PRODUCT HEADING ADS DETAIL REDUCER 

export const allProductAdsDetailReducer = ((state = {
    productDetails: []
}, action) => {
    switch (action.type) {
        case PRODUCT_ALL_HEADING_REQUEST:
            return {
                loading: true,
                ...state
            }
        case PRODUCT_ALL_HEADING_SUCCESS:
            return {
                loading: false,
                productDetails: action.payload,
            }
        case PRODUCT_ALL_HEADING_FAIL:
            return {
                loading: false,
                error: action.payload,
            }
        case CLEAR_ERROR:
            return {
                ...state,
                error: null
            }
        default:
            return state;

    }

})






