import React, { Fragment } from "react";
import "./loader.css";

function loader() {
  return (
    <Fragment>
      <div className="loader-container">
        <div className="loader-second-div">
          <div></div> <div></div> <div></div> <div></div> 
        </div>
      </div>
    </Fragment>
  );
}

export default loader;
