import React, { Fragment, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { clearErrorAction, createProductAction } from '../redux/actions/productAction'
import swal from 'sweetalert';
import { Button } from '@material-ui/core'
import Loader from '../layout/loader';
import AccountTreeIcon from '@material-ui/icons/AccountTree'
import DescriptionIcon from '@material-ui/icons/Description';
import StorageIcon from '@material-ui/icons/Storage'
import SpellCheckIcon from '@material-ui/icons/Spellcheck';
import DiscountIcon from '@mui/icons-material/Discount';
import AllInboxIcon from '@mui/icons-material/AllInbox';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import Filter6Icon from '@mui/icons-material/Filter6';
import FilterVintageIcon from '@mui/icons-material/FilterVintage';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import KeyboardIcon from '@mui/icons-material/Keyboard';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import PercentIcon from '@mui/icons-material/Percent';

import Sidebar from './Sidebar'
import './createNewProduct.css'
import { NEW_PRODUCT_RESET } from '../redux/constants/productConstants'


const CreateNewProduct = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { loading, error, success } = useSelector((state) => state.newProductCreate)

    const [name, setName] = useState('');
    const [tag, setTag] = useState('');
    const [metaTag, setMetaTag] = useState('');
    const [price, setPrice] = useState('');
    const [discountActive,setDiscountActive]= useState('');
    const [discountPrice,setDiscountPrice]=useState('')
    const [qualityType,setQualityType]= useState('');
    const [keyword, setKeyword] = useState('');
    const [color, setColor] = useState(['']);
    const [size, setSize] = useState(['']);
    const [brand, setBrand] = useState('');
    const [descripation, setDescripation] = useState('');
    const [catogery, setCatogery] = useState('');
    const [stock, setStock] = useState('');
    const [offers,setOffers]= useState('');
    const [shippingCharges,setShippingCharges]= useState('');
    const [isdeliveryFree,setIsdeliveryFree]= useState('');
    const [image, setImage] = useState('');
    const [headImage, setheadImage] = useState('');

    const catogeries = [
        'Formal',
        'Sports',
        'Classic',
        'Loafer',
        'Sleepers',
        'High Neck',
        'Boat Shoes'
    ]

    useEffect(() => {
        if (error) {
            swal({
                title: "Validation",
                text: error,
                icon: "error",
            })
            dispatch(clearErrorAction())
        }
        if (success) {
            swal({
                title: "Created.",
                text: success,
                icon: "success",
            })
            history.push('/Admin/dashboard')
            dispatch({ type: NEW_PRODUCT_RESET })
        }

    }, [dispatch, error, history, success])

    // on click function when submit button press
    const createProductSubmitHandler = async (e) => {
        e.preventDefault()
        if (!image) {
            return swal({
                title: "Validation Error",
                text: 'Please Select Images..!',
                icon: "info",
            })
        }
        let formData = new FormData();
        formData.append('name', name);
        formData.append('tag', tag);
        formData.append('metaTag', metaTag);
        formData.append('keyword', keyword);
        formData.append('price', price);
        formData.append('qualityType', qualityType);
        formData.append('discountActive', discountActive);
        formData.append('discountPrice', discountPrice);
        formData.append('color', color);
        formData.append('size', size);
        formData.append('brand', brand);
        formData.append('descripation', descripation);
        formData.append('catogery', catogery);
        formData.append('stock', stock);
        formData.append('offers', offers);
        formData.append('shippingCharges', shippingCharges);
        formData.append('isdeliveryFree', isdeliveryFree);
  

        for (let index = 0; index < image.length; index++) {
            formData.append('image', image[index]);
        }

        for (let index = 0; index < headImage.length; index++) {
            formData.append('headImage', headImage[index]);
        }

        dispatch(createProductAction(formData));

    }


    // // for images adding function

    const createProductImageChange = (e) => {
        setImage(e.target.files);
    }

    const setHeadImageProductChange = (e) => {
        setheadImage(e.target.files);
    }


    return (
        <Fragment>
            {loading ? <Loader /> : (<Fragment>
                <div className="dashboard-container">
                    <Sidebar />
                    <div className="newProductContainer">
                        <form className='createProductForm'
                            onSubmit={createProductSubmitHandler}  >
                            <h1>Create Product</h1>
                            <div>
                                <SpellCheckIcon />
                                <input
                                    type='text'
                                    placeholder='Product Name'
                                    required
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                            <div>
                                <LocalOfferIcon />
                                <input
                                    type='text'
                                    placeholder='Product tag'
                                    required
                                    value={tag}
                                    onChange={(e) => setTag(e.target.value)}
                                />
                            </div>

                            <div>
                                <TravelExploreIcon />
                                <input
                                    type='text'
                                    placeholder='For SEO /google search'
                                    required
                                    value={metaTag}
                                    onChange={(e) => setMetaTag(e.target.value)}
                                />
                            </div>

                            <div>
                                <KeyboardIcon />
                                <input
                                    type='text'
                                    placeholder='Keyword /*optinal search improve*/'
                                    required
                                    value={keyword}
                                    onChange={(e) => setKeyword(e.target.value)}
                                />
                            </div>
                            <div>
                                <AutoAwesomeIcon />
                                <input
                                    type='text'
                                    placeholder='Product Quality type helps to rank betters. @exmple: 10a or 7a'
                                    required
                                    value={qualityType}
                                    onChange={(e) => setQualityType(e.target.value)}
                                />
                            </div>

                            <div>
                                <AttachMoneyIcon />
                                <input
                                    type='number'
                                    placeholder='Price'
                                    required
                                    value={price}
                                    onChange={(e) => setPrice(e.target.value)}
                                />
                            </div>

                            <div>
                                <PercentIcon />

                                <select onChange={(e) => setDiscountActive(e.target.value)} required >
                                <option  > Select Discount Type</option>
                                    <option value={'true'} > Active</option>
                                    <option  value={'false'} >In-Active </option>
                                </select>
                            </div>

                            <div>
                                <DiscountIcon />
                                <input
                                    type='number'
                                    placeholder='Discount-Amount INR @Example: 0 RS. OR 10 RS.'
                                    required
                                    value={discountPrice}
                                    onChange={(e) => setDiscountPrice(e.target.value)}
                                />
                            </div>
                            <div>
                                <LocalShippingIcon />
                                <input
                                    type='number'
                                    placeholder='Add Shipping Charges for this item. @example:- 60rs'
                                    required
                                    value={shippingCharges}
                                    onChange={(e) => setShippingCharges(e.target.value)}
                                />
                            </div>

                            <div>
                                <ColorLensIcon />
                                <input
                                    type='text'
                                    placeholder='colors'
                                    required
                                    value={color}
                                    onChange={(e) => setColor(e.target.value)}
                                />
                            </div>

                            <div>
                                <Filter6Icon />
                                <input
                                    type='text'
                                    placeholder='sizes'
                                    required
                                    value={size}
                                    onChange={(e) => setSize(e.target.value)}
                                />
                            </div>

                            <div>
                                <CheckCircleOutlineIcon />
                                <input
                                    type='text'
                                    placeholder='Brand Name'
                                    required
                                    value={brand}
                                    onChange={(e) => setBrand(e.target.value)}
                                />
                            </div>

                            <div>
                                <FilterVintageIcon />
                                <input
                                    type='text'
                                    placeholder='Type offers if available @example: free delivery..etc'
                                    required
                                    value={offers}
                                    onChange={(e) => setOffers(e.target.value)}
                                />
                            </div>


                            <div>
                                <AllInboxIcon />

                                <select onChange={(e) => setIsdeliveryFree(e.target.value)} required >
                                <option  > Select Delivery Type</option>
                                    <option value={'true'} > Free</option>
                                    <option  value={'false'} >Charges-applied </option>
                                </select>
                            </div>

                            <div>
                                <DescriptionIcon />
                                <textarea
                                    placeholder='Descripation'
                                    required
                                    value={descripation}
                                    onChange={(e) => setDescripation(e.target.value)}
                                    cols={10}
                                    rows={1}
                                ></textarea>
                            </div>

                            <div>
                                <AccountTreeIcon />

                                <select onChange={(e) => setCatogery(e.target.value)} >
                                    <option value=''>Choose category</option>
                                    {
                                        catogeries.map((items) => (
                                            <option key={items} value={items} > {items}  </option>
                                        ))
                                    }
                                </select>
                            </div>

                            <div>
                                <StorageIcon />
                                <input
                                    type='number'
                                    placeholder='Stock'
                                    required
                                    value={stock}
                                    onChange={(e) => setStock(e.target.value)}
                                />
                            </div>
                            <div id="createProductFormFile">
                                <input type="file"
                                    name='image'
                                    accept='image/*'
                                    multiple
                                    onChange={createProductImageChange}
                                />
                            </div>
                            <div id="createProductFormFile">
                                <input type="file"
                                    name='headImage'
                                    accept='image/*'
                                    onChange={setHeadImageProductChange}
                                />
                            </div>
                            <Button
                                id='createProductBtn'
                                type='submit'
                                disabled={loading ? true : false}

                            >
                                Create
                            </Button>


                        </form>
                    </div>
                </div>
            </Fragment>)}
        </Fragment>
    )
}

export default CreateNewProduct