import React, { Fragment, useEffect } from 'react'
import Sidebar from './Sidebar'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { adminProductRequestAction } from '../redux/actions/productAction'
import { getAllCoupanAction } from '../redux/actions/coupanAction'
import { allCancelOrderByAdminAction, allReturnOrderByAdminAction } from '../redux/actions/orderAction'
import { Typography } from '@material-ui/core'
// import { Doughnut } from 'react-chartjs-2';
import './adminRoutes.css'
import { allOrderAction } from '../redux/actions/orderAction'
import { usersRequestByAdmin } from '../redux/actions/userAction'


function AdminRoutes() {

  const dispatch = useDispatch();


  const { products } = useSelector((state) => state.products)
  const { coupans } = useSelector((state) => state.coupans)
  const { cancelOrders } = useSelector((state) => state.allCancelOrderByAdmin)
  const { returnOrders } = useSelector((state) => state.allReturnOrderByAdmin)
  const { orders } = useSelector((state) => state.allOrders)
  const { users } = useSelector((state) => state.allUsers)
  // garph data

  useEffect(() => {
    dispatch(getAllCoupanAction())
    dispatch(allCancelOrderByAdminAction())
    dispatch(allReturnOrderByAdminAction())
    dispatch(allOrderAction())
    dispatch(adminProductRequestAction())
    dispatch(usersRequestByAdmin())
  }, [dispatch])


  let totalAmount = 0;

  orders && orders.forEach(element => {
    totalAmount += element.totalPrice;
  });

  // const data = {
  //   labels: ['Initial Amount', 'Amount Earned'],
  //   datasets: [
  //     {
  //       label: 'Total Amount',
  //       data: [0, 4000],
  //       backgroundColor: [
  //         'rgba(255, 99, 132, 0.2)',
  //         'rgba(54, 162, 235, 0.2)',
  //       ],
  //       borderColor: [
  //         'rgba(255, 99, 132, 1)',
  //         'rgba(54, 162, 235, 1)',
  //       ],
  //       borderWidth: 1,
  //     },
  //   ],
  // };

  // var option = {
  //   maintainAspectRatio: false,
  //   scales: {
  //     y: {
  //       beginAtZero: true
  //     }
  //   },
  //   legend: {
  //     lables: {
  //       fontSize: 14
  //     }
  //   }
  // }


  // line chart



  // doughnut chart

  // const doughnutState = {
  //   labels: ['Out Of Stock', 'In Stock'],

  //   datasets: [
  //     {
  //       backgroundColor: ['#004608', '#600004'],
  //       hoverBackgroundColor: ['#4B5000', '#35014F'],
  //       data: [2, 10]
  //     }
  //   ],


  // };

  return (
    <Fragment>
      <div className="dashboard-container">
        <Sidebar />
        <div className="main-container">
          <Typography component={'h1'}  >  Dashboard  </Typography>
          {/**first container */}
          <div className="main-container-order-summery">
            <div>
              <p>
                TOTAL AMOUNTS <br /> {totalAmount}
              </p>
            </div>


            {/**second container */}
            <div className="dashboardsummerybox-2">
              <Link to={'/Admin/dashboard/Products'}>
                <p>PRODUCT'S</p>
                <p>{products && products.length}</p>
              </Link>
              <Link to={'/Admin/dashboard/Orders'}
               className='admin-orders-section-style' >
                <p >ORDER'S</p>
                <p>{orders && orders.length}</p>
              </Link>
              <Link to={'/Admin/dashboard/Users'}
                  className='admin-user-section-style'>
                <p>USER'S</p>
                <p>{users && users.length}</p>
              </Link>
              <Link to={'/Admin/dashboard/all/coupans'}
               className='admin-coupan-section-style' >
                <p>COUPAN'S</p>
                <p>{coupans && coupans.length}</p>
              </Link>
              <Link to={'/Admin/dashboard/orders/allcancel'}>
                <p>CANCEL ORDERS</p>
                <p>{cancelOrders && cancelOrders.length}</p>
              </Link>
              <Link to={'/Admin/dashboard/orders/return-orders'}>
                <p>RETURN ORDERS</p>
                <p>{returnOrders && returnOrders.length}</p>
              </Link>

              {/* <Link to={'/Admin/dashboard/orders/return-orders'}>
                <p>SEARCHED KEYWORDS</p>
                <p>{returnOrders && returnOrders.length}</p>
              </Link> */}
            </div>
          </div>

        </div>
      </div>
    </Fragment>
  )
}

export default AdminRoutes