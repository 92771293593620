import React, { Fragment, useEffect } from 'react'
import LatestProduct from "./LatestProducts";
import swal from 'sweetalert';
import { useSelector, useDispatch } from 'react-redux'
import { clearErrorAction, productAction } from '../../../redux/actions/productAction'
import './latestProduct.css'
function LaestProductsProps() {
    const dispatch = useDispatch();
    const { loading, products, error } = useSelector(state => state.products)

    useEffect(() => {

        if (error) {
            swal({
                title: "Validation",
                text: error,
                icon: "error",
            })
            dispatch(clearErrorAction(error))

        }
        dispatch(productAction())

    }, [dispatch, error])




    return (
        <Fragment>
            <div className="product-main-page-style">
                <LatestProduct products={products} loading={loading} />
            </div>


        </Fragment>
    )
}

export default LaestProductsProps
