import axios from 'axios';
import {
    CLEAR_ERROR,
    USER_LOGIN_REQUEST,
    USER_LOGIN_REQUEST_SUCCESS,
    USER_LOGIN_REQUEST_FAIL,
    USER_SIGNIN_REQUEST,
    USER_SIGNIN_REQUEST_SUCCESS,
    USER_SIGNIN_REQUEST_FAIL,
    LOADING_USER_REQUEST,
    LOADING_USER_REQUEST_SUCCESS,
    LOADING_USER_REQUEST_FAIL,
    UPDATE_PROFILE_BY_USER_REQUEST,
    UPDATE_PROFILE_BY_USER_SUCCESS,
    UPDATE_PROFILE_BY_USER_FAIL,
    LOGOUT_USER_PROFILE_SUCCESS,
    LOGOUT_USER_PROFILE_FAIL,
    UPDATE_USER_PASSWORD_REQUEST,
    UPDATE_USER_PASSWORD_SUCCESS,
    UPDATE_USER_PASSWORD_FAIL,
    USER_DETAIL_REQUEST_BY_ADMIN,
    USER_DETAIL_SUCCESS_BY_ADMIN,
    USER_DETAIL_FAIL_BY_ADMIN,
    ALL_USER_REQUEST_BY_ADMIN,
    ALL_USER_SUCCESS_BY_ADMIN,
    ALL_USER_FAIL_BY_ADMIN,
    UPDATE_USER_DETAIL_REQUEST,
    UPDATE_USER_DETAIL_SUCCESS,
    UPDATE_USER_DETAIL_FAIL,
    DELETE_USER_DETAIL_REQUEST,
    DELETE_USER_DETAIL_SUCCESS,
    DELETE_USER_DETAIL_FAIL,
    RESET_PASSWORD_BY_EMAIL_REQUEST,
    RESET_PASSWORD_BY_EMAIL_SUCCESS,
    RESET_PASSWORD_BY_EMAIL_FAIL,
    PASSWORD_RESET_REQUEST,
    PASSWORD_RESET_SUCCESS,
    PASSWORD_RESET_FAIL

} from "../constants/userConstants"

// import {
//     URL
// } from '../../Url';
// login action

export const userAction = (email, password) => async (dispatch) => {

    try {

        dispatch({
            type: USER_LOGIN_REQUEST
        })
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };

        const {
            data
        } = await axios.post(`/api/Login`, {
            email,
            password
        }, config)
        dispatch({
            type: USER_LOGIN_REQUEST_SUCCESS,
            payload: data.msg
        })
    } catch (error) {
        dispatch({
            type: USER_LOGIN_REQUEST_FAIL,
            payload: error.response.data.msg
        })




    }

}


// signin action

export const userSigninAction = (firstName,
    lastName,
    email,
    number,
    password,
    confirmPassword,
    gender) => async (dispatch) => {
    try {
        dispatch({
            type: USER_SIGNIN_REQUEST
        })
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };

        const {
            data
        } = await axios.post(`/api/Signin`, {
            firstName,
            lastName,
            email,
            number,
            password,
            confirmPassword,
            gender
        }, config);

        dispatch({
            type: USER_SIGNIN_REQUEST_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: USER_SIGNIN_REQUEST_FAIL,
            payload: error.response.data.msg,
        })
    }

}

// user loading action

export const userLoadingAction = () => async (dispatch) => {
    try {
        dispatch({
            type: LOADING_USER_REQUEST
        })
        const config = {
            headers: {
                'Content-Type': 'application/json'
            },
           
        };
        const {
            data
        } = await axios.get(`/api/user/profile`, config);
        dispatch({
            type: LOADING_USER_REQUEST_SUCCESS,
            payload: data.user,
        })

    } catch (error) {
        dispatch({
            type: LOADING_USER_REQUEST_FAIL,
            payload: error.response.data.msg,
        })
    }

}



// user logout action

export const userLogoutAction = () => async (dispatch) => {
    try {
        await axios.get(`/api/Logout`);
        dispatch({
            type: LOGOUT_USER_PROFILE_SUCCESS,
        })

    } catch (error) {
        dispatch({
            type: LOGOUT_USER_PROFILE_FAIL,
            payload: error.response.data.msg,
        })
    }


}


// user update action

export const userUpdateAction = (firstName,
    lastName,
    email,
    number,
    gender) => async (dispatch) => {
    try {
        dispatch({
            type: UPDATE_PROFILE_BY_USER_REQUEST
        })
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };

        const {
            data
        } = await axios.put(`/api/updateProfile`, {
            firstName,
            lastName,
            email,
            number,
            gender
        }, config);

        dispatch({
            type: UPDATE_PROFILE_BY_USER_SUCCESS,
            payload: data.msg,
        })

    } catch (error) {
        dispatch({
            type: UPDATE_PROFILE_BY_USER_FAIL,
            payload: error.response.data.msg,
        })
    }


}



// user update password action

export const userUpdatePasswordAction = (oldPassword,
    newPassword,
    newConfirmPassword,
) => async (dispatch) => {
    try {
        dispatch({
            type: UPDATE_USER_PASSWORD_REQUEST
        })
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };

        const {
            data
        } = await axios.put(`/api/updatePassword/userprofile`, {
            oldPassword,
            newPassword,
            newConfirmPassword,
        }, config);

        dispatch({
            type: UPDATE_USER_PASSWORD_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: UPDATE_USER_PASSWORD_FAIL,
            payload: error.response.data.msg,
        })
    }


}



// USER'S REQUEST BY ADMIN

export const usersRequestByAdmin = () => async (dispatch) => {
    try {
        dispatch({
            type: ALL_USER_REQUEST_BY_ADMIN
        })

        const {
            data
        } = await axios.get(`/api/users/lists`);
        dispatch({
            type: ALL_USER_SUCCESS_BY_ADMIN,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: ALL_USER_FAIL_BY_ADMIN,
            payload: error.response.data.msg,
        })
    }


}

// USER DETAIL REQUEST BY ADMIN

export const userDetailRequestByAdmin = (id) => async (dispatch) => {
    try {
        dispatch({
            type: USER_DETAIL_REQUEST_BY_ADMIN
        })

        const {
            data
        } = await axios.get(`/api/single/user/${id}`);
        dispatch({
            type: USER_DETAIL_SUCCESS_BY_ADMIN,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: USER_DETAIL_FAIL_BY_ADMIN,
            payload: error.response.data.msg,
        })
    }


}




// UPDATE USER BY ADMIN THROW ID

export const updateUserByAdminAction = (id, firstName,
    lastName,
    email,
    number,
    gender,
    role) => async (dispatch) => {
    try {
        dispatch({
            type: UPDATE_USER_DETAIL_REQUEST
        })
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };

        const {
            data
        } = await axios.put(`/api/updateProfile/byadmin/${id}`, {
            firstName,
            lastName,
            email,
            number,
            gender,
            role
        }, config);

        dispatch({
            type: UPDATE_USER_DETAIL_SUCCESS,
            payload: data.msg,
        })

    } catch (error) {
        dispatch({
            type: UPDATE_USER_DETAIL_FAIL,
            payload: error.response.data.msg,
        })
    }


}





// DELETE USER BY ADMIN THROW ID

export const deleteUserByAdminAction = (id) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_USER_DETAIL_REQUEST
        })
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };

        const {
            data
        } = await axios.delete(`/api/deleteProfil/byAdmin/${id}`, config);

        dispatch({
            type: DELETE_USER_DETAIL_SUCCESS,
            payload: data.msg,
        })

    } catch (error) {
        dispatch({
            type: DELETE_USER_DETAIL_FAIL,
            payload: error.response.data.msg,
        })
    }


}


// password reset by email

export const passwordResetRequestByEmail = (email) => async (dispatch) => {

    try {
        dispatch({
            type: RESET_PASSWORD_BY_EMAIL_REQUEST
        })
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };

        const {
            data
        } = await axios.post(`/api/forget-password/request`, {
            email
        }, config);


        dispatch({
            type: RESET_PASSWORD_BY_EMAIL_SUCCESS,
            payload: data.message,
        })

    } catch (error) {
        dispatch({
            type: RESET_PASSWORD_BY_EMAIL_FAIL,
            payload: error.response.data.msg,
        })
    }


}

// password reset by token that was getting by email

export const passwordResetByToken = (password, confirmPassword, token) => async (dispatch) => {

    try {
        dispatch({
            type: PASSWORD_RESET_REQUEST
        })
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };

        const {
            data
        } = await axios.post(`/api/password/reset/${token}`, {
            password,
            confirmPassword
        }, config);

        dispatch({
            type: PASSWORD_RESET_SUCCESS,
            payload: data.message,
        })

    } catch (error) {
        dispatch({
            type: PASSWORD_RESET_FAIL,
            payload: error.response.data.msg,
        })
    }


}


// clear the error 


export const clearErrorAction = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERROR
    })
}