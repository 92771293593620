import axios from "axios";
import {
  CLEAR_CART_ERROR,
  ADD_TO_CART_FAIL,
  ADD_TO_CART_REQUEST,
  ADD_TO_CART_SUCCESS,
  ALL_CART_ITEM_FAIL,
  ALL_CART_ITEM_REQUEST,
  ALL_CART_ITEM_SUCCESS,
  REMOVE_FROM_CART_FAIL,
  REMOVE_FROM_CART_REQUEST,
  REMOVE_FROM_CART_SUCCESS,
  SAVE_SHIPPING_INFO,
  SAVE_BUY_ITEM_INFO
} from "../constants/cartConstants";

// import {URL} from '../../Url';

// cart action

export const cartAction =
  (id, quantity, size, colour, selectBox, productShippingCharges) =>
  async (dispatch) => {
    const { data } = await axios.get(`/api/add-to-cart/product/${id}`);
    const cartData = {
      product: data.isDataGet._id,
      name: data.isDataGet.name,
      price:
        data.isDataGet.discountActive === true
          ? data.isDataGet.price - data.isDataGet.discountPrice
          : data.isDataGet.price,
      image: data.isDataGet.mainImage[0].imageurl,
      productStock: data.isDataGet.productStock,
      size,
      colour,
      quantity,
      selectBox,
      productShippingCharges,
    };

    try {

      dispatch({
        type: ADD_TO_CART_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.post(
        `/api/create/cart`,
        { cartData },
        config
      );
      dispatch({
        type: ADD_TO_CART_SUCCESS,
        payload: data.msg,
      });
    } catch (error) {
      return dispatch({
        type: ADD_TO_CART_FAIL,
        payload: error.response.data.msg,
      });
    }

    // localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems));
  };

// remove cart action

export const cartRemoveAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: REMOVE_FROM_CART_REQUEST,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.delete(`/api/remove/cart/${id}`, config);

    dispatch({
      type: REMOVE_FROM_CART_SUCCESS,
      payload: data.msg,
    });
  } catch (error) {
    dispatch({
      type: REMOVE_FROM_CART_FAIL,
      payload: error.response.data.msg,
    });
  }

  // localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems));
};

// all cart items

export const allcartItemAction = () => async (dispatch) => {
  try {
    dispatch({
      type: ALL_CART_ITEM_REQUEST,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.get(`/api/cart`, config);
    // console.log(data)
    dispatch({
      type: ALL_CART_ITEM_SUCCESS,
      payload: data.msg,
    });
  } catch (error) {
    dispatch({
      type: ALL_CART_ITEM_FAIL,
      payload: error.response.data.msg,
    });
  }

  // localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems));
};

export const clearErrorAction = () => async (dispatch) => {
  dispatch({
    type: CLEAR_CART_ERROR,
  });
};

// Saving cart info
export const savingCartInfoAction = (data) => async (dispatch) => {
  dispatch({
    type: SAVE_SHIPPING_INFO,
    payload: data,
  });
  localStorage.setItem("shippingInfo", JSON.stringify(data));
};


export const savingBuyItemInfoAction =(data) => (dispatch)=> async(getState) => {


console.log(data)

  dispatch({
    type: SAVE_BUY_ITEM_INFO,
    payload: data,
  });
  localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems));
}
