import React, { Fragment } from 'react'

import MetaTag from '../../layout/MetaTag';
import {Helmet} from 'react-helmet'
import Navbar from '../HomeScreen/Navbar'
import SearchItems from './SearchItems'
import Footer from '../HomeScreen/bottomsection/Footer'

import './search.css'


function SearchScreen() {
    return (
        <Fragment>
            <MetaTag  title={'IM SHOPPING || SEARCH PAGE|| Search your shoes here...'}/>
            <Helmet  >
            <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=2.0" />
            <meta name="description" content={`Find the Shoes at IMONLINE.SHOP. search page , auto complete search , search page , product search , im search ,   Free delivery and returns on select orders. ,  search shoes here , im search page , auto complete search  `   } />
            <meta name="keywords" content={`  Shoes search page , auto complete page , im search , search here , search page, imonline.shop , search  page   search here `} />
            <meta name="robots" content="index, follow " />
            <meta property="og:title" content={`search  Shoes page . IMONLINE.SHOP , search page , auto complete search  page , im search page , search ,   `} />
            <meta property="og:description" content={`Find the  Shoes at IMONLINE.SHOP.  search here , search your shoes here , search shoes , search page  Free delivery and returns on select orders  , product page `} />
            <meta property="og:type" content="website" />
            <meta property="og:locale" content="en-GB_IN" />
            <meta property="og:site_name" content="IMONLINE.SHOP" />
     
          </Helmet>
              
            <Navbar />
            <div className="search-main-container-div">
                <div className="heading-of-im-search-product">
                    <h3> <span>i</span>M  SEARCH</h3>
                    <div className="powered-by-heading">
                        Powered by <span>i</span>M
                    </div>

                </div>
                <SearchItems />
            </div>
            <Footer />
        </Fragment>
    )
}

export default SearchScreen

