import React, { Fragment, useEffect } from 'react'
import { DataGrid } from '@material-ui/data-grid'
import { Link, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { clearErrorAction, allProductToWishListActionForAdmin } from '../redux/actions/wishListAction'
import swal from 'sweetalert';
// import { Button } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
// import DeleteIcon from '@material-ui/icons/Delete'
import Loader from '../layout/loader'
import Sidebar from './Sidebar'




function AllWishlistProducts() {

  const history = useHistory();
  const dispatch = useDispatch();

  const { loading, error, wishListProducts } = useSelector((state) => state.wishListAllAdmin)


console.log(wishListProducts)
  useEffect(() => {
    if (error) {
      swal({
        title: "Validation Error",
        text: error,
        icon: "error",
      })
      dispatch(clearErrorAction())

    }


    dispatch(allProductToWishListActionForAdmin())

  }, [dispatch, error, history])



  const columns = [
    { field: 'id', headerName: 'Wishlist ID', minWidth: 200, flex: 1.2 },
    {
      field: 'ProductName',
      headerName: 'Name',
      minWidth: 300,
      flex: 1,
    },
    {
      field: 'productPrice',
      headerName: 'Product Price',
      minWidth: 100,
      flex: 0.5,
    },
    {
      field: 'userId',
      headerName: 'User Id',
      minWidth: 250,
      flex: 1,
    },
    {
      field: 'productId',
      headerName: 'Product Id',
      minWidth: 250,
      flex: 1,
    },
    {
      field: 'action',
      headerName: 'Action',
      type: 'number',
      minWidth: 150,
      flex: 0.3,
      sortable: false,
      renderCell: (params) => {
        return (
          <Fragment>
            <Link to={`/Admin/Singel/complain/${params.getValue(params.id, 'id')}`}   >
              <EditIcon />
            </Link>

            {/* <Button onClick={() => deleteProductHandler(params.getValue(params.id, 'id'))}   >
                          <DeleteIcon />
                      </Button> */}
          </Fragment>
        )
      }
    },
  ]

  const rows = [];
  wishListProducts && wishListProducts.forEach(item => {
    rows.push({
      id: item._id,
      ProductName: item.name,
      productPrice: item.price,
      userId: item.user,
      productId: item.product,
      // user: item.user,
    })
  });



  return (
    <Fragment>
      {/* {deletedLoading ? <Loader /> : (<Fragment> */}
      {loading ? <Loader /> : <Fragment>
        <div className="dashboard-container">
          <Sidebar />
          <div className="productListContainer">
            <h1 className='productHeadingList'> ALL WISHLIST PRODUCT'S </h1>
            <DataGrid

              rows={rows}
              columns={columns}
              pageSize={20}
              disableSelectionOnClick
              className='productListTable'
              autoHeight
            />
          </div>
        </div>
      </Fragment>}
      {/* </Fragment>)} */}

    </Fragment>
  )
}

export default AllWishlistProducts