import React from 'react'
import SportsMainPage from '../Products/SportsMainPage'
function Sports() {
    return (
        <div>
            <SportsMainPage  />
        </div>
    )
}

export default Sports
