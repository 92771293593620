import React, { Fragment, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { clearErrorAction, allSearchedKeywordAction } from '../redux/actions/searchAction'
import swal from 'sweetalert';
import Loader from '../layout/loader'

import './allSearchKeywords.css'


function AllSearchedKeywords() {

    const history = useHistory();
    const dispatch = useDispatch();

    const { loading, error, keywords } = useSelector((state) => state.allSearchedKeyword)

    console.log(keywords)
    useEffect(() => {
        if (error) {
            swal({
                title: "Validation",
                text: error,
                icon: "error",
            })
            dispatch(clearErrorAction())

        }


        dispatch(allSearchedKeywordAction())

    }, [dispatch, error, history])


    return (
        <Fragment>
            {loading ? <Loader /> : (<Fragment>
                <div className="dashboard-container">
                    <div className="productListContainer">
                        <h4 className='productHeadingList'> All Searched Keyword's </h4>
                    </div>
                    <div className="searched-keywords-data-container">
                        {keywords && keywords.map((value, index) => (
                            <div className="heading-container-of-searched-keywords" key={index}>
                                <h2> {value.searchKeywords}  </h2>
                                <p> {String(value.createdAt)} </p>
                            </div>
                        ))}


                    </div>


                </div>

            </Fragment>)}

        </Fragment>
    )
}

export default AllSearchedKeywords