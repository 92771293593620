import {
    ALL_COMPLAIN_REQUEST,
    ALL_COMPLAIN_REQUEST_SUCCESS,
    ALL_COMPLAIN_REQUEST_FAIL,
    SINGEL_COMPLAIN_REQUEST,
    SINGEL_COMPLAIN_REQUEST_SUCCESS,
    SINGEL_COMPLAIN_REQUEST_FAIL,
    SINGEL_COMPLAIN_REQUEST_RESET,
    DELETE_COMPLAIN_REQUEST,
    DELETE_COMPLAIN_REQUEST_SUCCESS,
    DELETE_COMPLAIN_REQUEST_FAIL,
    DELETE_COMPLAIN_REQUEST_RESET,
    UPDATE_COMPLAIN_FAIL,
    UPDATE_COMPLAIN_REQUEST,
    UPDATE_COMPLAIN_SUCCESS,
    UPDATE_COMPLAIN_RESET,
    COMPLAIN_REQUEST,
    COMPLAIN_REQUEST_SUCCESS,
    COMPLAIN_REQUEST_FAIL,
    COMPLAIN_REQUEST_RESET,
    CLEAR_ERROR
} from '../constants/complainConstants'



// CREATE COMPLAIN BY USER REQUEST
export const createComplainReducer = (state = {}, action) => {
    switch (action.type) {

        case COMPLAIN_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case COMPLAIN_REQUEST_SUCCESS:
            return {
                loading: false,
                complainSubmit: action.payload,
            };
        case COMPLAIN_REQUEST_FAIL:
            return {
                loading: false,
                error: action.payload,
            };
        case COMPLAIN_REQUEST_RESET:
            return {
                ...state,
                complainSubmit: false,
            };
        case CLEAR_ERROR:
            return {
                ...state,
                error: null
            }
        default:
            return state;
    };


}


// GET ALL COMPLAIN BY ADMIN
export const allComplainReducerByAdmin = (state = {complains: []}, action) => {
    switch (action.type) {

        case ALL_COMPLAIN_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case ALL_COMPLAIN_REQUEST_SUCCESS:
            return {
                loading: false,
                complains: action.payload,
            };
        case ALL_COMPLAIN_REQUEST_FAIL:
            return {
                loading: false,
                error: action.payload,
            };
        case CLEAR_ERROR:
            return {
                ...state,
                error: null
            }
        default:
            return state;
    };


}




// GET singel COMPLAIN BY ADMIN
export const singelComplainReducerByAdmin = (state = {
    complain: []
}, action) => {
    switch (action.type) {

        case SINGEL_COMPLAIN_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SINGEL_COMPLAIN_REQUEST_SUCCESS:
            return {
                loading: false,
                complain: action.payload,
            };
        case SINGEL_COMPLAIN_REQUEST_FAIL:
            return {
                loading: false,
                error: action.payload,
            };
        case SINGEL_COMPLAIN_REQUEST_RESET:
            return {
                ...state,
                complain: false,
            };

        case CLEAR_ERROR:
            return {
                ...state,
                error: null
            }
        default:
            return state;
    };


}

// UPDATE COMPLAIN BY ADMIN
export const updateComplainReducerByAdmin = (state = {}, action) => {
    switch (action.type) {

        case UPDATE_COMPLAIN_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case UPDATE_COMPLAIN_SUCCESS:
            return {
                loading: false,
                isUpdated: action.payload,
            };
        case UPDATE_COMPLAIN_FAIL:
            return {
                loading: false,
                error: action.payload,
            };
        case UPDATE_COMPLAIN_RESET:
            return {
                ...state,
                isUpdated: false,
            };

        case CLEAR_ERROR:
            return {
                ...state,
                error: null
            }
        default:
            return state;
    };


}



// DELETED COMPLAIN BY ADMIN
export const deleteComplainReducerByAdmin = (state = {}, action) => {
    switch (action.type) {

        case DELETE_COMPLAIN_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case DELETE_COMPLAIN_REQUEST_SUCCESS:
            return {
                loading: false,
                isDeleted: action.payload,
            };
        case DELETE_COMPLAIN_REQUEST_FAIL:
            return {
                loading: false,
                error: action.payload,
            };
        case DELETE_COMPLAIN_REQUEST_RESET:
            return {
                ...state,
                isDeleted: false,
            };

        case CLEAR_ERROR:
            return {
                ...state,
                error: null
            }
        default:
            return state;
    };


}