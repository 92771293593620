import React, { Fragment, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import swal from 'sweetalert'
import './imForm.css'
import Loader from '../layout/loader'
import { clearErrorAction, createFormActionByUser } from '../redux/actions/imFormAction'
import axios from 'axios'
import LogoImage from '../images/output-onlinepngtools-removebg-preview.png'

import {CREATE_FORM_RESET} from '../redux/constants/imFormConstant'

function ImFormData() {

    const dispatch = useDispatch();
    const history = useHistory();


    const { loading, error, success } = useSelector((state) => state.createForm)

    const { user } = useSelector((state) => state.user)

    const [name, setName] = useState(user.firstName)
    const [email, setEmail] = useState(user.email)
    const [phone, setPhone] = useState(user.number)
    const [pincode, setPincode] = useState()
    const [address, setAddress] = useState('');
    const [state, setState] = useState('');
    const [city, setCity] = useState('')
    const [address1, setAddress1] = useState('');
    const [moreDetails, setMoreDetails] = useState('')


    const [pincodeVerify, setPincodeVerfiy] = useState('')


    useEffect(() => {
        if (error) {
            swal({
                title: 'Form Error !!',
                text: error,
                icon: 'error'
            })
            dispatch(clearErrorAction())
        }

        if (success) {
            swal({
                title: 'Success',
                text: success,
                icon: 'success'
            })
            history.push('/')
            dispatch({type : CREATE_FORM_RESET})
        }

    }, [error, dispatch, success, history])


    const CityPincodeHandler = async (e) => {
        e.preventDefault();
        if (!pincodeVerify) {
            return swal({
                title: "Validation Error",
                text: "Please type Pincode !",
                icon: "info"
            });
        }
        setPincode(pincodeVerify)
        const { data } = await axios.get(`https://api.postalpincode.in/pincode/${pincodeVerify}`);
        if (data[0].Status === 'Error') {
            return swal({
                title: "Validation Error",
                text: "Please type valid Pincode !",
                icon: "info",
                button: "OK",
            });
        } else {
            setCity(data[0].PostOffice[0].Name)
            setState(data[0].PostOffice[0].State)

        }

    }


    const formSubmitHandler = (e) => {
        e.preventDefault()
        dispatch(createFormActionByUser(name, email, phone, pincode, city, state, address, address1, moreDetails,user._id))
    }


    return (
        <Fragment>
            <div className="im-form-main-div">

                <div className="im-form-main-container-div">
                    <div className="logo-div">
                        <img src={LogoImage} alt='logo-img' />
                    </div>

                </div>


                {loading ? (<Fragment>
                    <Loader />
                </Fragment>) : (
                    <div className="form-main-div-container">
                        <div className="form-div-heading">
                            <p>Please Let Us Know About You.</p>
                        </div>
                        <form className='im-form-data' onSubmit={formSubmitHandler} action="POST">

                            <div className="form-input-div">
                                <input type="text" placeholder='Enter Your Name..' value={name} onChange={(e) => setName(e.target.value)} required />
                            </div>
                            <div className="form-input-div">
                                <input type="email" placeholder='Enter Your Registered Email..' value={email} onChange={(e) => setEmail(e.target.value)} required />
                            </div>
                            <div className="form-input-div">
                                <input type="number" placeholder='Enter Your Registered Moblie..' value={phone} onChange={(e) => setPhone(e.target.value)} required />
                            </div>
                            <div className="form-input-div-im-from">
                                <input type='number' placeholder='Please Enter Your Pincode..' value={pincodeVerify} onChange={(e) => setPincodeVerfiy(e.target.value)} />
                                <button onClick={CityPincodeHandler} className='pincode-btn-verfiy-im-form'>Search here</button>
                            </div>
                            <div className="form-input-div">
                                <input type="text" placeholder='Auto Fill State Name.. ' value={state} onChange={(e) => setState(e.target.value)} required readOnly={true} />
                            </div>
                            <div className="form-input-div">
                                <input type="text" placeholder='Auto Fill City Name.. ' value={city} onChange={(e) => setCity(e.target.value)} required readOnly={true} />
                            </div>

                            <div className="form-input-div">
                                <input type="text" placeholder='Your House.no Ward.on example@ house 123, ward 123 ' value={address} onChange={(e) => setAddress(e.target.value)} required />
                            </div>
                            <div className="form-input-div">
                                <input type="text" placeholder='Your Address in Detail..' required value={address1} onChange={(e) => setAddress1(e.target.value)} />
                            </div>

                            <div className="form-text-area-input-div">
                                <textarea type="text" placeholder='fill details what you want example@ size of sleeper or shoe like 6,7,8,9,10, special case, and  Shoe or Sleeper color : red ' required value={moreDetails} onChange={(e) => setMoreDetails(e.target.value)} >
                                </textarea>
                            </div>
                            <button className='im-form-data-submit-button' type="submit">
                                Submit
                            </button>
                        </form>

                    </div>
                )}
            </div>
        </Fragment>
    )
}

export default ImFormData