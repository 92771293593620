import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
// redux store
import store from './redux/store';
import { userLoadingAction } from './redux/actions/userAction';
// import Loader from './layout/loader';

//links of the page
import Signin from "./Signin";
import ProductDetail from "./components/Productsdetailscreen/Productcartdetail";
import Login from "./Login";
import Home from './Home';
import Sports from './shoespages/Sports';
import Checkout from './components/Checkoutfrom/Checkout';
import userProfile from './components/user/userProfile';
import ForgotPasswordEmail from './components/LoginScreen/ForgotPasswordEmail';
import WishList from './components/user/WishListProduct';
import ComplainPage from './components/user/ComplainPage';
import NotFound from './layout/NotFound';
import SearchDataAndFilters from './components/searchScreen/SearchDataAndFilters';
import NotificationPage from './components/notificationScreen/NotificationMainPage';
// import LoaderScreen from './layout/loader'));

// form page
import ImFormData from './formData/ImFormData';

// terms and conditions
import TermsOfUses from './components/AboutScreen/Policies/TermsOfUses';
import CookiesPolicy from './components/AboutScreen/Cookies-policy/PrivacyCookies';
import MyAccountPrivileges from './components/AboutScreen/accountInformation/MyAccountPrivileges';
import HowToShop from './components/AboutScreen/accountInformation/HowToShop';
import Promotion from './components/AboutScreen/accountInformation/Promotion';
import ReturnAndExchange from './components/AboutScreen/accountInformation/ReturnAndExchange';
import ShippingTracking from './components/AboutScreen/accountInformation/ShippingTracking';
import ProductAndPricing from './components/AboutScreen/accountInformation/ProductAndPricing';
import Security from './components/AboutScreen/accountInformation/Security';
import Payment from './components/AboutScreen/accountInformation/Payment';
import GiftBox from './components/AboutScreen/accountInformation/GiftAndCard';
import BrandedProfile from './components/AboutScreen/accountInformation/BrandProfile';


// Profile routes
import UserDetails from './components/user/UserDetails';
import UpdateProfile from './components/user/UpdateProfile';
import ProtectedRoute from './components/LoginScreen/ProtectedRoute';
import UpdatePassword from './components/user/UpdatePassword';
import ResetPasswordScreen from './components/LoginScreen/ResetPasswordScreen';

// payments routes
import ConfirmOrders from './components/Checkoutfrom/ConfirmOrders';
import PaymentConfirm from './components/Checkoutfrom/Payments';


// return order request by user
import ReturnOrderByUser from './components/deliveryScreen/ReturnOrderByUser';

// after payments done routes
import SuccessLogo from './components/deliveryScreen/SuccessLogo';
import CashOnDeliveryLogo from './components/deliveryScreen/CashOnDeliverySuccess';
import DeliveryPage from './components/deliveryScreen/DeliveryPage';
import DeliveryProductDetail from './components/deliveryScreen/DeliveryProductDetail';
import OrderCancelPage from './components/deliveryScreen/OrderCancelPage';
import AllReturnsOrdersByUser from './components/deliveryScreen/AllReturnsOrdersByUser';
import CancelOrderDetail from './components/deliveryScreen/CancelOrderDetail';


// direct buy routed
import ConfirmOrdersForBuyDirect from './components/Checkoutfrom/directBuySteps/ConfirmOrdersForBuyDirect';
import CheckoutForDirectBuy from './components/Checkoutfrom/directBuySteps/CheckoutForDirectBuy';
import PaymentForBuyDirect from './components/Checkoutfrom/directBuySteps/PaymentForBuyDirect';



// admin routes
import AdminRoutes from './Admin/AdminRoutes';
import AllProductsByAdmin from './Admin/AllProductsByAdmin';
import CreateNewProduct from './Admin/CreateNewProduct';
import EditProductByAdmin from './Admin/EditProductByAdmin';
import OrderRoutesByAdmin from './Admin/OrdersRoutesByAdmin';
import EditOrderByAdmin from './Admin/EditOrderByAdmin';
import AllUserByAdmin from './Admin/AllUserByAdmin';
import EditUserByAdmin from './Admin/EditUserByAdmin';
import AllAdsByAdmin from './Admin/AllAdsByAdmin';
import CreateAdsAdmin from './Admin/CreateAdsAdmin';
import EditAdsByAdmin from './Admin/EditAdsAdmin';
import AllCoupan from './Admin/AllCoupan';
import EditCoupanByAdmin from './Admin/EditCoupanByAdmin';
import CreateCoupanByAdmin from './Admin/CreateCoupanByAdmin';
import AllCancelOrders from './Admin/AllCancelOrders';
import AllReturnOrdersByAdmin from './Admin/AllReturnOrdersByAdmin';
import EditReturnOrderByAdmin from './Admin/EditReturnOrderByAdmin';
import EditCancelStatusByAdmin from './Admin/EditCancelStatusByAdmin';
import AllComplainByAdmin from './Admin/AllComplainByAdmin';
import GetSingelComplainByAdmin from './Admin/GetSingelComplainByAdmin';
import AllWishlistProducts from './Admin/AllWishlistProducts';
import AllSearchedKeywords from './Admin/AllSearchedKeywords';
import CreateNotificationByAdmin from './Admin/CreateNotificationByAdmin';
import AllNotificationByAdmin from './Admin/AllNotificationByAdmin';
import EditNotificationByAdmin from './Admin/EditNotificationByAdmin';
// leyout pages
// import WelcomeTemplates from './layout/WelcomeTamplates'
// // order router by user
// import TrackingOrder from './components/user/TrackingOrder'


// Search Screen 
import SearchScreen from './components/searchScreen/SearchScreen';

import TagSearchScreen from './components/HomeScreen/middlesection/TagSearchScreen/TagSearchScreen';
import ImForms from './Admin/ImForms';

// bank account route
import AddBankAccountDetails from './components/user/AddBankAccountDetails';


// cart page
import Cartpage from './components/CartScreen/Cartpage';



function App() {

  const { isAuthenticated, user } = useSelector(state => state.user)

  React.useEffect(() => {
    store.dispatch(userLoadingAction())
  }, [])



  return (
    <Router>
   
        {isAuthenticated && <UserDetails user={user} />}
        <Switch>

          {/**  Proctected Routes */}
          <ProtectedRoute exact path="/userprofile" component={userProfile} />
          <ProtectedRoute exact path="/user-profile/add-bank-account/" component={AddBankAccountDetails} />
          <ProtectedRoute exact path="/UpdateProfile" component={UpdateProfile} />
          <ProtectedRoute exact path="/UpdatePassword" component={UpdatePassword} />
          <ProtectedRoute exact path="/Checkout" component={Checkout} />
          <ProtectedRoute exact path="/confirm/orders" component={ConfirmOrders} />
          <ProtectedRoute exact path="/confirm/payments" component={PaymentConfirm} />
          <ProtectedRoute exact path="/payment/successfull" component={SuccessLogo} />
          <ProtectedRoute exact path="/cashonDelivery/payment/successfull" component={CashOnDeliveryLogo} />
          <ProtectedRoute exact path="/orders/Details/me" component={DeliveryPage} />
          <ProtectedRoute exact path="/order/:id" component={DeliveryProductDetail} />
          <ProtectedRoute exact path="/password/reset/:token" component={ResetPasswordScreen} />
          <ProtectedRoute exact path="/profile/order-cancel/:id" component={OrderCancelPage} />
          <ProtectedRoute exact path="/profile/Cancel-Orders-Detail" component={CancelOrderDetail} />
          {/* <ProtectedRoute exact path="/user/TrackingOrder/:id" component={TrackingOrder} /> */}
          <ProtectedRoute exact path="/user/returnorder/:returnOrderData/:orderId" component={ReturnOrderByUser} />
          <ProtectedRoute exact path="/user/All/return/orders" component={AllReturnsOrdersByUser} />
          <ProtectedRoute exact path="/user/complain/request" component={ComplainPage} />


          {/* FormData */}
          <ProtectedRoute exact path="/user/fill/form/data" component={ImFormData} />


          <ProtectedRoute exact path="/user/complain/request" component={ComplainPage} />
          {/**   route for admin */}
          <ProtectedRoute isAdmin={true} exact path="/Admin/dashboard" component={AdminRoutes} />
          <ProtectedRoute isAdmin={true} exact path="/Admin/dashboard/Products" component={AllProductsByAdmin} />
          <ProtectedRoute isAdmin={true} exact path="/Admin/dashboard/Create/Product" component={CreateNewProduct} />
          <ProtectedRoute isAdmin={true} exact path="/Admin/dashboard/UpdateProduct/:id" component={EditProductByAdmin} />
          <ProtectedRoute isAdmin={true} exact path="/Admin/dashboard/Orders" component={OrderRoutesByAdmin} />
          <ProtectedRoute isAdmin={true} exact path="/Admin/dashboard/Edit/order/:id" component={EditOrderByAdmin} />
          <ProtectedRoute isAdmin={true} exact path="/Admin/dashboard/im-all/forms" component={ImForms} />
          <ProtectedRoute isAdmin={true} exact path="/Admin/dashboard/Users" component={AllUserByAdmin} />
          <ProtectedRoute isAdmin={true} exact path="/Admin/dashboard/Users/:id" component={EditUserByAdmin} />
          <ProtectedRoute isAdmin={true} exact path="/Admin/dashboard/all/ads" component={AllAdsByAdmin} />
          <ProtectedRoute isAdmin={true} exact path="/Admin/dashboard/Edit-ads/:id" component={EditAdsByAdmin} />
          <ProtectedRoute isAdmin={true} exact path="/Admin/dashboard/Create/ads" component={CreateAdsAdmin} />
          <ProtectedRoute isAdmin={true} exact path="/Admin/dashboard/all/coupans" component={AllCoupan} />
          <ProtectedRoute isAdmin={true} exact path="/Admin/dashboard/Edit-Coupan/:id" component={EditCoupanByAdmin} />
          <ProtectedRoute isAdmin={true} exact path="/Admin/dashboard/Create-Coupan" component={CreateCoupanByAdmin} />
          <ProtectedRoute isAdmin={true} exact path="/Admin/dashboard/orders/allcancel" component={AllCancelOrders} />
          <ProtectedRoute isAdmin={true} exact path="/Admin/dashboard/orders/return-orders" component={AllReturnOrdersByAdmin} />
          <ProtectedRoute isAdmin={true} exact path='/Admin/updatestatus/return/order/:id' component={EditReturnOrderByAdmin} />
          <ProtectedRoute isAdmin={true} exact path='/Admin/updatestatus/cancel-Order/:id' component={EditCancelStatusByAdmin} />
          <ProtectedRoute isAdmin={true} exact path='/Admin/Allwishlist/products' component={AllWishlistProducts} />
          {/* AllSearchedKeywords */}
          <ProtectedRoute isAdmin={true} exact path='/Admin/Allsearched/keywords' component={AllSearchedKeywords} />

          {/* complain  */}
          <ProtectedRoute isAdmin={true} exact path='/Admin/All/complain' component={AllComplainByAdmin} />
          <ProtectedRoute isAdmin={true} exact path='/Admin/Singel/complain/:id' component={GetSingelComplainByAdmin} />


          {/* notification */}
          <ProtectedRoute isAdmin={true} exact path='/Admin/Create/Notification' component={CreateNotificationByAdmin} />
          <ProtectedRoute isAdmin={true} exact path='/Admin/All/Notifications' component={AllNotificationByAdmin} />
          <ProtectedRoute isAdmin={true} exact path='/Admin/Upadte/Notifications/:id' component={EditNotificationByAdmin} />

          {/**   route for direct buy product */}
          <ProtectedRoute exact path="/confirm/orders/directbuy" component={ConfirmOrdersForBuyDirect} />
          <ProtectedRoute exact path="/Checkout/directbuy" component={CheckoutForDirectBuy} />
          <ProtectedRoute exact path="/confirm/payments/directbuy" component={PaymentForBuyDirect} />

          {/**   Routes */}
          <Route exact path="/" component={Home} />
          <Route exact path="/Cart" component={Cartpage} />
          <Route exact path="/Signin" component={Signin} />
          <Route exact path="/ProductDetail/:id" component={ProductDetail} />
          <Route exact path="/Login" component={Login} />
          <Route exact path="/Product" component={Sports} />
          <Route exact path="/Admin/dashboard/all/ads" component={AllAdsByAdmin} />
          <Route exact path='/terms-and-conditions' component={TermsOfUses} />
          <Route exact path='/Cookies-Policy' component={CookiesPolicy} />
          <Route exact path='/login-forgot-password' component={ForgotPasswordEmail} />
          <Route exact path='/MyAccount-Privilages' component={MyAccountPrivileges} />
          <Route exact path='/how-to-shop' component={HowToShop} />
          <Route exact path='/promotions' component={Promotion} />
          <Route exact path='/about/return-and-exchange' component={ReturnAndExchange} />
          <Route exact path='/about/Shipping-Tracking' component={ShippingTracking} />
          <Route exact path='/about/Product-And-Pricing' component={ProductAndPricing} />
          <Route exact path='/about/Security' component={Security} />
          <Route exact path='/about/Payment' component={Payment} />
          <Route exact path='/about/GiftBox' component={GiftBox} />
          <Route exact path='/about/BrandedProfile' component={BrandedProfile} />
          <Route exact path="/user/wishlist" component={WishList} />
          <Route exact path="/find/shoes" component={SearchScreen} />
          <Route exact path="/searched/:SearchData" component={SearchDataAndFilters} />
          <Route exact path="/catogery/:search" component={TagSearchScreen} />
          <Route exact path="/notification" component={NotificationPage} />



          {/* <Route exact path="/loader" component={LoaderScreen} /> */}
          {/* Loader */}
          {/* WelcomeTemplates
        <Route exact path="/WelcomeTemplates" component={WelcomeTemplates} /> */}


          {/* not found page */}
          <Route exact path="*" component={NotFound} />

        </Switch>
    </Router>


  );
}

export default App

