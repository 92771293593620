import React, { Fragment, useEffect } from 'react'
import { DataGrid } from '@material-ui/data-grid'
import { Link, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { clearErrorAction, allOrderAction } from '../redux/actions/orderAction'
import swal from 'sweetalert';
import EditIcon from '@material-ui/icons/Edit'
import Sidebar from './Sidebar'
import Loader from '../layout/loader';
import './allProductByAdmin.css'


function OrdersRoutesByAdmin() {
    const history = useHistory();
    const dispatch = useDispatch();
    
    const { error, orders ,loading} = useSelector((state) => state.allOrders)

    useEffect(() => {
        if (error) {
            swal({
                title: "Validation",
                text: error,
                icon: "error",
              })
            dispatch(clearErrorAction())

        }


        dispatch(allOrderAction())

    }, [dispatch, error, history])



    const columns = [
        {
            field: 'id',
            headerName: 'Order Id',
            minWidth: 200,
            flex: 0.8,
        },
        {
            field: 'ItemQty',
            headerName: 'Quantity',
            type: 'number',
            minWidth: 150,
            flex: 0.3,
        },
        {
            field: 'Amount',
            headerName: 'Price',
            type: 'number',
            minWidth: 150,
            flex: 0.2,
        },
        {
            field: 'PaymentInfo',
            headerName: 'Payment Info',
            type: 'number',
            minWidth: 250,
            flex: 0.5,
            cellClassName: (params) => {
                return params.getValue(params.id, 'PaymentInfo') === 'Transaction Successfull' ? 'greenColor' : 'redColor'
            },
        },
        {
            field: 'Status',
            headerName: 'Status',
            type: 'number',
            minWidth: 250,
            flex: 0.5,
            cellClassName: (params) => {
                return params.getValue(params.id, 'Status') === 'Delivered' ? 'greenColor' : 'redColor'
            },
        },
        {
            field: 'action',
            headerName: 'Action',
            type: 'number',
            minWidth: 150,
            flex: 0.3,
            sortable: false,
            renderCell: (params) => {
                return (
                    <Fragment>
                        <Link to={`/Admin/dashboard/Edit/order/${params.getValue(params.id, 'id')}`}   >
                            <EditIcon />
                        </Link>
                    </Fragment>
                )
            }
        },
    ]

    const rows = [];
    orders && orders.forEach(item => {
        console.log(item)
        rows.push({
            id: item._id,
            ItemQty: item.orderItems.length,
            Amount: item.totalPrice,
            PaymentInfo: item.paymentInfo.status,
            Status: item.orderStatus,
        })
    });


    return (
        <Fragment>
            {loading ? <Loader/> : (<Fragment>
                <div className="dashboard-container">
                <Sidebar />
                <div className="productListContainer">
                    <h1 className='productHeadingList'> ALL ORDER'S </h1>
                    <DataGrid

                        rows={rows}
                        columns={columns}
                        pageSize={20}
                        disableSelectionOnClick
                        className='productListTable'
                        autoHeight
                    />
                </div>

            </div>

            </Fragment>)}
        </Fragment>
    )
}


export default OrdersRoutesByAdmin