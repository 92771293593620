import axios from "axios";
import {
    CLEAR_ERROR,
    CREATE_FORM_FAIL,
    CREATE_FORM_REQUEST,
    CREATE_FORM_SUCCESS,
    DELETE_FORM_FAIL,
    DELETE_FORM_REQUEST,
    DELETE_FORM_SUCCESS,
    GET_ALL_FORM_FAIL,
    GET_ALL_FORM_REQUEST,
    GET_ALL_FORM_SUCCESS,
    GET_FORM_FAIL,
    GET_FORM_REQUEST,
    GET_FORM_SUCCESS,
} from "../constants/imFormConstant";

// Create form by user reducers
export const createFormActionByUser =
    (name, email, phone, pincode,city,state, address, address2, moreDetails, user) =>
   
        async (dispatch) => {
            console.log(user)
            try {
                dispatch({
                    type: CREATE_FORM_REQUEST,
                });

                const config = {
                    headers: {
                        "Content-Type": "application/json",
                    },
                };
                const { data } = await axios.post(
                    `/api/create/form/user`,
                    { name, email, phone, pincode,city,state, address, address2, moreDetails,user },
                    config
                );

                dispatch({
                    type: CREATE_FORM_SUCCESS,
                    payload: data.msg,
                });
            } catch (error) {
                dispatch({
                    type: CREATE_FORM_FAIL,
                    payload: error.response.data.msg,
                });
            }
        };

// get all form by admin
export const getAllFormActionByAdmin = () => async (dispatch) => {
    try {
        dispatch({
            type: GET_ALL_FORM_REQUEST,
        });

        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };
        const { data } = await axios.get(
            `/api/get/all/form/admin`,
            config
        );
        dispatch({
            type: GET_ALL_FORM_SUCCESS,
            payload: data.msg,
        });
    } catch (error) {
        dispatch({
            type: GET_ALL_FORM_FAIL,
            payload: error.response.data.msg,
        });
    }
};


// get singel form by admin
export const getFormActionByAdmin = (id) => async (dispatch) => {
    try {
        dispatch({
            type: GET_FORM_REQUEST,
        });

        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };
        const { data } = await axios.get(
            `/api/get/singel/form/admin/${id}`,
            config
        );

        dispatch({
            type: GET_FORM_SUCCESS,
            payload: data.msg,
        });
    } catch (error) {
        dispatch({
            type: GET_FORM_FAIL,
            payload: error.response.data.msg,
        });
    }
};



// delete singel form by admin
export const deleteFormActionByAdmin = (id) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_FORM_REQUEST,
        });

        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };
        const { data } = await axios.delete(
            `/api/get/delete/form/admin/${id}`,
            config
        );

        dispatch({
            type: DELETE_FORM_SUCCESS,
            payload: data.msg,
        });
    } catch (error) {
        dispatch({
            type: DELETE_FORM_FAIL,
            payload: error.response.data.msg,
        });
    }
};



// clear error action
export const clearErrorAction = () => async (dispatch) => {
    dispatch({
      type: CLEAR_ERROR,
    });
  };