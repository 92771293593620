// LOGIN REQUEST
export const USER_LOGIN_REQUEST ='USER_LOGIN_REQUEST';
export const USER_LOGIN_REQUEST_SUCCESS ='USER_LOGIN_REQUEST_SUCCESS';
export const USER_LOGIN_REQUEST_FAIL ='USER_LOGIN_REQUEST_FAIL';


// SIGNIN REQUEST
export const USER_SIGNIN_REQUEST ='USER_SIGNIN_REQUEST';
export const USER_SIGNIN_REQUEST_SUCCESS ='USER_SIGNIN_REQUEST_SUCCESS';
export const USER_SIGNIN_REQUEST_FAIL ='USER_SIGNIN_REQUEST_FAIL';


// lOADING USER PROFILE
export const LOADING_USER_REQUEST ='LOADING_USER_REQUEST';
export const LOADING_USER_REQUEST_SUCCESS ='LOADING_USER_REQUEST_SUCCESS';
export const LOADING_USER_REQUEST_FAIL ='LOADING_USER_REQUEST_FAIL';
 


// UPDATE USER PROFILE
export const UPDATE_PROFILE_BY_USER_REQUEST ='UPDATE_PROFILE_BY_USER_REQUEST';
export const UPDATE_PROFILE_BY_USER_SUCCESS ='UPDATE_PROFILE_BY_USER_SUCCESS';
export const UPDATE_PROFILE_BY_USER_FAIL ='UPDATE_PROFILE_BY_USER_FAIL';
export const UPDATE_PROFILE_BY_USER_RESET ='UPDATE_PROFILE_BY_USER_RESET';



// LOGOUT USER PROFILE
export const LOGOUT_USER_PROFILE_SUCCESS ='UPDATE_USER_PROFILE_REQUEST';
export const LOGOUT_USER_PROFILE_FAIL ='UPDATE_USER_PROFILE_SUCCESS';



// UPDATE USER PASSWORD
export const UPDATE_USER_PASSWORD_REQUEST ='UPDATE_USER_PASSWORD_REQUEST';
export const UPDATE_USER_PASSWORD_SUCCESS ='UPDATE_USER_PASSWORD_SUCCESS';
export const UPDATE_USER_PASSWORD_FAIL ='UPDATE_USER_PASSWORD_FAIL';
export const UPDATE_USER_PASSWORD_RESET ='UPDATE_USER_PASSWORD_RESET';



// ALL USER BY ADMIN
export const ALL_USER_REQUEST_BY_ADMIN ='ALL_USER_REQUEST_BY_ADMIN';
export const ALL_USER_SUCCESS_BY_ADMIN ='ALL_USER_SUCCESS_BY_ADMIN';
export const ALL_USER_FAIL_BY_ADMIN ='ALL_USER_FAIL_BY_ADMIN';



//SINGEL USER DETAIL'S BY ADMIN
export const USER_DETAIL_REQUEST_BY_ADMIN ='USER_DETAIL_REQUEST_BY_ADMIN';
export const USER_DETAIL_SUCCESS_BY_ADMIN ='USER_DETAIL_SUCCESS_BY_ADMIN';
export const USER_DETAIL_FAIL_BY_ADMIN ='USER_DETAIL_FAIL_BY_ADMIN';



// UPDATE USER BY ADMIN
export const UPDATE_USER_DETAIL_REQUEST ='UPDATE_USER_DETAIL_REQUEST';
export const UPDATE_USER_DETAIL_SUCCESS ='UPDATE_USER_DETAIL_SUCCESS';
export const UPDATE_USER_DETAIL_FAIL ='UPDATE_USER_DETAIL_FAIL';
export const UPDATE_USER_DETAIL_RESET ='UPDATE_USER_DETAIL_RESET';



// DELETE USER BY ADMIN
export const DELETE_USER_DETAIL_REQUEST ='DELETE_USER_DETAIL_REQUEST';
export const DELETE_USER_DETAIL_SUCCESS ='DELETE_USER_DETAIL_SUCCESS';
export const DELETE_USER_DETAIL_FAIL ='DELETE_USER_DETAIL_FAIL';
export const DELETE_USER_DETAIL_RESET ='DELETE_USER_DETAIL_RESET';



// RESET PASSWORD BY EMAIL
export const RESET_PASSWORD_BY_EMAIL_REQUEST ='RESET_PASSWORD_BY_EMAIL_REQUEST';
export const RESET_PASSWORD_BY_EMAIL_SUCCESS ='RESET_PASSWORD_BY_EMAIL_SUCCESS';
export const RESET_PASSWORD_BY_EMAIL_FAIL ='RESET_PASSWORD_BY_EMAIL_FAIL';



//PASWORD RESET BY TOKEN
export const PASSWORD_RESET_REQUEST ='PASSWORD_RESET_REQUEST';
export const PASSWORD_RESET_SUCCESS ='PASSWORD_RESET_SUCCESS';
export const PASSWORD_RESET_FAIL ='PASSWORD_RESET_FAIL';



export const CLEAR_ERROR ='CLEAR_ERROR';