import React, { Fragment } from 'react'
import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { userUpdateAction, clearErrorAction, userLoadingAction } from '../../redux/actions/userAction'
import swal from 'sweetalert';
import MetaTag from '../../layout/MetaTag';

import './updateprofile.css'
import UpdateProfileImage from '../../images/undraw_Prototyping_process_re_7a6p.png';

import Loader from '../../layout/loader';
import { UPDATE_PROFILE_BY_USER_RESET } from '../../redux/constants/userConstants'


function UpdateProfile() {
    const history = useHistory();
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.user)
    const { loading,error, isUpdated } = useSelector(state => state.profileUpdateByUser)

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [number, setNumber] = useState('')
    const [gender, setGender] = useState('')


    // on submit fuction 

    const userUpdateSubmitHandler = async (e) => {
        e.preventDefault();

        if (!gender) {
            swal({
                title: "Validation Error",
                text: "please fill all fields..!",
                icon: "error",
            })
        }

        dispatch(userUpdateAction(firstName, lastName, email, number, gender))
    }


    useEffect(() => {
        if(user){
            setFirstName(user.firstName)
            setLastName(user.lastName)
            setEmail(user.email)
            setNumber(user.number)
            setGender(user.gender)
        }
        if (error) {
            swal({
                title: "Validation",
                text: error,
                icon: "error",
            })
            dispatch(clearErrorAction())
        }
        if (isUpdated) {
            swal({
                title: "Updated !!",
                text: isUpdated,
                icon: "success",
            })
            dispatch(userLoadingAction());
            history.push('/userprofile')
            dispatch({ type: UPDATE_PROFILE_BY_USER_RESET });
        }


    }, [dispatch, error, history, isUpdated, user])





    return (
        <Fragment>
            <MetaTag  title={'IM Online Shopping Update Profile Page'} />
            {loading ? <Loader /> : (<Fragment>
                <div className="update-profile-main-div">

                    <div className="update-prfile-image-div">
                        <img src={UpdateProfileImage} alt="www.imonline.shop" />
                    </div>

                    <div className="update-profile-form-div">
                        <div className="update-profile-form-div-heading">
                            <h2>update profile</h2>
                        </div>
                        <form onSubmit={userUpdateSubmitHandler} >
                            <div className='first-name-div-input'>
                                <input type="text" placeholder='First-name' value={firstName} onChange={(e) => setFirstName(e.target.value)} required />
                            </div>

                            <div className='first-name-div-input'>
                                <input type="text" placeholder='Last-name' value={lastName} onChange={(e) => setLastName(e.target.value)} required />
                            </div>

                            <div className='first-name-div-input'>
                                <input type="email" placeholder='Email' value={email} onChange={(e) => setEmail(e.target.value)} required />
                            </div>

                            <div className='first-name-div-input'>
                                <input type="number" placeholder='Number' value={number} onChange={(e) => setNumber(e.target.value)} required />
                            </div>

                            <div className="gender-input-right-side">
                                <label >Male :</label>
                                <input type="radio" onChange={(e) => setGender(e.target.value)} name="gender" value="male" />
                                <label >Female :</label>
                                <input type="radio" onChange={(e) => setGender(e.target.value)} name="gender" value="female" />
                                <label >Others :</label>
                                <input type="radio" onChange={(e) => setGender(e.target.value)} name="gender" value="others" />
                            </div>
                            <div className="button-update-form-div">
                                <button > Update Profile   </button>
                            </div>
                        </form>
                    </div>
                </div>


            </Fragment>)}

        </Fragment>
    )
}

export default UpdateProfile
