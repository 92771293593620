

import {
    ALL_COMPLAIN_REQUEST,
    ALL_COMPLAIN_REQUEST_SUCCESS,
    ALL_COMPLAIN_REQUEST_FAIL,
    SINGEL_COMPLAIN_REQUEST,
    SINGEL_COMPLAIN_REQUEST_SUCCESS,
    SINGEL_COMPLAIN_REQUEST_FAIL,
    DELETE_COMPLAIN_REQUEST,
    DELETE_COMPLAIN_REQUEST_SUCCESS,
    DELETE_COMPLAIN_REQUEST_FAIL,
    UPDATE_COMPLAIN_REQUEST,
    UPDATE_COMPLAIN_SUCCESS,
    UPDATE_COMPLAIN_FAIL,
    COMPLAIN_REQUEST,
    COMPLAIN_REQUEST_SUCCESS,
    COMPLAIN_REQUEST_FAIL,
    CLEAR_ERROR
} from '../constants/complainConstants';


import axios from 'axios'

// import {URL} from '../../Url';


// create complain by user
export const createComplainAction = (userName,userNumber,email,complainType,msg) => async (dispatch) => {
    try {

        dispatch({
            type: COMPLAIN_REQUEST
        })
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        const {
            data
        } = await axios.post(`/api/Complain/request`, {userName,userNumber,email,complainType,msg}, config)
        dispatch({
            type: COMPLAIN_REQUEST_SUCCESS,
            payload: data.msg
        })
    } catch (error) {
        dispatch({
            type: COMPLAIN_REQUEST_FAIL,
            payload: error.response.data.msg
        })
    }


}



// get all complain by admin
export const getAllComplainAction = () => async (dispatch) => {
    try {

        dispatch({
            type: ALL_COMPLAIN_REQUEST
        })
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        const {
            data
        } = await axios.get(`/api/all/complain-by-admin`, config)
        dispatch({
            type: ALL_COMPLAIN_REQUEST_SUCCESS,
            payload: data.createComplain
        })
    } catch (error) {
        dispatch({
            type: ALL_COMPLAIN_REQUEST_FAIL,
            payload: error.response.data.msg
        })
    }


}



// get singel complain by admin
export const singelComplainAction = (id) => async (dispatch) => {
    try {

        dispatch({
            type: SINGEL_COMPLAIN_REQUEST
        })
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        const {
            data
        } = await axios.get(`/api/Singel/Complain/${id}`, config)
        dispatch({
            type: SINGEL_COMPLAIN_REQUEST_SUCCESS,
            payload: data.createComplain
        })
    } catch (error) {
        dispatch({
            type: SINGEL_COMPLAIN_REQUEST_FAIL,
            payload: error.response.data.msg
        })
    }


}


// update complain by admin
export const updateComplainAction = (id,complainStatus) => async (dispatch) => {
    try {

        dispatch({
            type: UPDATE_COMPLAIN_REQUEST
        })
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        const {
            data
        } = await axios.put(`/api/Update/Complain/by-admin/${id}`,{complainStatus},config)
        dispatch({
            type: UPDATE_COMPLAIN_SUCCESS,
            payload: data.msg
        })
    } catch (error) {
        dispatch({
            type: UPDATE_COMPLAIN_FAIL,
            payload: error.response.data.msg
        })
    }


}






// delete complain by admin
export const deleteComplainAction = (id) => async (dispatch) => {
    try {

        dispatch({
            type: DELETE_COMPLAIN_REQUEST
        })
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        const {
            data
        } = await axios.delete(`/api/Delete/Complain/${id}`, config)
        dispatch({
            type: DELETE_COMPLAIN_REQUEST_SUCCESS,
            payload: data.msg
        })
    } catch (error) {
        dispatch({
            type: DELETE_COMPLAIN_REQUEST_FAIL,
            payload: error.response.data.msg
        })
    }


}


// clear the error 


export const clearErrorAction = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERROR
    })
}