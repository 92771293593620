import React, { Fragment, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { clearErrorAction, getSingelCancelOrderByAdmin, updateCancelOrderStatusByAdmin } from '../redux/actions/orderAction'
import { OrderDetailAction } from '../redux/actions/orderAction'
import { Typography } from '@mui/material'
import { Button } from '@material-ui/core'
import Sidebar from './Sidebar'
import Loader from '../layout/loader'
import swal from 'sweetalert';
import './editOrderByAdmin.css'
import { ORDER_CANCEL_UPDATE_STATUS_RESET } from '../redux/constants/orderConstants'

import CancelOrderDetailsPage2 from './CancelOrderDetailsPage2'

function EditCancelStatusByAdmin(props) {
  const dispatch = useDispatch();
  const history = useHistory();


  const { loading: orderLoading, order, error: orderError } = useSelector((state) => state.orderDetail)
  // orderDetail
  const { loading, error, cancelOrder } = useSelector((state) => state.singelCancelOrder)

  const { loading: cancelOrderLoading, error: cancelOrdersError, returnOrders } = useSelector((state) => state.cancelOrderStatusUpdateByAdmin)

  const [status, setStatus] = useState('')
  const [orderData, setOrderData] = useState('')

  let id = props.match.params.id;



  useEffect(() => {

    if (error) {
      swal({
        title: "Error",
        text: error,
        icon: "error",
      })
      dispatch(clearErrorAction())
    }
    if (orderError) {
      swal({
        title: "Error",
        text: orderError,
        icon: "error",
      })
      dispatch(clearErrorAction())
    }
    if (cancelOrdersError) {
      swal({
        title: "Error",
        text: cancelOrdersError,
        icon: "error",
      })
      dispatch(clearErrorAction())
    }

    if (returnOrders) {
      swal({
        title: "Cancel Status",
        text: returnOrders,
        icon: "success",
      })
      dispatch({ type: ORDER_CANCEL_UPDATE_STATUS_RESET })
    }


    dispatch(getSingelCancelOrderByAdmin(id))

  }, [cancelOrdersError, dispatch, error, history, id, orderError, returnOrders])






  const cancelOrderStatusSubmitHandler = async (e) => {
    e.preventDefault();
    if (!status) {
      return swal({
        title: "Validation Error",
        text: "Please Fill Status",
        icon: "info",
      })
    }

    dispatch(updateCancelOrderStatusByAdmin(id, status,cancelOrder && cancelOrder.userPhone));

  }


  const orderDetailHandler = (e) => {
    e.preventDefault();
    dispatch(OrderDetailAction(orderData))
    setOrderData('')

  }


  return (
    <Fragment>
      {cancelOrderLoading ? <Loader /> : (<Fragment>
        {loading ? <Loader /> : (<Fragment>
          <div className="dashboard-container">
            <Sidebar />

            <div className="edit-order-confirm-order-page">
              <div>
                <div className="edit-order-confirm-shipping-area">
                  <Typography>User Information      </Typography>
                  <div className="edit-order-confirm-shipping-area-box">
                    <div>
                      <p>Name:</p>
                      <span>{cancelOrder && cancelOrder.userName}</span>
                    </div>
                    <div>
                      <p>Email:</p>
                      <span>{cancelOrder && cancelOrder.userEmail}</span>
                    </div>
                    <div>
                      <p>Phone:</p>
                      <span>{cancelOrder && cancelOrder.userPhone}</span>
                    </div>
                  </div>
                </div>

                <div className="edit-order-confirm-shipping-area">
                  <Typography>Cancel Status </Typography>
                  <div className="edit-order-confirm-shipping-area-box">
                    <div>
                      <p>Cancel Request Date:</p>
                      <span> {String(cancelOrder.cancelInformation && cancelOrder.cancelInformation.createdAt).substring(0, 10)}</span>
                    </div>
                    <div>
                      <p>Status:</p>
                      <span style={{ color: cancelOrder.cancelInformation && cancelOrder.cancelInformation.status === 'Processing' ? 'red' : 'green' }} >{cancelOrder.cancelInformation && cancelOrder.cancelInformation.status}</span>
                    </div>
                    <div>
                      <p>Reason:</p>
                      <span>{cancelOrder && cancelOrder.cancelReason}</span>
                    </div>
                    <div>
                      <p> User Id:</p>
                      <span>{cancelOrder && cancelOrder.user}</span>
                    </div>
                    <div style={{ display: cancelOrder.cancelInformation && cancelOrder.cancelInformation.status === 'Processing' ? 'none' : 'block' }} >
                      <p>Cancel Request Confirm Date:</p>
                      <span> {String(cancelOrder.cancelConfirmAt && cancelOrder.cancelConfirmAt).substring(0, 10)}</span>
                    </div>
                  </div>
                </div>


                <Typography>Order ID </Typography>
                <div className="edit-order-confirm-shipping-area-box">
                  <div>
                    <p>Order:</p>
                    <span>{cancelOrder && cancelOrder.orderId}</span>
                  </div>
                </div>


                <div className="order-details-div">
                  <div className='order-details-container'>
                    <form onSubmit={orderDetailHandler}>
                      <input type="text" placeholder='Type Order id' required value={orderData} onChange={(e) => setOrderData(e.target.value)} />
                      {orderLoading ? (<button type="submit" disabled> ...</button>) : (<button type="submit"> Get</button>)}
                    </form>
                  </div>
                </div>
                <CancelOrderDetailsPage2  order={order} orderLoading={orderLoading} />

              </div>
              <div style={{ display: cancelOrder.cancelInformation && cancelOrder.cancelInformation.status === 'Processing' ? 'block' : 'none' }}   >
                <div className="edit-order-order-summery">
                  <Typography> Processing Order </Typography>
                  <div >
                    <div className='order-update-form'>
                      <select onChange={(e) => setStatus(e.target.value)}>
                        <option >Choose Status</option>
                        <option value='Confirmed'>Confirmed</option>
                      </select>
                    </div>
                    <Button
                      id='createProductBtn'
                      onClick={cancelOrderStatusSubmitHandler}
                      disabled={loading ? true : false}
                    >
                      Status Confirm
                    </Button>

                  </div>
                </div>
              </div>

            </div>
          </div>
        </Fragment>)}
      </Fragment>)}
    </Fragment>
  )
}

export default EditCancelStatusByAdmin