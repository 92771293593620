import React, { useEffect, Fragment, } from 'react'
import {
    useParams
} from "react-router-dom";

import './New.css';

import { useDispatch, useSelector } from 'react-redux'
import { getProductByTagAction, clearErrorAction } from '../../../../redux/actions/productAction'
import swal from 'sweetalert';
import TagPagination from './TagPagination';
import Footer from '../../../HomeScreen/bottomsection/Footer'
import Navbar from '../../Navbar'

function TagSearchScreen() {
    let { search } = useParams();
    const dispatch = useDispatch();

    const { products, loading, error } = useSelector((state) => state.categoryProduct)

    useEffect(() => {
        if (error) {
            swal({
                title: "Error",
                text: error,
                icon: "error",
            })
            dispatch(clearErrorAction())
        }


        dispatch(getProductByTagAction(search))
    }, [error, dispatch, search])

    const resultPerPages = 40;

    return (
        <Fragment>

            {/* Nav bar  */}
            <Navbar />

            {/* Menu bar */}
                <TagPagination loading={loading} resultPerPages={resultPerPages} products={products} />
    
            {/* footer */}
            <div>
                <Footer />
            </div>
        </Fragment>

    )
}

export default TagSearchScreen