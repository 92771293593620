import {
    CREATE_ORDER_REQUEST,
    CREATE_ORDER_SUCCESS,
    CREATE_ORDER_FAIL,
    MY_ORDER_REQUEST,
    MY_ORDER_SUCCESS,
    MY_ORDER_FAIL,
    ORDER_DETAIL_FAIL,
    ORDER_DETAIL_SUCCESS,
    ORDER_DETAIL_REQUEST,
    CLEAR_ERROR,
    ALL_ORDER_REQUEST,
    ALL_ORDER_SUCCESS,
    ALL_ORDER_FAIL,
    UPDATE_ORDER_REQUEST,
    UPDATE_ORDER_SUCCESS,
    UPDATE_ORDER_FAIL,
    UPDATE_ORDER_REST,
    ORDER_CANCEL_REQUEST,
    ORDER_CANCEL_SUCCESS,
    ORDER_CANCEL_REQUEST_FAIL,
    ORDER_CANCEL_REQUEST_RESET,
    INVOICE_CREATE_REQUEST,
    INVOICE_CREATE_SUCCESS,
    INVOICE_CREATE_FAIL,
    TRACKING_ORDER_REQUEST,
    TRACKING_ORDER_SUCCESS,
    TRACKING_ORDER_FAIL,
    RETURN_ORDER_REQUEST,
    RETURN_ORDER_SUCCESS,
    RETURN_ORDER_FAIL,
    GET_SINGAL_RETURN_ORDER_REQUEST,
    GET_SINGAL_RETURN_ORDER_SUCCESS,
    GET_SINGAL_RETURN_ORDER_FAIL,
    ALL_RETURN_ORDER_REQUEST,
    ALL_RETURN_ORDER_SUCCESS,
    ALL_RETURN_ORDER_FAIL,
    ALL_RETURN_ORDER_REQUEST_BY_ADMIN,
    ALL_RETURN_ORDER_SUCCESS_BY_ADMIN,
    ALL_RETURN_ORDER_FAIL_BY_ADMIN,
    RETURN_ORDER_STATUS_UPDATE_REQUEST,
    RETURN_ORDER_STATUS_UPDATE_SUCCESS,
    RETURN_ORDER_STATUS_UPDATE_FAIL,
    GET_SINGAL_CANCEL_ORDER_REQUEST,
    GET_SINGAL_CANCEL_ORDER_SUCCESS,
    GET_SINGAL_CANCEL_ORDER_FAIL,
    ALL_ORDER_CANCEL_REQUEST,
    ALL_ORDER_CANCEL_SUCCESS,
    ALL_ORDER_CANCEL_REQUEST_FAIL,
    ALL_ORDER_CANCEL_REQUEST_BY_ADMIN,
    ALL_ORDER_CANCEL_SUCCESST_BY_ADMIN,
    ALL_ORDER_CANCEL_REQUEST_FAIT_BY_ADMIN,
    ORDER_CANCEL_UPDATE_STATUS_REQUEST,
    ORDER_CANCEL_UPDATE_STATUS_SUCCESS,
    ORDER_UPDATE_STATUS_REQUEST,
    ORDER_UPDATE_STATUS_SUCCESS,
    ORDER_UPDATE_STATUS_FAIL,
    ORDER_UPDATE_STATUS_RESET,
    ORDER_CANCEL_UPDATE_STATUS_FAIL
} from '../constants/orderConstants'


// FOR DELIVERY DATA REDUCER
export const createOrderReducer = (state = {}, action) => {
    switch (action.type) {

        case CREATE_ORDER_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CREATE_ORDER_SUCCESS:
            return {
                loading: false,
                order: action.payload,
            };
        case CREATE_ORDER_FAIL:
            return {
                loading: false,
                error: action.payload,
            };
        case CLEAR_ERROR:
            return {
                ...state,
                error: null
            }
        default:
            return state;
    };


}


// FOR CREATE INVOICE BY USER
export const createInvoiceByUserReducer = (state = {}, action) => {
    switch (action.type) {

        case INVOICE_CREATE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case INVOICE_CREATE_SUCCESS:
            return {
                loading: false,
                invoice: action.payload,
            };
        case INVOICE_CREATE_FAIL:
            return {
                loading: false,
                error: action.payload,
            };
        case CLEAR_ERROR:
            return {
                ...state,
                error: null
            }
        default:
            return state;
    };


}


// RETURN ORDER BY USER
export const returnOrderByUserReducer = (state = {}, action) => {
    switch (action.type) {

        case RETURN_ORDER_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case RETURN_ORDER_SUCCESS:
            return {
                loading: false,
                returnOrder: action.payload,
            };
        case RETURN_ORDER_FAIL:
            return {
                loading: false,
                error: action.payload,
            };
        case CLEAR_ERROR:
            return {
                ...state,
                error: null
            }
        default:
            return state;
    };


}


// SINGEL RETURN ORDER BY USER
export const singelReturnOrderByAdminReducer = (state = {
    returnOrder:[]
}, action) => {
    switch (action.type) {

        case GET_SINGAL_RETURN_ORDER_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_SINGAL_RETURN_ORDER_SUCCESS:
            return {
                loading: false,
                returnOrder: action.payload,
            };
        case GET_SINGAL_RETURN_ORDER_FAIL:
            return {
                loading: false,
                error: action.payload,
            };
        case CLEAR_ERROR:
            return {
                ...state,
                error: null
            }
        default:
            return state;
    };


}



// GET ALL RETURN ORDER BY USER
export const allReturnOrderByUserReducer = (state = {
    returnOrders:[],
}, action) => {
    switch (action.type) {

        case ALL_RETURN_ORDER_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case ALL_RETURN_ORDER_SUCCESS:
            return {
                loading: false,
                returnOrders: action.payload,
            };
        case ALL_RETURN_ORDER_FAIL:
            return {
                loading: false,
                error: action.payload,
            };
        case CLEAR_ERROR:
            return {
                ...state,
                error: null
            }
        default:
            return state;
    };


}



// GET ALL RETURN ORDER BY ADMIN
export const allReturnOrderByAdminReducer = (state = {
    returnOrders:[],
}, action) => {
    switch (action.type) {

        case ALL_RETURN_ORDER_REQUEST_BY_ADMIN:
            return {
                ...state,
                loading: true,
            };
        case ALL_RETURN_ORDER_SUCCESS_BY_ADMIN:
            return {
                loading: false,
                returnOrders: action.payload,
            };
        case ALL_RETURN_ORDER_FAIL_BY_ADMIN:
            return {
                loading: false,
                error: action.payload,
            };
        case CLEAR_ERROR:
            return {
                ...state,
                error: null
            }
        default:
            return state;
    };


}


// RETURN ORDER STATUS BY ADMIN
export const returnOrderStatusByAdminReducer = (state = {}, action) => {
    switch (action.type) {

        case RETURN_ORDER_STATUS_UPDATE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case RETURN_ORDER_STATUS_UPDATE_SUCCESS:
            return {
                loading: false,
                returnOrders: action.payload,
            };
        case RETURN_ORDER_STATUS_UPDATE_FAIL:
            return {
                loading: false,
                error: action.payload,
            };
        case CLEAR_ERROR:
            return {
                ...state,
                error: null
            }
        default:
            return state;
    };


}



// SINGEL CANCEL ORDER BY USER
export const singelCancelOrderByAdminReducer = (state = {
    cancelOrder:[]
}, action) => {
    switch (action.type) {

        case GET_SINGAL_CANCEL_ORDER_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_SINGAL_CANCEL_ORDER_SUCCESS:
            return {
                loading: false,
                cancelOrder: action.payload,
            };
        case GET_SINGAL_CANCEL_ORDER_FAIL:
            return {
                loading: false,
                error: action.payload,
            };
        case CLEAR_ERROR:
            return {
                ...state,
                error: null
            }
        default:
            return state;
    };


}





// GET ALL CANCEL ORDER BY USER
export const allCancelOrderByUserReducer = (state = {
    cancelOrders:[],
}, action) => {
    switch (action.type) {

        case ALL_ORDER_CANCEL_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case ALL_ORDER_CANCEL_SUCCESS:
            return {
                loading: false,
                cancelOrders: action.payload,
            };
        case ALL_ORDER_CANCEL_REQUEST_FAIL:
            return {
                loading: false,
                error: action.payload,
            };
        case CLEAR_ERROR:
            return {
                ...state,
                error: null
            }
        default:
            return state;
    };


}



// GET ALL CANCEL ORDER BY ADMIN
export const allCancelOrderByAdminReducer = (state = {
    cancelOrders:[],
}, action) => {
    switch (action.type) {

        case ALL_ORDER_CANCEL_REQUEST_BY_ADMIN:
            return {
                ...state,
                loading: true,
            };
        case ALL_ORDER_CANCEL_SUCCESST_BY_ADMIN:
            return {
                loading: false,
                cancelOrders: action.payload,
            };
        case ALL_ORDER_CANCEL_REQUEST_FAIT_BY_ADMIN:
            return {
                loading: false,
                error: action.payload,
            };
        case CLEAR_ERROR:
            return {
                ...state,
                error: null
            }
        default:
            return state;
    };


}


// CANCEL ORDER STATUS BY ADMIN
export const cancelOrderStatusByAdminReducer = (state = {}, action) => {
    switch (action.type) {

        case ORDER_CANCEL_UPDATE_STATUS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case ORDER_CANCEL_UPDATE_STATUS_SUCCESS:
            return {
                loading: false,
                returnOrders: action.payload,
            };
        case ORDER_CANCEL_UPDATE_STATUS_FAIL:
            return {
                loading: false,
                error: action.payload,
            };
        case CLEAR_ERROR:
            return {
                ...state,
                error: null
            }
        default:
            return state;
    };


}



// FOR TRACKING ORDER BY USER
export const trackingOrderByUserReducer = (state = {}, action) => {
    switch (action.type) {
        case TRACKING_ORDER_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case TRACKING_ORDER_SUCCESS:
            return {
                loading: false,
                trackingOrder: action.payload,
            };
        case TRACKING_ORDER_FAIL:
            return {
                loading: false,
                error: action.payload,
            };
        case CLEAR_ERROR:
            return {
                ...state,
                error: null
            }
        default:
            return state;
    };


}



// FOR SHOW ALL THE ORDERS {ADMIN} 

export const allOrderReducer = (state = { orders: [] }, action) => {
    switch (action.type) {

        case ALL_ORDER_REQUEST:
            return {
                loading: true,
            };
        case ALL_ORDER_SUCCESS:
            return {
                loading: false,
                orders: action.payload,
            };
        case ALL_ORDER_FAIL:
            return {
                loading: false,
                error: action.payload,
            };
        case CLEAR_ERROR:
            return {
                ...state,
                error: null
            }
        default:
            return state;
    };


}



// FOR UPDATE THE ORDER BY {ADMIN} 

export const updateOrderReducer = (state = {}, action) => {
    switch (action.type) {

        case UPDATE_ORDER_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case UPDATE_ORDER_SUCCESS:
            return {
                ...state,
                loading: false,
                isUpdated: action.payload,
            };
        case UPDATE_ORDER_FAIL:
            return {
                loading: false,
                error: action.payload,
            };
        case UPDATE_ORDER_REST:
            return {
                ...state,
                isUpdated: false,
            };
        case CLEAR_ERROR:
            return {
                ...state,
                error: null
            }
        default:
            return state;
    };


}




// FOR UPDATE THE ORDER STATUS BY {ADMIN} 

export const updateOrderStatusByAdminReducer = (state = {}, action) => {
    switch (action.type) {

        case ORDER_UPDATE_STATUS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case ORDER_UPDATE_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                isUpdated: action.payload,
            };
        case ORDER_UPDATE_STATUS_FAIL:
            return {
                loading: false,
                error: action.payload,
            };
        case ORDER_UPDATE_STATUS_RESET:
            return {
                ...state,
                isUpdated: false,
            };
        case CLEAR_ERROR:
            return {
                ...state,
                error: null
            }
        default:
            return state;
    };


}


// ORDER CANCEL REDUCER BY USER

export const cancelOrderReducer = (state = {}, action) => {
    switch (action.type) {

        case ORDER_CANCEL_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case ORDER_CANCEL_SUCCESS:
            return {
                ...state,
                loading: false,
                isCancelled: action.payload,
            };
        case ORDER_CANCEL_REQUEST_FAIL:
            return {
                loading: false,
                error: action.payload,
            };
        case ORDER_CANCEL_REQUEST_RESET:
            return {
                ...state,
                isCancelled: false,
            };
        case CLEAR_ERROR:
            return {
                ...state,
                error: null
            }
        default:
            return state;
    };


}



// FOR SHOW THE BUY ITEMS 

export const myOrderReducer = (state = { orders: [] }, action) => {
    switch (action.type) {

        case MY_ORDER_REQUEST:
            return {
                loading: true,
            };
        case MY_ORDER_SUCCESS:
            return {
                loading: false,
                orders: action.payload,
            };
        case MY_ORDER_FAIL:
            return {
                loading: false,
                error: action.payload,
            };
        case CLEAR_ERROR:
            return {
                ...state,
                error: null
            }
        default:
            return state;
    };


}

// FOR SEE THE SINGEL ITEM

export const OrderDetailReducer = (state = { order: {} }, action) => {
    switch (action.type) {

        case ORDER_DETAIL_REQUEST:
            return {
                loading: true,
            };
        case ORDER_DETAIL_SUCCESS:
            return {
                loading: false,
                order: action.payload,
            };
        case ORDER_DETAIL_FAIL:
            return {
                loading: false,
                error: action.payload,
            };
        case CLEAR_ERROR:
            return {
                ...state,
                error: null
            }
        default:
            return state;
    };


}