import React, { Fragment } from 'react'
import Slider from "react-slick";
import {Link } from 'react-router-dom'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Loader from '../../../layout/loader';

import './latestProduct.css'


function LatestProductCard({ productDetails, loading }) {
    const settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
        speed: 500,
        slidesToShow: 1,
        arrows:false,
        slidesToScroll: 1
        
    };

    return (
        <Fragment>
            {loading ? <Loader /> : (<Fragment>

                <Slider {...settings}  >
                    {productDetails && productDetails.map((products, index) => (
                        <div className="container" key={index}>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="left-side-oc">
                                        <div className="product-tag">
                                           {products.tag}
                                        </div>
                                    <h1>{products.name}</h1>
                                    <p>{products.descriptions}</p>
                                  <Link to={'/Product'}> <button> Visit Collections    </button></Link>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="right-side-img">
                                    <img src={products.image[0].imageurl} alt={products.name} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            </Fragment>)}
        </Fragment>

    )
}

export default LatestProductCard