import React, { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../layout/loader";
import MetaTag from "../../layout/MetaTag";
import swal from "sweetalert";
import ImageGallery from "react-image-gallery";
// // icons
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import ReactStars from "react-rating-stars-component";
import { Helmet } from "react-helmet";
import FavoriteIcon from "@mui/icons-material/Favorite";

//pages
// import SimilarProductMainPage from "./SimilarProductsFile/SimilarProducts";
import RealtedBrand from "./SimilarProductsFile/RealtedBrand";
import Navbar from "../HomeScreen/Navbar";
import Footer from "../HomeScreen/bottomsection/Footer";
import Reviews from "../review/Reviews";

//css
import "./producttreview.css";

// icons
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";

// redux
import {
  productDetailAction,
  clearErrorAction,
} from "../../redux/actions/productAction";
import { addProductToWishListAction } from "../../redux/actions/wishListAction";
import { ADD_PRODUCT_REQUEST_RESET } from "../../redux/constants/wishListConstant";

import { cartAction } from "../../redux/actions/cartAction";
import { ADD_TO_CART_RESET } from "../../redux/constants/cartConstants";

import { directBuyAction } from "../../redux/actions/directBuyAction";

function Productcartdetail(props) {
  // // redux functions
  const dispatch = useDispatch();
  const history = useHistory();

  // user redux functions
  const { isAuthenticated } = useSelector((state) => state.user);

  const {
    error: wishListError,
    loading: wishListLoading,
    isProductAdd,
  } = useSelector((state) => state.wishListAdd);

  // const paymentAmount = JSON.parse(sessionStorage.getItem('productInfo'));
  const { error, loading, product, realtedBrand } =
    useSelector((state) => state.productDetail);

  const {
    error: addtoCartError,
    isProductAdded,
    loading: addtoCartLoading,
  } = useSelector((state) => state.cart);

  const [quantity, setQuantity] = useState(1);
  const [size, setSize] = useState();
  const [colour, setColour] = useState();
  const [selectBox, setSelectBox] = useState();

  // product state

  useEffect(() => {
    if (error) {
      swal({
        title: "Error",
        text: error,
        icon: "error",
      });
      dispatch(clearErrorAction());
    }

    if (addtoCartError) {
      swal({
        title: "Error",
        text: addtoCartError,
        icon: "error",
      });
      dispatch(clearErrorAction());
    }

    if (isProductAdded) {
      swal({
        title: "Product Added",
        text: isProductAdded,
        icon: "success",
      });
      dispatch({ type: ADD_TO_CART_RESET });
    }
    if (addtoCartLoading) {
      swal("Please Wait....", {
        button: false,
      });
    }

    if (wishListError) {
      swal({
        title: "Error",
        text: wishListError,
        icon: "error",
      });
      dispatch(clearErrorAction());
    }
    if (wishListLoading) {
      swal("Please Wait....", {
        button: false,
      });
    }
    if (isProductAdd) {
      swal({
        title: "Success",
        text: "Added to Wishlist.",
        icon: "success",
      });
      dispatch({ type: ADD_PRODUCT_REQUEST_RESET });
    }

    dispatch(productDetailAction(props.match.params.id));
  }, [
    dispatch,
    error,
    props.match.params.id,
    isProductAdd,
    wishListError,
    wishListLoading,
    addtoCartError,
    addtoCartLoading,
    isProductAdded,
  ]);

  const incrementQuantity = () => {
    if (product.productStock <= quantity) {
      return swal({
        title: "Validation Error",
        text: "Sorry you can-not add more products",
        icon: "info",
      });
    }
    const newValue = quantity + 1;
    setQuantity(newValue);
  };

  const decrementQuantity = () => {
    if (1 >= quantity) return;
    const newDecreaseValue = quantity - 1;
    setQuantity(newDecreaseValue);
  };

  //  ADD TO CART FUNCTION
  const submitHandler = (e) => {
    e.preventDefault();
    if (!size || !colour) {
      return swal({
        title: "Validation Error",
        text: "Please Select Colour and Size !!",
        icon: "info",
      });
    }
    if (!selectBox) {
      return swal({
        title: "Validation Error",
        text: "Please Select Box !!",
        icon: "info",
      });
    }

    dispatch(
      cartAction(
        props.match.params.id,
        quantity,
        size,
        colour,
        selectBox,
        product.shippingCharges && product.shippingCharges
      )
    );
  };

  // DIRECT BUY FUNCTION
  const directBuySubmitHandler = (e) => {
    e.preventDefault();

    if (!size || !colour) {
      return swal({
        title: "Validation Error",
        text: "Please Select Colour and Size..!!",
        icon: "info",
      });
    }

    if (!selectBox) {
      return swal({
        title: "Validation Error",
        text: "Please Select Box !!",
        icon: "info",
      });
    }

    if (!isAuthenticated) {
      swal({
        title: "Validation Error",
        text: "Please Login..!!",
        icon: "info",
      });
    }

    dispatch(
      directBuyAction(
        props.match.params.id,
        quantity,
        size,
        colour,
        selectBox,
        product.shippingCharges && product.shippingCharges
      )
    );

    history.push("/Login?redirect=/Checkout/directbuy");
    // window.location.reload()
  };

  // options for rating
  const options = {
    edit: false,
    color: "gray",
    activeColor: "yellow",
    size: window.innerWidth < 600 ? 20 : 30,
    value: product.ratings,
    isHalf: true,
  };

  const images = [];
  product.image &&
    product.image.map((item) => {
      return images.push({
        original: item.imageurl,
        thumbnail: item.imageurl,
      });
    });

  const wishListSubmitHandler = (id) => {
    dispatch(addProductToWishListAction(id));
  };

  // console.log(product.shippingCharges)

  return (
    <Fragment>
      {/* {wishListLoading ? <Loader /> : (<Fragment> */}
      {loading ? (
        <MetaTag title="Loading..." />
      ) : (
        <Fragment>
          {" "}
          {product.metaTag ? (
            <MetaTag title={product.metaTag} />
          ) : (
            <Fragment>
              {" "}
              <MetaTag title={product.name} />{" "}
            </Fragment>
          )}{" "}
        </Fragment>
      )}

      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          {product.mainImage &&
            product.mainImage.map((items) => (
              <Helmet>
                <meta
                  name="viewport"
                  content="width=device-width, initial-scale=1.0, maximum-scale=2.0"
                />
                <meta
                  name="description"
                  content={`Find the ${product.metaTag} Shoes at IMONLINE.SHOP.  Free delivery and returns on select orders`}
                />
                <meta name="keywords" content={`${product.name}Shoes`} />
                <meta name="robots" content="index, follow " />
                <meta
                  property="og:title"
                  content={`${product.name} Shoes. IMONLINE.SHOP  `}
                />
                <meta
                  property="og:description"
                  content={`Find the ${product.metaTag} Shoes at IMONLINE.SHOP.  Free delivery and returns on select orders`}
                />
                <meta property="og:type" content="website" />
                <meta
                  property="og:url"
                  content={items.imageurl}
                  key={items.public_id}
                />
                <meta
                  property="og:image"
                  content={items.imageurl}
                  key={items.public_id}
                />
                <meta property="og:locale" content="en-GB_IN" />
                <meta property="og:site_name" content="IMONLINE.SHOP" />
                <meta property="og:price:currency" content="INR" />
                <meta property="og:price:amount" content={product.price} />
              </Helmet>
            ))}
          <Navbar />
          <div className="container  carosule-image-div">
            <div className="row">
              <div className="col-md-5">
                {/* <div className="carosule-image-div"> */}
                <ImageGallery items={images} />
                {/* </div> */}
              </div>

              <div className="col-md-7" id="product-details-header-section">
                <div className="product-details-header-section-area">
                  <div>
                    {" "}
                    <p className="new-arrival "> {product.tag}</p>{" "}
                  </div>
                  {/*  wishList button  */}
                  <div>
                    {" "}
                    {product.productStock < 1 ? (
                      <button
                        type="button"
                        className="wish-list-btn"
                        onClick={() => wishListSubmitHandler(product._id)}
                        disabled
                      >
                        {" "}
                        <FavoriteIcon />
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="wish-list-btn"
                        onClick={() => wishListSubmitHandler(product._id)}
                      >
                        {" "}
                        <FavoriteIcon />
                      </button>
                    )}{" "}
                  </div>
                </div>

                <h2 className="img-cart-heading">{product.name}</h2>
                <div className="img-rating">
                  <h2 className="img-cart-rating-heading">Ratings :</h2>
                  <ReactStars {...options} />
                  <span className="review-span">
                    {product.numOfRating} Reviews
                  </span>
                </div>
                {product.discountActive === true ? (
                  <>
                    <p className="img-price">
                      {" "}
                      price :{" "}
                      <span>
                        <i class="fas fa-rupee-sign"></i>{" "}
                        <s className="productCartDeatails-page-css-product-price-tag">
                          {" "}
                          {product.price}
                        </s>{" "}
                        {product.price - product.discountPrice}{" "}
                      </span>{" "}
                    </p>
                  </>
                ) : (
                  <>
                    <p className="img-price">
                      {" "}
                      price :{" "}
                      <span>
                        <i class="fas fa-rupee-sign"></i> {product.price}{" "}
                      </span>{" "}
                    </p>
                  </>
                )}

                {product.discountActive === true ? (
                  <>
                    <p className="img-brand">
                      {" "}
                      Special Discount :{" "}
                      {Math.round(
                        (product.discountPrice /
                          (product.price + product.discountPrice)) *
                          100
                      )}{" "}
                      %{" "}
                    </p>
                  </>
                ) : null}
                <p
                  className={
                    product.productStock < 1 ? "redColor" : "greenColor"
                  }
                >
                  {" "}
                  <span className="abavilbility">availability :</span>
                  {product.productStock < 1 ? "out of Stock" : "in Stock"}{" "}
                </p>
                <p className="img-brand"> brand :{product.brandName} </p>

                {/* QAULITY LABLE */}
                <p className="product-quality-details">
                  {" "}
                  Quality :{" "}
                  {product.qualityType ? (
                    <p> {product.qualityType} </p>
                  ) : (
                    <p>FC 10A Quality </p>
                  )}{" "}
                </p>
                {/**QUANTITY BUTTON */}
                <label className="img-quantity-lable"> quantity : </label>
                <button
                  className="quantity-btn-add"
                  onClick={incrementQuantity}
                >
                  <AddIcon />{" "}
                </button>
                <input
                  className="img-input-area"
                  type="number"
                  value={quantity}
                  readOnly
                />
                <button
                  className="quantity-btn-remove"
                  onClick={decrementQuantity}
                >
                  {" "}
                  <RemoveIcon />{" "}
                </button>
                {/**SIZE BUTTON */}

                <div className="colour-detail-div">
                  <span>
                    <label className="">Sizes :</label>
                    <select onChange={(e) => setSize(e.target.value)}>
                      <option value="">Select Size</option>
                      {product.sizeOfShoes &&
                        product.sizeOfShoes.map((sizes, index) => (
                          <option value={sizes} key={index}>
                            {sizes}
                          </option>
                        ))}
                    </select>
                  </span>
                </div>

                {/**COLOUR BUTTON */}
                <div className="colour-detail-div">
                  <span>
                    <label className="">Colours :</label>
                    <select onChange={(e) => setColour(e.target.value)}>
                      <option value="">Select Colour</option>
                      {product.colorOfShoes &&
                        product.colorOfShoes.map((catagery, index) => (
                          <option value={catagery} key={index}>
                            {catagery}
                          </option>
                        ))}
                    </select>
                  </span>
                </div>

                {/**Box BUTTON */}
                <div className="colour-detail-div">
                  <span>
                    <label className="">Box Type :</label>
                    <select onChange={(e) => setSelectBox(e.target.value)}>
                      <option value="">Select Packaging Type</option>
                      <option value="0"> Default-Packing</option>
                      <option value="50">Simple-Box 50₹</option>
                      <option value="150">Original-Box 150₹</option>
                    </select>
                  </span>
                </div>

                {/**BUY BUTTON */}
                {product.productStock < 1 ? (
                  <button
                    type="button"
                    className="buysuccess"
                    onClick={directBuySubmitHandler}
                    disabled
                  >
                    Buy Now <ShoppingBagIcon />{" "}
                  </button>
                ) : (
                  <button
                    type="button"
                    className="buysuccess"
                    onClick={directBuySubmitHandler}
                  >
                    {" "}
                    Buy Now <ShoppingBagIcon />{" "}
                  </button>
                )}
                {/**CART BUTTON */}

                {product.productStock < 1 ? (
                  <button
                    className="cartsecondary"
                    onClick={submitHandler}
                    disabled
                  >
                    {" "}
                    Add to cart <ShoppingCartIcon />{" "}
                  </button>
                ) : (
                  <button className="cartsecondary" onClick={submitHandler}>
                    {" "}
                    Add to cart <ShoppingCartIcon />{" "}
                  </button>
                )}

                {product.isdeliveryFree === undefined ? (
                  <>
                    <>
                      <p className="product-details-delivery-charges-free">
                        Home Delivery Charges : <p> ** 100 Rs ** </p>{" "}
                      </p>
                    </>
                  </>
                ) : (
                  <>
                    {product.isdeliveryFree === false ? (
                      <>
                        <p className="product-details-delivery-charges">
                          {" "}
                          Cash on delivery Charges :{" "}
                          <p>{product.shippingCharges} Rs </p>{" "}
                        </p>
                      </>
                    ) : (
                      <>
                        <p className="product-details-delivery-charges-free">
                          {" "}
                          Delivery : <p> ** Delivery Is Free ** </p>{" "}
                        </p>
                      </>
                    )}
                  </>
                )}
                {product.offers === undefined ? (
                  <>
                    <div className="special-offer-not-available">
                      <p className="">
                        {" "}
                        Special Offers : <p>
                          {" "}
                          *** is not available now ***{" "}
                        </p>{" "}
                      </p>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="special-offer-available">
                      <p className="">
                        {" "}
                        Special Offers : <p>{product.offers} </p>{" "}
                      </p>
                    </div>
                  </>
                )}

                {/* {console.log(product)} */}
                {/* shippingCharges */}
                {/* quantity information */}
                <div className="total-quantity-informaion-container">
                  <p> * we have left qauntity of our product *</p>
                </div>
                <p className="total-qauntity-information">
                  {" "}
                  Total Quantity : <p>{product.productStock} </p>{" "}
                </p>
              </div>
              {/**DISCRIPTION */}
              <p className="img-brand"> Discription: </p>
              <p className="img-cart-detail">{product.discription} </p>
            </div>
          </div>

          <Reviews
            id={props.match.params.id}
            reviews={product.reviews}
            productStock={product.productStock}
          />
          {/* <div className="heading-of-realted-brand">
            <h6> Realted Product</h6>
          </div> */}
          {/* <div className="similar-products-container-div">
            <SimilarProductMainPage
              loading={loading}
              realtedCatogery={realtedCatogery}
            />
          </div> */}

          <div className="heading-of-realted-brand">
            <h6>More Items Of Same Brand</h6>
          </div>
          <div className="realted-brand-div-container">
            <RealtedBrand loading={loading} realtedBrand={realtedBrand} />
          </div>
          <Footer />
        </Fragment>
      )}
      {/* </Fragment>)} */}
    </Fragment>
  );
}

export default Productcartdetail;
