import axios from "axios";
import {
  SAVE_ADDRESS_REQUEST,
  SAVE_ADDRESS_SUCCESS,
  SAVE_ADDRESS_FAIL,
  GET_SAVE_INFO_REQUEST,
  GET_SAVE_INFO_SUCCESS,
  GET_SAVE_INFO_FAIL,
  GET_ALL_SAVE_INFO_REQUEST,
  GET_ALL_SAVE_INFO_SUCCESS,
  GET_ALL_SAVE_INFO_FAIL,
  GET_SINGEL_ADDRESS_BY_ADMIN,
  GET_SINGEL_ADDRESS_BY_SUCCESS,
  GET_SINGEL_ADDRESS_BY_FAIL,
  ADDRESS_DELETE_REQUEST,
  ADDRESS_DELETE_SUCCESS,
  ADDRESS_DELETE_FAIL,
  CLEAR_ERROR,
} from "../constants/SaveAddressConstants";

// saving shipping info
export const createSaveInfo =
  ({ name, email, address,landMark, city, autoSaveShippingState, district, pincode,phoneNo }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: SAVE_ADDRESS_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.post(
        `/api/shipping/data/create`,
        { name, email, address,landMark, city, autoSaveShippingState, district, pincode ,phoneNo},
        config
      );
      dispatch({
        type: SAVE_ADDRESS_SUCCESS,
        payload: data.msg,
      });
    } catch (error) {
      dispatch({
        type: SAVE_ADDRESS_FAIL,
        payload: error.response.data.msg,
      });
    }
  };

// getting all shipping address by user

export const gettingSaveInfo = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_SAVE_INFO_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.get(`/api/shipping/info`, config);
    dispatch({
      type: GET_SAVE_INFO_SUCCESS,
      payload: data.msg,
    });
  } catch (error) {
    dispatch({
      type: GET_SAVE_INFO_FAIL,
      payload: error.response.data.msg,
    });
  }
};

// getting all shipping address by admin

export const gettingAllSaveInfo = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_SAVE_INFO_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.get(`/api/shipping/info/admin`, config);
    dispatch({
      type: GET_ALL_SAVE_INFO_SUCCESS,
      payload: data.msg,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_SAVE_INFO_FAIL,
      payload: error.response.data.msg,
    });
  }
};

// getting singel shipping address by admin

export const gettingSingelSaveInfo = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_SINGEL_ADDRESS_BY_ADMIN,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.get(
      `/api/shipping/singel-info/admin/${id}`,
      config
    );
    dispatch({
      type: GET_SINGEL_ADDRESS_BY_SUCCESS,
      payload: data.msg,
    });
  } catch (error) {
    dispatch({
      type: GET_SINGEL_ADDRESS_BY_FAIL,
      payload: error.response.data.msg,
    });
  }
};

// deleting shipping address by admin

export const deleteSaveInfo = (id) => async (dispatch) => {
  try {
    dispatch({
      type: ADDRESS_DELETE_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.delete(
      `/api/shipping/info/admin/${id}`,
      config
    );
    dispatch({
      type: ADDRESS_DELETE_SUCCESS,
      payload: data.msg,
    });
  } catch (error) {
    dispatch({
      type: ADDRESS_DELETE_FAIL,
      payload: error.response.data.msg,
    });
  }
};


// clear the error 


export const clearErrorAction = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERROR
    })
}