import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import swal from 'sweetalert';
import { useHistory } from 'react-router-dom';
import { clearErrorAction, passwordResetByToken } from '../../redux/actions/userAction';
import Loader from '../../layout/loader';
import MetaTag from '../../layout/MetaTag';
import companyLogo from '../../images/output-onlinepngtools - Copy.png';



function ResetPasswordScreen(props) {
    const dispatch = useDispatch();
    const history = useHistory();

    const { error, loading, message } = useSelector((state) => state.passwordByToken)

    const [password, setPassword] = useState('');
    const [confrimPassword, setConfrimPassword] = useState('');


    useEffect(() => {

        if (error) {
            swal({
                title: "Validation Error",
                text: error,
                icon: "error",
            })
            dispatch(clearErrorAction())
        }

        if (message) {
            alert.success(message)
            history.push('/')
        }


    }, [dispatch, error, history, message])

    var token = props.match.params.token
    const ResetPasswordTokenHandler = (e) => {
        e.preventDefault();
        if (password !== confrimPassword) {
            return swal({
                title: "Validation Error",
                text: 'both password and confirm password should be same...',
                icon: "info",
            })
        }

        dispatch(passwordResetByToken(password, confrimPassword, token))


    }



    return (
        <Fragment>
              <MetaTag title={'Reset Your Password to stay Protected With Your information, IM Online Shopping'} />
              {loading ? <Loader /> : (<Fragment>
                <div className="password-reset-screen-div">
                    <div className="password-reset-screen-image">
                        <img src={companyLogo} alt="www.imonline.shop" />
                    </div>
                    <div className="password-reset-screen-heading">
                        <h6>Reset Password</h6>
                    </div>
                    <div className="password-reset-screen-form">
                        <form method="post" onSubmit={ResetPasswordTokenHandler}  >
                            <div className="password-div">
                                <input type="password" placeholder='Enter Password' value={password} onChange={(e) => setPassword(e.target.value)} required />
                            </div>
                            <div className="confirm-password-div">
                                <input type="password" placeholder='Confirm Password' value={confrimPassword} onChange={(e) => setConfrimPassword(e.target.value)} required />
                            </div>
                            <div className="reset-btn-div">
                                <button className='password-reset-screen-btn' > Reset Password </button>
                            </div>
                        </form>
                    </div>
                </div>
            </Fragment>)}
        </Fragment>

    )
}

export default ResetPasswordScreen