import React, { Fragment, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { clearErrorAction, singelComplainAction, updateComplainAction } from '../redux/actions/complainAction'
import { Typography } from '@mui/material'
import Sidebar from './Sidebar'
import Loader from '../layout/loader'
import { Button } from '@material-ui/core'
import swal from 'sweetalert';

import { UPDATE_COMPLAIN_RESET } from '../redux/constants/complainConstants'

function GetSingelComplainByAdmin(props) {

  const dispatch = useDispatch();


  const { loading, error, complain } = useSelector((state) => state.singelComplain)

  const { loading: updatedLoading, error: updatedError, isUpdated } = useSelector((state) => state.updateComplain)

  const [complainStatus, setComplainStatus] = useState('')

  let id = props.match.params.id;

  useEffect(() => {

    if (error) {
      swal({
        title: "Error",
        text: error,
        icon: "error",
      })
      dispatch(clearErrorAction())
    }

    if (updatedError) {
      swal({
        title: "Update Error",
        text: updatedError,
        icon: "error",
      })
      dispatch(clearErrorAction())
    }

    if (isUpdated) {
      swal({
        title: "Complain Solved",
        text: isUpdated,
        icon: "success",
      })
      dispatch({ type: UPDATE_COMPLAIN_RESET })
    }

    dispatch(singelComplainAction(id))

  }, [dispatch, error, id, isUpdated, updatedError])


  const updateComplainHandler = (e) => {
    e.preventDefault();
    dispatch(updateComplainAction(id, complainStatus))
  }


  return (
    <Fragment>
      {loading ? <Loader /> : (<Fragment>
        <div className="dashboard-container">
          <Sidebar />
          <div className="edit-order-confirm-order-page">
            <div>
              <div className="edit-order-confirm-shipping-area">
                <Typography>User Information      </Typography>
                <div className="edit-order-confirm-shipping-area-box">
                  <div>
                    <p>User Name:</p>
                    <span>{complain && complain.userName}</span>
                  </div>
                  <div>
                    <p>Email:</p>
                    <span>{complain && complain.userEmail}</span>
                  </div>

                  <div>
                    <p>Phone:</p>
                    <span>{complain && complain.userNumber}</span>
                  </div>

                </div>
              </div>

              <div className="edit-order-confirm-shipping-area">
                <Typography>Complain details </Typography>
                <div className="edit-order-confirm-shipping-area-box">
                  <div>
                    <p>Complain Type:</p>
                    <span>{complain && complain.complainType}</span>
                  </div>
                  <div>
                    <p> Complain:</p>
                    <span>{complain && complain.complainMsg}</span>
                  </div>
                  <div>
                    <p> Complain Date:</p>
                    <span>{String(complain.complainStatus && complain.complainStatus.createdAt).substring(0, 10)}</span>
                  </div>
                  <div>
                    <p> Complain Status:</p>
                    <span style={{ color: complain.complainStatus && complain.complainStatus.status === 'Complain in Process' ? 'red' : 'green' }} >{complain.complainStatus && complain.complainStatus.status}</span>
                  </div>
                  <div style={{ display: complain.complainStatus && complain.complainStatus.status === 'Solved' ? 'block' : 'none' }} >
                    <p> Complain Solved Date:</p>
                    <span >{String(complain && complain.solveAt).substring(0, 10)}</span>
                  </div>
                </div>
              </div>

              <div className="edit-order-confirm-shipping-area">
                <Typography>Complain ID & Email </Typography>
                <div className="edit-order-confirm-shipping-area-box">
                  <div>
                    <p>Id:</p>
                    <span>{complain && complain._id}</span>
                  </div>
                  <div>
                    <p>Email:</p>
                    <span>{complain && complain.userEmail}</span>
                  </div>
                </div>
              </div>
            </div>
            <div >

              {/*  ORDER STATUS UPDATE SECTION  */}
              {/* style={{ display: complain.complainStatus.status === 'Complain in Process' ? 'none' : 'block' }} */}
              <div style={{ display: complain.complainStatus && complain.complainStatus.status === 'Complain in Process' ? 'block' : 'none' }} >
                <div className="edit-order-order-summery">
                  <Typography> Complain Status </Typography>
                  <div >
                    <div className='order-update-form'>
                      <select onChange={(e) => setComplainStatus(e.target.value)} >
                        <option >Choose Status</option>
                        <option value='Solved'>Solved it</option>
                      </select>
                    </div>
                    <Button
                      id='createProductBtn'
                      onClick={updateComplainHandler}
                      disabled={updatedLoading ? true : false}
                    >
                      Status Confirm
                    </Button>

                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </Fragment>)}
    </Fragment>
  )
}

export default GetSingelComplainByAdmin