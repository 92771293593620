import React, { Fragment, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { clearErrorAction,createAdsAction } from '../redux/actions/productAdsActions'
import swal from 'sweetalert';
import { Button } from '@material-ui/core'
import Loader from '../layout/loader';
import DescriptionIcon from '@material-ui/icons/Description';
import SpellCheckIcon from '@material-ui/icons/Spellcheck'
import Filter6Icon from '@mui/icons-material/Filter6';

import Sidebar from './Sidebar'
import { CREATE_PRODUCT_HEADING_RESET } from '../redux/constants/productAdsConstants';

function CreateAdsAdmin() {

    const dispatch = useDispatch();
    const history = useHistory();
    const { loading, error, success } = useSelector((state) => state.productAdsCreate)
    const [name, setName] = useState('');
    const [tag, setTag] = useState('');
    const [descriptions, setDescriptions] = useState('');
    const [image, setImage] = useState('');

    useEffect(() => {
        if (error) {
            swal({
                title: "Validation",
                text: error,
                icon: "error",
              })
            dispatch(clearErrorAction())
        }
        if (success) {
            swal({
                title: "Created.",
                text: success,
                icon: "success",
              })
            history.push('/Admin/dashboard')
            dispatch({ type: CREATE_PRODUCT_HEADING_RESET })
        }

    }, [ dispatch, error, history, success])

    // on click function when submit button press
    const createProductSubmitHandler = async (e) => {
        e.preventDefault()


        let formData = new FormData();
        formData.append('name', name);
        formData.append('descriptions', descriptions);
        formData.append('tag', tag);



        for (let index = 0; index < image.length; index++) {
            formData.append('image', image[index]);
            console.log(image)
        }


        dispatch(createAdsAction(formData));

    }



    // // for images adding function


    const createAdsImageChange = (e) => {

        setImage(e.target.files);
    }


  return (
    <Fragment>
         <div className="dashboard-container">
                <Sidebar />
                {loading ? <Loader /> : (<Fragment>
                    <div className="newProductContainer">
                        <form className='createProductForm'
                            onSubmit={createProductSubmitHandler}  >
                            <h1>Create Product Ads</h1>
                            <div>
                                <SpellCheckIcon />
                                <input
                                    type='text'
                                    placeholder='Product Name'
                                    required
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>

                            <div>
                                <Filter6Icon />
                                <input
                                    type='text'
                                    placeholder='tag'
                                    required
                                    value={tag}
                                    onChange={(e) => setTag(e.target.value)}
                                />
                            </div>

                            <div>
                                <DescriptionIcon />
                                <textarea
                                    placeholder='Descripation'
                                    required
                                    value={descriptions}
                                    onChange={(e) => setDescriptions(e.target.value)}
                                    cols={10}
                                    rows={1}
                                ></textarea>
                            </div>

                            <div id="createProductFormFile">
                                <input type="file"
                                    name='image'
                                    accept='image/*'
                                    multiple
                                    onChange={createAdsImageChange}
                                />
                            </div>
                            {/* <div id="createProductFormFileImage">
                            {imagePreview.map((image, index) => (
                                <img key={index} src={image} alt="images" />
                            ))}


                        </div> */}

                            <Button
                                id='createProductBtn'
                                type='submit'
                                disabled={loading ? true : false}

                            >
                                Create Ads
                            </Button>


                        </form>
                    </div>




                </Fragment>)}
            </div>

    </Fragment>
    
  )
}

export default CreateAdsAdmin