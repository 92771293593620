import {
  CREATE_COUPAN_REQUEST,
  CREATE_COUPAN_REQUEST_SUCCESS,
  CREATE_COUPAN_REQUEST_FAIL,
  EDIT_COUPAN_REQUEST,
  EDIT_COUPAN_REQUEST_SUCCESS,
  EDIT_COUPAN_REQUEST_FAIL,
  ALL_COUPAN_REQUEST,
  ALL_COUPAN_REQUEST_SUCCESS,
  ALL_COUPAN_REQUEST_FAIL,
  SINGEL_COUPAN_REQUEST,
  SINGEL_COUPAN_REQUEST_SUCCESS,
  SINGEL_COUPAN_REQUEST_FAIL,
  DELETE_COUPAN_REQUEST,
  DELETE_COUPAN_REQUEST_SUCCESS,
  DELETE_COUPAN_REQUEST_FAIL,
  COUPAN_APPLY_REQUEST,
  COUPAN_APPLY_REQUEST_SUCCESS,
  COUPAN_APPLY_REQUEST_FAIL,
  CLEAR_ERROR,
} from "../constants/coupanConstant";

// CREATE COUPAN BY ADMIN
export const createCoupanReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_COUPAN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_COUPAN_REQUEST_SUCCESS:
      return {
        loading: false,
        coupan: action.payload,
      };
    case CREATE_COUPAN_REQUEST_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// EDIT COUPAN BY ADMIN
export const editCoupanReducer = (state = {}, action) => {
  switch (action.type) {
    case EDIT_COUPAN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case EDIT_COUPAN_REQUEST_SUCCESS:
      return {
        loading: false,
        isCoupanEdit: action.payload,
      };
    case EDIT_COUPAN_REQUEST_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// GET ALL COUPAN BY ADMIN
export const getAllCoupanReducer = (state = {}, action) => {
  switch (action.type) {
    case ALL_COUPAN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ALL_COUPAN_REQUEST_SUCCESS:
      return {
        loading: false,
        coupans: action.payload,
      };
    case ALL_COUPAN_REQUEST_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// GET SINGEL COUPAN BY ADMIN
export const singelCoupanReducer = (
  state = {
    coupan: [],
  },
  action
) => {
  switch (action.type) {
    case SINGEL_COUPAN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SINGEL_COUPAN_REQUEST_SUCCESS:
      return {
        loading: false,
        coupan: action.payload,
      };
    case SINGEL_COUPAN_REQUEST_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// DELETE COUPAN BY ADMIN
export const deleteCoupanReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_COUPAN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_COUPAN_REQUEST_SUCCESS:
      return {
        loading: false,
        isCoupanDelete: action.payload,
      };
    case DELETE_COUPAN_REQUEST_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// COUPAN APPLY REDUCER BY USER

// EDIT COUPAN BY ADMIN
export const applyCoupanByUserReducer = (state = {}, action) => {
  switch (action.type) {
    case COUPAN_APPLY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case COUPAN_APPLY_REQUEST_SUCCESS:
      return {
        loading: false,
        success: action.payload.msg,
        newValue: action.payload.newValue,
        coupanCode: action.payload.coupanCode,
        coupanValue: action.payload.coupanValue,
      };
    case COUPAN_APPLY_REQUEST_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
