import React, { Fragment } from 'react'
import { useEffect } from 'react';
import { useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import Loader from '../../layout/loader'
import './userDetail.css'
import {Helmet} from 'react-helmet'
import MetaTag from '../../layout/MetaTag'
// import swal from 'sweetalert';

// icons
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import SettingsIcon from '@material-ui/icons/Settings';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import PlaceIcon from '@mui/icons-material/Place';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import CancelIcon from '@mui/icons-material/Cancel';
import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated';


function UserProfile() {

    // history 
    const history = useHistory();

    // redux functions
    const { user, loading, isAuthenticated } = useSelector(state => state.user)

    // const [showPopUp, setShowPopUp] = useState(false)

    useEffect(() => {

        if (isAuthenticated === false) {
            history.push('/Login')
        }

    }, [history, isAuthenticated])



    return (
        <Fragment>
      <Helmet  >
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=2.0" />
        <meta name="description" content={` Welcome to im online shopping Account page || make account with us & do get updated with all informations.  `} />
        <meta name="keywords" content={` im shopping profile details page || profile details page || im profile details page || profile page.  || profile page || im user profile details page || details page || account details page  || details page || shopping account page || account page im|| im account`} />
        <meta name="robots" content="index, follow " />
        <meta property="og:title" content={` Welcome to im online shopping Account page || make account with us & do get updated with all informations.    `} />
        <meta property="og:description" content={` IM Online Shopping Profile Details Page `} />
        <meta property="og:type" content="website" />
        {/* <meta property="og:url" content={items.imageurl}   key={items.public_id}/>
            <meta property="og:image" content={items.imageurl}  key={items.public_id}/> */}
        <meta property="og:locale" content="en-GB_IN" />
        <meta property="og:site_name" content="IMONLINE.SHOP" />
        {/* <meta property="og:price:currency" content="INR" />
            <meta property="og:price:amount" content={product.price} /> */}
      </Helmet>

            {loading ? <Loader /> : <Fragment>
                <MetaTag title={`Welcome to IM Online shopping ${user.firstName}. its Your Profile Page`} />
                <div className='header-div'>
                    <Link to="/" >  <h6> <HomeOutlinedIcon />  </h6></Link>
                </div>
                <div className='main-heading-user-profile'>
                    <h2>{user.firstName} profile detail's </h2>
                </div>
                <div className="container bootstrap snippets bootdey">
                    <div className="panel-body inf-content">
                        <div className="row">
                            <div className="col-md-4">

                                <img alt="" title="" className="img-circle img-thumbnail isTooltip" data-original-title="Usuario" />
                                <ul title="Ratings" className="list-inline ratings text-center">

                                </ul>
                            </div>
                            <div className="col-md-6">
                                <div className="table-responsive">
                                    <table className="table table-user-information">
                                        <tbody>
                                            <tr>
                                                <td >
                                                    <strong>
                                                        <span className="glyphicon glyphicon-user  text-primary"></span>
                                                        Firstname
                                                    </strong>
                                                </td>
                                                <td className='user-profile-data-container-td'>
                                                    <h2> {user.firstName}</h2>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>
                                                        <span className="glyphicon glyphicon-cloud text-primary"></span>
                                                        Lastname
                                                    </strong>
                                                </td>
                                                <td className='user-profile-data-container-td'>
                                                    <h2> {user.lastName}</h2>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>
                                                        <span className="glyphicon glyphicon-envelope text-primary"></span>
                                                        Email
                                                    </strong>
                                                </td>
                                                <td className='user-profile-data-container-td'>
                                                    <h2> {user.email}</h2>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>
                                                        <span className="glyphicon glyphicon-bookmark text-primary"></span>
                                                        Number
                                                    </strong>
                                                </td>
                                                <td className='user-profile-data-container-td'>
                                                    <h2> {user.number}</h2>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>
                                                        <span className="glyphicon glyphicon-calendar text-primary"></span>
                                                        Gender
                                                    </strong>
                                                </td>
                                                <td className='user-profile-data-container-td'>
                                                    <h2> {user.gender}</h2>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>
                                                        <span className="glyphicon glyphicon-calendar text-primary"></span>
                                                        Created
                                                    </strong>
                                                </td>
                                                <td className='user-profile-data-container-td'>
                                                    <h2>  {String(user.date).substring(0, 10)}</h2>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className='edit-button-div'>
                                    <a href="https://im.shiprocket.co/" ><button className='edit-button'  > Track Order  <PlaceIcon /> </button></a>
                                    <Link to="/user-profile/add-bank-account/" ><button className='edit-button'  >  Refund Request  <BrowserUpdatedIcon /> </button></Link>
                                    <Link to="/user/All/return/orders" ><button className='edit-button'  >  Return & Exchange  <ViewInArIcon /> </button></Link>
                                    <Link to="/profile/Cancel-Orders-Detail" ><button className='edit-button'  >  Cancel Order's  <CancelIcon /> </button></Link>
                                    <Link to="/UpdateProfile" ><button className='edit-button'  >  Edit Profile  <SettingsIcon /> </button></Link>
                                    <span>
                                        <Link to="/UpdatePassword" ><button className='forget-password-button'  > Update Password    <LockOpenIcon />   </button></Link>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='footer-div'>
                    <Link to="/" >   <p class="rights"><span>© </span><span class="copyright-year">2021</span><span></span><span> www.imonline.shop</span><span></span><span> All CopyRight Reserved.</span></p></Link>
                </div>
            </Fragment>}
        </Fragment>
    )
}

export default UserProfile
