import React, { Fragment, useState, useEffect } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import swal from "sweetalert";
import Loader from "../layout/loader";
import {
    clearErrorAction,
    getSingelNotificationaActionByAdmin,
    updateNotificationaActionByAdmin
} from "../redux/actions/notificationAction";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom'
import "./createNotification.css";
import { UPDATE_NOTIFICATION_BY_ADMIN_RESET } from "../redux/constants/notificationConstants";

function EditNotificationByAdmin(props) {
    const history = useHistory();
    const dispatch = useDispatch();

    const { loading, error, notification } = useSelector(
        (state) => state.singelNotification
    );


    const { error: updateError, isUpdated } = useSelector(
        (state) => state.updateNotification
    );

    const [getUserId, setGetUserId] = useState("");
    const [getMessage, setGetMessage] = useState("");

    const notificationId = props.match.params.id;

    useEffect(() => {

        if (notification && notification._id !== notificationId) {
            dispatch(getSingelNotificationaActionByAdmin(notificationId))
        } else {
            setGetUserId(notification._id);
            setGetMessage(notification.message);
        }

        if (error) {
            swal({
                title: "Notification Error",
                text: error,
                icon: "error",
            });
            dispatch(clearErrorAction());
        }

        if (updateError) {
            swal({
                title: "Updation Error",
                text: updateError,
                icon: "error",
            });
            dispatch(clearErrorAction());
        }

        if (isUpdated) {
            swal({
                title: "Notification Updated.",
                text: isUpdated,
                icon: "success",
            });
            dispatch({ type: UPDATE_NOTIFICATION_BY_ADMIN_RESET })
        }

    }, [error, updateError, notification, notificationId, isUpdated, dispatch]);


    const getFormDataHandler = (e) => {
        e.preventDefault();
        dispatch(updateNotificationaActionByAdmin(getUserId, getMessage));
        setGetMessage('')
    };

    const getBackHandler = () => {
        history.push('/Admin/All/Notifications')
    }

    return (
        <Fragment>
            {loading ? (
                <Loader />
            ) : (
                <Fragment>
                    <div>
                        <div className="admin-panel-notification-main-continer">
                            <div className="admin-panel-back-home-div">
                                <p onClick={getBackHandler}>

                                    <ArrowBackIcon />
                                </p>
                            </div>
                            <h2 className="admin-panel-main-heading">
                                Notifications Update Panel.
                            </h2>
                        </div>
                        <form onSubmit={getFormDataHandler}>
                            <div className="notification-form-box">
                                <div className="notification-input-id">
                                    <input
                                        type="text"
                                        placeholder="Enter User ID.."
                                        value={getUserId}
                                        onChange={(e) => setGetUserId(e.target.value)}
                                        required
                                    />
                                </div>
                                <div
                                    className="notification-input-text-area-box"
                                >
                                    <textarea
                                        placeholder="Write Message...."
                                        cols="30"
                                        rows="10"
                                        value={getMessage}
                                        onChange={(e) => setGetMessage(e.target.value)}
                                    ></textarea>
                                </div>
                            </div>
                            <div className="notification-submit-div">
                                <button className="notification-form-submit-btn">
                                    {" "}
                                    Submit{" "}
                                </button>
                            </div>
                        </form>
                    </div>
                </Fragment>
            )}
        </Fragment>
    )
}

export default EditNotificationByAdmin