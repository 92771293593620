import React, { Fragment } from 'react'

function TermsOfUsesPart3() {
    return (
        <Fragment>
            <div className="terms-and-condition-bulk">
                <ul className='TERMS-AND-CONDITIONS-FOR-BULK-ORDERS'>
                TERMS AND CONDITIONS FOR BULK ORDERS
                    <li>Definitions used: "Bulk Order" means orders placed on the Site which are in
                        aggregate of a quantity of 10 or more items, or in aggregate of a value
                        exceeding INR 1,00,000. which are delivered to the same delivery address, within a
                        reasonable period.Each such order will be considered a “Bulk Order”.
                    </li>
                    <li>By confirming the Bulk Order and IM fulfilling the Bulk Order,
                        you are considered to have agreed to these Terms and Conditions for Bulk Orders
                        in its entirety without modification. IM reserves the right to amend
                        these Terms and Conditions for Bulk Orders without prior written notice,
                        at IM's sole discretion,by posting a copy of the amended
                        Terms and Conditions for Bulk Orders at the Online Store

                    </li>

                    <li>
                        You agree that you shall not advertise, offer for retail sale, resale,
                        distribution or export any of the Products purchased through the Bulk Order.
                    </li>

                    <li>
                        You shall not advertise any relationship or connection with IM.
                        Our fulfilment of the Bulk Order does not grant you any “distributorship”,
                        “authorized purchaser” or any such similar status with IM.
                    </li>
                    <li>
                        All Bulk Orders are subject to IM's online security screening.
                        You may be contacted via email/phone to provide additional
                        information required by IM's online security team.
                    </li>
                    <li>
                        You will incur an administrative charge if you cancel or amend the Bulk Order after confirmation.
                    </li>
                    <li>
                        IM reserves the right to limit quantities of the Products sold and may discontinue accepting orders
                        for any Product at any time. IM reserves the right to accept or reject Bulk Orders in any combination
                        or option(s) it determines to be in its best interest. IM
                        reserves the right to either process the Bulk Order partially and make refunds,
                        or reject the Bulk Order in its entirety.
                    </li>

                    <li>
                        Due to the larger volume ordered, delivery and/or
                        processing period of the Bulk Order may take longer than individual orders.
                    </li>

                    <li>
                        Bulk Orders are not eligible for refund or exchange except due to manufacturing defects.
                        All refunds or exchanges due to manufacturing defects must be accompanied by an official receipt.
                        We perform stringent checks on the Products prior to shipment to the courier company.
                    </li>

                    <li>
                        IM retail stores and the Site are the official channels for sale of the Products.
                        IM shall not be responsible for any Products sold by unauthorised resellers.
                        Please refer to Store Locator for our full store listing.
                    </li>

                    <li>
                        For Local Delivery Within Punjab, prices are inclusive of 18% Goods and Services Tax.
                        You shall be liable for any additional taxes and duties (if applicable).

                    </li>

                    <li>
                        For National Delivery Outside of Punjab, prices of the Products
                        shown on the Site are not inclusive of any applicable duties and
                        taxes to the designated delivery destination.
                        You will be solely responsible to pay all applicable duties and
                        taxes which may be imposed on the Products (where applicable).
                    </li>

                    <li>
                        You may be subject to prepayment of the applicable duties and taxes (where applicable).
                        In any event, if applicable duties and taxes are not collected during the prepayment,
                        you will be liable to make payment to the customs broker during the delivery to the
                        destination territory/address. You are advised to check with the local customs office
                        of the delivery destination for more details on the applicable duties and taxes
                        for the Products and any other payments required to be made to the payment to the customs broker.
                    </li>
                    <li>
                        The authorities in the delivery destination may require you to provide certain licenses, permits,
                        documentation before the Bulk Order may be released to you. It shall be your sole responsibility to
                        provide such documentation. In the event the Bulk Order is unable to be released to you due to your
                        inability to provide such documentation,IM shall be entitled to (i) claim the shipping
                        charges for both the delivery and the return of the Bulk Order, (ii) charge any duties, taxes or penalties
                        imposed upon us by the authorities in either the delivery or shipping destination, (iii) as well as an
                        administration fee to you. For the avoidance of doubt,
                        this clause shall apply notwithstanding the Bulk Order may have been shipped under express delivery.
                    </li>

                    <li>
                        Customs and import regulations or other regulations imposed by authorities in the delivery destination
                        may impose certain restrictions on goods being imported. Such restrictions may include restrictions
                        on certain materials used on the Products or limits on quantity. We will be unable to determine at
                        the confirmation of your Bulk Order whether such regulations exist and/or may affect delivery of the Bulk Order.
                        Under such circumstances, IM will use reasonable commercial efforts to attempt to
                        deliver the Bulk Order to you by providing supporting documents to the relevant authorities.
                        You are responsible for ensuring that the ordered Products comply with import requirements in
                        the delivery destination and are strongly advised to understand the delivery destination’s
                        customs and import regulations before placing the Bulk Order. In the event the Bulk Order is
                        unable to be released to you due to the failure to address such restrictions, IM
                        shall be entitled to (i) claim the shipping charges for both the delivery and the return of
                        the Bulk Order, (ii) charge any duties, taxes or penalties imposed upon us by the authorities
                        in either the delivery or shipping destination, (iii) as well as an administration fee to you.
                        For the avoidance of doubt, this clause shall apply notwithstanding the Bulk Order may
                        have been shipped under express delivery.
                    </li>
                </ul>
            </div>
        </Fragment>
    )
}

export default TermsOfUsesPart3