import React, { Fragment, useEffect ,useState} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { clearErrorAction, singelReturnOrderByAdminAction, OrderDetailAction ,updateReturnOrderStatusByAdminAction  } from '../redux/actions/orderAction'
import { Typography } from '@mui/material'
import { Button } from '@material-ui/core'
import Sidebar from './Sidebar'
import Loader from '../layout/loader'
import swal from 'sweetalert';
import './editOrderByAdmin.css'

import CancelOrderDetailsPage2 from './CancelOrderDetailsPage2'


import { RETURN_ORDER_STATUS_UPDATE_RESET } from '../redux/constants/orderConstants'
function EditReturnOrderByAdmin(props) {

  const dispatch = useDispatch();
  const history = useHistory();

  const { loading: orderLoading, order, error: orderError } = useSelector((state) => state.orderDetail)

  const { loading, error, returnOrder } = useSelector((state) => state.singelReturnOrder)
  const { loading: returnOrderLoading, error: returnOrdersError, returnOrders } = useSelector((state) => state.returnStatusUpdateByAdmin)

  const [status, setStatus] = useState('')

  const [orderData, setOrderData] = useState('')

  let id = props.match.params.id;

  useEffect(() => {
    if (error) {
      swal({
        title: "Error",
        text: error,
        icon: "error",
      })
      dispatch(clearErrorAction())
    }
    if (orderError) {
      swal({
        title: "Error",
        text: orderError,
        icon: "error",
      })
      dispatch(clearErrorAction())
    }

    if (returnOrdersError) {
      swal({
        title: "Error",
        text: returnOrdersError,
        icon: "error",
      })
      dispatch(clearErrorAction())
    }


    if (returnOrders) {
      swal({
        title: "Return Status",
        text: returnOrders,
        icon: "success",
      })
      dispatch({ type: RETURN_ORDER_STATUS_UPDATE_RESET })
    }
    dispatch(singelReturnOrderByAdminAction(id))
  
  }, [dispatch, error, history, id, orderError, returnOrders, returnOrdersError])


  const orderDetailHandler = (e) => {
    e.preventDefault();
    dispatch(OrderDetailAction(orderData))
    setOrderData('')

  }



  const returnOrderStatusSubmitHandler = async (e) => {
    e.preventDefault();
    if (!status) {
      return swal({
        title: "Validation Error",
        text: "Please Fill Status",
        icon: "info",
      })
    }

    dispatch(updateReturnOrderStatusByAdminAction(id, status));

  }

  return (
    <Fragment>
      {returnOrderLoading ? <Loader /> : (<Fragment>
        {loading ? <Loader /> : (<Fragment>
          <div className="dashboard-container">
            <Sidebar />
            <div className="edit-order-confirm-order-page">
              <div>
                <div className="edit-order-confirm-shipping-area">
                  <Typography>User Information      </Typography>
                  <div className="edit-order-confirm-shipping-area-box">
                    <div>
                      <p>Name:</p>
                      <span>{returnOrder && returnOrder.userName}</span>
                    </div>
                    <div>
                      <p>Email:</p>
                      <span>{returnOrder && returnOrder.userEmail}</span>
                    </div>
                    <div>
                      <p>Phone:</p>
                      <span>{returnOrder && returnOrder.userPhone}</span>
                    </div>
                  </div>
                </div>

                <div className="edit-order-confirm-shipping-area">
                  <Typography>Return Status </Typography>
                  <div className="edit-order-confirm-shipping-area-box">
                    <div>
                      <p>Status:</p>
                      <span style={{color: returnOrder.returnInformation && returnOrder.returnInformation.status === 'Processing ' ? 'red' : 'green'}}  >{returnOrder.returnInformation && returnOrder.returnInformation.status}</span>
                    </div>
                    <div>
                      <p>Created At:</p>
                      <span>{String(returnOrder.returnInformation && returnOrder.returnInformation.createdAt).substring(0, 10)} </span>
                    </div>
                    <div>
                      <p>Reason:</p>
                      <span>{returnOrder && returnOrder.returnReason}</span>
                    </div>
                    <div>
                      <p>Return Type:</p>
                      <span>{returnOrder && returnOrder.returnType}</span>
                    </div>
                    <div>
                      <p>Return Order Id:</p>
                      <span>{returnOrder && returnOrder.returnOrderId}</span>
                    </div>
                    <div>
                      <p> User Id:</p>
                      <span>{returnOrder && returnOrder.user}</span>
                    </div>
                    <div style={{display: returnOrder && returnOrder.returnAccpetAt ? 'block' : 'none'}}>
                      <p> Retured Accpeted At:</p>
                      <span>{String(returnOrder && returnOrder.returnAccpetAt).substring(0, 10)}</span>
                    </div>
                 
                  </div>
                </div>
                <Typography>Order ID </Typography>
                <div className="edit-order-confirm-shipping-area-box">
                  <div>
                    <p>Order:</p>
                    <span>{returnOrder && returnOrder.orderId}</span>
                  </div>
                </div>

                <div className="order-details-div">
                  <div className='order-details-container'>
                    <form onSubmit={orderDetailHandler}>
                      <input type="text" placeholder='Type Order id' required value={orderData} onChange={(e) => setOrderData(e.target.value)} />
                      {orderLoading ? (<button type="submit" disabled> ...</button>) : (<button type="submit"> Get</button>)}
                    </form>
                  </div>
                </div>
                <CancelOrderDetailsPage2  order={order} orderLoading={orderLoading} />
              </div>

              <div style={{display:returnOrder.returnInformation && returnOrder.returnInformation.status === 'Accept' ? 'none' : 'block'}} >
                <div className="edit-order-order-summery">
                  <Typography> Processing Order </Typography>
                  <div>
                    <div className='order-update-form'>
                      <select onChange={(e) => setStatus(e.target.value)}>
                        <option >Choose Status</option>
                        {returnOrder.returnInformation && returnOrder.returnInformation.status === 'Processing' && (
                          <option value='Confirmed' >Confirmed</option>
                        )}
                        {returnOrder.returnInformation && returnOrder.returnInformation.status === 'Confirmed' && (
                          <option value='Accept'>Accept</option>
                        )}

                      </select>
                    </div>
                    <Button
                      id='createProductBtn'
                      onClick={returnOrderStatusSubmitHandler}
                      disabled={loading ? true : false}
                    >
                      Status Confirm
                    </Button>

                  </div>
                </div>
              </div>
            </div>
          </div>

        </Fragment>)}
      </Fragment>)}
    </Fragment>
  )
}

export default EditReturnOrderByAdmin