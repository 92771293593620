import React from 'react'
import { Link } from 'react-router-dom'
import "../../../css/shoesbottom.css"
import Adidasshoes from "../../../images/NEW1.png";

function Shoesbottom() {
  return (
    <>
      <div className="shoes-bottom-background-colour">
       <Link to={'/Product'}> <img src={Adidasshoes} alt="www.imonline.shop" /></Link>
   
      </div>
    </>
  )
}

export default Shoesbottom
