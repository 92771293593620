import React, { Fragment, useState, useEffect } from "react";

import ReactPaginate from "react-paginate";
import Logo2 from "../../../../images/not_found_product.png";
import Loader from "../../../../layout/loader";
import TagSearchedItems from "./TagSearchedItems";


import './New.css'

function TagPagination({ loading, resultPerPages, products }) {
  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  // console.log(currentItems)

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + resultPerPages;
    // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    setCurrentItems(products.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(products.length / resultPerPages));
  }, [itemOffset, products, resultPerPages]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * resultPerPages) % products.length;
    // console.log(
    //     `User requested page number ${event.selected}, which is offset ${newOffset}`
    // );
    setItemOffset(newOffset);
  };

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          {currentItems.length === 0 ? (
            <Fragment>
              <div className="not-found-container">
                <img src={Logo2} alt="www.imonline.shop" />
                <h4>Sorry we did'nt found !!</h4>
              </div>
            </Fragment>
          ) : (
            <Fragment>
                 <div className="catogery-searched-result-main-div">
                 <TagSearchedItems currentItems={currentItems} />
                 </div>
              
              
            </Fragment>
          )}
        </Fragment>
      )}

      <ReactPaginate
        breakLabel="..."
        previousClassName={"react-pagenation-prev"}
        previousLinkClassName={"react-pagenation-prev-a"}
        nextLabel="Next"
        nextClassName={"react-pagenation-prev"}
        nextLinkClassName={"react-pagenation-prev-a"}
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        containerClassName={"react-pagenation-container"}
        breakClassName={"react-pagenation-li"}
        breakLinkClassName={"react-pagenation-li-a"}
        pageClassName={"react-pagenation-page-div"}
        pageLinkClassName={"react-pagenation-page-div-a"}
        activeClassName={"react-pagenation-active-class"}
        activeLinkClassName={"react-pagenation-active-class-a"}
        disabledClassName={"react-disable-pagenation-class"}
        disabledLinkClassName={"react-disable-pagenation-class-a"}
        pageCount={pageCount}
        previousLabel="Prev"
      />
    </Fragment>
  );
}

export default TagPagination;
