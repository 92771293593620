import axios from "axios";
import {
  GET_ALL_NOTIFICATION_FAIL,
  GET_ALL_NOTIFICATION_REQUEST,
  GET_ALL_NOTIFICATION_SUCCESS,
  NOTIFICATION_READED_FAIL,
  NOTIFICATION_READED_REQUEST,
  NOTIFICATION_READED_SUCCESS,
  CLEAR_ERROR,
  NOTIFICATION_SEND_REQUEST,
  NOTIFICATION_SEND_SUCCESS,
  NOTIFICATION_SEND_FAIL,
  GET_ALL_NOTIFICATION_BY_ADMIN_REQUEST,
  GET_ALL_NOTIFICATION_BY_ADMIN_SUCCESS,
  GET_ALL_NOTIFICATION_BY_ADMIN_FAIL,
  GET_SINGEL_NOTIFICATION_BY_ADMIN_REQUEST,
  GET_SINGEL_NOTIFICATION_BY_ADMIN_SUCCESS,
  GET_SINGEL_NOTIFICATION_BY_ADMIN_FAIL,
  UPDATE_NOTIFICATION_BY_ADMIN_REQUEST,
  UPDATE_NOTIFICATION_BY_ADMIN_SUCCESS,
  UPDATE_NOTIFICATION_BY_ADMIN_FAIL,
  DELETE_NOTIFICATION_BY_ADMIN_REQUEST,
  DELETE_NOTIFICATION_BY_ADMIN_SUCCESS,
  DELETE_NOTIFICATION_BY_ADMIN_FAIL
} from "../constants/notificationConstants";

// GET ALL NOTIFICATION BY USER
export const getAllNotificationaActionByUser = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_NOTIFICATION_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.get(`/api/all/notifaction/user`, config);

    dispatch({
      type: GET_ALL_NOTIFICATION_SUCCESS,
      payload: data.msg,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_NOTIFICATION_FAIL,
      payload: error.response.data.msg,
    });
  }
};

// NOTIFICATION READ BY USER

export const readNotificationaActionByUser = (id) => async (dispatch) => {
  try {
    dispatch({
      type: NOTIFICATION_READED_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.get(
      `/api/read/notifaction/user/${id}`,
      config
    );
    dispatch({
      type: NOTIFICATION_READED_SUCCESS,
      payload: data.msg,
    });
  } catch (error) {
    dispatch({
      type: NOTIFICATION_READED_FAIL,
      payload: error.response.data.msg,
    });
  }
};



// notification send by admin
export const sendNotificationaActionByUser = (id,message) => async (dispatch) => {
  try {
    dispatch({
      type: NOTIFICATION_SEND_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.post(
      `/api/create/notifaction/admin/${id}`,{message},
      config
    );
    dispatch({
      type: NOTIFICATION_SEND_SUCCESS,
      payload: data.msg,
    });
  } catch (error) {
    dispatch({
      type: NOTIFICATION_SEND_FAIL,
      payload: error.response.data.msg,
    });
  }
};



// GET ALL NOTIFICATION BY ADMIN

export const getAllNotificationaActionByAdmin = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_NOTIFICATION_BY_ADMIN_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.get(`/api/get/all/notifaction/admin`, config);

    dispatch({
      type: GET_ALL_NOTIFICATION_BY_ADMIN_SUCCESS,
      payload: data.msg,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_NOTIFICATION_BY_ADMIN_FAIL,
      payload: error.response.data.msg,
    });
  }
};



// GET SINGEL NOTIFICATION BY ADMIN

export const getSingelNotificationaActionByAdmin = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_SINGEL_NOTIFICATION_BY_ADMIN_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.get(
      `/api/get/singel/notifaction/admin/${id}`,
      config
    );

    console.log(data)
    dispatch({
      type: GET_SINGEL_NOTIFICATION_BY_ADMIN_SUCCESS,
      payload: data.msg,
    });
  } catch (error) {
    dispatch({
      type: GET_SINGEL_NOTIFICATION_BY_ADMIN_FAIL,
      payload: error.response.data.msg,
    });
  }
};



// UPDATE NOTIFICATION BY ADMIN

export const updateNotificationaActionByAdmin = (id,message) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_NOTIFICATION_BY_ADMIN_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.put(
      `/api/update/singel/notifaction/admin/${id}`,{message},
      config
    );
    dispatch({
      type: UPDATE_NOTIFICATION_BY_ADMIN_SUCCESS,
      payload: data.msg,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_NOTIFICATION_BY_ADMIN_FAIL,
      payload: error.response.data.msg,
    });
  }
};

// DELETE NOTIFICATION BY ADMIN
export const deleteNotificationaActionByAdmin = (id) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_NOTIFICATION_BY_ADMIN_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.delete(
      `/api/remove/singel/notifaction/admin/${id}`,
      config
    );
    dispatch({
      type: DELETE_NOTIFICATION_BY_ADMIN_SUCCESS,
      payload: data.msg,
    });
  } catch (error) {
    dispatch({
      type: DELETE_NOTIFICATION_BY_ADMIN_FAIL,
      payload: error.response.data.msg,
    });
  }
};





// clear error action
export const clearErrorAction = () => async (dispatch) => {
  dispatch({
    type: CLEAR_ERROR,
  });
};
