import React, { Fragment, useEffect } from 'react'
import { DataGrid } from '@material-ui/data-grid'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {getAllFormActionByAdmin,clearErrorAction} from '../redux/actions/imFormAction'
import swal from 'sweetalert';
// import { Button } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
// import DeleteIcon from '@material-ui/icons/Delete'
import Sidebar from './Sidebar'
import Loader from '../layout/loader'



function ImForms() {
    const dispatch = useDispatch();

    const { loading,error,forms } = useSelector((state) => state.getallForm)


    useEffect(() => {
        if (error) {
          swal({
            title: "Error",
            text: error,
            icon: "error",
          })
          dispatch(clearErrorAction())
        }
    
        dispatch(getAllFormActionByAdmin())
      }, [  dispatch, error  ])


    const columns = [
        { field: 'id', headerName: 'Delivery ID', minWidth: 170, flex: 0.7 },
        {
            field: 'Name',
            headerName: 'Delivery Name',
            minWidth: 150,
            flex: 0.5,
          },
          {
            field: 'Email',
            headerName: 'Delivery Email',
            minWidth: 150,
            flex: 0.5,
          },
        {
          field: 'RequestCreate',
          headerName: 'Request Submit',
          type: 'number',
          minWidth: 150,
          flex: 0.5,
        },
        {
          field: 'DeliveryStatus',
          headerName: 'Status',
          type: 'number',
          minWidth: 150,
          flex: 0.5,
        },
        {
            field: 'Pincode',
            headerName: 'Delivery Pincode',
            type: 'number',
            minWidth: 150,
            flex: 0.5,
          },
        {
          field: 'action',
          headerName: 'Action',
          type: 'number',
          minWidth: 150,
          flex: 0.3,
          sortable: false,
          renderCell: (params) => {
            return (
              <Fragment>
                <Link to={`/Admin/Upadte/Notifications/${params.getValue(params.id, 'id')}`}   >
                  <EditIcon />
                </Link>
              </Fragment>
            )
          }
        },
      ]

      const rows = [];
      forms && forms.forEach(item => {
        rows.push({
          id: item._id,
          Name:item.name,
          Email:item.email,
          RequestCreate: item.createdAt,
          DeliveryStatus: item.status,
          Pincode : item.pincode,
        })
      });

  return (
    <Fragment>
      {loading ? <Loader /> : (<Fragment>

        <div className="dashboard-container">
          <Sidebar />
          <div className="productListContainer">
            <h1 className='productHeadingList'> DELIVERY REQUEST'S </h1>
            <DataGrid

              rows={rows}
              columns={columns}
              pageSize={30}
              disableSelectionOnClick
              className='productListTable'
              autoHeight
            />
          </div>

        </div>
      </Fragment>)}
    </Fragment>
  )
}

export default ImForms