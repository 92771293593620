import {
    CLEAR_ERROR,
    CREATE_REVIEW_FAIL,
    CREATE_REVIEW_REQUEST,
    CREATE_REVIEW_RESET,
    CREATE_REVIEW_SUCCESS,

} from '../constants/reviewConstants'



// REVIEW CREATE REDUCER BY USER

export const reviewCreateReducer = ((state = {}, action) => {
    switch (action.type) {
        case CREATE_REVIEW_REQUEST:
            return {
                ...state,
                loading: true,

            }
        case CREATE_REVIEW_SUCCESS:
            return {
                loading: false,
                success: action.payload,
            }
        case CREATE_REVIEW_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case CREATE_REVIEW_RESET:
            return {
                ...state,
                success: false,
            }
        case CLEAR_ERROR:
            return {
                ...state,
                error: null
            }
        default:
            return state;

    }


})

