import React, { Fragment, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import MetaTag from '../../layout/MetaTag'
import { Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import swal from 'sweetalert';
// import axios from 'axios';
import './confirmOrder.css'
import CheckoutStep from './CheckoutStep'
import Loader from '../../layout/loader'

import { allcartItemAction } from '../../redux/actions/cartAction';
import { clearErrorAction, applyCoupanAction } from '../../redux/actions/coupanAction'



function ConfirmOrders() {
    const history = useHistory();
    const dispatch = useDispatch();

    // shipping 
    const { shippingInfo } = useSelector((state) => state.shippingInfo)

    // cart reducers
   const { carts } = useSelector((state) => state.carts);

    const { error, loading, success, coupanCode, coupanValue } = useSelector((state) => state.coupanApply)

    // apply coupan 
    const [applyCoupan, setApplyCoupan] = useState('');

    const subTotal = carts.reduce(
        (acc, item) => acc + item.quantity * item.price, 0
    )

    let itemPrice;

    carts && carts.map((newNumber) => (
        itemPrice = Number(newNumber.price)
    ))

    // for quantity calculation 
    const shippingQuantity = carts && carts.reduce(
        (acc, item) => acc + item.quantity * 1, 0
    )

    const shippingPrice = carts && carts.map((shippingprice) => {
        console.log(shippingprice)
        return shippingprice.productShippingCharges
    })

    const shippingCharges = shippingPrice === undefined || null ? shippingQuantity * shippingPrice : shippingQuantity * 100;

    var boxType;
    carts && carts.map((newNumber) => (
        boxType = Number(newNumber.selectBox)
    ))

    const boxCharges = shippingQuantity * boxType;

    // round off tax
    const tax = subTotal * 0.18;

    // round off total 
    const roundOffTotalTax = Math.round(tax)

    // const productSubTotal = subTotal + roundOffTotalTax;
    const totalPrice = subTotal + roundOffTotalTax + shippingCharges + boxCharges;

    const address = `${shippingInfo.address}
    ,${shippingInfo.landMark}
    ,${shippingInfo.city}
    ,${shippingInfo.state}
    ,${shippingInfo.district}
    ,${shippingInfo.pincode}
    ,${shippingInfo.phoneNo}`

    const proccedToPayments = () => {
        const data = {
            itemPrice,
            subTotal,
            shippingCharges,
            roundOffTotalTax,
            totalPrice,
            coupanCode,
            coupanValue,
            boxCharges,
            carts
        }

        sessionStorage.setItem('orderInfo', JSON.stringify(data))
        history.push('/confirm/payments')

    }



    const coupanApplySubmitHandler = async (e) => {
        e.preventDefault()
        if (!applyCoupan) {
            return swal({
                title: "Sorry",
                text: "Please Type Coupon..!",
                icon: "info",
            })

        }
        dispatch(applyCoupanAction(applyCoupan, totalPrice))
    }


    useEffect(() => {
        if (error) {
            swal({
                title: 'Error',
                text: error,
                icon: 'error'
            })
            dispatch(clearErrorAction())
        }
        if (success) {
            swal({
                title: 'Success',
                text: success,
                icon: 'success'
            })
        }


        dispatch(allcartItemAction())

    }, [dispatch, error, success])






    return (
        <Fragment>

            {loading ? <Loader /> : (<Fragment>
                <MetaTag title={'IM Online Shopping Confirm Product Details Page'} />
                <CheckoutStep activeStep={1} />
                <div className="confirm-order-page">
                    <div>
                        <div className="confirm-shipping-area">
                            <Typography>Shipping Info       </Typography>
                            <div className="confirm-shipping-area-box">
                                <div>
                                    <p>Name:</p>
                                    <span>{shippingInfo.name}</span>
                                </div>
                                <div>
                                    <p>Email:</p>
                                    <span>{shippingInfo.email}</span>
                                </div>
                                <div>
                                    <p>Phone:</p>
                                    <span>{shippingInfo.phoneNo}</span>
                                </div>
                                <div>
                                    <p>Address:</p>
                                    <span>{address}</span>
                                </div>
                            </div>
                        </div>
                        <div className="confirm-cart-item">
                            <Typography>Your Cart Items :  </Typography>
                            <div className="confirm-cartItems-container">
                                {carts && carts.map((item) => (
                                    <div key={item.product}  >
                                        <img src={item.image} alt="imonline.shop" />
                                        <Link to={`/ProductDetail/${item.product}`}>
                                            {item.name}
                                        </Link>

                                        <span>
                                            {item.quantity} <CloseIcon /> <span></span>{item.price} =
                                            <b> <CurrencyRupeeIcon />{item.quantity * item.price}     </b>
                                        </span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>


                    {/*    */}
                    <div>
                        <div className="order-summery">
                            <Typography>Order Summery  </Typography>
                            <div>
                                <div>
                                    <p>Subtotal:</p>
                                    <span>  {subTotal} <CurrencyRupeeIcon /> </span>
                                </div>

                                <div>
                                    <p>Shipping Charges:</p>
                                    <span> {shippingCharges} <CurrencyRupeeIcon />  </span>
                                </div>

                                <div>
                                    <p>Box Charges:</p>
                                    <span> {boxCharges} <CurrencyRupeeIcon />  </span>
                                </div>

                                <div>
                                    <p>GST:</p>
                                    <span> {roundOffTotalTax} <CurrencyRupeeIcon /> </span>
                                </div>
                            </div>
                            <div className="order-summery-total">
                                <p>
                                    <b>Total :</b>
                                </p>
                                <span>{totalPrice} <CurrencyRupeeIcon /> </span>
                            </div>
                            {success ? (<Fragment>
                                <div className="coupan-div">
                                    <input type="text" className='apply-coupan-text-input' value={applyCoupan} onChange={(e) => setApplyCoupan(e.target.value)} required />
                                    <button className='apply-coupan-div' onClick={coupanApplySubmitHandler}>apply coupan</button>
                                    <div className="coupan-apply-details-">
                                        <div>
                                            <p>
                                                <b>Discount : {coupanValue}</b>
                                            </p>
                                        </div>
                                        <div>
                                            <p>
                                                <b>Coupan Code :{coupanCode}</b>
                                            </p>
                                        </div>

                                    </div>
                                    <p>please do not the refresh page !!</p>
                                </div>
                            </Fragment>) : (<Fragment>
                                <div className="coupan-div">
                                    <input type="text" className='apply-coupan-text-input' value={applyCoupan} onChange={(e) => setApplyCoupan(e.target.value)} required />
                                    <button className='apply-coupan-div' onClick={coupanApplySubmitHandler}>apply coupan</button>
                                </div>
                            </Fragment>)}


                            <button className='procced-to-payment' onClick={proccedToPayments} > Procced To Payment  </button>
                        </div>
                    </div>


                </div>
            </Fragment>)}
        </Fragment>

    )
}

export default ConfirmOrders
