import {
  ACCOUNT_DETAILS_FAIL,
  ACCOUNT_DETAILS_REQUEST,
  ACCOUNT_DETAILS_SUCCESS,
  ALL_ACCOUNT_DETAILS_FAIL,
  ALL_ACCOUNT_DETAILS_REQUEST,
  ALL_ACCOUNT_DETAILS_SUCCESS,
  CLEAR_ERROR,
  SAVE_BANK_ACCOUNT_DETAILS_FAIL,
  SAVE_BANK_ACCOUNT_DETAILS_REQUEST,
  SAVE_BANK_ACCOUNT_DETAILS_SUCCESS,
} from "../constants/refundConstant";
import axios from "axios";

// SAVE BANK ACCOUNT
export const saveBankAccountDetailsAction =
  (
    name,
    email,
    bankIfscCode,
    stateName,
    bankName,
    bankAddress,
    branchName,
    bankCityName,
    bankContactNumber,
    bankAccountNo,
    confirmBankAccountNo,
    fullBankName
  ) =>
  async (dispatch) => {
    try {

      // console.log( name,
      //   email,
      //   bankIfscCode,
      //   stateName,
      //   bankName,
      //   bankAddress,
      //   branchName,
      //   bankCityName,
      //   bankContactNumber,
      //   bankAccountNo,
      //   confirmBankAccountNo,
      //   fullBankName)
      // name,
      //   email,
      //   bankName,
      //   bankAccountNo,
      //   fullBankName,
      //   confirmBankAccountNo,
      //   stateName,
      //   bankAddress,
      //   branchName,
      //   bankCityName,
      //   bankIfscCode,
      //   bankContactNumber,
      dispatch({
        type: SAVE_BANK_ACCOUNT_DETAILS_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.post(
        `/api/save-bank-account/details`,
        {
          name,
          email,
          bankIfscCode,
          stateName,
          bankName,
          bankAddress,
          branchName,
          bankCityName,
          bankContactNumber,
          bankAccountNo,
          confirmBankAccountNo,
          fullBankName,
        },
        config
      );
      dispatch({
        type: SAVE_BANK_ACCOUNT_DETAILS_SUCCESS,
        payload: data.msg,
      });
    } catch (error) {
      return dispatch({
        type: SAVE_BANK_ACCOUNT_DETAILS_FAIL,
        payload: error.response.data.msg,
      });
    }
  };

// GET SAVING BANK ACCOUNT BY USER
export const getAccountDetailsAction = () => async (dispatch) => {
  try {
    dispatch({
      type: ACCOUNT_DETAILS_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.get(`/api/user-accounts/details`, config);
    dispatch({
      type: ACCOUNT_DETAILS_SUCCESS,
      payload: data.msg,
    });
  } catch (error) {
    return dispatch({
      type: ACCOUNT_DETAILS_FAIL,
      payload: error.response.data.msg,
    });
  }
};

// GET SAVING BANK ACCOUNT BY ADMIN
export const allBankAccountDetailsAction = () => async (dispatch) => {
  try {
    dispatch({
      type: ALL_ACCOUNT_DETAILS_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.get(
      `/api/all-save-bank-accounts/details`,
      config
    );
    dispatch({
      type: ALL_ACCOUNT_DETAILS_SUCCESS,
      payload: data.msg,
    });
  } catch (error) {
    return dispatch({
      type: ALL_ACCOUNT_DETAILS_FAIL,
      payload: error.response.data.msg,
    });
  }
};

// error
export const clearErrorAction = () => async (dispatch) => {
  dispatch({
    type: CLEAR_ERROR,
  });
};
