import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

import Navbar from '../../HomeScreen/Navbar';
import MetaTag from '../../../layout/MetaTag';
import Footer from '../../HomeScreen/bottomsection/Footer';
import { Helmet } from 'react-helmet'

import SideBarAccount from './SideBarAccount'

function HowToShop() {
    return (
        <Fragment>

            <Helmet  >
                <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=2.0" />
                <meta name="description" content={`How to shop page || How will I know if IMONLINE.SHOP has received my order? ,   There’s an item missing from my order, what should I do?  ||  How can I view my order information and history? ||     Can I amend my order? || im online shop || how to shop at imonline.shop  || imonline.shop || how to do shop || shopping with im ||`} />
                <meta name="keywords" content={`Can I cancel my order? || I did not complete the entire checkout process. Will my item(s) remain in my shopping bag?  || how will I be refunded? ||  My order was unsuccessful, what should I do? ||  I received an error when placing my order, what should I do? ||  imonline.shop , how to shop || shop   page   ||. imonline.shop , how to do shop page`} />
                <meta name="robots" content="index, follow " />
                <meta property="og:title" content={`How to shop page || How will I know if IMONLINE.SHOP has received my order? ,   There’s an item missing from my order, what should I do?  ||  How can I view my order information and history? ||     Can I amend my order? || im online shop || how to shop at imonline.shop  || imonline.shop || how to do shop || shopping with im ||`} />
                <meta property="og:description" content={`Can I cancel my order? || I did not complete the entire checkout process. Will my item(s) remain in my shopping bag?  || how will I be refunded? ||  My order was unsuccessful, what should I do? ||  I received an error when placing my order, what should I do? ||  imonline.shop , how to shop || shop   page   ||. imonline.shop , how to do shop page`} />
                <meta property="og:type" content="website" />
                {/* <meta property="og:url" content={items.imageurl}   key={items.public_id}/>
            <meta property="og:image" content={items.imageurl}  key={items.public_id}/> */}
                <meta property="og:locale" content="en-GB_IN" />
                <meta property="og:site_name" content="IMONLINE.SHOP" />
                {/* <meta property="og:price:currency" content="INR" />
            <meta property="og:price:amount" content={product.price} /> */}
            </Helmet>

            <MetaTag title={'IM Online Shopping How to Shop Details Page'} />
            <Navbar />
            <div className="container">
                <div className="row">
                    <div className="col-md-3">
                        <SideBarAccount />
                    </div>
                    <div className="col-md-9">
                        <div className="right-side-paragraph">
                            <div className="h2-heading">
                                <h2> HOW TO SHOP </h2>
                            </div>
                            <div className="details-of-account-privileges">
                                <ul>
                                    How will I know if IMONLINE.SHOP has received my order?
                                    <li>
                                        <p>
                                            After you place your order, you will receive a confirmation email from IMONLINE.SHOP
                                            within 15 minutes informing you that your order has been received.
                                            If you do not receive a confirmation email after 15 minutes
                                            (please ensure the email was not sent to your junk folder),
                                            it means we have not received your order and no payment has been
                                            received by IMONLINE.SHOP You will then need to place a new order.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            If you placed the order through your account, you can locate it on My Orders & Returns under My Account.
                                            Please contact us at information@imonline.shop or on voice chat for further assistance.
                                        </p>
                                    </li>
                                </ul>
                                <ul>
                                    There’s an item missing from my order, what should I do?
                                    <li>
                                        Please check the invoice that was included in your package. If there is any missing item(s),
                                        contact us at information@imonline.shop or on voice chat.
                                    </li>
                                    <li>
                                        All orders will be fulfilled based on available stock. If a particular product in your
                                        order does not meet our quality checks or is out of stock,
                                        your order will be processed with the remaining item(s). Item(s)
                                        not included will be refunded accordingly.
                                    </li>
                                </ul>
                                <ul>
                                    How can I view my order information and history?
                                    <li>
                                        To view your order information and history, <Link to={'/Login'}><span>sign in  </span>  </Link> to your account,
                                        go to My Account and then to My Purchases & Returns.
                                        Track the View Order button that corresponds to your order number.
                                    </li>
                                    <li>
                                        If you have opted for guest checkout, you can view your order information
                                        via the confirmation email sent to you. Please contact us at
                                        information@imonline.shop or on live chat for further assistance.
                                    </li>
                                </ul>
                                <ul>
                                    Can I amend my order?
                                    <li>
                                        For international orders, we are only able to amend the billing/shipping address
                                    </li>
                                    <li>
                                        You can’t amend the size/colour of the item(s); remove or add item(s); or change the payment mode
                                    </li>
                                </ul>
                                <ul>
                                    Can I cancel my order?
                                    <li>
                                        Once your order has been placed successfully, you will not be able to cancel it.
                                    </li>
                                </ul>
                                <ul>
                                    How do I add items back to my shopping bag after cancelling my order?
                                    <li>
                                        All orders, including cancelled orders, will be recorded in your purchase history.
                                        To reorder,<Link to={'/Login'}><span>sign in  </span>  </Link> in to your account, go to My Account and then to My Purchases & Returns.
                                        Locate the View Order Details button that corresponds to your order.
                                        You can view the items you have cancelled and add them back to your shopping <Link to={'/Cart'}><span>cart. </span>  </Link>
                                    </li>
                                </ul>
                                <ul>
                                    I did not complete the entire checkout process. Will my item(s) remain in my shopping bag?
                                    <li>
                                        All items that you previously added into your shopping bag under your account will remain in your
                                        shopping bag until you have successfully placed the order or removed the item(s) from it.
                                    </li>
                                </ul>
                                <ul>
                                    My order was cancelled due to online security checks or stock availability, how will I be refunded?
                                    <li>
                                        If your order was cancelled and your payment was captured, you will be refunded via your original payment mode.
                                        Please allow up to 14 working days for payment to be refunded. Once your refund is processed,
                                        you will receive a notification email. After we have refunded you, it might take up to 7 working days
                                        or more to see it reflected in your account. This depends on your payment services provider.
                                        If you have not received your refund after 7 working days, contact your payment processor directly for assistance.
                                    </li>
                                </ul>
                                <ul>
                                    When I place an item in my shopping bag, will it automatically be reserved for me?
                                    <li>
                                        Placing an item in your shopping bag does not guarantee that the item has been reserved.
                                        Complete the checkout process to ensure you get your item(s).
                                    </li>
                                </ul>
                                <ul>
                                    My order was unsuccessful, what should I do?
                                    <li>
                                        If your order was not successfully placed, please check that the correct address,
                                        postal code and authentication code (if applicable) were used. Do note that periods
                                        (.) and commas (,) are the only recognised characters within the order fields.
                                    </li>
                                </ul>
                                <ul>
                                    I received an error when placing my order, what should I do?
                                    <li>
                                        Please provide a screenshot of the page where you experienced the error, or copy the error message and send it to information@imonline.shop for further assistance.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    )
}

export default HowToShop