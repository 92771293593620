import React, { Fragment } from 'react'
import { Link, useHistory } from 'react-router-dom'
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import ReplyIcon from '@mui/icons-material/Reply';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import InfoIcon from '@mui/icons-material/Info';
import LockIcon from '@mui/icons-material/Lock';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import RedeemIcon from '@mui/icons-material/Redeem';



function SideBarAccount() {

    const history = useHistory();

    const howToShopHandler = () => {
        history.push('/how-to-shop')
        window.location.reload()
    }

    const promotionHandler = () => {
        history.push('/promotions')
        window.location.reload()
    }

    const returnAndExchangeHandler = () => {
        history.push('/about/return-and-exchange')
        window.location.reload()
    }

    const shippingAndTrackingHandler = () => {
        history.push('/about/Shipping-Tracking')
        window.location.reload()
    }


    const productAndPricingHandler = () => {
        history.push('/about/Product-And-Pricing')
        window.location.reload()
    }

    const securityHandler = () => {
        history.push('/about/Security')
        window.location.reload()

    }

    const paymentHandler = () => {
        history.push('/about/Payment')
        window.location.reload()

    }

    const giftBoxHandler = () => {
        history.push('/about/GiftBox')
        window.location.reload()

    }


    return (
        <Fragment>

            <div className="left-side-buttons-for-buy">
                <button className='buttons-for-shop' onClick={howToShopHandler}  > <ShoppingBagIcon /> HOW TO SHOP</button>
                <button className='buttons-for-shop' onClick={promotionHandler}   > <VolumeUpIcon />PROMOTIONS</button>
                <button className='buttons-for-shop' onClick={returnAndExchangeHandler}> <ReplyIcon /> RETURNS & EXCHANGES</button>
                <button className='buttons-for-shop' onClick={shippingAndTrackingHandler}> <LocalShippingIcon /> SHIPPING & TRACKING</button>
                <button className='buttons-for-shop' onClick={productAndPricingHandler}> <InfoIcon /> PRODUCTS & PRICING</button>
                <button className='buttons-for-shop' onClick={securityHandler}   >  <LockIcon /> SECURITY</button>
                <button className='buttons-for-shop' onClick={paymentHandler}> <AttachMoneyIcon /> PAYMENT</button>
                <button className='buttons-for-shop' onClick={giftBoxHandler}  > <RedeemIcon /> GIFT BOX</button>

            </div>

            <div className="left-side-container">

                <p>NEED HELP? GET IN TOUCH WITH US</p>
                <div className="button-for-submit">
                    <Link to='/user/complain/request'><button>SUBMIT A REQUEST</button></Link>
                </div>

            </div>

        </Fragment>
    )
}

export default SideBarAccount