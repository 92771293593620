
// GET ALL USER NOTIFICATIONS
export const GET_ALL_NOTIFICATION_REQUEST='GET_ALL_NOTIFICATION_REQUEST';
export const GET_ALL_NOTIFICATION_SUCCESS='GET_ALL_NOTIFICATION_SUCCESS';
export const GET_ALL_NOTIFICATION_FAIL='GET_ALL_NOTIFICATION_FAIL';
export const GET_ALL_NOTIFICATION_RESET='GET_ALL_NOTIFICATION_RESET';

// READ NOTIFICATION BY USERS
export const NOTIFICATION_READED_REQUEST='NOTIFICATION_READED_REQUEST';
export const NOTIFICATION_READED_SUCCESS='NOTIFICATION_READED_SUCCESS';
export const NOTIFICATION_READED_FAIL='NOTIFICATION_READED_FAIL';

// CREATE NOTIFICATION BY ADMIN
export const NOTIFICATION_SEND_REQUEST='NOTIFICATION_SEND_REQUEST';
export const NOTIFICATION_SEND_SUCCESS='NOTIFICATION_SEND_SUCCESS';
export const NOTIFICATION_SEND_FAIL='NOTIFICATION_SEND_FAIL';
export const NOTIFICATION_SEND_RESET='NOTIFICATION_SEND_RESET';

// GET ALL NOTIFICATION BY ADMIN
export const GET_ALL_NOTIFICATION_BY_ADMIN_REQUEST='GET_ALL_NOTIFICATION_BY_ADMIN_REQUEST';
export const GET_ALL_NOTIFICATION_BY_ADMIN_SUCCESS='GET_ALL_NOTIFICATION_BY_ADMIN_SUCCESS';
export const GET_ALL_NOTIFICATION_BY_ADMIN_FAIL='GET_ALL_NOTIFICATION_BY_ADMIN_FAIL';

// GET SINGEL NOTIFICATION BY ADMIN
export const GET_SINGEL_NOTIFICATION_BY_ADMIN_REQUEST='GET_SINGEL_NOTIFICATION_BY_ADMIN_REQUEST';
export const GET_SINGEL_NOTIFICATION_BY_ADMIN_SUCCESS='GET_SINGEL_NOTIFICATION_BY_ADMIN_SUCCESS';
export const GET_SINGEL_NOTIFICATION_BY_ADMIN_FAIL='GET_SINGEL_NOTIFICATION_BY_ADMIN_FAIL';

// UPDATE NOTIFICATION BY ADMIN
 export const UPDATE_NOTIFICATION_BY_ADMIN_REQUEST='UPDATE_NOTIFICATION_BY_ADMIN_REQUEST';
 export const UPDATE_NOTIFICATION_BY_ADMIN_SUCCESS='UPDATE_NOTIFICATION_BY_ADMIN_SUCCESS';
 export const UPDATE_NOTIFICATION_BY_ADMIN_FAIL='UPDATE_NOTIFICATION_BY_ADMIN_FAIL';
 export const UPDATE_NOTIFICATION_BY_ADMIN_RESET='UPDATE_NOTIFICATION_BY_ADMIN_RESET';

//  DELETE NOTIFICATION BY ADMIN
export const DELETE_NOTIFICATION_BY_ADMIN_REQUEST='DELETE_NOTIFICATION_BY_ADMIN_REQUEST';
export const DELETE_NOTIFICATION_BY_ADMIN_SUCCESS='DELETE_NOTIFICATION_BY_ADMIN_SUCCESS';
export const DELETE_NOTIFICATION_BY_ADMIN_FAIL='DELETE_NOTIFICATION_BY_ADMIN_FAIL';
export const DELETE_NOTIFICATION_BY_ADMIN_RESET='DELETE_NOTIFICATION_BY_ADMIN_RESET';


// CLEAR ERROR
export const CLEAR_ERROR='CLEAR_ERROR';