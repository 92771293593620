import React, { Fragment, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ReactStars from "react-rating-stars-component";
import CloseIcon from '@mui/icons-material/Close';
import { addEllipsis } from '../../../../utils/textRapUtils'
import swal from 'sweetalert';
import './New.css'
import { directBuyAction } from '../../../../redux/actions/directBuyAction';

function TagSearchedItems({ currentItems }) {

    const dispatch = useDispatch();
    const history = useHistory();

    const { isAuthenticated } = useSelector(state => state.user)


    const [buyNowButtonClick, setBuyNowButtonClick] = useState(false)
    const [seletedProductId, setSelectedProductId] = useState()
    const [selectBox, setSelectBox] = useState('')
    const [colour, setColour] = useState('')
    const [size, setSize] = useState('')


    const buyNowHandler = (id) => {
        if (!id) {
            return swal({
                title: "Validation Info",
                text: 'Invalid..!!',
                icon: "info",
            })
        }

        setSelectedProductId(id)
        setBuyNowButtonClick(true)
    }


    // buy button handler

    const bestSellerBuyNowHanlder = (e) => {
        e.preventDefault();
        console.log('hiii')

        if (!size || !colour) {
            return swal({
                title: "Validation Error",
                text: 'Please Select Colour and Size..!!',
                icon: "info",
            })

        }

        if (!selectBox) {
            return swal({
                title: "Validation Error",
                text: 'Please Select Box !!',
                icon: "info",
            })
        }
        // by defalut it will be one
        const quantity = 1;

        dispatch(directBuyAction(seletedProductId, quantity, size, colour, selectBox));


        if (!isAuthenticated) {
            swal({
                title: "Validation Error",
                text: 'Please Login..!!',
                icon: "info",
            })
        }

        history.push('/Login?redirect=/Checkout/directbuy')
    }

    //    button close function

    const closeBestSellerBuyNowButton = () => {
        setBuyNowButtonClick(false)
        setSelectedProductId('')
    }






    return (
        <Fragment>
            <Fragment>

                {currentItems && currentItems.map((pro, index) => (
                    <div className="catogery-searched-result-product-style-main-container" key={index}>
                        <div className="catogery-searched-result-product-style-image">
                            <Link to={`/ProductDetail/${pro._id}`}><img src={pro.mainImage[0].imageurl} alt="www.im.com" /></Link>
                        </div>
                        <h6 className='catogery-searched-result-product-style-h6'>  <Link to={`/ProductDetail/${pro._id}`}> {pro.tag}  </Link>    </h6>
                        <div className="catogery-searched-result-product-style-item-name-heading-style">
                            <h3>  <Link to={`/ProductDetail/${pro._id}`}> {addEllipsis(pro.name)}  </Link>    </h3>
                        </div>
                        <div className="catogery-searched-result-product-style-item-review-style">
                            <p> <ReactStars

                                padding='5px'
                                edit={false}
                                color='gray'
                                activeColor='#ffd700'
                                size={window.innerWidth < 600 ? 25 : 40}
                                value={pro.ratings}
                                isHalf={true} />             </p>
                        </div>
                        <div className="catogery-searched-result-product-style-item-buttons-style">
                            <button onClick={() => buyNowHandler(pro._id)} className='searched-product-buy-now-button-style'> <ShoppingCartIcon /> </button>
                        </div>

                        {buyNowButtonClick !== false && seletedProductId === pro._id ? (
                            <>
                                <div className="buy-now-main-container">
                                    <div className="buy-now-second-conatiner-close-div">
                                        <button onClick={closeBestSellerBuyNowButton}> <CloseIcon /> </button>
                                    </div>
                                    <div className="buy-now-second-container">
                                        <div className="buy-now-third-container">
                                            <h2 className="heading-for-buy-now">
                                                Select Options
                                            </h2>
                                        </div>
                                        <form onSubmit={bestSellerBuyNowHanlder}>
                                            { /**SIZE BUTTON */}
                                            <div className="buy-now-size-detail-div">
                                                <span><label className="">Sizes</label>
                                                    <select onChange={(e) => setSize(e.target.value)}>
                                                        <option value=''>Select Size</option>
                                                        {pro.sizeOfShoes && pro.sizeOfShoes.map((sizes, index) => (
                                                            <option value={sizes} key={index} >{sizes}</option>
                                                        ))}
                                                    </select>
                                                </span>
                                            </div>
                                            {/**COLOUR BUTTON */}
                                            <div className="buy-now-colour-detail-div" >
                                                <span><label className="">Colours </label>
                                                    <select onChange={(e) => setColour(e.target.value)} >
                                                        <option value=''>Select Colour</option>
                                                        {pro.colorOfShoes && pro.colorOfShoes.map((catagery, index) => (
                                                            <option value={catagery} key={index} >{catagery}</option>
                                                        ))}
                                                    </select>
                                                </span>

                                            </div>


                                            {/**Box BUTTON */}
                                            <div className="buy-now-colour-detail-div" >
                                                <span><label className="">Box Type </label>
                                                    <select onChange={(e) => setSelectBox(e.target.value)} >
                                                        <option value=''>Select Packaging Type</option>
                                                        <option value='0'> Default-Packing</option>
                                                        <option value='50'>Simple-Box  50₹</option>
                                                        <option value='150' >Original-Box  150₹</option>
                                                    </select>
                                                </span>
                                            </div>
                                            <div className="buy-now-container-btn-div">
                                                <input type='submit' value="Buy Now" />
                                            </div>

                                        </form>
                                    </div>

                                </div>
                            </>
                        ) : (null)}


                    </div>
                ))}

            </Fragment>
        </Fragment>
    )
}

export default TagSearchedItems