import axios from "axios";

import {
    ADD_PRODUCT_REQUEST,
    ADD_PRODUCT_REQUEST_SUCCESS,
    ADD_PRODUCT_REQUEST_FAIL,
    REMOVE_PRODUCT_REQUEST,
    REMOVE_PRODUCT_REQUEST_SUCCESS,
    REMOVE_PRODUCT_REQUEST_FAIL,
    ALL_WISHLIST_PRODUCT_REQUEST,
    ALL_WISHLIST_PRODUCT_REQUEST_SUCCESS,
    ALL_WISHLIST_PRODUCT_REQUEST_FAIL,
    ALL_WISHLIST_PRODUCT_REQUEST_ADMIN,
    ALL_WISHLIST_PRODUCT_REQUEST_ADMIN_SUCCESS,
    ALL_WISHLIST_PRODUCT_REQUEST_ADMIN_FAIL,
    CLEAR_ERROR
} from '../constants/wishListConstant'


// import {URL} from '../../Url';


// create coupan by admin
export const addProductToWishListAction = (id) => async (dispatch) => {
    try {

        dispatch({
            type: ADD_PRODUCT_REQUEST
        })
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        const {
            data
        } = await axios.post(`/api/add-product/wishlist`, {
            id
        }, config)
        dispatch({
            type: ADD_PRODUCT_REQUEST_SUCCESS,
            payload: data.msg
        })
    } catch (error) {
        dispatch({
            type: ADD_PRODUCT_REQUEST_FAIL,
            payload: error.response.data.msg
        })
    }


}






//remove product from wishList
export const removeProductToWishListAction = (id) => async (dispatch) => {
    try {

        dispatch({
            type: REMOVE_PRODUCT_REQUEST
        })
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        const {
            data
        } = await axios.delete(`/api/remove-product/wishlist/${id}`, {
            id
        }, config)
        dispatch({
            type: REMOVE_PRODUCT_REQUEST_SUCCESS,
            payload: data.msg
        })
    } catch (error) {
        dispatch({
            type: REMOVE_PRODUCT_REQUEST_FAIL,
            payload: error.response.data.msg
        })
    }


}



// wishlist item by user
export const allProductToWishListAction = () => async (dispatch) => {
    try {

        dispatch({
            type: ALL_WISHLIST_PRODUCT_REQUEST
        })
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        const {
            data
        } = await axios.get(`/api/allWishList/user`, config)
        dispatch({
            type: ALL_WISHLIST_PRODUCT_REQUEST_SUCCESS,
            payload: data.allProductInWishList
        })
    } catch (error) {
        dispatch({
            type: ALL_WISHLIST_PRODUCT_REQUEST_FAIL,
            payload: error.response.data.msg
        })
    }


}



// wishlist item by admin
export const allProductToWishListActionForAdmin = () => async (dispatch) => {
    try {

        dispatch({
            type: ALL_WISHLIST_PRODUCT_REQUEST_ADMIN
        })
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        const {
            data
        } = await axios.get(`/api/allWishList/user/admin`, config)
        dispatch({
            type: ALL_WISHLIST_PRODUCT_REQUEST_ADMIN_SUCCESS,
            payload: data.allProductInWishList
        })
    } catch (error) {
        dispatch({
            type: ALL_WISHLIST_PRODUCT_REQUEST_ADMIN_FAIL,
            payload: error.response.data.msg
        })
    }


}





// clear the error 


export const clearErrorAction = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERROR
    })
}