
// GET ALL COUPANS BY ADMIN
export const ALL_COUPAN_REQUEST ='ALL_COUPAN_REQUEST';
export const ALL_COUPAN_REQUEST_SUCCESS ='ALL_COUPAN_REQUEST_SUCCESS';
export const ALL_COUPAN_REQUEST_FAIL ='ALL_COUPAN_REQUEST_FAIL';


// GET SINGEL COUPAN BY ID

export const SINGEL_COUPAN_REQUEST ='SINGEL_COUPAN_REQUEST';
export const SINGEL_COUPAN_REQUEST_SUCCESS ='SINGEL_COUPAN_REQUEST_SUCCESS';
export const SINGEL_COUPAN_REQUEST_FAIL ='SINGEL_COUPAN_REQUEST_FAIL';



// CREATE COUPAN BY ADMIN

export const CREATE_COUPAN_REQUEST ='CREATE_COUPAN_REQUEST';
export const CREATE_COUPAN_REQUEST_SUCCESS ='CREATE_COUPAN_REQUEST_SUCCESS';
export const CREATE_COUPAN_REQUEST_FAIL ='CREATE_COUPAN_REQUEST_FAIL';


// EDIT COUPAN BY ADMIN

export const EDIT_COUPAN_REQUEST ='EDIT_COUPAN_REQUEST';
export const EDIT_COUPAN_REQUEST_SUCCESS ='EDIT_COUPAN_REQUEST_SUCCESS';
export const EDIT_COUPAN_REQUEST_FAIL ='EDIT_COUPAN_REQUEST_FAIL';


// DELETE COUPAN BY ADMIN

export const DELETE_COUPAN_REQUEST ='DELETE_COUPAN_REQUEST';
export const DELETE_COUPAN_REQUEST_SUCCESS ='DELETE_COUPAN_REQUEST_SUCCESS';
export const DELETE_COUPAN_REQUEST_FAIL ='DELETE_COUPAN_REQUEST_FAIL';



export const COUPAN_APPLY_REQUEST ='COUPAN_APPLY_REQUEST';
export const COUPAN_APPLY_REQUEST_SUCCESS ='COUPAN_APPLY_REQUEST_SUCCESS';
export const COUPAN_APPLY_REQUEST_FAIL ='COUPAN_APPLY_REQUEST_FAIL';




export const CLEAR_ERROR ='CLEAR_ERROR';