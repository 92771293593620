export const ADD_TO_CART_REQUEST ='ADD_TO_CART_REQUEST';
export const ADD_TO_CART_SUCCESS='ADD_TO_CART_SUCCESS';
export const ADD_TO_CART_FAIL='ADD_TO_CART_FAIL';
export const ADD_TO_CART_RESET='ADD_TO_CART_RESET';


export const ALL_CART_ITEM_REQUEST='ALL_CART_ITEM_REQUEST';
export const ALL_CART_ITEM_SUCCESS='ALL_CART_ITEM_SUCCESS';
export const ALL_CART_ITEM_FAIL='ALL_CART_ITEM_FAIL';


export const REMOVE_FROM_CART_REQUEST ='REMOVE_FROM_CART_REQUEST';
export const REMOVE_FROM_CART_SUCCESS ='REMOVE_FROM_CART_SUCCESS';
export const REMOVE_FROM_CART_FAIL ='REMOVE_FROM_CART_FAIL';
export const REMOVE_FROM_CART_RESET='REMOVE_FROM_CART_RESET';


export const CLEAR_CART_ERROR='CLEAR_CART_ERROR';

export const SAVE_SHIPPING_INFO ='SAVE_SHIPPING_INFO';

export const SAVE_BUY_ITEM_INFO='SAVE_BUY_ITEM_INFO';