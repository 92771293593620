import React, { Fragment, useEffect } from 'react';
// import Navbar from '../../components/HomeScreen/Navbar';
// import Footer from '../../components/HomeScreen/bottomsection/Footer';
import swal from 'sweetalert';
import { Link } from 'react-router-dom'
import { DataGrid } from '@material-ui/data-grid'
import { useSelector, useDispatch } from 'react-redux'
import { allCancelOrderByUserAction, clearErrorAction } from '../../redux/actions/orderAction'
import Loader from '../../layout/loader'
import MetaTag from '../../layout/MetaTag'
import EmptyOrder from './EmptyOrder';
import LaunchIcon from '@mui/icons-material/Launch';
import Typography from '@material-ui/core/Typography'

// css
import './cancelOrderDetail.css'

function CancelOrderDetail() {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.user)
  const { error, loading, cancelOrders } = useSelector((state) => state.allCancelOrderByUser)

  useEffect(() => {

    if (error) {
      swal({
        title: "Error",
        text: error,
        icon: 'error',
      });
      dispatch(clearErrorAction())
    }

    dispatch(allCancelOrderByUserAction())

  }, [dispatch, error])


  const columns = [
    { field: 'id', headerName: 'Cancel ID', minWidth: 250, flex: 1 },
    {
      field: 'cancelStatus',
      headerName: 'Cancel Status',
      minWidth: 200,
      flex: 0.5,
    },
    {
      field: 'orderId',
      headerName: 'Order Id',
      type: 'number',
      minWidth: 300,
      flex: 1,
    },
    {
      field: 'userId',
      headerName: 'User Id',
      type: 'number',
      minWidth: 300,
      flex: 1,
    },
    {
      field: 'cancelConfirmCreate',
      headerName: 'Cancel Confirm CreatedAt',
      type: 'number',
      minWidth: 250,
      flex: 0.5,
    },
    {
      field: 'createAt',
      headerName: 'CreatedAt',
      type: 'number',
      minWidth: 250,
      flex: 0.5,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      minWidth: 150,
      type: "number",
      flex: 0.3,
      sortable: false,
      renderCell: (params) => {
        return (
          <Link to={`/order/${params.getValue(params.id, 'orderId')}`}  >

            <LaunchIcon />
          </Link>
        )
      }
    }

  ]

  const rows = [];
  cancelOrders && cancelOrders.forEach(item => {
    rows.push({
      id: item._id,
      cancelStatus: item.cancelInformation.status,
      orderId: item.orderId,
      userId: item.user,
      cancelConfirmCreate: (item.cancelConfirmAt === '' ? (<Fragment> <p>Soon Will Update</p> </Fragment>) : (String(item.cancelConfirmAt).substring(0, 10))),
      createAt: (item.cancelInformation.createdAt).substring(0, 10),
    })
  });



  return (
    <Fragment>
      <MetaTag title={`IM Online Shopping All Cancel Orders by ${user.firstName} Details Page `} />
      {loading ? <Loader /> : (<Fragment>
        {cancelOrders.lenght === 0 ? (<Fragment>
          <EmptyOrder />
        </Fragment>) : (<Fragment>
          <div className="myOrdersPage">
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={20}
              disableSelectionOnClick
              className='productListTable'
              autoHeight
            />


            <Typography id='myOrdersHeading'>{user.firstName}'s Cancelled Items </Typography>
          </div>
        </Fragment>)}
      </Fragment>)}
    </Fragment>
  )
}

export default CancelOrderDetail