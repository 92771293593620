import React, { Fragment, useEffect } from 'react'

import { useAlert } from 'react-alert'
import FeatureProps from './FeatureProps';
import { useSelector, useDispatch } from 'react-redux'
import { Carousel } from 'react-bootstrap';
import { clearErrorAction, productAction } from '../../../../redux/actions/productAction'

function Feature() {

    const dispatch = useDispatch();
    const alert = useAlert();

    const { loading, products, error } = useSelector(state => state.products)

    useEffect(() => {

        if (error) {
            alert.error(error)
            dispatch(clearErrorAction(error))

        }
        dispatch(productAction())

    }, [alert, dispatch, error])


    return (
        <Fragment>
            <Carousel>
                <Carousel.Item interval={5000}>
                    <img
                        className="carsoule-photo"
                        src={'https://res.cloudinary.com/im-online-shop/image/upload/v1671444505/ads%20images/Best_Biriyani_Facebook_Post_1_ulzdgn.png'}
                        alt="First slide"
                        height={'100%'}
                    />
                </Carousel.Item>
                <Carousel.Item interval={5000}>
                    <img
                        className="carsoule-photo"
                        src={'https://res.cloudinary.com/im-online-shop/image/upload/v1671444505/ads%20images/Best_Biriyani_Facebook_Post_1_ulzdgn.png'}
                        alt="First slide"
                        height={'100%'}
                    />
                </Carousel.Item>
            </Carousel>
            <div className='bestSellerHeadingDiv' >
                <h1 className='bestSellerHeading'>Feature </h1>
                <p className='bestSellerPara'>Get all Feature Shoes exclusive for men's</p>
            </div>
            <div className="best-seller-container-div">
                <FeatureProps products={products} loading={loading} />
            </div>
        </Fragment>
    )
}

export default Feature

