import React from 'react'


function NewArrivalHeading() {

 

    return (
        <div className='bestSellerHeadingDiv'>
            <h1 className='bestSellerHeading'>   NEW ARRIVAL</h1>
            <p className='bestSellerPara'>Get Latest New Arrival exclusively For Men's.</p>
        </div>
    )
}

export default NewArrivalHeading
