import React from 'react'

import SportsShoesCard from './SportsShoesCard'
import SportsHeading from "./SportsHeading"
import Navbar from "../components/HomeScreen/Navbar"
import Footer from '../components/HomeScreen/bottomsection/Footer'

import './sports.css'

function SportsMainPage() {


    return (
        <div>
            <Navbar/>
            {/* <SportsHeading/> */}
            <SportsShoesCard/>
            <Footer/>
        </div>
    )
}

export default SportsMainPage
