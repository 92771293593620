import {
  CLEAR_ERROR,
  DELETE_NOTIFICATION_BY_ADMIN_FAIL,
  DELETE_NOTIFICATION_BY_ADMIN_REQUEST,
  DELETE_NOTIFICATION_BY_ADMIN_RESET,
  DELETE_NOTIFICATION_BY_ADMIN_SUCCESS,
  GET_ALL_NOTIFICATION_BY_ADMIN_FAIL,
  GET_ALL_NOTIFICATION_BY_ADMIN_REQUEST,
  GET_ALL_NOTIFICATION_BY_ADMIN_SUCCESS,
  GET_ALL_NOTIFICATION_FAIL,
  GET_ALL_NOTIFICATION_REQUEST,
  GET_ALL_NOTIFICATION_RESET,
  GET_ALL_NOTIFICATION_SUCCESS,
  GET_SINGEL_NOTIFICATION_BY_ADMIN_FAIL,
  GET_SINGEL_NOTIFICATION_BY_ADMIN_REQUEST,
  GET_SINGEL_NOTIFICATION_BY_ADMIN_SUCCESS,
  NOTIFICATION_READED_FAIL,
  NOTIFICATION_READED_REQUEST,
  NOTIFICATION_READED_SUCCESS,
  NOTIFICATION_SEND_FAIL,
  NOTIFICATION_SEND_REQUEST,
  NOTIFICATION_SEND_RESET,
  NOTIFICATION_SEND_SUCCESS,
  UPDATE_NOTIFICATION_BY_ADMIN_FAIL,
  UPDATE_NOTIFICATION_BY_ADMIN_REQUEST,
  UPDATE_NOTIFICATION_BY_ADMIN_RESET,
  UPDATE_NOTIFICATION_BY_ADMIN_SUCCESS,
} from "../constants/notificationConstants";

// get all notification by user reducers
export const getAllUserNotificationReducers = (
  state = { notification: [] },
  action
) => {
  switch (action.type) {
    case GET_ALL_NOTIFICATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_NOTIFICATION_SUCCESS:
      return {
        loading: false,
        notification: action.payload,
      };
    case GET_ALL_NOTIFICATION_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case GET_ALL_NOTIFICATION_RESET:
      return {
        ...state,
        notification: false,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

// read notification by user reducers

export const readNotificationFunctionReducers = (state = {}, action) => {
  switch (action.type) {
    case NOTIFICATION_READED_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case NOTIFICATION_READED_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload,
      };

    case NOTIFICATION_READED_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

// NOTIFICATION SEND BY ADMIN
export const sendNotificationFunctionReducers = (state = {}, action) => {
  switch (action.type) {
    case NOTIFICATION_SEND_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case NOTIFICATION_SEND_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload,
      };

    case NOTIFICATION_SEND_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case NOTIFICATION_SEND_RESET:
      return {
        ...state,
        success: false,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

// GET ALL NOTIFICATION BY ADMIN

export const getAllNotificationReducersByAdmin = (
  state = { notification: [] },
  action
) => {
  switch (action.type) {
    case GET_ALL_NOTIFICATION_BY_ADMIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_NOTIFICATION_BY_ADMIN_SUCCESS:
      return {
        loading: false,
        notification: action.payload,
      };
    case GET_ALL_NOTIFICATION_BY_ADMIN_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

// GET SINGEL NOTIFICATION BY ADMIN

export const getSingelNotificationReducersByAdmin = (
  state = { notification: [] },
  action
) => {
  switch (action.type) {
    case GET_SINGEL_NOTIFICATION_BY_ADMIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_SINGEL_NOTIFICATION_BY_ADMIN_SUCCESS:
      return {
        loading: false,
        notification: action.payload,
      };
    case GET_SINGEL_NOTIFICATION_BY_ADMIN_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

// UPDATE NOTIFICATION BY ADMIN REDUCERS

export const updateNotificationReducersByAdmin = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_NOTIFICATION_BY_ADMIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_NOTIFICATION_BY_ADMIN_SUCCESS:
      return {
        loading: false,
        isUpdated: action.payload,
      };
    case UPDATE_NOTIFICATION_BY_ADMIN_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case UPDATE_NOTIFICATION_BY_ADMIN_RESET:
      return {
        ...state,
        isUpdated: false,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

// DELETE NOTIFICATION BY ADMIN

export const deleteNotificationReducersByAdmin = (state = {}, action) => {
  switch (action.type) {
    case DELETE_NOTIFICATION_BY_ADMIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_NOTIFICATION_BY_ADMIN_SUCCESS:
      return {
        loading: false,
        isDeleted: action.payload,
      };
    case DELETE_NOTIFICATION_BY_ADMIN_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case DELETE_NOTIFICATION_BY_ADMIN_RESET:
      return {
        ...state,
        isDeleted: false,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};
