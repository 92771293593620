import React, { Fragment, useRef, useEffect, useState } from 'react'
import CheckoutStep from './CheckoutStep'
import axios from 'axios'
import MetaTag from '../../layout/MetaTag'
import swal from 'sweetalert';
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Typography } from '@mui/material'
import './payments.css'
import { useAlert } from 'react-alert'
import { createOrderAction, clearErrorAction } from '../../redux/actions/orderAction'


import loginAuthnetication from '../../images/undraw_Mobile_encryption_re_yw3o.png'


// url for request

// import {URL} from '../../Url'

function Payments() {

    // total amount 
    const paymentAmount = JSON.parse(sessionStorage.getItem('orderInfo'))

    // saving amount for delivery details and backend data
    const productAmountWithGst = {
        roundOffTotalTax: paymentAmount && paymentAmount.roundOffTotalTax,
        shippingCharges: paymentAmount && paymentAmount.shippingCharges,
        subTotal: paymentAmount && paymentAmount.subTotal,
        boxCharges: paymentAmount && paymentAmount.boxCharges,
        itemPrice: paymentAmount && paymentAmount.itemPrice,
        totalPrice: paymentAmount && paymentAmount.totalPrice,
        coupanCode: paymentAmount && paymentAmount.coupanCode,
        coupanValue: paymentAmount && paymentAmount.coupanValue,
    }


    // for sending back to backend throw axios
    const finalAmount = {
        totalAmountWithGst: paymentAmount && paymentAmount.totalPrice,
        valueCoupan: paymentAmount && paymentAmount.coupanValue,
    }



    // cart and shipping info
    const { shippingInfo } = useSelector((state) => state.shippingInfo)

    // user details
    const { user } = useSelector((state) => state.user)

    //order delivery 
    const { error } = useSelector((state) => state.newOrder)

    // alert function
    const alert = useAlert();

    // using dispatch

    const dispatch = useDispatch();

    // using history for redirect

    const history = useHistory();

    // button 
    const payBtn = useRef(null);

    // for checkout verifaction 
    const [isVerfied, setIsVerfied] = useState('');

    // otp verifaction
    const [otp, setOtp] = useState()


    const [advacePayment, setAdvacePayment] = useState('unessential')


    // delivery data   
    const order = {
        userEmail: user.email,
        userName: user.firstName,
        userNumber: user.number,
        shippingInfo: shippingInfo,
        orderItems: paymentAmount.carts,
        itemPrice: productAmountWithGst.itemPrice,
        subTotal:productAmountWithGst.subTotal,
        taxPrice: productAmountWithGst.roundOffTotalTax,
        shoesPackingDetails: productAmountWithGst.boxCharges,
        shippingPrice: productAmountWithGst.shippingCharges,
        totalPrice: productAmountWithGst.totalPrice,
        coupanCode: productAmountWithGst.coupanCode,
        coupanValue: productAmountWithGst.coupanValue
    }


    // load script for open a razorpay screen
    const loadScript = (src) => {

        return new Promise((resolve) => {
            const script = document.createElement('script')
            script.src = src

            script.onload = () => {
                resolve(true)
            }
            script.onerror = () => {
                resolve(false)
            }

            document.body.appendChild(script)
        })


    }

    // function trigger when online pay button clicked
    const submitHandlerPayments = async () => {
        payBtn.current.disabled = true;
        try {

            // getting api key for payment
            const { data: { key } } = await axios.get(`/api/getKey`)

            // sending order id to backend
            const { data } = await axios.post(`/api/payment/confirm`, finalAmount)
            // loading script from razorpay 
            const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')

            if (!res) {
                return swal({
                    title: 'error',
                    text: 'something wrong...!',
                    icon: 'error'
                })

            }


            // order details generated by razorpay
            var options = {
                "key": key,
                "amount": data.amount.toString(),
                "currency": data.currency,
                "name": user.firstName,
                "description": data.notes.description,
                "order_id": data.id,
                "handler": async function (response) {

                    const paymentData = {
                        orderCreationId: data.id,
                        razorpayPaymentId: response.razorpay_payment_id,
                        razorpayOrderId: response.razorpay_order_id,
                        razorpaySignature: response.razorpay_signature,
                    };

                    // sending data in backend for verfication that payment is legit
                    const result = await axios.post(`/api/paymentCapture`, paymentData);
                    alert.success(result.data.msg);
                    if (result.data.msg === "Transaction Successfull") {
                        order.paymentInfo = {
                            id: result.data.orderId,
                            status: result.data.msg
                        };
                        dispatch(createOrderAction(order))
                        history.push('/payment/successfull')
                    } else {
                        swal({
                            title: 'error',
                            text: "There's some issue while processing payment...!",
                            icon: 'error'
                        })
                    }
                },
                "prefill": {
                    "name": user.firstName,
                    "email": user.email,
                    "contact": user.number
                },
                "notes": {
                    "address": {
                        "line1": shippingInfo.address,
                        "city": shippingInfo.city,
                        "state": shippingInfo.state,
                        "postalCode": shippingInfo.pincode,
                        "district": shippingInfo.district,
                        "Landmark": shippingInfo.landMark
                    }
                },
            }
            const rzp1 = new window.Razorpay(options);
            rzp1.open()



        } catch (error) {
            payBtn.current.disabled = false;
            swal({
                title: 'error',
                text: error.message,
                icon: 'error'
            })
        }

    }





    // advance payment submit handler 
    const advancePaymentSubmitHandler = async () => {

        if (!advacePayment) {
            return swal({
                title: 'Payment Information',
                text: 'Please Select Amount You want to pay in Advance.',
                icon: 'info'
            })
        }

        try {

            // getting api key for payment
            const { data: { key } } = await axios.get(`/api/getKey`)

            // sending order id to backend
            const { data } = await axios.post(`/api/advance-payment/confirm`, { finalAmount, advacePayment })
            // loading script from razorpay 
            const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')

            if (!res) {
                return swal({
                    title: 'error',
                    text: 'something wrong...!',
                    icon: 'error'
                })

            }


            // order details generated by razorpay
            var options = {
                "key": key,
                "amount": data.orderDetails.amount.toString(),
                "currency": data.orderDetails.currency,
                "name": user.firstName,
                "description": data.orderDetails.notes.description,
                "order_id": data.orderDetails.id,
                "handler": async function (response) {

                    const paymentData = {
                        orderCreationId: data.orderDetails.id,
                        razorpayPaymentId: response.razorpay_payment_id,
                        razorpayOrderId: response.razorpay_order_id,
                        razorpaySignature: response.razorpay_signature,
                    };

                    // sending data in backend for verfication that payment is legit
                    const result = await axios.post(`/api/paymentCapture`, paymentData);
                    alert.success(result.data.msg);
                    if (result.data.msg === "Transaction Successfull") {
                        order.adavncePayment = {
                            id: result.data.orderId,
                            status: result.data.msg,
                            advanceAmountPercentage: advacePayment,
                            amount: data.msg
                        };
                        dispatch(createOrderAction(order))
                        history.push('/payment/successfull')
                    } else {
                        swal({
                            title: 'error',
                            text: "There's some issue while processing payment...!",
                            icon: 'error'
                        })
                    }
                },
                "prefill": {
                    "name": user.firstName,
                    "email": user.email,
                    "contact": user.number
                },
                "notes": {
                    "address": {
                        "line1": shippingInfo.address,
                        "city": shippingInfo.city,
                        "state": shippingInfo.state,
                        "postalCode": shippingInfo.pincode,
                        "district": shippingInfo.district,
                        "Landmark": shippingInfo.landMark
                    }
                },
            }
            const rzp1 = new window.Razorpay(options);
            rzp1.open()


        } catch (error) {
            swal({
                title: 'error',
                text: error.message,
                icon: 'error'
            })
        }




    }





    // using effect 
    useEffect(() => {
        if (error) {
            swal({
                title: 'error',
                text: error,
                icon: 'error'
            })
            dispatch(clearErrorAction())
        }


    }, [alert, dispatch, error])


    const cashOnDeliveryHandler = async (e) => {
        e.preventDefault()
        axios.post(`/api/Order/confirmation-number`, {
            number: user.number,
        }).then(function (response) {
            console.log(response);
            setIsVerfied(response.data.smsResult.status)
            if (response.data.smsResult.status === 'pending') {
                swal({
                    title: 'Verfication Code',
                    text: "verfication code sent successfully to your number !!",
                    icon: 'success',
                    button: "OK"
                })

            }
        }).catch(function (error) {
            swal({
                title: 'Internal Error',
                text: error,
                icon: 'error'
            })
        });


    }


    const submitHandlerForCheckoutVerfication = (e) => {
        e.preventDefault()
        alert.success('please wait...')
        axios.post(`/api/Order/confirmation-otp`, {
            number: user.number,
            otp: otp
        }).then(function (response) {
            console.log(response);
            if (response.data.smsResult.valid === true) {
                swal({
                    title: 'Verfication',
                    text: 'verified successfully',
                    icon: 'success',
                    button: "OK"
                })
                dispatch(createOrderAction(order))
                history.push('/cashonDelivery/payment/successfull')
            } else if (response.data.smsResult.valid === false) {
                swal({
                    title: 'Verfication',
                    text: 'invaild otp !!',
                    icon: 'error'
                })
            }

        })
            .catch(function (error) {
                swal({
                    title: 'Internal Error',
                    text: error,
                    icon: 'error'
                })

            });




    }







    return (
        <Fragment>
            <Fragment>
                <MetaTag title={'IM Online Shopping Secure Payment Page'} />
                {isVerfied === 'pending' ? <div className='order-verfication-main-div'  >
                    <img src={loginAuthnetication} alt="www.imonline.shop" />
                    <div className="verfication-div-heading">
                        <h2>Verification</h2>
                        <p>you will get a OTP via SMS</p>
                    </div>
                    <form method="Post">
                        <div className="order-verfication-div">
                            <input type="text" placeholder="confirm-OTP" className="order-verfication-div-field" value={otp} onChange={(e) => setOtp(e.target.value)} required />
                            <button className="sumbit-btn-form" onClick={submitHandlerForCheckoutVerfication}> verify </button>
                        </div>
                    </form>
                    <p>please do not refresh page !!</p>
                </div> : (<Fragment>

                    <CheckoutStep activeStep={2} />
                    <div className="delivery-address-details">
                        <Typography>Delivery Address</Typography>
                        <div className="deliver-address">
                            Name : {shippingInfo.name}, Email : {shippingInfo.email} ,
                            Phone : {shippingInfo.phoneNo}, Pincode : {shippingInfo.pincode} ,
                            State : {shippingInfo.state} ,Address : {shippingInfo.address},
                            City : {shippingInfo.city}, District:{shippingInfo.district},
                            Landmark : {shippingInfo.landMark}
                        </div>

                        {/**ADVANCE PAYMENT DIV BUTTON */}
                        <div className="advance-payment-div">
                            <span><label className="">Want to Book Your favourite sneaker in advance ? :</label>
                                <select onChange={(e) => setAdvacePayment(e.target.value)}>
                                    <option value=''>Payment </option>
                                    <option value='unessential' defaultChecked> No I WILL. PAY ON DELIVERY </option>
                                    <option value='10'> 10% IN ADVANCE. INCREASE POSSIBILITY TO BE YOURS </option>
                                    <option value='25'> 25% IN ADVANCE. INCREASE POSSIBILITY TO BE YOURS</option>
                                    <option value='50'>50% IN ADVANCE. INCREASE POSSIBILITY TO BE YOURS</option>
                                    <option value='75' >75% IN ADVANCE. INCREASE POSSIBILITY TO BE YOURS</option>
                                    <option value='90' >90% IN ADVANCE. INCREASE POSSIBILITY TO BE YOURS</option>
                                </select>
                            </span>
                        </div>

                        {advacePayment !== 'unessential' ? (
                            <Fragment>
                                <div className="advance-payment-div-button">
                                    <button onClick={advancePaymentSubmitHandler}>
                                        book now
                                    </button>
                                </div>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <form onSubmit={cashOnDeliveryHandler}>
                                    <label className='cash-on-delivery-label'> Cash on Delivery

                                    </label>
                                    <div className='cod-btn'  >
                                        <input type="submit" value="Cash on Delivery" />
                                    </div>
                                </form>

                                <div className="online-payments-options-div">
                                    <h6 className="payonline-heading">
                                        Proceed To Pay Online
                                    </h6>
                                    <button onClick={submitHandlerPayments} ref={payBtn}> Pay Now  </button>
                                </div>
                            </Fragment>
                        )}

                    </div>
                </Fragment>)}
            </Fragment>


        </Fragment>
    )
}

export default Payments
