import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { userAction, clearErrorAction, userLoadingAction } from '../../redux/actions/userAction'
import swal from 'sweetalert';
import MetaTag from '../../layout/MetaTag';
import { useAlert } from 'react-alert'

import { Helmet } from 'react-helmet'

// css and components
import "../../css/login.css"
import Navbar from '../HomeScreen/Navbar'



import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LockIcon from '@mui/icons-material/Lock';
import Loader from '../../layout/loader';




function Loginpage() {
  // location
  const location = useLocation();
  //history
  const history = useHistory();

  const alert = useAlert();

  const dispatch = useDispatch();
  const { error, loading, isAuthenticated, user } = useSelector((state) => state.user)

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const loginHandler = async (e) => {
    e.preventDefault();
    if (!email || !password) {
      return swal({
        title: "Validation Error",
        text: 'fill all the fields',
        icon: "info",
      })

    }

    dispatch(userAction(email, password))

  }

  // use of location to redirect specfic route
  const redirect = location.search ? location.search.split('=')[1] : '/userprofile';

  // '/userprofile'
  useEffect(() => {
    if (error) {
      swal({
        title: "Login error",
        text: error,
        icon: "error",
      })
      dispatch(clearErrorAction)
    }
    if (isAuthenticated === true) {
      swal({
        title: "Login",
        text: user,
        icon: "success",
      })
      history.push(redirect)
      dispatch(userLoadingAction())
    }

  }, [alert, dispatch, error, history, isAuthenticated, redirect, user])





  return (
    <Fragment>
      <Helmet  >
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=2.0" />
        <meta name="description" content={`Login to IMONLINE.SHOP , TO stay in touch with us || login page || im login page || login  || im login || login im ||`} />
        <meta name="keywords" content={` Shoes , login  page, imonline.shop , login page || imonline.shop login page || login page `} />
        <meta name="robots" content="index, follow " />
        <meta property="og:title" content={`login to stay in touch with us . IMONLINE.SHOP , login  page  `} />
        <meta property="og:description" content={`Find the Shoes at IMONLINE.SHOP.  Free delivery and returns on select orders  , login  page || create account || login in || log || log in im `} />
        <meta property="og:type" content="website" />
        {/* <meta property="og:url" content={items.imageurl}   key={items.public_id}/>
            <meta property="og:image" content={items.imageurl}  key={items.public_id}/> */}
        <meta property="og:locale" content="en-GB_IN" />
        <meta property="og:site_name" content="IMONLINE.SHOP" />
        {/* <meta property="og:price:currency" content="INR" />
            <meta property="og:price:amount" content={product.price} /> */}
      </Helmet>
      <MetaTag title={'Welcome Back to IM Online Shopping, Login to stay Updated.'} />
      {loading ? <Loader /> : <Fragment>

        <Navbar />
        <div className="loginheader">
          <div className="login-form-area">
            <div className="login-with-us-head">
              <h2 className="login-h2">Welcome back to IMShop !</h2>
            </div>
            <form method="POST" onSubmit={loginHandler}>
              <div className="email-name">
                <div>
                <AccountCircleIcon/>
                </div>
                <div>
                  <input type="email" placeholder="Your Email" className="email-field" value={email} onChange={(e) => setEmail(e.target.value)} required />
                </div>
              </div>

              <div className="password">
                <div>
                  <LockIcon />
                </div>
               <div>
               <input type="password" placeholder="Your Password" className="email-field" value={password} onChange={(e) => setPassword(e.target.value)} required />
               </div>
              </div>
              <div className="forget-password">
                <Link to="/login-forgot-password"  > <p>forgot password ?</p></Link>
              </div>
              <button className="submit-now-btn" >Submit</button>
            </form>
            <div className="login-google-policy">
              <span>This page is protected by reCAPTCHA, and subject to the<a href='https://policies.google.com/privacy'> <span> Google Privacy Policy</span> </a>  and <a href='https://policies.google.com/terms'><span>Terms of Service</span> </a>  </span>
            </div>
            <div className="allready-account">
              <h2> Create an account ?<Link to="./signin"  > SignIn    </Link></h2>
            </div>
          </div>
        </div>
      </Fragment>}
    </Fragment>

  )
}

export default Loginpage
