import React, { Fragment, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { clearErrorAction, updateUserByAdminAction, userDetailRequestByAdmin } from '../redux/actions/userAction'
import swal from 'sweetalert';
import { Button } from '@material-ui/core'
import Loader from '../layout/loader';
import EmailIcon from '@mui/icons-material/Email';
import SpellCheckIcon from '@material-ui/icons/Spellcheck'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import Filter6Icon from '@mui/icons-material/Filter6';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Sidebar from './Sidebar'
import './createNewProduct.css'
import { UPDATE_USER_DETAIL_RESET } from '../redux/constants/userConstants'


function EditUserByAdmin(props) {
    const dispatch = useDispatch();
    const history = useHistory();

    const { loading, error, user } = useSelector(state => state.userDetailAdmin)
    const { loading: updateLoading, error: updateError, isUpdated } = useSelector(state => state.userUpdateByAdmin)

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [gender, setGender] = useState('');
    const [number, setNumber] = useState('');
    const [role, setRole] = useState('');


    // geting user id for detail and updates
    const userId = props.match.params.id;

    useEffect(() => {

        if (user && user._id !== userId) {
            dispatch(userDetailRequestByAdmin(userId))
        } else {

            // asinging values
            setFirstName(user.firstName);
            setLastName(user.lastName);
            setEmail(user.email);
            setGender(user.gender);
            setNumber(user.number);
            setRole(user.role);

        }

        if (error) {
            swal({
                title: "Validation",
                text: error,
                icon: "error",
            })
            dispatch(clearErrorAction())
        }

        if (updateError) {
            swal({
                title: "Validation",
                text: updateError,
                icon: "error",
            })
            dispatch(clearErrorAction())
        }

        if (isUpdated) {
            swal({
                title: "Updated.",
                text: isUpdated,
                icon: "success",
            })
            dispatch({ type: UPDATE_USER_DETAIL_RESET })
        }

    }, [dispatch, error, history, isUpdated, user, updateError, userId])

    // on click function when submit button press
    const updateAdminSubmitHandler = async (e) => {
        e.preventDefault()

        // dispatch function for updating user
        dispatch(updateUserByAdminAction(userId, firstName, lastName, email, number, gender, role));

    }


    return (
        <Fragment>
            {loading ? <Loader /> : (<Fragment>
                {updateLoading ? <Loader /> : (<Fragment>
                    <div className="dashboard-container">
                        <Sidebar />
                        <div className="newProductContainer">
                            <form className='createProductForm'
                                onSubmit={updateAdminSubmitHandler}  >
                                <h1>Update Admin</h1>
                                <div style={{marginBottom:'10px'}}>
                                    <div className="heading-of-new-users div">
                                        <div>  Create Date : <span>{String(user && user.date).substring(0, 10)} </span> </div>
                                    </div>
                                </div>

                                <div>
                                    <SpellCheckIcon />
                                    <input
                                        type='text'
                                        placeholder='Firstname'
                                        required
                                        value={firstName}
                                        onChange={(e) => setFirstName(e.target.value)}
                                    />
                                </div>

                                <div>
                                    <AttachMoneyIcon />
                                    <input
                                        type='text'
                                        placeholder='Lastname'
                                        required
                                        value={lastName}
                                        onChange={(e) => setLastName(e.target.value)}
                                    />
                                </div>
                                <div>
                                    <EmailIcon />
                                    <input
                                        type='text'
                                        placeholder='email'
                                        required
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </div>

                                <div>
                                    <LocalPhoneIcon />
                                    <input
                                        type='number'
                                        placeholder='Number'
                                        required
                                        value={number}
                                        onChange={(e) => setNumber(e.target.value)}
                                    />
                                </div>

                                <div>
                                    <AccountCircleIcon />

                                    <select value={role} onChange={(e) => setRole(e.target.value)} >
                                        <option value=''>Choose Role </option>
                                        <option value='admin'> Admin  </option>
                                        <option value='user'> User  </option>
                                    </select>
                                </div>

                                <div>
                                    <Filter6Icon />

                                    <select value={gender} onChange={(e) => setGender(e.target.value)} >
                                        <option value=''>Choose Gender </option>
                                        <option value='male'> Male  </option>
                                        <option value='female'> Female  </option>
                                        <option value='other'> Other  </option>
                                    </select>
                                </div>
                                {/* button for submit */}
                                <Button
                                    id='createProductBtn'
                                    type='submit'
                                    disabled={updateLoading ? true : false}

                                >
                                    Create
                                </Button>


                            </form>
                        </div>
                    </div>
                </Fragment>)}
            </Fragment>)}
        </Fragment>

    )
}

export default EditUserByAdmin