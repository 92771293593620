import React from 'react'
import Logo from '../images/logo1.png'
import { Link } from 'react-router-dom'
import { TreeView, TreeItem } from '@material-ui/lab'
import ExpandMoreIcons from '@material-ui/icons/ExpandMore'
import PostAddIcons from '@material-ui/icons/PostAdd'
import AddIcons from '@material-ui/icons/Add'
import ImportExportIcons from '@material-ui/icons/ImportExport'
import ListAltIcons from '@material-ui/icons/ListAlt'
import DashBoardIcons from '@material-ui/icons/Dashboard'
import PeopleIcon from '@material-ui/icons/People'
import RateReviewIcon from '@material-ui/icons/RateReview'
import WidgetsIcon from '@mui/icons-material/Widgets';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import FavoriteIcon from '@mui/icons-material/Favorite';
import SearchIcon from '@mui/icons-material/Search';
import NotificationAddIcon from '@mui/icons-material/NotificationAdd';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';




import './sidebar.css'

function Sidebar() {
    return (
        <div className='sideBar'  >
            <Link to={'/'}>
                <img src={Logo} alt='www.imonline.shop' />
            </Link>
            <Link to={'/Admin/dashboard'} >
                <p>
                    <DashBoardIcons />
                    Dasboard
                </p>
            </Link>
            <Link>
                <TreeView
                    defaultCollapseIcon={<ExpandMoreIcons />}
                    defaultExpandIcon={<ImportExportIcons />}
                >
                    <TreeItem nodeId='1' label='Products'>
                        <Link to={'/Admin/dashboard/Products'} >
                            <TreeItem nodeId='2' label='All' icon={<PostAddIcons />}> </TreeItem>
                        </Link>
                        <Link to={'/Admin/dashboard/Create/Product'}>
                            <TreeItem nodeId='3' label='Create' icon={<AddIcons />}> </TreeItem>
                        </Link>
                        <Link to={'/Admin/dashboard/all/ads'} >
                            <TreeItem nodeId='4' label='All Ads' icon={<PostAddIcons />}> </TreeItem>
                        </Link>
                        <Link to={'/Admin/dashboard/Create/ads'}>
                            <TreeItem nodeId='5' label='Create Ads' icon={<AddIcons />}> </TreeItem>
                        </Link>
                    </TreeItem>
                </TreeView>
            </Link>
            <Link>

                <TreeView
                    defaultCollapseIcon={<ExpandMoreIcons />}
                    defaultExpandIcon={<ImportExportIcons />}
                >
                    <TreeItem nodeId='1' label='Users'>
                        <Link to={'/Admin/dashboard/Users'} >
                            <TreeItem nodeId='2' label='All Users' icon={<PeopleIcon />}> </TreeItem>
                        </Link>
                        <Link to={'/Admin/Create/Notification'}>
                            <TreeItem nodeId='3' label='Send Notification' icon={<NotificationAddIcon />}> </TreeItem>
                        </Link>
                        <Link to={'/Admin/All/Notifications'}>
                            <TreeItem nodeId='3' label='All Notification' icon={<NotificationsActiveIcon />}> </TreeItem>
                        </Link>
                    </TreeItem>
                </TreeView>
            </Link>
            <Link to={'/Admin/review'}  >
                <p>
                    <RateReviewIcon />
                    Reviews
                </p>
            </Link>
            <Link to={'/Admin/Allwishlist/products'}  >
                <p>
                    <FavoriteIcon />
                    Wishlists Products
                </p>
            </Link>
            <Link to={'/Admin/All/complain'}  >
                <p>
                    <DriveFileRenameOutlineIcon />
                    All Complains
                </p>
            </Link>
            <Link>
                <TreeView
                    defaultCollapseIcon={<ExpandMoreIcons />}
                    defaultExpandIcon={<ImportExportIcons />}
                >
                    <TreeItem nodeId='1' label='Coupan'>
                        <Link to={'/Admin/dashboard/all/coupans'} >
                            <TreeItem nodeId='2' label='All' icon={<PostAddIcons />}> </TreeItem>
                        </Link>
                        <Link to={'/Admin/dashboard/Create-Coupan'}>
                            <TreeItem nodeId='3' label='Create' icon={<AddIcons />}> </TreeItem>
                        </Link>
                    </TreeItem>
                </TreeView>
            </Link>
            <Link to={'/Admin/dashboard/Orders'}  >
                <p>
                    <WidgetsIcon />
                    All Orders
                </p>
            </Link>
            <Link>
                <TreeView
                    defaultCollapseIcon={<ExpandMoreIcons />}
                    defaultExpandIcon={<ImportExportIcons />}
                >
                    <TreeItem nodeId='1' label="Return & Cancel's">
                        <Link to={'/Admin/dashboard/orders/return-orders'} >
                            <TreeItem nodeId='2' label='All Returns' icon={<ListAltIcons />}> </TreeItem>
                        </Link>
                        <Link to={'/Admin/dashboard/orders/allcancel'} >
                            <TreeItem nodeId='2' label='All Cancel' icon={<ListAltIcons />}> </TreeItem>
                        </Link>
                    </TreeItem>
                </TreeView>
            </Link>

            <Link to={'/Admin/Allsearched/keywords'}  >
                <p>
                    <SearchIcon />
                    All Searched Keywords
                </p>
            </Link>

        </div>
    )
}

export default Sidebar