import React, { Fragment, useEffect } from 'react'
import { useAlert } from 'react-alert'
import OnSellProps from './OnSellProps';
import { useSelector, useDispatch } from 'react-redux'
// import { Carousel } from 'react-bootstrap';
import { clearErrorAction, productAction } from '../../../../redux/actions/productAction'

function OnSell() {

    const dispatch = useDispatch();
    const alert = useAlert();

    const { loading, products, error } = useSelector(state => state.products)

    useEffect(() => {

        if (error) {
            alert.error(error)
            dispatch(clearErrorAction(error))

        }
        dispatch(productAction())

    }, [alert, dispatch, error])


    return (
        <Fragment>
            <div className='bestSellerHeadingDiv'>
                <h1 className='bestSellerHeading'> On Sell   </h1>
                <p className='bestSellerPara'>Get all On Sell Shoes exclusive for men's</p>
            </div>

            <div className="best-seller-container-div">
                <OnSellProps products={products} loading={loading} />
            </div>

        </Fragment>
    )
}

export default OnSell
