import {
  CLEAR_ERROR,
  CREATE_FORM_FAIL,
  CREATE_FORM_REQUEST,
  CREATE_FORM_RESET,
  CREATE_FORM_SUCCESS,
  DELETE_FORM_FAIL,
  DELETE_FORM_REQUEST,
  DELETE_FORM_RESET,
  DELETE_FORM_SUCCESS,
  GET_ALL_FORM_FAIL,
  GET_ALL_FORM_REQUEST,
  GET_ALL_FORM_SUCCESS,
  GET_FORM_FAIL,
  GET_FORM_REQUEST,
  GET_FORM_SUCCESS,
} from "../constants/imFormConstant";

// CREATE FORM BY USER REDUCERS
export const createFormReducers = (state = {}, action) => {
  switch (action.type) {
    case CREATE_FORM_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_FORM_SUCCESS:
      return {
        loading: false,
        success: action.payload,
      };
    case CREATE_FORM_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

      case CREATE_FORM_RESET: 
      return {
        loading: false,
        success: null,
        error: null,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

// GET ALL FORM BY ADMIN REDUCERS
export const getAllFormReducers = (state = { forms: [] }, action) => {
  switch (action.type) {
    case GET_ALL_FORM_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_FORM_SUCCESS:
      return {
        loading: false,
        forms: action.payload,
      };
    case GET_ALL_FORM_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

// GET SINGEL FORM BY ADMIN REDUCERS
export const getSingelFormReducers = (state = { form: [] }, action) => {
  switch (action.type) {
    case GET_FORM_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_FORM_SUCCESS:
      return {
        loading: false,
        form: action.payload,
      };
    case GET_FORM_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

// DELETE FORM BY ADMIN REDUCERS
export const deleteFormReducers = (state = {}, action) => {
  switch (action.type) {
    case DELETE_FORM_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_FORM_SUCCESS:
      return {
        loading: false,
        isDeleted: action.payload,
      };
    case DELETE_FORM_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case DELETE_FORM_RESET:
      return {
        ...state,
        isDeleted: false,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

