import { useSelector, useDispatch } from 'react-redux';
import { Fragment, useEffect, useState } from 'react';
import swal from 'sweetalert';
import { createReviewAction, clearErrorAction } from '../../redux/actions/reviewAction';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@material-ui/core';
import { Rating } from '@material-ui/lab';
import ReactStars from "react-rating-stars-component";
import { CREATE_REVIEW_RESET } from '../../redux/constants/reviewConstants';
import Loader from '../../layout/loader';
import './review.css';


function Reviews({ id, reviews, productStock }) {

  // console.log(reviews)

  const { isAuthenticated, loading: loadingUser } = useSelector(state => state.user)

  const { loading, error, success } = useSelector((state) => state.reviewCreate)

  // redux functions
  const dispatch = useDispatch();



  // review state
  const [review, setreview] = useState('');
  const [images, setImages] = useState('');
  const [rating, setRating] = useState(0);
  const [open, setOpen] = useState(false);

  // const [imagesLength, setImagesLength] = useState()
  // console.log(imagesLength)

  useEffect(() => {


    if (error) {
      swal({
        title: "Error",
        text: error,
        icon: "error",
      })
      dispatch(clearErrorAction(error))
    }

    if (success) {
      swal({
        title: "Success",
        text: success,
        icon: "success",
      })
      dispatch({ type: CREATE_REVIEW_RESET })
      window.location.reload()
    }


  }, [dispatch, error, success])



  // review submit handler

  const sumbitReviewToggle = (e) => {
    e.preventDefault();
    open ? setOpen(false) : setOpen(true);
  }

  const onChangeFile = (e) => {
    setImages(e.target.files);
  }


  const sumbitDataHandler = (e) => {
    e.preventDefault();
    if (!isAuthenticated === true) {
      return swal({
        title: "Validation",
        text: 'login please !!',
        icon: "info",
      })

    } else {
      if (!review || !rating || !images) {
        return swal({
          title: "Validation Error",
          text: "can't review without all fields",
          icon: "info",
        })
      } else {
        const formData = new FormData();
        formData.append('review', review);
        formData.append('productId', id);
        formData.append('rating', rating)
        for (let index = 0; index < images.length; index++) {
          formData.append('image', images[index]);
        }

        dispatch(createReviewAction(formData))
        setOpen(false)
        setreview('')
        setImages('')
        setRating(0)
      }
    }
  }

  return (
    <Fragment>

      <div className="review-heading">
        <h2>Product Review's  </h2>
      </div>

      {loading ? <Loader /> : (<Fragment>
        {loadingUser ? <Loader /> : <Fragment>
          <section>
            <div className="container">
              {reviews && reviews.map((reviewData, index) => (
                <div className="review-main-container-div" key={index}>
                  <div className="user-details-container">
                    <div className="name-div">
                      <span style={{ color: 'blue' }}> Name </span> : {reviewData.name}
                    </div>
                    <div className="date-container">
                      <span style={{ color: 'blue' }}> Post At </span> : {reviewData.date}
                    </div>
                  </div>
                  <div className="div-2">
                  <div className="image-container-div">
                    {/* <Carousel> */}
                    {reviewData.image && reviewData.image.map(image => (
                      <div className="review-image-main-container">
                        <img src={image.imageurl} alt={'image-review'} />
                      </div>
                    ))}
                  </div>
                  </div>
                
                  <div className="review-sections">
                    <ReactStars
                      edit={false}
                      color={'gray'}
                      activeColor={'yellow'}
                      size={window.innerWidth < 600 ? 20 : 30}
                      value={reviewData.rating}
                      isHalf={true}
                    />
                  </div>
                  <div className="review-details-conatiner">
                    <h6> Review :  {reviewData.review}</h6>
                  </div>
                </div>
              ))}
            </div>
          </section>
        </Fragment>}

        <div className="main-head-form">
          <div className="form-heading">
            <h6>Write your review about product..</h6>
          </div>
          <form>
            <div className="second-main-head-form">
              <div className="">
                <div className="">
                  {productStock < 1 ?
                    <div>
                      <label htmlFor="review">
                        <textarea id="review" name='review' rows="5" cols={'60'} onChange={(e) => setreview(e.target.value)} className="form-review-area" disabled  ></textarea>
                      </label>
                    </div>
                    : <div>
                      <label htmlFor="review">
                        <textarea id="review" placeholder='Write Your Review... example@ its a really good product...' name='review' rows="5" cols={'60'} onChange={(e) => setreview(e.target.value)} className="form-review-area"></textarea>
                      </label>
                    </div>}
                  <Dialog
                    aria-labelledby='simple-dialog-title'
                    open={open}
                    onClose={sumbitReviewToggle}
                  >
                    <DialogTitle>Submit Review</DialogTitle>
                    <DialogContent className='sumbitDialog'>
                      <Rating onChange={(e) => setRating(e.target.value)}
                        value={rating}
                        size='large'
                      />
                    </DialogContent>
                    <DialogActions>
                      <Button color='secondary'>Cancel</Button>
                      <Button color='primary' onClick={sumbitDataHandler}>Submit</Button>
                    </DialogActions>
                  </Dialog>

                  {productStock < 1 ?
                    <div className='review-image-file-select-area'>
                      <label htmlFor="file" className='choose-btn'  >
                        <input type="file"
                          name='image'
                          accept='image/*'
                          multiple
                          onChange={onChangeFile}
                          disabled
                        />

                      </label>
                    </div>
                    : <div className='review-image-file-select-area'>
                      <label htmlFor="file" className='choose-btn'  >
                        <input type="file"
                          name='image'
                          accept='image/*'
                          multiple
                          onChange={onChangeFile}
                        />

                      </label>
                    </div>}
                </div>
              </div>
              <div className="">
                {loading ? <button onClick={sumbitReviewToggle} className="create-review-btn" disabled   > Post Review </button> : <button onClick={sumbitReviewToggle} className="create-review-btn"     > Post Review </button>}
              </div>
            </div>
          </form>
        </div>

      </Fragment>)}
    </Fragment>
  )
}

export default Reviews