import React, { Fragment, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import swal from 'sweetalert';
import { Button } from '@material-ui/core'
import Loader from '../layout/loader';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import SpellCheckIcon from '@material-ui/icons/Spellcheck'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import PercentIcon from '@mui/icons-material/Percent';

import Sidebar from './Sidebar'

import { createCoupanAction, clearErrorAction } from '../redux/actions/coupanAction'


function CreateCoupanByAdmin() {
    const dispatch = useDispatch();
    const history = useHistory();
    const { loading, error, coupan } = useSelector(state => state.coupanCreate)

    const [coupanCode, setCoupanCode] = useState('');
    const [coupanValue, setCoupanValue] = useState('');
    const [coupanMinValue, setCoupanMinValue] = useState('');
    const [isPercent, setIsPercent] = useState('');
    const [isActive, setIsActive] = useState('');


    const precent = [
        'true',
        'false'
    ]


    const active = [
        'true',
        'false'
    ]


    useEffect(() => {


        if (error) {
            swal({
                title: "Validation",
                text: error,
                icon: "error",
            })
            dispatch(clearErrorAction())
        }

        if (coupan) {
            swal({
                title: "Coupon Created.",
                text: coupan,
                icon: "success",
            })
        }

    }, [coupan, dispatch, error, history])

    const createCoupanHandler = (e) => {
        e.preventDefault()

        dispatch(createCoupanAction(coupanCode, coupanValue, coupanMinValue, isActive, isPercent));
    }


    return (
        <Fragment>
            {loading ? <Loader /> : (<Fragment>
                <div className="dashboard-container">
                    <Sidebar />
                    <div className="newProductContainer">
                        <form className='createProductForm'
                            onSubmit={createCoupanHandler}  >
                            <h1>Coupan Create</h1>
                            <div>
                                <SpellCheckIcon />
                                <input
                                    type='text'
                                    placeholder='Coupan-code'
                                    required
                                    value={coupanCode}
                                    onChange={(e) => setCoupanCode(e.target.value)}
                                />
                            </div>
                            <div>
                                <LocalOfferIcon />
                                <input
                                    type='number'
                                    placeholder='Coupan-Value'
                                    required
                                    value={coupanValue}
                                    onChange={(e) => setCoupanValue(e.target.value)}
                                />
                            </div>

                            <div>
                                <AttachMoneyIcon />
                                <input
                                    type='number'
                                    placeholder='Coupan-min-value'
                                    required
                                    value={coupanMinValue}
                                    onChange={(e) => setCoupanMinValue(e.target.value)}
                                />
                            </div>

                            <div>
                                <PercentIcon />

                                <select onChange={(e) => setIsPercent(e.target.value)} required >
                                    <option value={'select Options'}></option>
                                    <option >{isPercent}</option>
                                    {
                                        precent.map((items) => (
                                            <option key={items} value={items} > {items}  </option>
                                        ))
                                    }
                                </select>
                            </div>

                            <div>
                                <ToggleOnIcon />

                                <select onChange={(e) => setIsActive(e.target.value)} required >
                                    <option >{isActive}</option>
                                    {
                                        active.map((items) => (
                                            <option key={items} value={items} > {items}  </option>
                                        ))
                                    }
                                </select>
                            </div>

                            {/* button for submit */}
                            <Button
                                id='createProductBtn'
                                type='submit'
                                disabled={loading ? true : false}

                            >
                                Create
                            </Button>
                        </form>
                    </div>
                </div>
            </Fragment>)}

        </Fragment>
    )
}

export default CreateCoupanByAdmin