export const addEllipsis = (text) => {
  if (text.length > 30) {
    return text.substring(0, 30) + '...';
  }
  return text;

}

export const textRapForNameSearch = (text) => {
  if (text.length > 24) {
    return text.substring(0, 24) + '...';
  }
  return text;

}