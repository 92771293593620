import React, { Fragment } from 'react'
import MetaTag from '../../layout/MetaTag'
import './successLogo.css'

import { Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'

import verifiedLogo from '../../images/undraw_verified_re_4io7.png';



function CashOnDeliverySuccess() {
    return (
        <Fragment>
            <MetaTag  title={'Your Order Has Been Placed Successfully'}  />
            <div className="verfied-div-success-logo">
                <img src={verifiedLogo} alt="www.imonline.shop" />
                <Typography> Your Order has been Placed Successfully</Typography>
                <Link to={'/orders/Details/me'} > View Order's</Link>
            </div>

        </Fragment>

    )
}

export default CashOnDeliverySuccess