import React, { Fragment, useEffect } from 'react'
import { DataGrid } from '@material-ui/data-grid'
import { Link, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { clearErrorAction, getAllComplainAction } from '../redux/actions/complainAction'
import swal from 'sweetalert';
// import { Button } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
// import DeleteIcon from '@material-ui/icons/Delete'
import Loader from '../layout/loader'
import Sidebar from './Sidebar'

// import { DELETE_COMPLAIN_REQUEST_RESET } from '../redux/constants/complainConstants'

function AllComplainByAdmin() {

    const history = useHistory();
    const dispatch = useDispatch();

    const { loading, error, complains } = useSelector((state) => state.allComplain)

    // const { loading: deletedLoading, error: deletedError, isDeleted } = useSelector((state) => state.deleteComplain)

    useEffect(() => {
        if (error) {
            swal({
                title: "Validation Error",
                text: error,
                icon: "error",
            })
            dispatch(clearErrorAction())

        }

        // if (deletedError) {
        //     swal({
        //         title: "Validation Error",
        //         text: deletedError,
        //         icon: "error",
        //     })
        //     dispatch(clearErrorAction())

        // }
        // if (isDeleted) {
        //     swal({
        //         title: "Success",
        //         text: isDeleted,
        //         icon: "success",
        //     })

        //     dispatch({ type: DELETE_COMPLAIN_REQUEST_RESET })

        // }


        dispatch(getAllComplainAction())

    }, [dispatch, error, history])


    // const deleteProductHandler = (id) => {
    //     dispatch(deleteComplainAction(id))
    // }


    const columns = [
        { field: 'id', headerName: 'Complain ID', minWidth: 200, flex: 1 },
        {
            field: 'userEmail',
            headerName: 'Email',
            minWidth: 300,
            flex: 1,
        },
        {
            field: 'complainStatus',
            headerName: 'Complain Status',
            minWidth: 250,
            flex: 1,
        },
        {
            field: 'action',
            headerName: 'Action',
            type: 'number',
            minWidth: 150,
            flex: 0.3,
            sortable: false,
            renderCell: (params) => {
                return (
                    <Fragment>
                        <Link to={`/Admin/Singel/complain/${params.getValue(params.id, 'id')}`}   >
                            <EditIcon />
                        </Link>

                        {/* <Button onClick={() => deleteProductHandler(params.getValue(params.id, 'id'))}   >
                            <DeleteIcon />
                        </Button> */}
                    </Fragment>
                )
            }
        },
    ]

    const rows = [];
    complains && complains.forEach(item => {
        rows.push({
            id: item._id,
            userEmail: item.userEmail,
            complainStatus: item.complainStatus.status,
            user: item.user,
        })
    });
    return (
        <Fragment>
            {/* {deletedLoading ? <Loader /> : (<Fragment> */}
                {loading ? <Loader /> : <Fragment>
                    <div className="dashboard-container">
                        <Sidebar />
                        <div className="productListContainer">
                            <h1 className='productHeadingList'> ALL COMPLAIN'S </h1>
                            <DataGrid

                                rows={rows}
                                columns={columns}
                                pageSize={20}
                                disableSelectionOnClick
                                className='productListTable'
                                autoHeight
                            />
                        </div>
                    </div>
                </Fragment>}
            {/* </Fragment>)} */}

        </Fragment>
    )
}

export default AllComplainByAdmin