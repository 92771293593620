import axios from 'axios';


// constants for request
import {
    DIRECT_BUY_REQUEST,
    DIRECT_BUY_REQUEST_SUCCESS,
    DIRECT_BUY_REQUEST_FAIL
} from '../constants/directBuyConstants'


// import {URL} from '../../Url';

// product detail for direct action

export const directBuyAction = (id, quantity, size, colour,selectBox,productShippingCharges) => async (dispatch, getState) => {
    try {

        dispatch({
            type: DIRECT_BUY_REQUEST
        })
        const {
            data
        } = await axios.get(`/api/add-to-cart/product/${id}`);

        dispatch({
            type: DIRECT_BUY_REQUEST_SUCCESS,
            payload: {
                product: data.isDataGet._id,
                name: data.isDataGet.name,
                price: data.isDataGet.discountActive === true ? ((data.isDataGet.price) - (data.isDataGet.discountPrice)) : (data.isDataGet.price),
                image: data.isDataGet.mainImage[0].imageurl,
                productStock: data.isDataGet.productStock,
                size,
                colour,
                quantity,
                selectBox ,
                productShippingCharges
            },

        })
        
     localStorage.setItem('productBuy', JSON.stringify(getState().directBuy.productBuy));

    } catch (error) {
        dispatch({
            type: DIRECT_BUY_REQUEST_FAIL,
            payload: error.response.data.msg,
        })
    }
}