import React, { Fragment } from 'react'

import Navbar from '../../HomeScreen/Navbar'
import Footer from '../../HomeScreen/bottomsection/Footer'
import MetaTag from '../../../layout/MetaTag';
import { Helmet } from 'react-helmet'

import './privacyCookies.css';


function PrivacyCookies() {
    return (
        <Fragment>

            <Helmet  >
                <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=2.0" />
                <meta name="description" content={`   PROTECTING YOUR PRIVACY ||  HOW WE COLLECT YOUR INFORMATION ||   HOW WE USE YOUR INFORMATION ||   RETENTION OF INFORMATION ||  SEEING ADVERTS FOR IMONLINE.SHOP ONLINE    ||       SHARING YOUR INFORMATION||         DATA PROTECTION ||    You can contact the Data Protection Officer at: || Email: dataprotection@imonline.shop  ||    YOUR RIGHTS `} />
                <meta name="keywords" content={`  CHANGES TO HOW WE PROTECT YOUR PRIVACY ||  LANGUAGE||  HOW TO CONTACT US ||   Please do not hesitate to contact our Customer Service team or our Privacy team,|| `} />
                <meta name="robots" content="index, follow " />
                <meta property="og:title" content={`    PRIVACY, COOKIES AND DATA PROTECTION POLICY|| HOW WE COLLECT YOUR INFORMATION ||   HOW WE USE YOUR INFORMATION ||   RETENTION OF INFORMATION ||  SEEING ADVERTS FOR IMONLINE.SHOP ONLINE    ||       SHARING YOUR INFORMATION||         DATA PROTECTION ||    You can contact the Data Protection Officer at: || Email: dataprotection@imonline.shop  ||    YOUR RIGHTS `} />
                <meta property="og:description" content={`     CHANGES TO HOW WE PROTECT YOUR PRIVACY ||  LANGUAGE||  HOW TO CONTACT US ||   Please do not hesitate to contact our Customer Service team or our Privacy team,|| `} />
                <meta property="og:type" content="website" />
                {/* <meta property="og:url" content={items.imageurl}   key={items.public_id}/>
            <meta property="og:image" content={items.imageurl}  key={items.public_id}/> */}
                <meta property="og:locale" content="en-GB_IN" />
                <meta property="og:site_name" content="IMONLINE.SHOP" />
                {/* <meta property="og:price:currency" content="INR" />
            <meta property="og:price:amount" content={product.price} /> */}
            </Helmet>
            <MetaTag title={'IM Online Shopping Privacy and Cookies Safety Realted Details Page'} />
            <Navbar />
            <div className="privacy-cookies-data">
                <h2>PRIVACY, COOKIES AND DATA PROTECTION POLICY</h2>
                <ul className='OVERVIEW' >
                    OVERVIEW
                    <li>
                        IM ONLINE SHOPPING COMP. is part of the IM group. This Privacy, Data Protection and Cookies Policy (“Policy”)
                        explains how we use the information we collect from you when you use our website or mobile application (“Site”).
                        This Policy also applies to information we receive from you through any social media platforms that we control.
                    </li>
                    <li>
                        IM ONLINE SHOPPING COMP. is responsible for your privacy when you use our Site.
                    </li>
                    <li>
                        IM aims to be transparent about the information we collect about you,
                        how it is used and whom it is shared with. We collect your information:
                        <ul>
                            <li>
                                to improve your user experience;
                            </li>
                            <li>
                                under a secure environment;
                            </li>
                            <li>
                                but you can decide what and how you want to hear from us;
                            </li>
                            <li>
                                only when necessary.
                            </li>
                        </ul>
                    </li>
                </ul>
                <ul className='PROTECTING-YOUR-PRIVACY'>
                    PROTECTING YOUR PRIVACY
                    <li>
                        Protecting the privacy and security of our customers and Site visitors is of utmost importance to IM.
                        We appreciate and understand how important privacy is to you. If you have any questions about how we protect your privacy,
                        contact us at dataprotection@imonline.shop.
                    </li>

                    <li>
                        This Policy applies to your use of our services and complies with the
                        requirements of the EU General Data Protection Regulation (“GDPR”).
                    </li>
                    <li>
                        For all our services, the company responsible for your privacy is IM ONLINE SHOPPING COMP.
                    </li>
                    <li>
                        IM respects the privacy of children. IM will never knowingly request or use personal
                        information from minors. If we become aware that we have collected personal information from a user of the
                        Site who is under the age of 18, we will remove that minor's personal information from our files.
                        Through the access and use of the Site, you have represented and warranted that you are 18 years of age or over.
                        If you are under 18, please do not submit any personal information to us, and rely on a parent or guardian to assist you.
                    </li>

                </ul>
                <ul className='HOW-WE-COLLECT-YOUR-INFORMATION'>
                    HOW WE COLLECT YOUR INFORMATION
                    <li>To the extent permissible under the applicable law(s), we may collect information about you when you:-

                        <ul>
                            <li><b>Complete a service application form.</b>
                                We may collect information such as your name, address, e-mail address, telephone number and your location.</li>
                            <li><b>Register to use our Site.</b>We may collect information such as your name, address,
                                e-mail address, telephone number, and your location.</li>
                            <li><b>Place an order using our Site.</b> We may collect information such as your name, address,
                                e-mail address, telephone number, payment details and your location.</li>
                            <li><b>Contacting Customer Service, either via E-mail, telephone, fax or live chat.</b>
                                We may collect information such as your name, address, e-mail address, telephone number.

                            </li>
                            <li><b>Sign up as an Affiliate of IM</b>
                                We may collect information such as your name, address, e-mail address,
                                telephone number, payment details and your location
                            </li>

                            <li><b>Sign up for newsletters and other marketing content. </b>
                                We may collect information such as your name, address, e-mail address
                                and telephone number and your location.
                            </li>
                            <li><b>Take part in surveys, enter into any competitions, prize draws.</b>We may collect information such as
                                your name, address, e-mail address, telephone number and your location.</li>
                            <li><b>Interacting with us using social media.</b>We may collect information such as your e-mail address,
                                your social media handle, and your location.</li>
                            <li><b>Our close circuit television recordings in our physical stores or premises.</b>
                                We may collect information such as recordings of your image.
                            </li>
                            <li><b>Contacting us offline. </b>We may collect information such as your name,
                                your telephone number and your location.</li>
                            <li><b>From our partners.</b> We may collect information such as your name, your e-mail address, your telephone number and your location.</li>
                        </ul>
                    </li>
                    <li>Providing your personal information to us is not mandatory. However, if you choose not to share this
                        information with us you may not be able to make purchases from our Site
                        and you are unlikely to receive optimal overall customer experience.
                    </li>
                </ul>


                <ul className='HOW-WE-USE-YOUR-INFORMATION'>
                    HOW WE USE YOUR INFORMATION
                    <li>
                        We may use your information for the following reasons:-
                        <ul>
                            <li>To operate the Site, such as to:-
                                <ul>
                                    <li>To create and maintain your account;</li>
                                    <li>Communicate with you about your account with IMONLINE.SHOP</li>
                                </ul>
                            </li>

                            <li>To provide services to you, such as to:-

                                <ul>
                                    <li>Process payments and refunds;</li>
                                    <li>Deliver your purchases;</li>
                                    <li>Handling returns;</li>
                                    <li>Provide customer service support to you, whether online or offline;</li>
                                    <li>Run surveys, competitions or promotions;</li>
                                    <li>To communicate with you, whether online or offline, and on any platform.</li>

                                </ul>
                            </li>
                            <li>To manage our business needs, such as testing our IT systems, research,
                                data analysis, improving our Site, and developing new products and services.</li>
                            <li>To prevent fraud and other malicious acts.</li>
                            <li>To provide marketing to you about IM group’s products, competitions, giveaways and surveys.</li>
                            <li>To provide customised and personalised services to you, such as analysing and predicting your interests so as to
                                customise your online experience including showing you things that interest you.</li>
                            <li>To respond to your requests, such to contact you when you have submitted questions/inquiries to our Customer Service team.</li>
                            <li>To create new and exciting features and products for our customers.</li>
                            <li>To comply with regulatory requirements or obligations.</li>
                            <li>To conduct investigations or take action in relation to any violation of our terms and conditions for Services.</li>
                            <li>To conduct necessary due diligence, such as credit checks.</li>
                            <li>To enforce our legal rights.</li>
                            <li>Any other actions reasonably related to the above specified purposes.</li>
                        </ul>

                    </li>
                    <li>We also anonymise and aggregate personal information to ensure that you cannot be identified,
                        and use it for data analysis and other legitimate purposes.</li>
                </ul>

                <ul className='RETENTION-OF-INFORMATION'>
                    RETENTION OF INFORMATION
                    <li>We will keep your information for as long as you have your account, or as long as it is necessary
                        to provide our services to you, or (in the case of any contact you may have with our Customer Service team)
                        for as long as it is necessary to provide support-related reporting and trend analysis only.</li>

                    <li>We may also hold onto some of your information even after you have closed your account or
                        when it is no longer needed to provide the services to you. This is for reasonably necessary purposes,
                        or when we are required to meet legal or regulatory requirements,
                        resolve disputes, prevent fraud and abuse, or enforce our terms and conditions,
                    </li>
                </ul>

                <ul
                    className='MARKETING-MESSAGES'
                >
                    <li>
                        If you give us permission, we will send you marketing messages
                        by email and/or SMS to keep you updated on what we’re up to and to help you view and find our products.
                    </li>

                    <li>
                        We may also hold onto some of your information even after you have closed your account or when
                        it is no longer needed to provide the services to you. This is for reasonably necessary purposes,
                        or when we are required to meet legal or regulatory requirements,
                        resolve disputes, prevent fraud and abuse, or enforce our terms and conditions,

                        <ul>
                            <li> Through your account settings</li>
                            <li>By clicking on the ‘unsubscribe’ link in any email</li>
                            <li>By contacting our Customer Service team</li>
                        </ul>
                    </li>
                    <li>Once you do this, we will ensure that you do not receive further marketing messages.
                        Please note that it may take a few days for all of our systems to be updated,
                        so you may still receive messages from us while we process your request.
                        This process will not stop us from providing service communications, such as order updates.
                    </li>
                </ul>

                <ul className='SEEING-ADVERTS-FOR-IMONLINE.SHOP-ONLINE'>
                    SEEING ADVERTS FOR IMONLINE.SHOP ONLINE

                    <li>
                        We engage in online advertising to update you on what we are up to and to help you view and find our products.
                    </li>

                    <li>
                        We aim to show you IM banners and ads that are relevant to you through a variety of digital marketing networks,
                        ad exchanges and social media. We also use a range of advertising technologies like pixels, cookies and ad tags services.
                        For more information on the technologies we use, please refer to our Cookies Policy, below.
                    </li>
                    <li>
                        We will tailor banners and ads of interest to you, depending on how you use the Site
                        (for example, your search history and the content you have previously read).
                    </li>
                </ul>
                <ul className='SHARING-YOUR-INFORMATION'>
                    SHARING YOUR INFORMATION
                    <li>
                        We do not sell any of your personal information to any third party.
                    </li>

                    <li>
                        However, as a key part of providing our services to you,
                        we do share your data with the following categories of companies:
                        <ul>
                            <li>Companies in the IM group, as different units of our group are responsible for different activities;</li>

                            <li>
                                Companies involved in getting your purchases to you,
                                such as payment service providers, warehouses, order packers, and delivery companies;
                            </li>

                            <li>
                                Professional service providers, such as marketing agencies,
                                advertising partners website hosts and 3rd party platform providers, who help us run our business;
                            </li>
                            <li>
                                Law enforcement and fraud prevention agencies or anybody required by law; and
                            </li>
                            <li>
                                Companies approved by you, such as social media sites (if you choose to link your account to us).
                            </li>
                        </ul>
                    </li>
                    <li>
                        We may also use data processors and other related companies during the course of our business.
                        We protect your personal information through the use of data protection agreements with our data processors and related companies.
                        These data protection agreements are in compliance with the GDPR.
                    </li>
                    <li>
                        We may provide third parties with aggregated but anonymised information and analytics (that does not identify you) about our customers.
                    </li>

                    <li>
                        In the event that IM or its assets are sold, acquired or
                        merged with a third party, resulting in
                        your personal information being transferred to such third party,
                        your personal information will at all times continue to be subject to a
                        privacy policy that complies with the requirements of the GDPR.
                    </li>
                </ul>

                <ul className='DATA-PROTECTION' >
                    DATA PROTECTION

                    <li>
                        We have implemented security safeguards designed to protect your personal information, such as HTTPS.
                        While we are dedicated to securing our systems and the Site, we cannot warrant the security of
                        any information that you send us. There is no guarantee that data may not be accessed, disclosed,
                        altered, or destroyed by breach of any of our physical, technical, or managerial safeguards.
                    </li>
                    <li>
                        You are responsible for securing and maintaining the privacy of your password(s) and Account/profile registration
                        information and verifying that the information we maintain about you is accurate and current. We are not responsible
                        for protecting any information that we share with a third-party based on an account connection that you have authorised.
                    </li>

                    <li>
                        We have appointed a Data Protection Officer to oversee our compliance with the GDPR.
                        We may also delegate other employees with the IM group
                        to act on behalf of the DPO or to take responsibility for the day-to-day
                        collection, processing and use of your information.
                    </li>

                    <li>
                        You can contact the Data Protection Officer at:
                    </li>
                    <li><b>Data Protection Officer</b></li>
                    <li>IM ONLINE SHOPPING COMP.
                        MRCG+87X,Chhanna Rd,Dehliz Kalan Ahmedgarh,Punjab 148021,india </li>
                    <li>Email: dataprotection@imonline.shop</li>
                </ul>

                <ul className='YOUR-RIGHTS'   >
                    YOUR RIGHTS
                    <li>We respect your rights under the GDPR. Your rights include the following:-

                        <ul>
                            <li>
                                The right to be informed about how your personal information is being used;
                            </li>

                            <li>The right to access the personal information we hold about you;</li>
                            <li>The right to request the correction of inaccurate personal information we hold about you
                                (although you can do most of this through My Account );
                            </li>
                            <li>The right to request deletion of your data, or to stop processing it or collecting it, in certain circumstances;
                            </li>

                            <li>The right to stop direct marketing messages (which you can do through My Account ) and to withdraw consent for other consent-based processing at any time;
                            </li>
                            <li>The right to request that we transfer or port elements of your data either to you or another service provider;
                            </li>

                            <li>
                                The right to complain to your data protection regulator;
                            </li>

                            <li>The right to withdraw your consent by writing to our Data Protection Officer at dataprotection@imonline.shop
                            </li>
                        </ul>
                    </li>
                    <li>
                        If you want to exercise your rights, make a complaint, or just have questions,
                        please click here to contact our Customer Service team or click here to contact our Data Protection Officer.

                    </li>

                </ul>


                <ul className='CHANGES-TO-HOW-WE-PROTECT-YOUR-PRIVACY'>
                    CHANGES TO HOW WE PROTECT YOUR PRIVACY
                    <li>
                        We may amend this Policy from time to time to reflect how we are processing your data.
                    </li>
                    <li>
                        If we make significant changes, we will make them clear on the IM website
                        or other IM services, or by some other means of contact such as e-mail,
                        so that you are able to review the changes before you continue to use our Site.
                        If you object to any changes, you may close your account.
                    </li>

                    <li>
                        You acknowledge that your continued use of our services,
                        after we publish or send a notice about our changes to this Policy,
                        means that the collection, use and sharing of your personal information is subject to the updated Policy.
                    </li>
                </ul>

                <ul className='LANGUAGE'  >
                    LANGUAGE

                    <li>The original Privacy Policy is in English. We have translated the Privacy Policy into other languages.
                        However, should there be any inconsistencies between the English version
                        of this Privacy Policy and any translations, the English version shall prevail.
                    </li>
                </ul>
                <ul className='HOW-TO-CONTACT-US'  >
                    HOW TO CONTACT US
                    <li>
                        If you:
                        <ul>
                            <li>Have any questions or feedback about this notice;</li>
                            <li>Would like us to stop using your information; or</li>
                            <li>Want to exercise any of your rights as set out above, or make a complaint</li>
                        </ul>
                    </li>
                    <li>Please do not hesitate to contact our Customer Service team or our Privacy team,
                        who will gladly answer any questions you may have.
                        You can also contact our Customer Service team via our website. You can also write to us at:
                    </li>
                    <li><b>IM ONLINE SHOPPING COMP.</b></li>
                    <li> MRCG+87X,Chhanna Rd,Dehliz Kalan Ahmedgarh,Punjab 148021,india</li>
                    <li>contact : <b> (+91)7658007326 </b></li>
                    <li>Email : im@imonline.shop</li>
                </ul>

            </div>

            <Footer />
        </Fragment>
    )
}

export default PrivacyCookies