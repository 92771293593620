
// SAVE BANK ACCOUNT REQUEST
export const SAVE_BANK_ACCOUNT_DETAILS_REQUEST='SAVE_BANK_ACCOUNT_DETAILS_REQUEST';
export const SAVE_BANK_ACCOUNT_DETAILS_SUCCESS='SAVE_BANK_ACCOUNT_DETAILS_SUCCESS';
export const SAVE_BANK_ACCOUNT_DETAILS_FAIL='SAVE_BANK_ACCOUNT_DETAILS_FAIL';
export const SAVE_BANK_ACCOUNT_DETAILS_RESET='SAVE_BANK_ACCOUNT_DETAILS_RESET';


// GET BANK ACCOUNT DETAILS
export const ACCOUNT_DETAILS_REQUEST='ACCOUNT_DETAILS_REQUEST';
export const ACCOUNT_DETAILS_SUCCESS='ACCOUNT_DETAILS_SUCCESS';
export const ACCOUNT_DETAILS_FAIL='ACCOUNT_DETAILS_FAIL';


// GET ALL BANK ACCOUNT DETAILS BY ADMIN
export const ALL_ACCOUNT_DETAILS_REQUEST='ALL_ACCOUNT_DETAILS_REQUEST';
export const ALL_ACCOUNT_DETAILS_SUCCESS='ALL_ACCOUNT_DETAILS_SUCCESS';
export const ALL_ACCOUNT_DETAILS_FAIL='ALL_ACCOUNT_DETAILS_FAIL';


export const CLEAR_ERROR='CLEAR_ERROR';


