// ADD KEYWORDS TO DATABASE
export const CREATE_SEARCH_REQUEST ='CREATE_SEARCH_REQUEST';
export const CREATE_SEARCH_SUCCESS ='CREATE_SEARCH_SUCCESS';
export const CREATE_SEARCH_FAIL ='CREATE_SEARCH_FAIL';
export const CREATE_SEARCH_RESET ='CREATE_SEARCH_RESET';



// GETTING ALL KEYWORDS BY ADMIN
export const ALL_SEARCHED_REQUEST ='ALL_SEARCHED_REQUEST';
export const ALL_SEARCHED_SUCCESS ='ALL_SEARCHED_SUCCESS';
export const ALL_SEARCHED_FAIL ='ALL_SEARCHED_FAIL';
export const ALL_SEARCHED_RESET ='ALL_SEARCHED_RESET';




export const CLEAR_ERROR ='CLEAR_ERROR';