import React,{Fragment} from 'react'

import {Link} from 'react-router-dom'

import EmptyReturnOrderImage from '../../images/undraw_Not_found_re_ddk1.png';


function EmptyRetrunOrder() {
  return (
    <Fragment>
    <div className="if-not-order-div">
        <div className="image-of-not-div">
            <img src={EmptyReturnOrderImage} alt="www.imonline.shop" />
        </div>
        <div className="paragraph-of-not-div">
            <p>yet not return product !</p>
           <Link to={'/Product'}> <button>back</button> </Link>
        </div>
    </div>
</Fragment>
  )
}

export default EmptyRetrunOrder