import React, { Fragment, useEffect } from 'react'

import { useAlert } from 'react-alert'
import BestSellerProps from './BestSellerProps';
import { useSelector, useDispatch } from 'react-redux'
import { Carousel } from 'react-bootstrap';
import { clearErrorAction, productAction } from '../../../../redux/actions/productAction'
import photoImage from '../../../../images/LNN.png'

import './bestSeller.css'


function BestSeller() {


    const dispatch = useDispatch();
    const alert = useAlert();

    const { loading, products, error } = useSelector(state => state.products)

    useEffect(() => {

        if (error) {
            alert.error(error)
            dispatch(clearErrorAction(error))

        }
        dispatch(productAction())

    }, [alert, dispatch, error])




    return (
        <Fragment>
            <Carousel prevLabel={null} nextLabel={null} indicators={false}>
                <Carousel.Item interval={5000} >
                    <img
                        className="carsoule-photo"
                        src={photoImage}
                        alt='second'
                    />
                </Carousel.Item>
                <Carousel.Item interval={5000}>
                    <img
                        className="carsoule-photo"
                        src={photoImage}
                        alt='first'
                    />
                </Carousel.Item>
            </Carousel>
            <div className='bestSellerHeadingDiv'   >
                <h1 className='bestSellerHeading'> BEST SELLERS </h1>
                <p className='bestSellerPara'>Get All Best Seller Shoes Exclusive for Men's</p>
            </div>
            <div className="best-seller-container-div">
                <BestSellerProps products={products} loading={loading} />
            </div>


        </Fragment>
    )
}

export default BestSeller
