import React, { Fragment } from 'react'

// from material ui core
import Slider from '@material-ui/core/Slider'




import './filterCard.css'

const FilterCard = ({price,priceHandler}) => {
    return (
        <Fragment>
            <Slider
                value={price}
                onChange={priceHandler}
                valueLabelDisplay='on'
                aria-labelledby='range-slider'
                min={0}
                max={50000}
            />


        </Fragment>
    )
}

export default FilterCard
