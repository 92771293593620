import React from 'react'
import Helmat from 'react-helmet'


function MetaTag({title}) {
  return (
   <Helmat>
    <title>{title}</title>
   </Helmat>
  )
}

export default MetaTag