import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import Slider from "react-slick";
import "../../css/menubar.css";

// import Menubarimage from "../../images/royal_looks12__20200722_27-removebg-preview.png";

// icons
import LockOpen from "@material-ui/icons/LockOpen";
import PersonAddAltIcon from "@material-ui/icons/PersonAddOutlined";
import ShoppingBagIcon from "@material-ui/icons/ShoppingBasketOutlined";

import FirstImage from "../../images/shortflatshoes.jpg";
import SecondImage from "../../images/runningsports.jpg";
import ThirdImage from "../../images/11.jpg";
import FourthImage from "../../images/3.jpg";
import FifthImage from "../../images/4.jpg";
import SixthImage from "../../images/13.jpg";
import SeventhImage from "../../images/15.jpg";

function Menubar() {
  const { isAuthenticated } = useSelector((state) => state.user);

  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <Fragment>
      {isAuthenticated !== true ? (
        <div className="menu-bar">
          <Slider {...settings}>
            <div className="offer-section-here">
              <h6 className="offer-message-heading">
                * !! ATTENTION !! * Special Offer * *
              </h6>
            </div>
            <div className="offer-section-here">
              <h6 className="offer-message-heading">
                * !! Create Your Account with us and get one slide free off
                cost. !! *
              </h6>
            </div>
          </Slider>
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                {/* <div className="main-container-style"> */}
                <h1 className="main-heading">
                  Its time to play with shoes buy exclusively trendy shoes for
                  men's
                </h1>
                <div className="both-login-sign-buttons">
                  <Link to="./login">
                    {" "}
                    <button className="login-btn">
                      login
                      <LockOpen />{" "}
                    </button>
                  </Link>
                  <Link to="./signin">
                    <button className="sign-in-btn">
                      {" "}
                      signin
                      <PersonAddAltIcon />{" "}
                    </button>
                  </Link>
                </div>
                <Link to={"./Product"}>
                  <button className="buy-now-button-menu-bar">
                    {" "}
                    shop now <ShoppingBagIcon />{" "}
                  </button>
                </Link>
              </div>

              <div className="col-md-6">
                <Slider {...settings}>
                  <div className="img-menu-bar">
                    <img
                      className="img-menu-bar-image-style-for-design"
                      src={FirstImage}
                      alt="www.imonline.shop"
                    />
                  </div>
                  <div className="img-menu-bar">
                    <img
                      className="img-menu-bar-image-style-for-design"
                      src={SecondImage}
                      alt="www.imonline.shop"
                    />
                  </div>
                  <div className="img-menu-bar">
                    <img
                      className="img-menu-bar-image-style-for-design"
                      src={ThirdImage}
                      alt="www.imonline.shop"
                    />
                  </div>
                  <div className="img-menu-bar">
                    <img
                      className="img-menu-bar-image-style-for-design"
                      src={FourthImage}
                      alt="www.imonline.shop"
                    />
                  </div>
                  <div className="img-menu-bar">
                    <img
                      className="img-menu-bar-image-style-for-design"
                      src={FifthImage}
                      alt="www.imonline.shop"
                    />
                  </div>
                  <div className="img-menu-bar">
                    <img
                      className="img-menu-bar-image-style-for-design"
                      src={SixthImage}
                      alt="www.imonline.shop"
                    />
                  </div>
                  <div className="img-menu-bar">
                    <img
                      className="img-menu-bar-image-style-for-design"
                      src={SeventhImage}
                      alt="www.imonline.shop"
                    />
                  </div>
                </Slider>
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Fragment>
          <div className="menu-bar">
            <Slider {...settings}>
              <div className="offer-section-here">
                <h6 className="offer-message-heading">
                  * !! Welcome to IM buy exclusively branded First Copy 10A Sneakers  !! THANKYOU FROM IM. *
                </h6>
              </div>
              <div className="offer-section-here">
                <h6 className="offer-message-heading">
                  * Important Service Alert - We Sell Only First copy 10A Sneakers and Original Sneaker with Return and Exchange Policy. *
                </h6>
              </div>
            </Slider>
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  {/* <div className="main-container-style"> */}
                  <h1 className="main-heading">
                    buy exclusively trendy shoes for men's only on IMONLINE.SHOP
                  </h1>
                  <Link to={"./Product"}>
                    {" "}
                    <button className="buy-now-button-menu-bar">
                      {" "}
                      Checkout Today !! <ShoppingBagIcon />{" "}
                    </button>
                  </Link>
                </div>
                {/* </div> */}
                <div className="col-md-6">
                  <Slider {...settings}>
                    <div className="img-menu-bar">
                      <img
                        className="img-menu-bar-image-style-for-design"
                        src={FirstImage}
                        alt="www.imonline.shop"
                      />
                    </div>
                    <div className="img-menu-bar">
                      <img
                        className="img-menu-bar-image-style-for-design"
                        src={SecondImage}
                        alt="www.imonline.shop"
                      />
                    </div>
                    <div className="img-menu-bar">
                      <img
                        className="img-menu-bar-image-style-for-design"
                        src={ThirdImage}
                        alt="www.imonline.shop"
                      />
                    </div>
                    <div className="img-menu-bar">
                      <img
                        className="img-menu-bar-image-style-for-design"
                        src={FourthImage}
                        alt="www.imonline.shop"
                      />
                    </div>
                    <div className="img-menu-bar">
                      <img
                        className="img-menu-bar-image-style-for-design"
                        src={FifthImage}
                        alt="www.imonline.shop"
                      />
                    </div>
                    <div className="img-menu-bar">
                      <img
                        className="img-menu-bar-image-style-for-design"
                        src={SixthImage}
                        alt="www.imonline.shop"
                      />
                    </div>
                    <div className="img-menu-bar">
                      <img
                        className="img-menu-bar-image-style-for-design"
                        src={SeventhImage}
                        alt="www.imonline.shop"
                      />
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
}

export default Menubar;
