import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CheckoutStep from "../CheckoutStep";
import { useHistory } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import MetaTag from "../../../layout/MetaTag";
import "../checout.css";

// icons
import PinDropIcon from "@mui/icons-material/PinDrop";
import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon";
import EmailIcon from "@mui/icons-material/Email";
import HomeIcon from "@mui/icons-material/Home";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import PhoneIcon from "@mui/icons-material/Phone";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import TransferWithinAStationIcon from "@mui/icons-material/TransferWithinAStation";
import Loader from "../../../layout/loader";
import { savingCartInfoAction } from "../../../redux/actions/cartAction";
import {
  createSaveInfo,
  clearErrorAction,
  gettingSaveInfo,
} from "../../../redux/actions/saveAddressAction";

import { SAVE_ADDRESS_RESET } from "../../../redux/constants/SaveAddressConstants";
import { Typography } from "@mui/material";

function CheckoutForDirectBuy() {
  const history = useHistory();
  const dispatch = useDispatch();

  // const { shippingInfo } = useSelector((state) => state.cart);

  // console.log(shippingInfo);

  //  save shipping info selector
  const { loading, success, message, error } = useSelector(
    (state) => state.saveInfo
  );

  const {
    loading: infoLoading,
    info,
    error: infoError,
  } = useSelector((state) => state.info);
  // user details
  const { user } = useSelector((state) => state.user);

  const [name, setName] = useState(user.firstName);
  const [email, setEmail] = useState(user.email);
  const [address, setAddress] = useState('');
  const [landMark, setLandMark] = useState('');
  const [city, setCity] = useState('');
  const [autoSaveShippingState, setAutoSaveShippingState] = useState('');
  const [district, setDistrict] = useState('');
  const [pincode, setPincode] = useState('');
  const [phoneNo, setPhoneNo] = useState('');

  // city pincode verfication usestate

  const [pincodeVerify, setPincodeVerfiy] = useState();

  // address checkbox
  const [addressChecked, setAddressChecked] = useState("");
  const [isAddressChecked, setIsAddressChecked] = useState(false);

  useEffect(() => {
    if (error) {
      swal({
        title: "error",
        text: error,
        icon: "error",
      });
      dispatch(clearErrorAction());
    }
    if (success === true) {
      swal({
        title: "success",
        text: message,
        icon: "success",
      });
      dispatch({ type: SAVE_ADDRESS_RESET });
    }

    if (infoError) {
      swal({
        title: "error",
        text: infoError,
        icon: "error",
      });
      dispatch(clearErrorAction());
    }

    dispatch(gettingSaveInfo());
  }, [dispatch, error, success, message, infoError]);

  const onSubmitHandler = (e) => {
    e.preventDefault();
    if (phoneNo.length < 10 || phoneNo.length > 10) {
      return swal({
        title: "Validation Error",
        text: "Please enter right number !!",
        icon: "info",
        button: "OK",
      });
    }
    dispatch(
      createSaveInfo({
        name,
        email,
        address,
        landMark,
        city,
        autoSaveShippingState,
        pincode,
        phoneNo,
        district,
      })
    );
  };

  const CityPincodeHandler = async (e) => {
    e.preventDefault();

    setPincode(pincodeVerify);
    const { data } = await axios.get(
      `https://api.postalpincode.in/pincode/${pincodeVerify}`
    );
    if (data[0].Status === "Error") {
      return swal({
        title: "Validation Error",
        text: "Please type valid Pincode !",
        icon: "info",
        button: "OK",
      });
    } else {
      setCity(data[0].PostOffice[0].Name);
      setAutoSaveShippingState(data[0].PostOffice[0].State);
      setDistrict(data[0].PostOffice[0].District);
    }
  };

  const addressCheckoutHandler = async (e) => {
    e.preventDefault();
    try {
      if (!addressChecked) {
        return swal({
          title: "Validation Error",
          text: "Please Select Delivery Address !!",
          icon: "info",
        });
      }

      const { data } = await axios.get(`/api/shipping/info/${addressChecked}`);

      if (data.msg !== "") {
        const shippingData = {
          name: data.msg[0].name,
          email: data.msg[0].email,
          address: data.msg[0].address,
          landMark: data.msg[0].landMark,
          city: data.msg[0].city,
          state: data.msg[0].state,
          pincode: data.msg[0].pincode,
          phoneNo: data.msg[0].phoneNo,
          district: data.msg[0].district,
        };


        console.log(shippingData)
        dispatch(savingCartInfoAction(shippingData));

        setTimeout(() => {
          history.push("/confirm/orders/directbuy");
        }, 500);
      } else {
        return swal({
          title: "Validation Error",
          text: "Something Wrong !!",
          icon: "info",
        });
      }
    } catch (error) {
      return swal({
        title: "Validation Error",
        text: error,
        icon: "error",
      });
    }
  };

  const onHandlerChange = (e) => {
    setAddressChecked(e.target.value);
    setIsAddressChecked(true);
  };

  return (
    <Fragment>
      <MetaTag title={"IM Online Shopping Shipping Details Page"} />
      <CheckoutStep activeStep={0} />

      {loading ? (
        <Loader />
      ) : (
        <>
          {/* showing save info  */}
          {infoLoading ? (
            <>
              <h1> Wait for second we are loading your save address.....</h1>
            </>
          ) : (
            <>
              {info.length <= 0 ? (
                <>
                  <div className="save-info-empty-data-container">
                    <Typography>
                      {" "}
                      Please Add your Address for future delivery!{" "}
                    </Typography>
                  </div>
                </>
              ) : (
                <>
                  <div className="save-info-main-container">
                    <div className="save-info-div-container">
                      <h2> Delivery Address </h2>
                      {info &&
                        info.map((add, index) => (
                          <>
                            <p
                              key={add._id}
                              className="save-info-delivery-address-detail"
                            >
                              {isAddressChecked === true ? (
                                <input
                                  disabled
                                  type="checkbox"
                                  value={add._id}
                                  name="checkbox"
                                  onChange={onHandlerChange}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  value={add._id}
                                  name="checkbox"
                                  onChange={onHandlerChange}
                                />
                              )}
                              {add.name} ,{add.email},{add.address},
                              {add.landMark},{add.pincode},{add.city},
                              {add.state},{add.district}
                            </p>
                          </>
                        ))}
                    </div>
                  </div>

                  <div className="save-info-main-container-checkout-button">
                    <button onClick={addressCheckoutHandler}> Continue </button>
                  </div>
                </>
              )}
            </>
          )}

          {/* showing save info  */}
          <div className="shipping-container">
            <div className="shipping-box">
              <h6 className="shipping-heading">Add Shipping Details</h6>

              <form
                className="shipping-from"
                encType="multipart/form-data"
                onSubmit={onSubmitHandler}
              >
                <div className="checkout-form-main-div">
                  <div>
                    <InsertEmoticonIcon />
                    <input
                      type="text"
                      required
                      placeholder="Name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>

                  <div>
                    <EmailIcon />
                    <input
                      type="text"
                      required
                      placeholder="Vaild Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>

                  <div>
                    <HomeIcon />
                    <input
                      type="text"
                      required
                      placeholder="Address"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                    />
                  </div>

                  <div>
                    <LocationCityIcon />
                    <input
                      type="text"
                      required
                      placeholder="Land Mark"
                      value={landMark}
                      onChange={(e) => setLandMark(e.target.value)}
                    />
                  </div>

                  <div>
                    <PinDropIcon />
                    <input
                      type="number"
                      required
                      placeholder="Pin Code"
                      value={pincodeVerify}
                      onChange={(e) => setPincodeVerfiy(e.target.value)}
                    />
                    <button
                      onClick={CityPincodeHandler}
                      className="pincode-btn-verfiy"
                    >
                      Search here
                    </button>
                  </div>
                  <div>
                    <PhoneIcon />
                    <input
                      type="number"
                      required
                      placeholder="Phone"
                      value={phoneNo}
                      onChange={(e) => setPhoneNo(e.target.value)}
                      size="10"
                    />
                  </div>
                  <div>
                    <TransferWithinAStationIcon />
                    <input
                      type="text"
                      required
                      value={autoSaveShippingState}
                      placeholder="State"
                      readOnly={true}
                    />
                  </div>

                  <div>
                    <MyLocationIcon />
                    <input
                      type="text"
                      required
                      value={district}
                      placeholder="district"
                      readOnly={true}
                    />
                  </div>

                  <div>
                    <HomeWorkIcon />
                    <input
                      type="text"
                      required
                      value={city}
                      placeholder="city"
                      readOnly={true}
                    />
                  </div>
                  <input
                    type="submit"
                    value="Save"
                    className="shipping-btn"
                    disabled={autoSaveShippingState ? false : true}
                  />
                </div>
              </form>
            </div>
          </div>
        </>
      )}
    </Fragment>
  );
}

export default CheckoutForDirectBuy;
