import React, { Fragment } from 'react'

import Navbar from '../../HomeScreen/Navbar';
import MetaTag from '../../../layout/MetaTag';
import Footer from '../../HomeScreen/bottomsection/Footer';
import { Helmet } from 'react-helmet'

import SideBarAccount from './SideBarAccount'



function Payment() {
    return (
        <Fragment>

            <Helmet  >
                <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=2.0" />
                <meta name="description" content={` What forms of payment can I use? || What currencies do you accept? ||  What is 3-D Secure payment?, ||  What is CyberSource?  ||    Which are the digital currencies that will be eligible for payment at check-out? ||    Why is there a second step needed to authorise my payment? || im online shop || payment methods and details at imonline.shop  || imonline.shop || payment regarding details || shopping with im ||`} />
                <meta name="keywords" content={` My payment has been declined, what should I do? ||Why are there extra charges reflected on my credit card bill? ||  Why do I see double records on my online bank statement for my purchase?  || `} />
                <meta name="robots" content="index, follow " />
                <meta property="og:title" content={`payment details  page || more details about payment security with IMONLINE.SHOP , |Why are there extra charges reflected on my credit card bill?  || Which are the digital currencies that will be eligible for payment at check-out? ||    Why is there a second step needed to authorise my payment? || im online shop || payment methods at imonline.shop  || imonline.shop || payments shop || shopping with im ||`} />
                <meta property="og:description" content={` What forms of payment can I use? || What currencies do you accept? ||  What is 3-D Secure payment?, ||  What is CyberSource?  ||    Which are the digital currencies that will be eligible for payment at check-out? ||    Why is there a second step needed to authorise my payment? || im online shop || payment methods and details at imonline.shop  || imonline.shop || payment regarding details || shopping with im ||`} />
                <meta property="og:type" content="website" />
                {/* <meta property="og:url" content={items.imageurl}   key={items.public_id}/>
            <meta property="og:image" content={items.imageurl}  key={items.public_id}/> */}
                <meta property="og:locale" content="en-GB_IN" />
                <meta property="og:site_name" content="IMONLINE.SHOP" />
                {/* <meta property="og:price:currency" content="INR" />
            <meta property="og:price:amount" content={product.price} /> */}
            </Helmet>


            <MetaTag title={'IM Online Shopping Payment and Safety Realted Details Page'} />
            <Navbar />
            <div className="container">
                <div className="row">
                    <div className="col-md-3">
                        <SideBarAccount />
                    </div>
                    <div className="col-md-9">
                        <div className="right-side-paragraph">
                            <div className="h2-heading">
                                <h2> PAYMENT </h2>
                            </div>
                            <div className="details-of-account-privileges">
                                <ul>
                                    What forms of payment can I use?
                                    <li>
                                        We accept payment via MasterCard, Visa, Rupay, NetBanking, Online-Payment, and Other Pay application.
                                    </li>
                                </ul>
                                <ul>
                                    What currencies do you accept?
                                    <li>
                                        For now We Only accept Indian Rupee
                                    </li>
                                </ul>
                                <ul>
                                    What is 3-D Secure payment?
                                    <li>
                                        3-D Secure is an XML-based protocol used as an added layer of security
                                        for online credit and debit card transactions. It was developed by Visa
                                        to improve the security of internet payments and offered to customers as
                                        the Verified by Visa service. Services based on the protocol have also
                                        been adopted by MasterCard, under the name MasterCard Secure Code. 3-D
                                        Secure adds another authentication step for online payments. 3-D Secure
                                        should not be confused with the
                                        Card Security Code (commonly known as CVV) which is a short
                                        numeric code that is printed at the back of the credit card.
                                    </li>
                                </ul>
                                <ul>
                                    What is CyberSource?
                                    <li>
                                        CyberSource, a wholly owned subsidiary of Visa Inc.,
                                        is the only integrated payment management platform built
                                        on secure Visa infrastructure and is one of the leading providers for
                                        internet and mobile payment services. CyberSource provides
                                        its services to banks, corporations and e-businesses in the worldwide market.
                                        For credit card payments,
                                        you will be linked to CyberSource’s website,
                                        a secure site where all your credit card information will be stored.
                                    </li>
                                </ul>
                                <ul>
                                    Which are the digital currencies that will be eligible for payment at check-out?
                                    <li>
                                        We currently Not  accept *Bitcoin, Ethereum, and Tether*.
                                    </li>
                                </ul>

                                <ul>
                                    Why is there a second step needed to authorise my payment?
                                    <li>
                                        All payments made via debit cards,online-pay and
                                        credit card will have two records, authorisation and settlement.
                                        Authorisation refers to funds held by the bank,
                                        and settlement refers to the process where the merchant
                                        has captured the fund that was on hold previously.
                                        Please note that you are only charged once although
                                        there are two separate records of authorisation and settlement.
                                        Please check with your bank for further clarification.
                                    </li>
                                </ul>
                                <ul>
                                    My payment has been declined, what should I do?
                                    <li>
                                        Please try again and make sure you’re including the correct information for login details,
                                        card number, expiry date, etc. If the problem persists,
                                        contact your issuing bank or try an alternative mode of payment.
                                        For further assistance, please contact us at information@imonline.shop or on voice chat.
                                    </li>
                                </ul>
                                <ul>
                                    Why are there extra charges reflected on my credit card bill?
                                    <li>
                                        Your issuing bank may impose small administrative charges for overseas usage.
                                        Please contact your bank for further information.
                                    </li>
                                </ul>
                                <ul>
                                    Why do I see double records on my online bank statement for my purchase?
                                    <li>
                                        All payments made using a debit card,online-pay and
                                        credit card will result in two records: authorisation and settlement.
                                        You will see both records reflected on your online bank statement.
                                        Authorisation refers to funds held by the bank while the payment is in the process of being approved or declined.
                                        Settlement refers to the actual payment
                                        (i.e the merchant has captured the fund that was on hold previously).
                                        Please note that although there are two records,
                                        there is only one single charge. For further clarification, please speak to your bank.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <Footer />
        </Fragment>
    )
}

export default Payment