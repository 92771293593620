import {
  ADD_TO_CART_FAIL,
  ADD_TO_CART_REQUEST,
  ADD_TO_CART_RESET,
  ADD_TO_CART_SUCCESS,
  ALL_CART_ITEM_FAIL,
  ALL_CART_ITEM_REQUEST,
  ALL_CART_ITEM_SUCCESS,
  CLEAR_CART_ERROR,
  REMOVE_FROM_CART_FAIL,
  REMOVE_FROM_CART_REQUEST,
  REMOVE_FROM_CART_RESET,
  REMOVE_FROM_CART_SUCCESS,
  SAVE_BUY_ITEM_INFO,
  SAVE_SHIPPING_INFO
} from "../constants/cartConstants";

//  add to cart reducers
export const saveShippingInfoReducer = ((state = {
    cartItems:[], shippingInfo: {}
}, action) => {
    switch (action.type) {
        case SAVE_SHIPPING_INFO:
            return {
                ...state,
                shippingInfo: action.payload
            }
        case SAVE_BUY_ITEM_INFO :
          return {
            ...state,
            cartItems:action.payload
          }

        default:
            return state;

    }

})

// ADD TO CART REDUCERS
export const addToCartReducers = (state = {}, action) => {
  switch (action.type) {
    case ADD_TO_CART_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ADD_TO_CART_SUCCESS:
      return {
        ...state,
        loading: false,
        isProductAdded: action.payload,
      };
    case ADD_TO_CART_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case ADD_TO_CART_RESET:
      return {
        ...state,
        loading: false,
        isProductAdded: false,
      };

    case CLEAR_CART_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

// REMOVE TO CART REDUCERS
export const removeFromCartReducers = (state = {}, action) => {
  switch (action.type) {
    case REMOVE_FROM_CART_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case REMOVE_FROM_CART_SUCCESS:
      return {
        ...state,
        loading:false,
        Removed: action.payload,
      };
    case REMOVE_FROM_CART_FAIL:
      return {
        ...state,
        loading:false,
        error: action.payload,
      };

      case REMOVE_FROM_CART_RESET:
        return{
          ...state,
          loading:false,
          Removed:null
        }

    case CLEAR_CART_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

// GET ALL CART ITEMS

export const allCartItemsByUserReducer = ((state = { carts:[]}, action) => {
  switch (action.type) {
      case ALL_CART_ITEM_REQUEST:
          return {
              ...state,
              loading: true,
          }
      case ALL_CART_ITEM_SUCCESS:
          return {
              loading: false,
              carts: action.payload,
          }
      case ALL_CART_ITEM_FAIL:
          return {
              loading: false,
              error: action.payload,
          }
      case CLEAR_CART_ERROR:
          return {
              ...state,
              error: null
          }

      default:
          return state;

  }


})

