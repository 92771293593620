import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

import Navbar from '../../HomeScreen/Navbar';
import MetaTag from '../../../layout/MetaTag';
import Footer from '../../HomeScreen/bottomsection/Footer';
import {Helmet} from 'react-helmet'
import './myAccountPrivilage.css'

import SideBarAccount from './SideBarAccount'

function MyAccountPrivileges() {
    return (
        <Fragment>
<Helmet  >
                <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=2.0" />
                <meta name="description" content={`MY ACCOUNT & PRIVILEGES ||   Do I need to have an account to shop at IMONLINE.SHOP ? ||   What are the benefits of creating an account?, ||   How do I create an account? ||, what should I do?  ||  How do I sign in to my account? ||     How do I reset/change my password? || im online shop || imonline.shop  || imonline.shop || my account and privilege || How can I edit/update my account details? || shopping with im ||`} />
                <meta name="keywords" content={`MY ACCOUNT & PRIVILEGES||  Do I need to have an account to shop. ||  shop at IMONLINE.SHOP ?  || What are the benefits of creating an account?, with imonline.shop||  How do I create an account?  what should I do? ||  How do I sign in to my account?, what should I do? ||  imonline.shop , my account setup || account and privileges page   || imonline.shop , my account full details page || account details || accounts details and secuirty`} />
                <meta name="robots" content="index, follow " />
                <meta property="og:title" content={`MY ACCOUNT & PRIVILEGES||  Do I need to have an account to shop. ||  shop at IMONLINE.SHOP ?  || What are the benefits of creating an account?, with imonline.shop||  How do I create an account?  what should I do? ||  How do I sign in to my account?, what should I do? ||  imonline.shop , my account setup || account and privileges page   || imonline.shop , my account full details page || account details || accounts details and secuirty`} />
                <meta property="og:type" content="website" />
                {/* <meta property="og:url" content={items.imageurl}   key={items.public_id}/>
            <meta property="og:image" content={items.imageurl}  key={items.public_id}/> */}
                <meta property="og:locale" content="en-GB_IN" />
                <meta property="og:site_name" content="IMONLINE.SHOP" />
                {/* <meta property="og:price:currency" content="INR" />
            <meta property="og:price:amount" content={product.price} /> */}
            </Helmet>

            <MetaTag title={'IM Online Shopping My Account Privileges Details Page'} />
            <Navbar />
            <div className="container">
                <div className="row">
                    <div class="col-md-3">
                        <SideBarAccount />
                    </div>

                    <div className="col-md-9">
                        <div className="right-side-paragraph">
                            <div className="h2-heading">
                                <h2> MY ACCOUNT & PRIVILEGES </h2>
                            </div>
                            <div className="details-of-account-privileges">
                                <ul>
                                    Do I need to have an account to shop at IMONLINE.SHOP ?
                                    <p>Having an account with IMONLINE.SHOP will speed up the checkout process
                                        and allow you to keep track of your orders.
                                        You can <span> <Link to={'/Login'} >sign up </Link></span>  for an account before shopping or <span> <Link to={'/Signin'} >create one </Link></span> before you head to checkou
                                        You also have the option to shop through guest checkout. However, your information will not be stored
                                        and you will not be able to check or make amendments to your order if you have opted for
                                        guest checkout. If you change your mind, you can still create an account after placing your
                                        order using your current details.</p>
                                </ul>

                                <ul>
                                    What are the benefits of creating an account?
                                    <li>By creating an account, you will be able to:</li>
                                    <li>Checkout faster by saving your address in your Address Book</li>
                                    <li>View your order status and history under <span> <Link to={'/Login'} >My Purchases & Returns</Link> </span></li>
                                    <li>Save your favourite items in your Wishlist</li>
                                    <li>Receive the latest news on Promotions, <span> <Link to={'/Login'} >New Arrivals </Link>  </span> and  <Link to={'/Login'} >Online Exclusives </Link> </li>
                                </ul>
                                <ul>
                                    How do I create an account?
                                    <li>Head on over to the <span> <Link to={'/Signin'} >Sign In page </Link></span>  and fill in your particulars under Create An Account.</li>
                                </ul>

                                <ul>
                                    How do I sign in to my account?
                                    <li>Enter your email address and password into the <span> <Link to={'/Login'} >Sign In</Link> </span>  field.</li>
                                </ul>

                                <ul>
                                    How do I reset/change my password?
                                    <li>Head to the <span> <Link to={'/Login'} >Sign In</Link> </span>  page and enter your email
                                        address under <span> <Link to={'/login-forgot-password'} >Forgot Password.</Link> </span>
                                        You will receive an email with a link to reset your password.</li>
                                    <li>
                                        To change your password, sign into your account and head to <span> <Link to={'/UpdatePassword'} >Change Password.</Link> </span>
                                        You can also do this through your <span> <Link to={'/userprofile'} >Account Details </Link> </span> page.
                                    </li>

                                </ul>
                                <ul>How can I edit/update my account details?
                                    <li>Sign in to your account, head to  <span> <Link to={'/userprofile'} > My Account  </Link> </span> and You can then edit/update your particulars.</li>
                                </ul>



                            </div>
                        </div>
                    </div>


                </div>
            </div>



            <Footer />
        </Fragment>
    )
}

export default MyAccountPrivileges