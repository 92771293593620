
// ADD PRODUCT TO WISHLIST
export const ADD_PRODUCT_REQUEST ='ADD_PRODUCT_REQUEST';
export const ADD_PRODUCT_REQUEST_SUCCESS ='ADD_PRODUCT_REQUEST_SUCCESS';
export const ADD_PRODUCT_REQUEST_FAIL ='ADD_PRODUCT_REQUEST_FAIL';
export const ADD_PRODUCT_REQUEST_RESET ='ADD_PRODUCT_REQUEST_RESET';


// REMOVE PRODUCT TO WISHLIST
export const REMOVE_PRODUCT_REQUEST ='REMOVE_PRODUCT_REQUEST';
export const REMOVE_PRODUCT_REQUEST_SUCCESS ='REMOVE_PRODUCT_REQUEST_SUCCESS';
export const REMOVE_PRODUCT_REQUEST_FAIL ='REMOVE_PRODUCT_REQUEST_FAIL';
export const REMOVE_PRODUCT_REQUEST_RESET ='REMOVE_PRODUCT_REQUEST_RESET';

// GET ALL PRODUCT IN WISHLIST
export const ALL_WISHLIST_PRODUCT_REQUEST ='ALL_WISHLIST_PRODUCT_REQUEST';
export const ALL_WISHLIST_PRODUCT_REQUEST_SUCCESS ='ALL_WISHLIST_PRODUCT_REQUEST_SUCCESS';
export const ALL_WISHLIST_PRODUCT_REQUEST_FAIL ='ALL_WISHLIST_PRODUCT_REQUEST_FAIL';

// GET ALL PRODUCT IN WISHLIST FOR ADMIN
export const ALL_WISHLIST_PRODUCT_REQUEST_ADMIN ='ALL_WISHLIST_PRODUCT_REQUEST_ADMIN';
export const ALL_WISHLIST_PRODUCT_REQUEST_ADMIN_SUCCESS ='ALL_WISHLIST_PRODUCT_REQUEST_ADMIN_SUCCESS';
export const ALL_WISHLIST_PRODUCT_REQUEST_ADMIN_FAIL ='ALL_WISHLIST_PRODUCT_REQUEST_ADMIN_FAIL';




// CLEAR ERROR

export const CLEAR_ERROR ='CLEAR_ERROR';