import {
    CLEAR_ERROR,
    CREATE_SEARCH_REQUEST,
    CREATE_SEARCH_SUCCESS,
    CREATE_SEARCH_FAIL,
    CREATE_SEARCH_RESET,
    ALL_SEARCHED_REQUEST,
    ALL_SEARCHED_SUCCESS,
    ALL_SEARCHED_FAIL,
    ALL_SEARCHED_RESET
} from '../constants/searchConstants'

// REVIEW CREATE REDUCER BY USER

export const searchKeywordReducer = ((state = {}, action) => {
    switch (action.type) {
        case CREATE_SEARCH_REQUEST:
            return {
                ...state,
                loading: true,

            }
        case CREATE_SEARCH_SUCCESS:
            return {
                loading: false,
                success: action.payload,
            }
        case CREATE_SEARCH_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case CREATE_SEARCH_RESET:
            return {
                ...state,
                success: false,
            }
        case CLEAR_ERROR:
            return {
                ...state,
                error: null
            }
        default:
            return state;

    }


})




export const allSearchKeywordReducer = ((state = {
    keywords: [],
}, action) => {
    switch (action.type) {
        case ALL_SEARCHED_REQUEST:
            return {
                ...state,
                loading: true,

            }
        case ALL_SEARCHED_SUCCESS:
            return {
                loading: false,
                keywords: action.payload,
            }
        case ALL_SEARCHED_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case ALL_SEARCHED_RESET:
            return {
                ...state,
                success: false,
            }
        case CLEAR_ERROR:
            return {
                ...state,
                error: null
            }
        default:
            return state;

    }


})