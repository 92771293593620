import React, { Fragment, useEffect } from 'react'
import { DataGrid } from '@material-ui/data-grid'
import { Link, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { clearErrorAction, allCancelOrderByAdminAction } from '../redux/actions/orderAction'
import swal from 'sweetalert';
// import { Button } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
// import DeleteIcon from '@material-ui/icons/Delete'
import Loader from '../layout/loader'
import Sidebar from './Sidebar'
import './allProductByAdmin.css'

function AllCancelOrders() {

    const history = useHistory();
    const dispatch = useDispatch();

    //for delete product by admin

    const { loading, error, cancelOrders } = useSelector((state) => state.allCancelOrderByAdmin)

    useEffect(() => {
        if (error) {
            swal({
                title: "Validation",
                text: error,
                icon: "error",
            })
            dispatch(clearErrorAction())

        }
        dispatch(allCancelOrderByAdminAction())

    }, [dispatch, error, history])



    const columns = [
        { field: 'id', headerName: 'Cancel Order ID', minWidth: 200, flex: 1 },
        {
            field: 'status',
            headerName: 'Status',
            minWidth: 150,
            flex: 0.3,
        },
        {
            field: 'order_Id',
            headerName: 'Order Id',
            minWidth: 300,
            flex: 1,
        },
        {
            field: 'user',
            headerName: 'User Id',
            minWidth: 250,
            flex: 1,
        },
        {
            field: 'action',
            headerName: 'Action',
            type: 'number',
            minWidth: 150,
            flex: 0.3,
            sortable: false,
            renderCell: (params) => {
                return (
                    <Fragment>
                        <Link to={`/Admin/updatestatus/cancel-Order/${params.getValue(params.id, 'id')}`}   >
                            <EditIcon />
                        </Link>

                    </Fragment>
                )
            }
        },
    ]

    const rows = [];
    cancelOrders && cancelOrders.forEach(item => {
        rows.push({
            id: item._id,
            status: item.cancelInformation.status,
            order_Id: item.orderId,
            user: item.user,
        })
    });

    return (
        <Fragment>
                <div className="dashboard-container">
                <Sidebar />
                {loading ? <Loader /> : <Fragment>
                    <div className="productListContainer">
                        <h1 className='productHeadingList'> ALL CANCEL ORDERS'S </h1>
                        <DataGrid

                            rows={rows}
                            columns={columns}
                            pageSize={20}
                            disableSelectionOnClick
                            className='productListTable'
                            autoHeight
                        />
                    </div>
                </Fragment>}

            </div>
        </Fragment>
    )
}

export default AllCancelOrders