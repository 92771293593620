import {
    CREATE_PRODUCT_HEADING_REQUEST,
    CREATE_PRODUCT_HEADING_SUCCESS,
    CREATE_PRODUCT_HEADING_FAIL,
    UPDATE_PRODUCT_HEADING_REQUEST,
    UPDATE_PRODUCT_HEADING_SUCCESS,
    UPDATE_PRODUCT_HEADING_FAIL,
    PRODUCT_HEADING_REQUEST_ID,
    PRODUCT_HEADING_REQUEST_ID_SUCCESS,
    PRODUCT_HEADING_REQUEST_ID_FAIL,
    PRODUCT_ALL_HEADING_REQUEST,
    PRODUCT_ALL_HEADING_SUCCESS,
    PRODUCT_ALL_HEADING_FAIL,
   

    CLEAR_ERROR
} from '../constants/productAdsConstants'


import axios from 'axios';

// import {URL} from '../../Url';


// product detail action

export const productAdsDetailAction = (productAds_Id) => async (dispatch) => {
    console.log(productAds_Id)
    try {
        dispatch({
            type: PRODUCT_HEADING_REQUEST_ID
        })
        const {
            data
        } = await axios.get(`/api/admin/productHeading/${productAds_Id}`);
        console.log(data)
        dispatch({
            type: PRODUCT_HEADING_REQUEST_ID_SUCCESS,
            payload: data.productHeading,
        })

    } catch (error) {
        dispatch({
            type:PRODUCT_HEADING_REQUEST_ID_FAIL,
            payload: error.response.data.msg,
        })
    }


}

// admin ads request 

export const adminAdsRequestAction = () => async (dispatch) => {

    try {
        dispatch({
            type: PRODUCT_ALL_HEADING_REQUEST
        })
        const {
            data
        } = await axios.get(`/api/admin/product-headings`);
        dispatch({
            type: PRODUCT_ALL_HEADING_SUCCESS,
            payload: data.productHeadings,
        })

    } catch (error) {
        dispatch({
            type: PRODUCT_ALL_HEADING_FAIL,
            payload: error.response.data.msg,
        })
    }

}

// update product by admin action
export const updateAdsAction = (productAds_Id,formData) => async (dispatch) => {
    try {

        dispatch({
            type: UPDATE_PRODUCT_HEADING_REQUEST
        })
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        const {
            data
        } = await axios.put(`/api/update/product-heading/${productAds_Id}`, formData, config)
        console.log(data)
        dispatch({
            type: UPDATE_PRODUCT_HEADING_SUCCESS,
            payload: data.success
        })
    } catch (error) {
        dispatch({
            type: UPDATE_PRODUCT_HEADING_FAIL,
            payload: error.response.data.msg
        })
    }


}



// create product by admin action
export const createAdsAction = (formData) => async (dispatch) => {
    try {

        dispatch({
            type: CREATE_PRODUCT_HEADING_REQUEST
        })
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        const {
            data
        } = await axios.post(`/api/header/create`, formData, config)
        dispatch({
            type: CREATE_PRODUCT_HEADING_SUCCESS,
            payload: data.success
        })
    } catch (error) {
        dispatch({
            type: CREATE_PRODUCT_HEADING_FAIL,
            payload: error.response.data.msg
        })
    }


}

// clear the error 


export const clearErrorAction = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERROR
    })
}