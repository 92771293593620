import React from 'react'
import "../../../css/productsdetailsbar.css";


function ProductsDetailsBar() {

    return (
        <>
        <div className="product-heading-name">
        <ul  className="products-ul-list"  >
            <li> NEW ARRIVAL</li>
             <li>TRENDING</li>
             <li>BEST SELLERS</li>
             <li>FEATURED</li>
             <li>ON SALL</li>
          </ul>

        </div>
        </>
    )
}

export default ProductsDetailsBar
