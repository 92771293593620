import {
    CLEAR_ERROR,
    GET_PRODUCT_BY_TAG_REQUEST,
    GET_PRODUCT_BY_TAG_SUCCESS,
    GET_PRODUCT_BY_TAG_FAIL,
    PRODUCT_REQUEST,
    PRODUCT_REQUEST_FAIL,
    PRODUCT_REQUEST_SUCCESS,
    PRODUCT_DETAIL_REQUEST,
    PRODUCT_DETAIL_REQUEST_SUCCESS,
    PRODUCT_DETAIL_REQUEST_FAIL,
    ADMIN_PRODUCT_REQUEST,
    ADMIN_PRODUCT_REQUEST_FAIL,
    ADMIN_PRODUCT_REQUEST_SUCCESS,
    NEW_PRODUCT_REQUEST,
    NEW_PRODUCT_REQUEST_SUCCESS,
    NEW_PRODUCT_FAIL,
    NEW_PRODUCT_RESET,
    DELETE_PRODUCT_REQUEST,
    DELETE_PRODUCT_REQUEST_SUCCESS,
    DELETE_PRODUCT_FAIL,
    DELETE_PRODUCT_RESET,
    UPDATE_PRODUCT_REQUEST,
    UPDATE_PRODUCT_REQUEST_SUCCESS,
    UPDATE_PRODUCT_FAIL,
    UPDATE_PRODUCT_RESET,
    SEARCH_PRODUCT_REQUEST,
    SEARCH_PRODUCT_REQUEST_SUCCESS,
    SEARCH_PRODUCT_REQUEST_FAIL,
    SEARCH_PRODUCT_WITH_FILTERS_REQUEST,
    SEARCH_PRODUCT_WITH_FILTERS_REQUEST_SUCCESS,
    SEARCH_PRODUCT_WITH_FILTERS_REQUEST_FAIL

} from "../constants/productConstants"

// GET PRODUCT BY TAG REDUCERS

export const getProductByTagReducer = ((state = {
    products: []
}, action) => {
    switch (action.type) {
        case GET_PRODUCT_BY_TAG_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case GET_PRODUCT_BY_TAG_SUCCESS:
            return {
                ...state,
                loading: false,
                products: action.payload.products,
            }

        case GET_PRODUCT_BY_TAG_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case CLEAR_ERROR:
            return {
                ...state,
                error: null
            }
        default:
            return state;

    }


})





// get all products
export const productReducer = ((state = {
    products: []
}, action) => {
    switch (action.type) {
        case PRODUCT_REQUEST:
        case ADMIN_PRODUCT_REQUEST:
            return {
                loading: true,
                products: []
            }
        case PRODUCT_REQUEST_SUCCESS:
            return {
                loading: false,
                products: action.payload.products,
                productCount: action.payload.productCount,
            }

        case ADMIN_PRODUCT_REQUEST_SUCCESS:
            return {
                loading: false,
                products: action.payload
            }
        case PRODUCT_REQUEST_FAIL:
        case ADMIN_PRODUCT_REQUEST_FAIL:
            return {
                loading: false,
                error: action.payload,
            }
        case CLEAR_ERROR:
            return {
                ...state,
                error: null
            }
        default:
            return state;

    }


})


// PRODUCT DETAIL REDUCER 

export const productDetailReducer = ((state = {
    product: []
}, action) => {
    switch (action.type) {
        case PRODUCT_DETAIL_REQUEST:
            return {
                loading: true,
                ...state
            }
        case PRODUCT_DETAIL_REQUEST_SUCCESS:
            return {
                loading: false,
                product: action.payload.isDataGet,
                realtedCatogery:action.payload.realtedCatogery,
                realtedBrand:action.payload.realtedBrand
            }
        case PRODUCT_DETAIL_REQUEST_FAIL:
            return {
                loading: false,
                error: action.payload,
            }
        case CLEAR_ERROR:
            return {
                ...state,
                error: null
            }
        default:
            return state;

    }

})



// AUTO COMPLETE SEARCH REDUCERS 

export const autoCompleteSearchProductReducer = ((state = {
    product: []
}, action) => {
    switch (action.type) {
        case SEARCH_PRODUCT_REQUEST:
            return {
                loading: true,
                ...state
            }
        case SEARCH_PRODUCT_REQUEST_SUCCESS:
            return {
                loading: false,
                product: action.payload,
            }
        case SEARCH_PRODUCT_REQUEST_FAIL:
            return {
                loading: false,
                error: action.payload,
            }
        case CLEAR_ERROR:
            return {
                ...state,
                error: null
            }
        default:
            return state;

    }

})


// SEARCH REDUCERS FOR SHOWS DATA AND FILTERS

export const searchDataForFiltersReducer = ((state = {
    product: []
}, action) => {
    switch (action.type) {
        case SEARCH_PRODUCT_WITH_FILTERS_REQUEST:
            return {
                loading: true,
                ...state
            }
        case SEARCH_PRODUCT_WITH_FILTERS_REQUEST_SUCCESS:
            return {
                loading: false,
                product: action.payload,
            }
        case SEARCH_PRODUCT_WITH_FILTERS_REQUEST_FAIL:
            return {
                loading: false,
                error: action.payload,
            }
        case CLEAR_ERROR:
            return {
                ...state,
                error: null
            }
        default:
            return state;

    }

})


// PRODUCT CREATE REDUCER by admin

export const productCreateReducer = ((state = {
    product: []
}, action) => {
    switch (action.type) {
        case NEW_PRODUCT_REQUEST:
            return {
                ...state,
                loading: true,

            }
        case NEW_PRODUCT_REQUEST_SUCCESS:
            return {
                loading: false,
                product: action.payload,
                success: action.payload.success,

            }
        case NEW_PRODUCT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case NEW_PRODUCT_RESET:
            return {
                ...state,
                success: false,
            }
        case CLEAR_ERROR:
            return {
                ...state,
                error: null
            }
        default:
            return state;

    }


})


// PRODUCT DELETE REDUCER BY ADMIN

export const productDeleteReducer = ((state = {}, action) => {
    switch (action.type) {
        case DELETE_PRODUCT_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case DELETE_PRODUCT_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                isDeleted: action.payload,
            }
        case DELETE_PRODUCT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case DELETE_PRODUCT_RESET:
            return {
                ...state,
                isDeleted: false,
            }
        case CLEAR_ERROR:
            return {
                ...state,
                error: null
            }
        default:
            return state;

    }


})


// PRODUCT UPDATE REDUCER BY ADMIN

export const productUpdateReducer = ((state = {}, action) => {
    switch (action.type) {
        case UPDATE_PRODUCT_REQUEST:
            return {
                ...state,
                loading: true,

            }
        case UPDATE_PRODUCT_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                isUpdated: action.payload,

            }
        case UPDATE_PRODUCT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case UPDATE_PRODUCT_RESET:
            return {
                ...state,
                isUpdated: false,
            }
        case CLEAR_ERROR:
            return {
                ...state,
                error: null
            }
        default:
            return state;

    }


})