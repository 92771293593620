
// SAVE ADDRESS INFO
export const SAVE_ADDRESS_REQUEST='SAVE_ADDRESS_REQUEST';
export const SAVE_ADDRESS_SUCCESS='SAVE_ADDRESS_SUCCESS';
export const SAVE_ADDRESS_FAIL='SAVE_ADDRESS_FAIL';
export const SAVE_ADDRESS_RESET='SAVE_ADDRESS_RESET';

// GETTING SAVE INFO
export const GET_SAVE_INFO_REQUEST='GET_SAVE_INFO_REQUEST';
export const GET_SAVE_INFO_SUCCESS='GET_SAVE_INFO_SUCCESS';
export const GET_SAVE_INFO_FAIL='GET_SAVE_INFO_FAIL';


// GETTING SHIPPING INFO BY ADMIN 
export const GET_SINGEL_ADDRESS_BY_ADMIN='GET_SINGEL_ADDRESS_BY_ADMIN'
export const GET_SINGEL_ADDRESS_BY_SUCCESS='GET_SINGEL_ADDRESS_BY_SUCCESS'
export const GET_SINGEL_ADDRESS_BY_FAIL='GET_SINGEL_ADDRESS_BY_FAIL'


// GETTING ALL SAVE INFO BY ADMIN
export const GET_ALL_SAVE_INFO_REQUEST='GET_ALL_SAVE_INFO_REQUEST';
export const GET_ALL_SAVE_INFO_SUCCESS='GET_ALL_SAVE_INFO_SUCCESS';
export const GET_ALL_SAVE_INFO_FAIL='GET_ALL_SAVE_INFO_FAIL';


// DELETE SAVE ADDRESS BY ADMIN
export const ADDRESS_DELETE_REQUEST='ADDRESS_DELETE_REQUEST';
export const ADDRESS_DELETE_SUCCESS='ADDRESS_DELETE_SUCCESS';
export const ADDRESS_DELETE_FAIL='ADDRESS_DELETE_FAIL';
export const ADDRESS_DELETE_RESET='ADDRESS_DELETE_RESET';


// CLEAR ERROR 
export const CLEAR_ERROR='CLEAR_ERROR';

