import React, { Fragment, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import swal from 'sweetalert';
import { Button } from '@material-ui/core'
import Loader from '../layout/loader';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import SpellCheckIcon from '@material-ui/icons/Spellcheck'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import PercentIcon from '@mui/icons-material/Percent';
import Sidebar from './Sidebar'
import { clearErrorAction, singelCoupanAction, editCoupanAction } from '../redux/actions/coupanAction'



function EditCoupanByAdmin(props) {
    const dispatch = useDispatch();
    const history = useHistory();

    const { error, loading, isCoupanEdit } = useSelector(state => state.coupanUpdate)
    const {loading:coupanLoading, error: coupanError, coupan } = useSelector(state => state.coupan)
    
    const [coupanCode, setCoupanCode] = useState('');
    const [coupanValue, setCoupanValue] = useState('');
    const [coupanMinValue, setCoupanMinValue] = useState('');
    const [isPercent, setIsPercent] = useState('');
    const [isActive, setIsActive] = useState('');


    const precent = [
        'true',
        'false'
    ]


    const active = [
        'true',
        'false'
    ]



    const coupanId = props.match.params.id;

    
    useEffect(() => {
        if (coupan && coupan._id !== coupanId) {
            dispatch(singelCoupanAction(coupanId))
        }else{
            
            setCoupanCode(coupan.couponCode);
            setCoupanValue(coupan.couponValue);
            setCoupanMinValue(coupan.couponMinValue);
            setIsPercent(coupan.isCouponTypePercent);
            setIsActive(coupan.isActive);

        }
       

        if (error) {
            swal({
                title: "Validation",
                text: error,
                icon: "error",
              })
            dispatch(clearErrorAction())
        }
        if (coupanError) {
            swal({
                title: "Validation",
                text: coupanError,
                icon: "error",
              })
            dispatch(clearErrorAction())
        }
        if (isCoupanEdit) {
            swal({
                title: "Created.",
                text: isCoupanEdit,
                icon: "success",
              })
        }

    }, [ coupan, coupanError, coupanId, dispatch, error, history, isCoupanEdit])

    const updateCoupanHandler = (e) => {
        e.preventDefault()
      
        dispatch(editCoupanAction(coupanId,  coupanCode, coupanValue, coupanMinValue, isActive, isPercent));
    }

    return (
        <Fragment>
            {coupanLoading ? <Loader /> : (<Fragment>
                <div className="dashboard-container">
                    <Sidebar />
                    {loading ? <Loader /> : (<Fragment>
                        <div className="newProductContainer">
                            <form className='createProductForm'
                                onSubmit={updateCoupanHandler}  >
                                <h1>Coupan Update</h1>
                                <div>
                                    <SpellCheckIcon />
                                    <input
                                        type='text'
                                        placeholder='Coupan-code'
                                        required
                                        value={coupanCode}
                                        onChange={(e) => setCoupanCode(e.target.value)}
                                    />
                                </div>
                                <div>
                                    <LocalOfferIcon />
                                    <input
                                        type='number'
                                        placeholder='Coupan-Value'
                                        required
                                        value={coupanValue}
                                        onChange={(e) => setCoupanValue(e.target.value)}
                                    />
                                </div>

                                <div>
                                    <AttachMoneyIcon />
                                    <input
                                        type='number'
                                        placeholder='Coupan-min-value'
                                        required
                                        value={coupanMinValue}
                                        onChange={(e) => setCoupanMinValue(e.target.value)}
                                    />
                                </div>

                                <div>
                                    <PercentIcon />

                                    <select onChange={(e) => setIsPercent(e.target.value)} required >
                                        <option >{isPercent}</option>
                                        {
                                            precent.map((items) => (
                                                <option key={items} value={items} > {items}  </option>
                                            ))
                                        }
                                    </select>
                                </div>

                                <div>
                                    <ToggleOnIcon />

                                    <select onChange={(e) => setIsActive(e.target.value)} required >
                                        <option >{isActive}</option>
                                        {
                                            active.map((items) => (
                                                <option key={items} value={items} > {items}  </option>
                                            ))
                                        }
                                    </select>
                                </div>

                                {/* button for submit */}
                                <Button
                                    id='createProductBtn'
                                    type='submit'
                                    disabled={loading ? true : false}

                                >
                                    Update
                                </Button>


                            </form>
                        </div>
                    </Fragment>)}
                </div>
            </Fragment>)}

        </Fragment>
    )
}

export default EditCoupanByAdmin