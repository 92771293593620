import React, { Fragment } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom';
import * as EmailValidator from 'email-validator';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { userSigninAction, clearErrorAction, userLoadingAction } from '../../redux/actions/userAction'
import swal from 'sweetalert';
import MetaTag from '../../layout/MetaTag'
import { useAlert } from 'react-alert'
import { Helmet } from 'react-helmet'
import axios from 'axios'
// from components and css file 

import "./signin.css";
import Navbar from '../HomeScreen/Navbar'
import Footer from '../HomeScreen/bottomsection/Footer'

import Loader from '../../layout/loader';
import otpImage from '../../images/undraw_Confirmed_re_sef7.png'


// icons
import PersonIcon from '@mui/icons-material/Person';
import BadgeIcon from '@mui/icons-material/Badge';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LockIcon from '@mui/icons-material/Lock';
import KeyIcon from '@mui/icons-material/Key';




// form useState hooks 

function Signinpage() {
  // location
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const alert = useAlert();

  const { error, loading, isAuthenticated } = useSelector((state) => state.user)

  const [firstName, setfirstName] = useState('')
  const [lastName, setlastName] = useState('')
  const [email, setemail] = useState('')
  const [number, setnumber] = useState()
  const [password, setpassword] = useState('')
  const [confirmPassword, setconfirmPassword] = useState('')
  const [gender, setGender] = useState("male")
  const [isPending, setIsPending] = useState()
  const [otp, setOtp] = useState('')
  const [terms, setTerms] = useState(true)
  const [offers, setOffers] = useState(true)



  const verficationSubmitHandler = (e) => {

    e.preventDefault()
    if (!firstName || !lastName || !email || !number || !password || !confirmPassword || !gender) {
      return swal({
        title: "Validation Error",
        text: "please enter your information",
        icon: "info",
      })
    }

    if (offers === false) {
      return swal({
        title: "Validation Error",
        text: "accept all terms & conditons to procced",
        icon: "info",
      })
    }

    if (terms === false) {
      return swal({
        title: "Validation Error",
        text: "accept all terms & conditons to procced",
        icon: "info",
      })
    }

    var userValidation = EmailValidator.validate(email).valueOf()
    if (!userValidation === true) {
      return swal({
        title: "Validation Error",
        text: "invaild email ..!!",
        icon: "info",
      })
    }
    if (number.lenght < 10 || number.lenght > 10) {
      return swal({
        title: "Validation Error",
        text: "invaild number..!!",
        icon: "info",
      })
    }
    if (password !== confirmPassword) {
      return swal({
        title: "Validation Error",
        text: "Password and Confirm Password Should be same..!!",
        icon: "info",
      })
    } else if (password.length < 6) {
      return swal({
        title: "Validation Error",
        text: "Password Should be atleat 6 character..!!",
        icon: "info",
      })
    }
    alert.info('Please wait for second..!!')

    axios.post(`/api/verfiy-number`, {
      number: number,
    }).then(function (response) {
      setIsPending(response.data.smsResult.status)
      if (response.data.smsResult.status === 'pending') {
        swal({
          title: "Verfication Code",
          text: "Verfication Code Sent Successfully to Your Number..!!",
          icon: "success",
        })

      }
    }).catch(function (error) {
      swal({
        title: "Internal Error",
        text: error,
        icon: "error",
      })
    });

  }

  // RESEND CLICK FUNCTIONS 

  const repeatVerficationSubmitHandler = (e) => {
    e.preventDefault()
    alert.info('Verfication code re-sending...!')
    axios.post(`/api/verfiy-number`, {
      number: number,
    }).then(function (response) {
      setIsPending(response.data.smsResult.status)
      if (response.data.smsResult.status === 'pending') {
        swal({
          title: "Verfication Code",
          text: "Verfication Code re-sent Successfully to your Number..!!",
          icon: "success",
        })
      }
    }).catch(function (error) {
      swal({
        title: "Internal Error",
        text: error,
        icon: "error",
      })
    });


  }

  const submitHandlerForSign = async (e) => {
    e.preventDefault()
    alert.info("Verfing your otp..!")
    axios.post(`/api/verfiy-otp`, {
      number: number,
      otp: otp
    }).then(function (response) {
      if (response.data.smsResult.valid === true) {
        swal({
          title: "OTP Verfication",
          text: "verified successfully",
          icon: "success",
        })
        dispatch(userSigninAction(firstName,
          lastName,
          email,
          number,
          password,
          confirmPassword,
          gender))

      } else if (response.data.smsResult.valid === false) {
        swal({
          title: "OTP Verfication",
          text: "invaild otp !!",
          icon: "error",
        })
      }

    })
      .catch(function (error) {
        swal({
          title: "Internal Error",
          text: error,
          icon: "error",
        })
      });


  }
  const policyAgreeHandler = (e) => {
    e.preventDefault()
    setTerms(true)
  }

  const offerAgreeHandler = (e) => {
    e.preventDefault()
    setOffers(true)
  }


  useEffect(() => {
    if (error) {
      alert.error(error)
      dispatch(clearErrorAction)
    }
    if (isAuthenticated) {
      history.push('/userprofile')
      dispatch(userLoadingAction())
    }

  }, [alert, dispatch, error, history, isAuthenticated, location.pathname])

  return (
    <Fragment>
      <Helmet  >
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=2.0" />
        <meta name="description" content={`sign up || create account with us  to IMONLINE.SHOP , TO stay in touch with us || sign in  page || im sign in  page || signin  || im signup || signin im ||`} />
        <meta name="keywords" content={` Shoes , sign in  page, imonline.shop , sign in page || imonline.shop , signin page || sign in  page  || create with us. imonline.shop , signin page`} />
        <meta name="robots" content="index, follow " />
        <meta property="og:title" content={`signin to stay in touch with us . IMONLINE.SHOP , signin  page || create account with us  `} />
        <meta property="og:description" content={`Find the Shoes at IMONLINE.SHOP.  Free delivery and returns on select orders  , signin  page || create account || signin in || sign || log in im `} />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en-GB_IN" />
        <meta property="og:site_name" content="IMONLINE.SHOP" />
      </Helmet>
      <MetaTag title={'Welcome to IM Online Shopping SignIn Page, Create a Account! to connect with us.'} />
      {loading ? <Loader /> : (<Fragment>

        {isPending === 'pending' ?
          <div className="signup-div">
            <div className="img-authentication">
              <img src={otpImage} alt="www.imonline.shop" />
            </div>
            <div className="login-authentication-para">
              <p>Enter the OTP to verfiy your moblie number.</p>
              <p className='resend-otp'> Did'nt you receive OTP ? <span onClick={repeatVerficationSubmitHandler}>Resend OTP</span> </p>
            </div>
            <form method="Post" onSubmit={submitHandlerForSign} >
              <div className="Firstname">
                <input type="text" placeholder="confirm-OTP" className="firstname-field" value={otp} onChange={(e) => setOtp(e.target.value)} required />
                <button className="sumbit-btn-form"> verfiy </button>
              </div>
            </form>
          </div>
          : (<Fragment>
            <Navbar />
            <div className="new2">
              <div className="right-side-form-for-account">
                <div className="right-side-form-heading">
                  <h4>WELCOME T0 IM Family</h4>
                </div>

                <div className="sign-in-form-post-div">

                  <form method='POST' onSubmit={verficationSubmitHandler} className='form-container-of-sign-up'>

                    <div className="input-right-side">
                      <PersonIcon />
                      <input type="text" placeholder='Firstname' className='first-name-input' value={firstName} onChange={(e) => setfirstName(e.target.value)} required />

                    </div>
                    <div className="input-right-side">
                      <BadgeIcon />
                      <input type="text" placeholder='Lastname' className='first-name-input' value={lastName} onChange={(e) => setlastName(e.target.value)} required />

                    </div>
                    <div className="input-right-side">
                      <EmailIcon />
                      <input type="email" placeholder='Email' className='first-name-input' value={email} onChange={(e) => setemail(e.target.value)} required />
                    </div>
                    <div className="input-right-side">
                      <LocalPhoneIcon />
                      <input type="number" placeholder='Moblie no.' className='first-name-input' minLength={10} maxLength={10} value={number} onChange={(e) => setnumber(e.target.value)} required />

                    </div>
                    <div className="input-right-side">
                      <KeyIcon />
                      <input type="password" placeholder='Password' className='first-name-input' value={password} onChange={(e) => setpassword(e.target.value)} required />
                      {/* <div className='password-detail-right-side'  > * at least 10 characters, including letters (a-z, A-Z), 1 uppercase and 1 numeric character * </div> */}
                    </div>
                    <div className="input-right-side">
                      <LockIcon />
                      <input type="password" placeholder='Conf-Password' className='first-name-input' value={confirmPassword} onChange={(e) => setconfirmPassword(e.target.value)} required />

                    </div>
                    <div className="signin-input-right-side">
                      <label >Male </label>
                      <input type="radio" defaultChecked={true} onChange={(e) => setGender(e.target.value)} name="gender" value="male" />
                      <label >Female </label>
                      <input type="radio" onChange={(e) => setGender(e.target.value)} name="gender" value="female" />
                      <label >Others </label>
                      <input type="radio" onChange={(e) => setGender(e.target.value)} name="gender" value="others" />

                    </div>
                    <div className="checkbox-div-right-side">
                      <input defaultChecked={true} type="checkbox" readOnly required onChange={policyAgreeHandler} />
                      <span className="checkbox-div-right-side-span-div" >I agree to Im Online Shopping Co.<Link to='/terms-and-conditions'> <span> Terms of Use</span> </Link>  and <Link to='/Cookies-Policy.
                    '><span>Privacy Policy</span> </Link>  </span>

                      <div className="offer-div">
                        <input defaultChecked={true} type="checkbox" required readOnly onChange={offerAgreeHandler} />
                        <span className="checkbox-div-right-side-span-div">I want to receive special offers and latest updates and products  </span>
                      </div>
                    </div>

                    <div className="button-right-side-signup">
                      <button > sign up </button>
                    </div>
                    <div className="google-policy">
                      <span className='google-policy-protected-page'>This page is protected by reCAPTCHA, and subject to the<a href='https://policies.google.com/privacy'> <span> Google Privacy Policy</span>  </a>  and <a href='https://policies.google.com/terms'><span>Terms of Service</span> </a>  </span>
                    </div>

                    <div className="allready-account">
                      <h2> Already have an account ?<Link to="./Login"  > Login    </Link></h2>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <Footer />
          </Fragment>)}
      </Fragment>)}
    </Fragment>
  )
}

export default Signinpage
