import React, { Fragment, useEffect } from 'react'
import { DataGrid } from '@material-ui/data-grid'
import { Link, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { clearErrorAction, adminProductRequestAction, productDeleteAction } from '../redux/actions/productAction'
import swal from 'sweetalert';
import { Button } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import Sidebar from './Sidebar'
import './allProductByAdmin.css'
import { DELETE_PRODUCT_RESET } from '../redux/constants/productConstants'
import Loader from '../layout/loader'




function AllProductsByAdmin() {
    const history = useHistory();
    const dispatch = useDispatch();


    const { loading, error, products } = useSelector((state) => state.products)

    //for delete product by admin

    const { loading: deleteLoading, error: deleteError, isDeleted } = useSelector((state) => state.deleteProduct)


    const deleteProductHandler = (id) => {
        dispatch(productDeleteAction(id))

    }

    // const productSelectedHandler = ({data}) => {
    //     console.log(data)
    //     console.log(isChecked)
    // }



    useEffect(() => {
        if (error) {
            swal({
                title: "Validation",
                text: error,
                icon: "error",
            })
            dispatch(clearErrorAction())

        }

        if (deleteError) {
            swal({
                title: "Validation",
                text: deleteError,
                icon: "error",
            })
            dispatch(clearErrorAction())

        }
        if (isDeleted) {
            swal({
                title: "Deleted.",
                text: isDeleted,
                icon: "success",
            })
            dispatch({ type: DELETE_PRODUCT_RESET })
        }

        dispatch(adminProductRequestAction())

    }, [deleteError, dispatch, error, history, isDeleted])





    const columns = [
        // { field: '', headerName: 'Select', type:'checkbox', minWidth: 200, flex: 0.5 },
        { field: 'id', headerName: 'Product ID', minWidth: 200, flex: 0.5 },
        {
            field: 'name',
            headerName: 'Name',
            minWidth: 300,
            flex: 1,
        },
        {
            field: 'stock',
            headerName: 'Stock',
            type: 'number',
            minWidth: 150,
            flex: 0.3,
        },
        {
            field: 'price',
            headerName: 'Price',
            type: 'number',
            minWidth: 250,
            flex: 0.5,
        },
        {
            field: 'action',
            headerName: 'Action',
            type: 'number',
            minWidth: 150,
            flex: 0.3,
            sortable: false,
            renderCell: (params) => {
                return (
                    <Fragment>
                        <Link to={`/Admin/dashboard/UpdateProduct/${params.getValue(params.id, 'id')}`}   >
                            <EditIcon />
                        </Link>

                        <Button onClick={() => deleteProductHandler(params.getValue(params.id, 'id'))}   >
                            <DeleteIcon />
                        </Button>

                        {/* <Button onClick={() => productSelectedHandler(params.getValue(params.id, 'id'))}   >   */}
                        {/* <button  >
                        <input type='checkbox' multiple={true} value={params.getValue(params.id, 'id')}  onChange={(e)=>setIsChecked(e.target.value)} />

                        </button> */}
              

                        {/* <DeleteIcon /> */}
                        {/* nClick={() => deleteProductHandler(params.getValue(params.id, 'id')) */}
                    </Fragment>
                )
            }
        },
    ]

    const rows = [];
    products && products.forEach(item => {
        rows.push({
            id: item._id,
            stock: item.productStock,
            price: item.price,
            name: item.name,
        })
    });


    return (
        <Fragment>
            {loading ? <Loader /> : (<Fragment>
                {deleteLoading ? <Loader /> : (<Fragment>
                    <div className="dashboard-container">
                        <Sidebar />
                        <div className="productListContainer">
                            <h1 className='productHeadingList'> ALL PRODUCT'S </h1>
                            <DataGrid

                                rows={rows}
                                columns={columns}
                                pageSize={20}
                                disableSelectionOnClick
                                className='productListTable'
                                autoHeight
                            />
                        </div>
                    </div>
                </Fragment>)}
            </Fragment>)}
        </Fragment>
    )
}

export default AllProductsByAdmin