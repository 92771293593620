import React, { Fragment, useEffect } from 'react'
import { DataGrid } from '@material-ui/data-grid'
import { Link, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import swal from 'sweetalert';
import { Button } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import Sidebar from './Sidebar'
import Loader from '../layout/loader'

import { getAllCoupanAction, deleteCoupanAction, clearErrorAction } from '../redux/actions/coupanAction'




function AllCoupan() {

  const history = useHistory();
  const dispatch = useDispatch();

  const { loadingDelete, error, isCoupanDelete, } = useSelector((state) => state.coupanDelete)

  //for delete product by admin
  const { loading, coupans, error: coupanError } = useSelector((state) => state.coupans)


  useEffect(() => {
    if (error) {
      swal({
        title: "Validation",
        text: error,
        icon: "error",
      })
      dispatch(clearErrorAction())
    }

    if (coupanError) {
      swal({
        title: "Validation",
        text: coupanError,
        icon: "error",
      })
      dispatch(clearErrorAction())
    }

    if (isCoupanDelete) {
      swal({
        title: "Deleted.",
        text: isCoupanDelete,
        icon: "success",
      })
    }


    dispatch(getAllCoupanAction())
  }, [ coupanError, dispatch, error, history, isCoupanDelete])

  const deleteCoupanHandler = (id) => {
    dispatch(deleteCoupanAction(id))
  }



  const columns = [
    { field: 'id', headerName: 'Coupan Id', minWidth: 170, flex: 0.7 },
    {
      field: 'couponCode',
      headerName: 'Coupan',
      minWidth: 150,
      flex: 0.5,
    },
    {
      field: 'couponValue',
      headerName: 'Coupan Value',
      type: 'number',
      minWidth: 150,
      flex: 0.5,
    },
    {
      field: 'couponMinValue',
      headerName: 'Min Value',
      type: 'number',
      minWidth: 150,
      flex: 0.5,
    },
    {
      field: 'action',
      headerName: 'Action',
      type: 'number',
      minWidth: 150,
      flex: 0.3,
      sortable: false,
      renderCell: (params) => {
        return (
          <Fragment>
            <Link to={`/Admin/dashboard/Edit-Coupan/${params.getValue(params.id, 'id')}`}   >
              <EditIcon />
            </Link>

            <Button onClick={() => deleteCoupanHandler(params.getValue(params.id, 'id'))}   >
              <DeleteIcon />
            </Button>
          </Fragment>
        )
      }
    },
  ]


  const rows = [];
  coupans && coupans.forEach(item => {
    rows.push({
      id: item._id,
      couponCode: item.couponCode,
      couponValue: item.couponValue,
      couponMinValue: item.couponMinValue
    })
  });


  return (
    <Fragment>
      {loadingDelete ? <Loader /> : (<Fragment>
        {loading ? <Loader /> : (<Fragment>

          <div className="dashboard-container">
            <Sidebar />
            <div className="productListContainer">
              <h1 className='productHeadingList'> ALL COUPAN'S </h1>
              <DataGrid

                rows={rows}
                columns={columns}
                pageSize={10}
                disableSelectionOnClick
                className='productListTable'
                autoHeight
              />
            </div>

          </div>
        </Fragment>)}
      </Fragment>)}

    </Fragment>
  )
}

export default AllCoupan