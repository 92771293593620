import React, { Fragment, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { SpeedDial, SpeedDialAction } from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";
import Backdrop from "@material-ui/core/Backdrop";
import DashboardIcon from "@material-ui/icons/Dashboard";
import PersonIcon from "@material-ui/icons/Person";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ListAltIcon from "@material-ui/icons/ListAlt";
import HomeIcon from "@mui/icons-material/Home";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import FavoriteIcon from "@mui/icons-material/Favorite";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import "./userDetail.css";
import { userLogoutAction } from "../../redux/actions/userAction";
import { getAllNotificationaActionByUser } from "../../redux/actions/notificationAction";
import LogoImg from "../../images/output-onlinepngtools.png";
// import swal from 'sweetalert';
import { useAlert } from "react-alert";
import { useState } from "react";
// import axios from 'axios'

function UserDetails({ user }) {
  const history = useHistory();
  const alert = useAlert();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [isAdmin, setIsAdmin] = useState("");
  const [notificationLenght, setNotificationLenght] = useState();

  const { notification } = useSelector((state) => state.userNotification);

   // wishlist items
   const { wishListProducts } = useSelector(state => state.wishListAll)


    // cart items 
  const { carts} = useSelector((state) => state.carts);

  useEffect(() => {
    // calling api for data
    fetch("/api/checking=true/admin/url")
      .then((results) => results.json())
      .then((data) => {
        const { key } = data;
        setIsAdmin(key);
      })
      .catch((err) => console.log(err));
    setNotificationLenght(notification.length);
    dispatch(getAllNotificationaActionByUser());
  }, [dispatch, notification]);

  const options = [
    { icon: <HomeIcon />, name: "Home", func: homeAccount },
    { icon: <PersonIcon />, name: "Profile", func: account },
    { icon: <FavoriteIcon />, name: `Wishlist (${wishListProducts && wishListProducts.length})`, func: favorite },
    { icon: <ListAltIcon />, name: "Orders", func: orders },
    {
      icon: <ShoppingCartIcon />,
      name: `Cart(${carts && carts.length})`,
      func: cartFuction,
    },
    { icon: <ExitToAppIcon />, name: "Logout", func: logoutUser },
  ];

  if (user.role === isAdmin) {
    options.unshift({
      icon: <DashboardIcon />,
      name: "Dashboard",
      func: dashboard,
    });
  }
  if (window.innerWidth < 600) {
    options.unshift({
      icon: <NotificationsActiveIcon />,
      name: `Alert(${notificationLenght})`,
      func: notificationFunc,
    });
  }

  function account() {
    history.push("/userprofile");
  }
  function logoutUser() {
    dispatch(userLogoutAction());
    alert.success("logout successfully!!");
    history.push("/Login");
  }
  function orders() {
    history.push("/orders/Details/me");
  }
  function dashboard() {
    history.push("/Admin/dashboard");
  }
  function favorite() {
    history.push("/user/wishlist");
  }
  function cartFuction() {
    history.push("/Cart");
  }
  function homeAccount() {
    history.push("/");
  }
  function notificationFunc() {
    history.push("/notification");
  }

  return (
    <Fragment>
      <Backdrop open={open} style={{ zIndex: "10" }} />
      <SpeedDial
        ariaLabel="SpeedDail tooltip example"
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        className="speedDailClass"
        icon={
          <img
            className="speedDailClassImage"
            src={LogoImg}
            alt="www.imonline.shop"
          />
        }
      >
        {options.map((item) => (
          <SpeedDialAction
            icon={item.icon}
            tooltipTitle={item.name}
            key={item.index}
            onClick={item.func}
            tooltipOpen={window.innerWidth < 600 ? true : false}
          />
        ))}
      </SpeedDial>
    </Fragment>
  );
}

export default UserDetails;
