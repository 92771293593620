import React, { Fragment } from 'react'

import Navbar from '../../HomeScreen/Navbar';

import Footer from '../../HomeScreen/bottomsection/Footer';
import MetaTag from '../../../layout/MetaTag';

import SideBarAccount from './SideBarAccount'
import {Helmet} from 'react-helmet'


function GiftAndCard() {
    return (
        <Fragment>
            <Helmet  >
                <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=2.0" />
                <meta name="description" content={`Gift and Cards || gift and special details offer with us  to IMONLINE.SHOP ,  What sizes do the gift boxes come in?  || How do I purchase a gift box?  || Can I return the gift box? || gifts || im gift and cards  || gifts and cards ||`} />
                <meta name="keywords" content={` gifts and cards || new offers and gifts  || gifts offer and cards || card || gifts page ||  imonline.shop , gifts || CARDS  page || HOW TO DO I PURCHASE gift and  page  || create with us. imonline.shop , signin page`} />
                <meta name="robots" content="index, follow " />
                <meta property="og:title" content={`Gifts and Cards page || vouchars || im gifts and vouchars . IMONLINE.SHOP , gifts  page || create account with us  `} />
                <meta property="og:description" content={`Gift and Cards || gift and special details offer with us  to IMONLINE.SHOP ,  What sizes do the gift boxes come in?  || How do I purchase a gift box?  || Can I return the gift box? || gifts || im gift and cards  || gifts and cards ||`} />
                <meta property="og:type" content="website" />
                {/* <meta property="og:url" content={items.imageurl}   key={items.public_id}/>
            <meta property="og:image" content={items.imageurl}  key={items.public_id}/> */}
                <meta property="og:locale" content="en-GB_IN" />
                <meta property="og:site_name" content="IMONLINE.SHOP" />
                {/* <meta property="og:price:currency" content="INR" />
            <meta property="og:price:amount" content={product.price} /> */}
            </Helmet>
            <MetaTag title={'IM Online Shopping Gift and Shopping Card Details'} />
            <Navbar />
            <div className="container">
                <div className="row">
                    <div className="col-md-3">
                        <SideBarAccount />
                    </div>
                    <div className="col-md-9">
                        <div className="right-side-paragraph">
                            <div className="h2-heading">
                                <h2> GIFT BOX </h2>
                            </div>
                            <div className="details-of-account-privileges">
                                <ul>
                                    What sizes do the gift boxes come in?
                                    <li>
                                        Gift boxes are available in sizes medium and large.
                                        Refer to our size guide for measurements.
                                    </li>
                                </ul>
                                <ul>
                                    How do I purchase a gift box?
                                    <li>
                                        Gift boxes are only available for purchase at checkout.
                                        You must checkout an item first to qualify for a gift box purchase.
                                    </li>
                                </ul>
                                <ul>
                                    Will my items be pre-packed inside the gift box?
                                    <li>
                                        Depending on how it is shipped, your items may not be pre-packed inside the gift box.
                                        Instead, the gift box may come flat-packed within your order.
                                    </li>
                                </ul>
                                <ul>
                                    Can I return the gift box?
                                    <li>
                                        Gift boxes are not eligible for return or exchange.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    )
}

export default GiftAndCard