import React, { Fragment, useEffect } from 'react'
// import Navbar from '../../components/HomeScreen/Navbar';
// import Footer from '../../components/HomeScreen/bottomsection/Footer';
import { Link } from 'react-router-dom'
import { DataGrid } from '@material-ui/data-grid'
import swal from 'sweetalert';
import { useSelector, useDispatch } from 'react-redux'
import { allReturnOrderByUserAction, clearErrorAction } from '../../redux/actions/orderAction';
import Loader from '../../layout/loader';
import MetaTag from '../../layout/MetaTag';
import LaunchIcon from '@mui/icons-material/Launch';
import EmptyReturnOrder from './EmptyRetrunOrder';
import Typography from '@material-ui/core/Typography'





function AllReturnsOrdersByUser() {

  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.user)
  const { error, loading, returnOrders } = useSelector((state) => state.allReturnOrderByUser)

  useEffect(() => {

    if (error) {
      swal({
        title: "Error",
        text: error,
        icon: 'error',
      });
      dispatch(clearErrorAction())
    }

    dispatch(allReturnOrderByUserAction())

  }, [dispatch, error])



  const columns = [
    { field: 'id', headerName: 'Return ID', minWidth: 200, flex: 0.5 },
    {
      field: 'returnStatus',
      headerName: 'Return Status',
      minWidth: 250,
      flex: 0.4,
    },
    {
      field: 'orderId',
      headerName: 'Order Id',
      type: 'number',
      minWidth: 300,
      flex: 0.3,
    },
    {
      field: 'userId',
      headerName: 'User Id',
      type: 'number',
      minWidth: 300,
      flex: 0.5,
    },
    {
      field: 'returnAccpet',
      headerName: 'Return Accpet',
      type: 'number',
      minWidth: 250,
      flex: 0.5,
    },
    {
      field: 'createAt',
      headerName: 'CreatedAt',
      type: 'number',
      minWidth: 250,
      flex: 0.5,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      minWidth: 150,
      type: "number",
      flex: 0.3,
      sortable: false,
      renderCell: (params) => {
        return (
          <Link to={`/order/${params.getValue(params.id, 'orderId')}`}  >

            <LaunchIcon />
          </Link>
        )
      }
    }

  ]

  const rows = [];
  returnOrders && returnOrders.forEach(item => {
    rows.push({
      id: item._id,
      returnStatus: item.returnInformation.status,
      orderId: item.orderId,
      userId: item.user,
      returnAccpet: (item.returnAccpetAt === '' ? (<Fragment> <p>Soon Will Update</p> </Fragment>) : (String(item.returnAccpetAt).substring(0, 10))),
      createAt: (item.returnInformation.createdAt).substring(0, 10),
    })
  });



  return (
    <Fragment>
      <MetaTag title={`IM Online Shopping All Returns Orders by ${user.firstName} Details Page `} />
      {loading ? <Loader /> : (<Fragment>
        {returnOrders.lenght === 0 ? (<Fragment>
          <EmptyReturnOrder />
        </Fragment>) : (<Fragment>
          <Fragment>
            {loading ? <Loader /> : (<Fragment>
              <div className="myOrdersPage">
                <DataGrid
                  rows={rows}
                  columns={columns}
                  pageSize={20}
                  disableSelectionOnClick
                  className='productListTable'
                  autoHeight
                />

                <Typography id='myOrdersHeading'>{user.firstName}'s Returned Items </Typography>
              </div>
            </Fragment>)}
          </Fragment>
        </Fragment>)}
      </Fragment>)}
    </Fragment>
  )
}

export default AllReturnsOrdersByUser