import React, { Fragment, useEffect } from 'react'
import { DataGrid } from '@material-ui/data-grid'
import { Link, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { clearErrorAction, adminAdsRequestAction } from '../redux/actions/productAdsActions';
import swal from 'sweetalert';
import EditIcon from '@material-ui/icons/Edit'
import Loader from '../layout/loader'
import Sidebar from './Sidebar'
import './allUserByAdmin.css'




function AllAdsByAdmin() {

  const history = useHistory();
  const dispatch = useDispatch();
  
  const { loading, error, productDetails } = useSelector((state) => state.getAllProductAds)


  useEffect(() => {
    if (error) {
      swal({
        title: "Validation",
        text: error,
        icon: "error",
      })
      dispatch(clearErrorAction())
    }



    dispatch(adminAdsRequestAction())

  }, [dispatch, error, history])



  const columns = [
    { field: 'id', headerName: 'User ID', minWidth: 200, flex: 0.5 },
    {
      field: 'name',
      headerName: 'Name',
      minWidth: 150,
      flex: 0.5,
    },
    {
      field: 'descriptions',
      headerName: 'Descriptions',
      minWidth: 300,
      flex: 0.3,
    },
    {
      field: 'tag',
      headerName: 'Tag',
      minWidth: 300,
      flex: 0.3,
    },
    {
      field: 'action',
      headerName: 'Action',
      type: 'number',
      minWidth: 150,
      flex: 0.3,
      sortable: false,
      renderCell: (params) => {
        return (
          <Fragment>
            <Link to={`/Admin/dashboard/Edit-ads/${params.getValue(params.id, 'id')}`}   >
              <EditIcon />
            </Link>
          </Fragment>
        )
      }
    },
  ]

  const rows = [];
  productDetails && productDetails.forEach(item => {
    rows.push({
      id: item._id,
      descriptions: item.descriptions,
      tag: item.tag,
      name: item.name,
    })
  });


  return (
    <Fragment>
      {loading ? <Loader /> : (
        <Fragment>
          <div className="dashboard-container">
            <Sidebar />
            <div className="productListContainer">
              <h1 className='productHeadingList'> ALL ADS </h1>
              <DataGrid

                rows={rows}
                columns={columns}
                pageSize={20}
                disableSelectionOnClick
                className='productListTable'
                autoHeight
              />
            </div>

          </div>
        </Fragment>
      )}

    </Fragment>
  )
}

export default AllAdsByAdmin