import React, { Fragment, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import Loader from '../../layout/loader'
import swal from 'sweetalert';
import { Typography } from '@material-ui/core'
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import DeleteIcon from '@mui/icons-material/Delete';
import CallMadeIcon from '@mui/icons-material/CallMade';
import emptyImage from '../../images/undraw_Empty_re_opql.png'
import { REMOVE_PRODUCT_REQUEST_RESET } from '../../redux/constants/wishListConstant';
import { clearErrorAction, allProductToWishListAction, removeProductToWishListAction } from '../../redux/actions/wishListAction'
import Navbar from '../HomeScreen/Navbar';
import Footer from '../HomeScreen/bottomsection/Footer'
import MetaTag from '../../layout/MetaTag';
import AuthenticatedPage from './AuthenticatedPage';
import { textRapForNameSearch } from '../../utils/textRapUtils'


import './wishList.css'
function WishListProduct() {
    const dispatch = useDispatch();
    // redux functions
    const { user, isAuthenticated } = useSelector(state => state.user)
    const { loading, wishListProducts } = useSelector(state => state.wishListAll)
    const { loading: removeLoading, error: removeError, isProductRemove } = useSelector(state => state.wishListRemove)


    useEffect(() => {

        if (removeError) {
            swal({
                title: "Validation",
                text: removeError,
                icon: "error",
            })
            dispatch(clearErrorAction())
        }

        if (isProductRemove) {
            swal({
                title: "Removed",
                text: 'Your favorite item has removed',
                icon: "success",
            })
            dispatch({ type: REMOVE_PRODUCT_REQUEST_RESET })
        }


        dispatch(allProductToWishListAction())
    }, [dispatch, isProductRemove, removeError])


    const wishListRemoveHandler = (id) => {

        dispatch(removeProductToWishListAction(id))

    }





    return (
        <Fragment>
            {isAuthenticated === true ? (<Fragment>
                <MetaTag title={`IM Online Shopping, Welcome ${user.firstName} to Your favourite Product list page`} />
                {loading ? <Loader /> : (<Fragment>
                    <Navbar />
                    <div className="wish-list-div">
                        <div className="wishlist-heading-div">
                            <div className="icons-div">
                                <FavoriteBorderIcon />
                            </div>
                            <div className="heading">
                                <h2>My wishlist</h2>
                            </div>
                        </div>
                    </div>
                    {wishListProducts && wishListProducts.length === 0 ? (<Fragment>
                        <div className="empty-image-div">
                            <img src={emptyImage} alt="www.imonline.shop" />
                            <div className="paragraph-of-empty-div">
                                <Typography> <p>Yet haven't Add Any Favorite Shoes !!</p></Typography>
                            </div>
                        </div>
                    </Fragment>) : (<Fragment>
                        {removeLoading ? <Loader /> : (<Fragment>

                            <div className="wishlist-product-div">
                                {wishListProducts && wishListProducts.map((product, index) => (
                                    //    console.log(product)
                                    <div className="wishlist-main-container">

                                        <div className="wishlist-image-section">
                                            <img src={product.image} alt={product.name} />
                                        </div>
                                        <div className="wishlist-details-section">
                                            <div className="wishlist-product-name">
                                                {textRapForNameSearch(product.name)}
                                            </div>
                                            <div className="wishlist-product-price">
                                                Price: {product.price} <span><CurrencyRupeeIcon /> </span> 
                                            </div>

                                            <div className="wishlist-btns-sections">
                                               <Link to={`/ProductDetail/${product.product}` }> <button  className="wishlist-product-view-btn">
                                                    <CallMadeIcon />
                                                </button> </Link>
                                                <button  onClick={()=>wishListRemoveHandler(product._id)}  className="wishlist-product-delete-btn">
                                                <DeleteIcon />
                                                </button>
                                            </div>
                                        </div>
                                    </div>




                                ))}
                            </div>


                        </Fragment>)}

                    </Fragment>)}
                    <Footer />
                </Fragment>)}
            </Fragment>) : (<Fragment>
                <Navbar />
                <AuthenticatedPage />
                <Footer />
            </Fragment>)}

        </Fragment>
    )
}

export default WishListProduct

