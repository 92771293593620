import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import SideBarAccount from './SideBarAccount'
import Navbar from '../../HomeScreen/Navbar';
import MetaTag from '../../../layout/MetaTag';
import Footer from '../../HomeScreen/bottomsection/Footer';
import { Helmet } from 'react-helmet'


function Promotion() {
    return (
        <Fragment>


            <Helmet  >
                <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=2.0" />
                <meta name="description" content={`  What promotions does IM run? ||  Can I use more than one promo code on my order? ||  My promo code is not working, what should I do?    ||     What are the terms and conditions for promotions? ||   What happens if the promotion I’m interested in has expired? || `} />
                <meta name="keywords" content={` ABOUT OUR PROMOTIONS ||   What promotions does IM run? ||  Can I use more than one promo code on my order? ||  My promo code is not working, what should I do?    ||     What are the terms and conditions for promotions? ||   What happens if the promotion I’m interested in has expired? || `} />
                <meta name="robots" content="index, follow " />
                <meta property="og:title" content={` ABOUT OUR PROMOTIONS || What promotions does IM run? ||  Can I use more than one promo code on my order? ||  My promo code is not working, what should I do?    ||     What are the terms and conditions for promotions? ||   What happens if the promotion I’m interested in has expired? || `} />
                <meta property="og:description" content={`  What promotions does IM run? ||  Can I use more than one promo code on my order? ||  My promo code is not working, what should I do?    ||     What are the terms and conditions for promotions? ||   What happens if the promotion I’m interested in has expired? || `} />
                <meta property="og:type" content="website" />
                {/* <meta property="og:url" content={items.imageurl}   key={items.public_id}/>
            <meta property="og:image" content={items.imageurl}  key={items.public_id}/> */}
                <meta property="og:locale" content="en-GB_IN" />
                <meta property="og:site_name" content="IMONLINE.SHOP" />
                {/* <meta property="og:price:currency" content="INR" />
            <meta property="og:price:amount" content={product.price} /> */}
            </Helmet>
            <Navbar />
            <MetaTag title={'IM Online Shopping Promotion and Coupan Realted Details Page'} />
            <div className="contianer">
                <div className="row">
                    <div className="col-md-3">
                        <SideBarAccount />
                    </div>
                    <div className="col-md-9">
                        <div className="right-side-paragraph">
                            <div className="h2-heading">
                                <h2> ABOUT OUR PROMOTIONS </h2>
                            </div>
                            <div className="details-of-account-privileges">
                                <ul>
                                    What promotions does IM run?
                                    <li>
                                        Special promotions run regularly <Link to={'/'} ><span>IMONLINE.SHOP</span> </Link>  as a limited time offer or on a while stocks last basis.
                                        We also issue special promo codes and vouchers which you can use for your future orders.
                                        However, no price adjustment in any form will be provided for previous orders
                                    </li>
                                </ul>
                                <ul>
                                    Can I use more than one promo code on my order?
                                    <li>
                                        Only one promo code can be used per order.
                                    </li>
                                </ul>
                                <ul>
                                    My promo code is not working, what should I do?
                                    <li>
                                        Please check that the promo code is still valid and not out of date.
                                        Then refer to the terms and conditions set out for that promotion.
                                        If you require further assistance, please contact us information@imonline.shop
                                        or on voice chat. Promo codes can’t be applied on sale item(s).
                                    </li>
                                </ul>
                                <ul>
                                    What are the terms and conditions for promotions?
                                    <li>
                                        Promotions are not exchangeable for cash, voucher or in any other form.
                                        Promotions are only valid at IM online stores unless otherwise stated.
                                        <Link to={'/'} ><span>IMONLINE.SHOP</span> </Link> reserves the rights to modify or terminate the promotion if, in any way, it becomes technically compromised.
                                        All terms and conditions are subject to change without prior notice.
                                        In the event of a dispute, <Link to={'/'} ><span>IMONLINE.SHOP</span> </Link> decision is final.
                                    </li>
                                </ul>
                                <ul>
                                    What happens if the promotion I’m interested in has expired?
                                    <li>
                                        All IM promotions are subject to our terms and conditions. You can’t use a promotion that has expired.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </Fragment>
    )
}

export default Promotion