import React, { Fragment, useState } from 'react'
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CloseIcon from '@mui/icons-material/Close';
import ReactStars from "react-rating-stars-component";
import Loader from '../../../../layout/loader'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { addEllipsis } from '../../../../utils/textRapUtils'
import swal from 'sweetalert';

import { directBuyAction } from '../../../../redux/actions/directBuyAction';

function TrendingHeadingProps({ products, loading }) {
    const dispatch = useDispatch();
    const history = useHistory();
    const bestSellerProduct = 'Trending'

    const { isAuthenticated } = useSelector(state => state.user)

    const [currentImage, setCurrentImage] = useState('')
    const [productId, setProductId] = useState('')

    const [buyNowButtonClick, setBuyNowButtonClick] = useState(false)
    const [seletedProductId, setSelectedProductId] = useState()
    const [selectBox, setSelectBox] = useState('')
    const [colour, setColour] = useState('')
    const [size, setSize] = useState('')



    const productHandler = (id) => {
        history.push(`/ProductDetail/${id}`);
    }



    const imageSelectHandler = (productUrl, productId) => {
        setCurrentImage(productUrl)
        setProductId(productId)
    }


    const buyNowHandler = (id) => {
        if (!id) {
            return swal({
                title: "Validation Info",
                text: 'Invalid..!!',
                icon: "info",
            })
        }

        setSelectedProductId(id)
        setBuyNowButtonClick(true)
    }


    // buy button handler

    const bestSellerBuyNowHanlder = (e) => {
        e.preventDefault();
        console.log('hiii')

        if (!size || !colour) {
            return swal({
                title: "Validation Error",
                text: 'Please Select Colour and Size..!!',
                icon: "info",
            })

        }

        if (!selectBox) {
            return swal({
                title: "Validation Error",
                text: 'Please Select Box !!',
                icon: "info",
            })
        }
        // by defalut it will be one
        const quantity = 1;

        dispatch(directBuyAction(seletedProductId, quantity, size, colour, selectBox));


        if (!isAuthenticated) {
            swal({
                title: "Validation Error",
                text: 'Please Login..!!',
                icon: "info",
            })
        }

        history.push('/Login?redirect=/Checkout/directbuy')
    }

    //    button close function

    const closeBestSellerBuyNowButton = () => {
        setBuyNowButtonClick(false)
        setSelectedProductId('')
    }





    return (
        <Fragment>
            {loading ? <Loader /> : (<Fragment>
                {products && products.map((product, index) => {
                    return product.tag === bestSellerProduct ? (
                        <div className="bestSeller-pro" key={index} >
                            <Link to={`/ProductDetail/${product._id}`}>   {currentImage === '' ? (<img src={product.mainImage[0].imageurl} onClick={() => productHandler(product._id)} alt={product.name} />) : (product._id === productId ? (<img src={currentImage} onClick={() => productHandler(product._id)} alt={product.name} />) : (<img src={product.mainImage[0].imageurl} onClick={() => productHandler(product._id)} alt={product.name} />))}</Link>

                            <div className='bestSeller-pro-slide-div'   >
                                {product.image.map((newImage, index) => (
                                    <img src={newImage.imageurl} onClick={() => imageSelectHandler(newImage.imageurl, product._id)} alt={product.name} />
                                ))}
                            </div>

                            <div className="bestSeller-descripation">
                                <h6>  <Link to={`/ProductDetail/${product._id}`}> {product.tag}  </Link>    </h6>
                                <h3 className='best-seller-product-name'>  <Link to={`/ProductDetail/${product._id}`}> {product.price} Rs  </Link>    </h3>
                                <div className="bestSeller-Star">
                                    <p> <ReactStars

                                        padding='5px'
                                        edit={false}
                                        color='gray'
                                        activeColor='#ffd700'
                                        size={window.innerWidth < 600 ? 20 : 30}
                                        value={product.ratings}
                                        isHalf={true} />             </p>
                                </div>
                                <h3 className='best-seller-product-name'>  <Link to={`/ProductDetail/${product._id}`}> {addEllipsis(product.name)}  </Link>    </h3>
                            </div>
                            <div className="best-seller-props-button-main-div">
                                <button onClick={() => buyNowHandler(product._id)}  > <ShoppingCartIcon /> </button>
                            </div>

                            {buyNowButtonClick !== false && seletedProductId === product._id ? (
                                <>
                                    <div className="buy-now-main-container">
                                        <div className="buy-now-second-conatiner-close-div">
                                            <button onClick={closeBestSellerBuyNowButton}> <CloseIcon /> </button>
                                        </div>
                                        <div className="buy-now-second-container">
                                            <div className="buy-now-third-container">
                                                <h2 className="heading-for-buy-now">
                                                    Select Options
                                                </h2>
                                            </div>
                                            <form onSubmit={bestSellerBuyNowHanlder}>
                                                { /**SIZE BUTTON */}
                                                <div className="buy-now-size-detail-div">
                                                    <span><label className="">Sizes</label>
                                                        <select onChange={(e) => setSize(e.target.value)}>
                                                            <option value=''>Select Size</option>
                                                            {product.sizeOfShoes && product.sizeOfShoes.map((sizes, index) => (
                                                                <option value={sizes} key={index} >{sizes}</option>
                                                            ))}
                                                        </select>
                                                    </span>
                                                </div>
                                                {/**COLOUR BUTTON */}
                                                <div className="buy-now-colour-detail-div" >
                                                    <span><label className="">Colours </label>
                                                        <select onChange={(e) => setColour(e.target.value)} >
                                                            <option value=''>Select Colour</option>
                                                            {product.colorOfShoes && product.colorOfShoes.map((catagery, index) => (
                                                                <option value={catagery} key={index} >{catagery}</option>
                                                            ))}
                                                        </select>
                                                    </span>

                                                </div>


                                                {/**Box BUTTON */}
                                                <div className="buy-now-colour-detail-div" >
                                                    <span><label className="">Box Type </label>
                                                        <select onChange={(e) => setSelectBox(e.target.value)} >
                                                            <option value=''>Select Packaging Type</option>
                                                            <option value='0'> Default-Packing</option>
                                                            <option value='50'>Simple-Box  50₹</option>
                                                            <option value='150' >Original-Box  150₹</option>
                                                        </select>
                                                    </span>
                                                </div>
                                                <div className="buy-now-container-btn-div">
                                                    <input type='submit' value="Buy Now" />
                                                </div>

                                            </form>
                                        </div>

                                    </div>
                                </>
                            ) : (null)}

                        </div>
                    ) : null;
                })}
            </Fragment>)}
        </Fragment>
    )
}

export default TrendingHeadingProps