import React, { useState } from 'react'
// import Sportspage from './Sportspage'
import { useSelector, useDispatch } from 'react-redux'
import { Fragment, useEffect } from 'react'
// import Loader from '../layout/loader';
import MetaTag from '../layout/MetaTag';
import swal from 'sweetalert';
import { productAction } from '../redux/actions/productAction'
import { Helmet } from 'react-helmet'
import { createSearchedKeywordAction } from '../redux/actions/searchAction'


import './sports.css'

// import filter box 
import FilterCard from '../layout/FilterCard'
import SearchIcon from '@mui/icons-material/Search';
// import pagination 
// import PaginationPage from '../layout/PaginationPage'

// import image for ads

import shinkuImage from '../images/shinku.png'
import SportsPagePagination from './SportsPagePagination';

function SportsShoesCard() {
   const dispatch = useDispatch();

   const { error, loading, products } = useSelector(state => state.products)

   const [price, setPrice] = useState([0, 50000])
   const [search, setSearch] = useState('')
   const [keyword, setKeyword] = useState('')
   const [tag, setTag] = useState('')
   const [category, setCategory] = useState('')

   const resultPerPages = 50



   useEffect(() => {

      if (error) {
         swal({
            title: "Validation",
            text: error,
            icon: "error",
         })
      }

      dispatch(productAction(keyword))

      if (keyword !== '') {
         dispatch(createSearchedKeywordAction(keyword))
      }

   }, [dispatch, error, keyword])



   // price filter function

   const priceHandler = (event, newPrice) => {
      setPrice(newPrice)


   }


   // search filter function
   const sumbitHandler = (e) => {
      e.preventDefault();
      setKeyword(search)


   }


   const productFilterHandler = (e) => {
      e.preventDefault();

      dispatch(productAction(keyword, category, tag, price))


   }


   return (
      <Fragment>
         <MetaTag title={`IM Online shopping all latest products details, page`} />

         <Helmet  >
            <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=2.0" />
            <meta name="description" content={`Find the ${keyword} Shoes at IMONLINE.SHOP.  Free delivery and returns on select orders`} />
            <meta name="keywords" content={`${keyword} Shoes , Product page, imonline.shop , product page `} />
            <meta name="robots" content="index, follow " />
            <meta property="og:title" content={`${keyword} Shoes. IMONLINE.SHOP , product page  `} />
            <meta property="og:description" content={`Find the ${keyword} Shoes at IMONLINE.SHOP.  Free delivery and returns on select orders  , product page `} />
            <meta property="og:type" content="website" />
            {/* <meta property="og:url" content={items.imageurl}   key={items.public_id}/>
            <meta property="og:image" content={items.imageurl}  key={items.public_id}/> */}
            <meta property="og:locale" content="en-GB_IN" />
            <meta property="og:site_name" content="IMONLINE.SHOP" />
            {/* <meta property="og:price:currency" content="INR" />
            <meta property="og:price:amount" content={product.price} /> */}
         </Helmet>
         <form onSubmit={sumbitHandler}>
            <div className="search-div">
               <div className="search-div-main-container-in-shoes-section">
                  
               <input className='search-input' placeholder='Type Shoes Name...' type="search" onChange={(e) => setSearch(e.target.value)} required />
               <button className='search-btn-input' type='submit'><SearchIcon /></button>
               </div>
            </div>
         </form>

         <SportsPagePagination products={products} resultPerPages={resultPerPages} loading={loading} />

         <form onSubmit={productFilterHandler}>

            <div className="filter-box">
               <div className="price-filter-div">
                  <div className="filter-heading">
                     Price Limit
                  </div>

                  <FilterCard price={price} priceHandler={priceHandler} />
               </div>
            </div>

            {/* <form onSubmit={filterSubmitHandler}> */}

            <div className="filter-select-box-div">
               <select onChange={(e) => setCategory(e.target.value)} required>
                  <option value=''>Choose Category</option>
                  <option value=''>All Categories</option>
                  <option value='Formal'>Formal</option>
                  <option value='Classic'>Classic</option>
                  <option value='Sports'>Sports</option>
                  <option value='Loafer'>Loafer</option>
                  <option value='High Neck'>High Neck</option>
                  <option value='Boat Shoes'>Boat Shoes</option>
                  <option value='Sleepers'>Sleepers</option>
               </select>
            </div>



            <div className="filter-sort-by-product-catogery-div">
               <select onChange={(e) => setTag(e.target.value)} required>
                  <option value=''>Sort by</option>
                  <option value=''>Sort All</option>
                  <option value='New Arrival'>New Arrival</option>
                  <option value='On Sale'>On Sale</option>
                  <option value='Best Seller'>Best Seller</option>
                  <option value='Trending'>Trending</option>
                  <option value='Featured'>Featured</option>
                  <option value='New Collection'>New Collection</option>
               </select>
            </div>
            <div className='fliter-submit-button-main-container'>
            <button type="submit" className='product-filter-button'> Filter  </button>
            </div>
     
         </form>

         <div className="ad-image">
            <img src={shinkuImage} alt="www.imonline.shop" />
         </div>


      </Fragment>
   )
}

export default SportsShoesCard
