import React, { Fragment, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import swal from 'sweetalert';
import {
    useParams
} from "react-router-dom";
import { Helmet } from 'react-helmet'

import { clearErrorAction, searchDataForFiltersAction } from '../../redux/actions/productAction'
import PagenationForProduct from './pagenationForProduct';
import Navbar from '../HomeScreen/Navbar'
import Footer from '../HomeScreen/bottomsection/Footer'

import NotFoundImage from '../../images/not-found.png'
import Loader from '../../layout/loader';
import MetaTag from '../../layout/MetaTag';

import './search.css'

function SearchDataAndFilters() {

    let { SearchData } = useParams();

    const dispatch = useDispatch();

    const { error, product, loading } = useSelector(state => state.searchData)



    const itemsPerPage = 20;

    useEffect(() => {
        if (error) {
            swal({
                title: "Error",
                text: error,
                icon: "error",
            })
            dispatch(clearErrorAction())
        }


        dispatch(searchDataForFiltersAction(SearchData))


    }, [SearchData, dispatch, error])


    return (
        <Fragment>
            <MetaTag title={'IM SHOPPING || SEARCH PAGE || Your Searched Result Page'} />

            <Helmet  >
                <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=2.0" />
                <meta name="description" content={`Find the ${SearchData} Shoes at IMONLINE.SHOP.  Free delivery and returns on select orders`} />
                <meta name="keywords" content={`${SearchData} Shoes , searched page, imonline.shop , searched page , searched page ,  `} />
                <meta name="robots" content="index, follow " />
                <meta property="og:title" content={`${SearchData} Shoes. IMONLINE.SHOP , searched page  `} />
                <meta property="og:description" content={`Find the ${SearchData} Shoes at IMONLINE.SHOP.  Free delivery and returns on select orders  , searched page `} />
                <meta property="og:type" content="website" />
                {/* <meta property="og:url" content={items.imageurl}   key={items.public_id}/>
            <meta property="og:image" content={items.imageurl}  key={items.public_id}/> */}
                <meta property="og:locale" content="en-GB_IN" />
                <meta property="og:site_name" content="IMONLINE.SHOP" />
                {/* <meta property="og:price:currency" content="INR" />
            <meta property="og:price:amount" content={product.price} /> */}
            </Helmet>

            <Navbar />
            {loading ? <Loader /> : (<Fragment>

                {product.length === 0 ? (<Fragment>
                    <div className="product-not-found-container">
                        <div className="image-div-of-not-found">
                            <img src={NotFoundImage} alt="www.imonline.shop" />
                        </div>
                        <p>!! Sorry Not Found Product Realted to this {SearchData} </p>
                    </div>
                </Fragment>) : (
                    <Fragment>

                        <div className="input-search-data-filters-container">
                            <h6 className="input-type-heading">
                                Searched Results
                            </h6>
                        </div>


                        <PagenationForProduct product={product} itemsPerPage={itemsPerPage} loading={loading} />
                    </Fragment>

                )}

            </Fragment>)}
            <Footer />
        </Fragment>
    )
}

export default SearchDataAndFilters