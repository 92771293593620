import React, { Fragment, useState, useEffect } from 'react'
import { Link, } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import swal from 'sweetalert';
// logo image
import Logo from '../../images/output-onlinepngtools-removebg-preview.png'
// icons
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import FavoriteIcon from '@mui/icons-material/Favorite';
import DensityMediumIcon from '@mui/icons-material/DensityMedium';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';

import {allcartItemAction} from '../../redux/actions/cartAction'
import { clearErrorAction, getAllNotificationaActionByUser, readNotificationaActionByUser } from '../../redux/actions/notificationAction'

// css file 
import "../../css/navbar.css";

function Navbar() {
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false)

  const [isClicked, setIsClicked] = useState(true)
  const [getNotification, setGetNotification] = useState()
  const [checkNotificationRead, setCheckNotificationRead] = useState(false)
  const { isAuthenticated } = useSelector((state) => state.user)

  const { notification, error } = useSelector((state) => state.userNotification)

  // cart items 
  const { carts } = useSelector((state) => state.carts);

  // console.log(carts)


  // wishlist items
  // const { wishListProducts } = useSelector(state => state.wishListAll)


  useEffect(() => {
    if (isAuthenticated === true) {
      notification.map((data) => (
        data.notifactionRead === true ? setCheckNotificationRead(true) : setCheckNotificationRead(false)
      ))
      dispatch(getAllNotificationaActionByUser())
      setGetNotification(notification.length)
    } else {
      return null;
    }

    if (error) {
      swal({
        title: "Notification Error",
        text: error,
        icon: "error",
      })
      dispatch(clearErrorAction())
    }

    dispatch(allcartItemAction())

  }, [dispatch, notification, isAuthenticated, error])


  const getClickHandler = (id) => {
    dispatch(readNotificationaActionByUser(id))
  }


  return (
    <Fragment>

      {isAuthenticated === false ? (
        <Fragment>
          <div className="navbar-heading">
            <div className="left-side-navbar-logo">
              <Link to="/">  <img src={Logo} alt="www.imonline.shop" /> </Link>
            </div>



            <ul className={isOpen ? 'main-menu-ul-moblie' : 'main-menu-ul'} onClick={() => setIsOpen(false)}>
              <Link to="/" ><li >  Home   </li></Link>
              <Link to="/Product" > <li className='shoes-icons-of-li-list'> Latest Sneakers </li></Link>
              <Link to="/find/shoes">  <li>  <SearchIcon />  </li></Link>

               <div className="wishlist-and-cart-container">
               <Link to="/user/wishlist"> <li>   <FavoriteIcon /> </li></Link>
               <Link to="/cart"> <li>  <ShoppingBagIcon />  </li></Link>
               </div>
            </ul>

            <button className='side-bar-div' onClick={() => setIsOpen(!isOpen)} >
              {isOpen ? <CloseIcon /> : <DensityMediumIcon />}
            </button>

          </div>
        </Fragment>
      ) : (
        <Fragment>
          <div className="navbar-heading">
            <div className="left-side-navbar-logo">
              <Link to="/">  <img src={Logo} alt="www.imonline.shop" /> </Link>
            </div>



            <ul className={isOpen ? 'main-menu-ul-moblie' : 'main-menu-ul'} onClick={() => setIsOpen(false)}>
              <Link to="/" ><li > Home  </li></Link>
              <Link to="/Product" > <li className='shoes-icons-of-li-list'> Latest Sneakers </li></Link>
              <Link to="/find/shoes">  <li>  <SearchIcon />  </li></Link>

                <Link to="/user/wishlist"> <li >   <FavoriteIcon />   </li></Link>
              <li className='notification-menu-li' >  <div className='notification-btn' onClick={() => setIsClicked(!isClicked)}>
                <div className='notification-icon-style'>
                  <NotificationsActiveIcon /> <p className={checkNotificationRead === true ? 'hide-notification' : 'show-notification'} >{getNotification}</p>
                </div>

              </div>

                {/* className='notification-menu-div' */}
                <div className={isClicked ? 'notification-div-none' : 'notification-menu-div'}>
                  <div>

                    {notification.length === 0 ? (
                      <Fragment>
                        <div className='empty-notification-div'>
                          <p className='empty-notification-para'>
                            Oohu !! Look Likes You Have'nt have Any Notification.
                          </p>
                        </div>
                      </Fragment>
                    ) : (
                      <Fragment>
                        {notification.map((data) => (
                          <div className={data.notifactionRead === true ? 'notification-message-read' : 'notification-message-unread'} onClick={() => getClickHandler(data._id)}    > {data.message}
                          </div>

                        ))}
                      </Fragment>
                    )
                    }

                  </div>
                </div>

              </li>

              {carts && carts.length !== 0 ? (
                <Link to="/cart"> <li className='nav-bar-cart-item-count-li'>  <ShoppingBagIcon /> <span>{carts && carts.length}</span>   </li> </Link>
              ) : (
                <Link to="/cart"> <li className='nav-bar-cart-item-count-li'>  <ShoppingBagIcon />   </li> </Link>
              )}

            </ul>

            <button className='side-bar-div' onClick={() => setIsOpen(!isOpen)} >
              {isOpen ? <CloseIcon /> : <DensityMediumIcon />}
            </button>



          </div>
        </Fragment>
      )}

    </Fragment>
  )
}

export default Navbar
