import React, { Fragment, useState, useEffect } from 'react';
import { useHistory,Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { orderCancelAction, clearErrorAction } from '../../redux/actions/orderAction';
import Loader from '../../layout/loader';
import swal from 'sweetalert';
import RecieptOrder from '../../images/undraw_Receipt_re_fre3.png';
import './delivery.css';
import { ORDER_CANCEL_REQUEST_RESET } from '../../redux/constants/orderConstants'
import MetaTag from '../../layout/MetaTag';
// orderCancel

function OrderCancelPage(props) {
    const history = useHistory();
    const dispatch = useDispatch();


    // redux functions
    const { user } = useSelector(state => state.user)

    // user redux functions
    const { loading, isCancelled, error } = useSelector(state => state.orderCancel)

    const [textAreaValue, setTextAreaValue] = useState('')
    const [isChacked, setIsChacked] = useState(false);
    const [accept, setAccept] = useState(false);

    const id = props.match.params.id;

    const cancelOrderHandler = (e) => {
        e.preventDefault();
        e.preventDefault();
        if (isChacked === false) {
            return swal({
                title: "Validation Error",
                text: 'please accpet all terms..!',
                icon: 'info',
            });
        }
        if (accept === false) {
            return swal({
                title: "Validation Error",
                text: 'please accpet all terms..!',
                icon: 'info',
            });
        }
    
        dispatch(orderCancelAction(user.firstName,user.email,user.number,id, textAreaValue))

    }

    useEffect(() => {
        if (error) {
            swal({
                title: "Error",
                text: error,
                icon: 'error',
            });
            dispatch(clearErrorAction())
        }

        if (isCancelled) {
            swal({
                title: "Success",
                text: isCancelled,
                icon: 'success',
            });
            history.push('/userprofile')
            dispatch({ type: ORDER_CANCEL_REQUEST_RESET })
        }


    }, [dispatch, error, history, isCancelled])

    const returnAgreeHandler = (e) => {
        e.preventDefault();
        setIsChacked(true)
    }

    const returnPolicyAgreeHandler = (e) => {
        e.preventDefault();
        setAccept(true)

    }
const cancelProcessHandler =(e)=>{
    e.preventDefault();
    setTextAreaValue('')
}

    return (
        <Fragment>
            <MetaTag  title={'IM Online Shopping Order Cancel Request Page'}   />
            {loading ? <Loader /> : (<Fragment>
                <div className="order-cancel-div">
                    <div className="order-cancel-img-div">
                        <img src={RecieptOrder} alt="www.imonline.shop" />
                    </div>
                    <div className="heading-for-cancel-order">
                        <h2 className="heading-cancel">
                            ORDER CANCEL REQUEST
                        </h2>
                        <p className="para-graph-cancel-order">
                            Provide cancellation information for this order.
                        </p>
                    </div>
                    <div className="order-cancel-form-div">
                        <form onSubmit={cancelOrderHandler}>
                        <div className="cheack-box-div">
                            <input type="checkbox" readOnly required={true} onChange={returnAgreeHandler} />Do you really want to cancel your order
                        </div>
                        <div className="cheack-box-div">
                            <input type="checkbox" readOnly required={true} onChange={returnPolicyAgreeHandler} /> <span>I agree to IM ONLINE SHOPPING <Link to='/terms-and-conditions'> <span> Terms of Use</span> </Link>  and <Link to='/about/return-and-exchange'><span>Return & Cancel Policy</span> </Link>  </span>
                        </div>
                            <div className="reason-to-cancel-order">
                                <textarea cols="40" rows="5" placeholder='please type your reason...' value={textAreaValue} onChange={(e) => setTextAreaValue(e.target.value)} required>
                                </textarea>
                            </div>
                            <div>
                                <button className="submit-order-cancel-button" type='submit'>
                                    submit
                                </button>
                                <button className="submit-order-cancel-button" onClick={cancelProcessHandler}>
                                    cancel 
                                </button>
                            </div>
                        </form>

                    </div>

                </div>
            </Fragment>)}
        </Fragment>
    )
}

export default OrderCancelPage