import React, { Fragment } from 'react'

import Navbar from '../../HomeScreen/Navbar';
import Footer from '../../HomeScreen/bottomsection/Footer';
import MetaTag from '../../../layout/MetaTag';
import { Helmet } from 'react-helmet'

import SideBarAccount from './SideBarAccount'


function ShippingTracking() {
    return (
        <Fragment>
            <Helmet  >
                <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=2.0" />
                <meta name="description" content={`   How do I make a return? || How do I return an order that was purchased online? ||   Our return policy is only applicable to any item(s)  purchased online and delivered in India, within 30 days of receiving your order.    ||     IMONLINE.SHOP (RETURNS DEPT)||   IM Group Headquarters  Chhanna Rd,Dehliz Kalan,Ahmedgarh  || Punjab 148021,INDIA.`} />
                <meta name="keywords" content={` SHIPPING || SHIPPING & TRACKING|| What are the available delivery options and rates? ||    We currently offer two delivery options in India, with order tracking services. ||   Delivery Destination: India  ||    DELIVERY OPTIONS  ||  STANDARD||EXPRESS || ₹ 150  EXCLUDES duties and taxes, with tracking ||  Orders will arrive within 8–10 working days* ||   Does my order come with a guaranteed delivery date?  ||     Deliveries are made Monday through Friday, except for Saturday, Sunday and Public Holidays.`} />
                <meta name="robots" content="index, follow " />
                <meta property="og:title" content={`    DUTIES & TAXES|| How do I track my order?||  I have not received my package, what should I do? ||  Can I change my shipping address after my order has been dispatched? ||   How is a failed delivery processed?   ||     My location is not on the list of delivery destinations, can I still place my order? ||  How do I make sure I have selected the correct site to shop on? ||  Are there any restricted areas you do not ship to?  `} />
                <meta property="og:description" content={`      Do I have to pay duties and taxes? ||     Can I request for a tax refund? ||   `} />
                <meta property="og:type" content="website" />
                {/* <meta property="og:url" content={items.imageurl}   key={items.public_id}/>
            <meta property="og:image" content={items.imageurl}  key={items.public_id}/> */}
                <meta property="og:locale" content="en-GB_IN" />
                <meta property="og:site_name" content="IMONLINE.SHOP" />
                {/* <meta property="og:price:currency" content="INR" />
            <meta property="og:price:amount" content={product.price} /> */}
            </Helmet>

            <MetaTag title={'IM Online Shopping Shipping and Tracking Safety Realted Details Page'} />
            <Navbar />
            <div className="container">
                <div className="row">
                    <div className="col-md-3">
                        <SideBarAccount />
                    </div>
                    <div className="col-md-9">
                        <div className="right-side-paragraph">
                            <div className="h2-heading">
                                <h2> SHIPPING & TRACKING </h2>
                            </div>
                            <div className="details-of-account-privileges">
                                <h6>
                                    SHIPPING
                                </h6>
                                <ul>

                                    What are the available delivery options and rates?
                                    <li>
                                        <h6>BUSINESS AS USUAL AT IMONLINE.SHOP</h6>
                                    </li>
                                    <li>
                                        <p>
                                            Given the current COVID-19 situation, we would like to reassure you that imonline.shop
                                            is operating as per normal. We are still dispatching orders from our warehouse and working
                                            hard to make deliveries as safe as possible.
                                            However, you may experience order delays in the event that our
                                            couriers are unable to make deliveries. Use the tracking link sent to you after order
                                            confirmation to get real-time updates from our couriers directly.
                                            Should you have any questions, please contact our Customer
                                            Service team during our opening hours.
                                        </p>
                                    </li>
                                    <li>
                                        We currently offer two delivery options in India, with order tracking services.
                                    </li>
                                    <li>
                                        Delivery Destination: India
                                    </li>

                                    <li>
                                        <table className='shipping-order-details-servive'>
                                            <tr className='shipping-order-details-servive-row-of-table' >
                                                <td>DELIVERY OPTIONS</td>
                                                <td>RATES</td>
                                                <td>DELIVERY TIME</td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    STANDARD
                                                </td>
                                                <td>
                                                    ₹ 150
                                                    Free delivery orders above ₹ 2,000.01
                                                    EXCLUDES duties and taxes, with tracking
                                                </td>
                                                <td>
                                                    Orders will arrive within 8–10 working days*
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>EXPRESS</td>
                                                <td>
                                                    ₹ 200
                                                    (Includes duties and taxes, with tracking)
                                                </td>
                                                <td>
                                                    Orders will arrive within 2–4 working days*.
                                                </td>
                                            </tr>

                                        </table>
                                    </li>
                                    <li>
                                        *Working days refer to Mondays to Fridays, not including Saturdays, Sundays, and Public Holidays in India.
                                        Orders placed and confirmed by 0900 hours (GMT+8) will be processed on the same day,
                                        while orders placed and confirmed after 0900 (GMT+8) on Friday, Saturday or Sunday will be processed on the next working day.

                                    </li>

                                    <li>
                                        <ul style={{ backgroundColor: 'pink', padding: '30px', margin: '15px', listStyle: 'disc' }}>
                                            <h6>IMPORTANT:</h6>
                                            <li>
                                                Please note that the <b> Standard Delivery option does not include duties and taxes.</b>
                                                All items entering the state may be subject to customs inspection,
                                                and duties and taxes, in accordance with local regulations.
                                                As such, <b> these additional duties and taxes will be borne by you.</b>
                                            </li>
                                            <li>
                                                Customers who opt for Standard Delivery will receive an email from our customer service team
                                                requiring an acknowledgment that they agree to pay duties and taxes on their order.
                                                Please reply to the email within 3 days.
                                                If you fail to do so or disagree to paying duties and taxes, the order will be cancelled.
                                            </li>
                                            <li>
                                                <b>  Duties and taxes on all Express Delivery orders will be paid by IMONLINE.SHOP.</b>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                                <ul>
                                    Does my order come with a guaranteed delivery date?
                                    <li>
                                        Deliveries are made Monday through Friday, except for Saturday, Sunday and Public Holidays.
                                    </li>
                                    <li>
                                        IMONLINE.SHOP is committed to delivering your order at the soonest
                                        in compliance with the rules and regulations
                                        of the authorities at the delivery destination. Orders containing
                                        restricted materials might be denied delivery
                                        due to a location's regulatory restrictions.
                                        Please check with your local authorities on the relevant
                                        import regulations before placing an order.
                                    </li>
                                    <li>
                                        Please note that the expected delivery date/time is at our best approximation and might be affected by conditions
                                        such as shipping restrictions, payment authorisation, online security checks and stock availability.
                                    </li>
                                    <li>
                                        Please check with your local authorities on the relevant import regulations before placing an order.
                                        We shall not be held responsible for any delays in orders
                                        delivery due to actions by the authorities at the delivery destination.
                                    </li>
                                    <li>
                                        Online Security Check is a preventive measure against credit card fraud transactions.
                                        It aims to protect our customers from unauthorized and fraudulent dealings. We might request
                                        for additional information and documents for payment verification.
                                        In such situations, your order(s) might be delayed.
                                        We seek your kind understanding in the delay of the shipment as a result of online security checks.
                                    </li>
                                </ul>
                                <ul>
                                    How can I track my order status?
                                    <li>
                                        Once you’ve signed into your account, you can view your order status
                                        in My Purchases & Returns under My Account.
                                        Your order status is reflected next to your order number
                                    </li>
                                    <li>
                                        If you opt for guest checkout, you will still be able to view your order status.
                                    </li>
                                    <li>
                                        For further assistance, please contact us at information@imonline.shop.
                                    </li>
                                    <li>
                                        <table>
                                            <tr>
                                                <td>
                                                    STATUS
                                                </td>
                                                <td>
                                                    DESCRIPTION
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    In Progress
                                                </td>
                                                <td>
                                                    Your order is currently being processed.
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Shipped
                                                </td>
                                                <td>
                                                    Your order has been sent to the designated courier company.
                                                    You will receive a notification email with a tracking number.
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Cancelled
                                                </td>
                                                <td>
                                                    Your order has been cancelled.
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Complete
                                                </td>
                                                <td>
                                                    Your order has been successfully received.
                                                </td>
                                            </tr>
                                        </table>
                                    </li>
                                </ul>
                                <ul>
                                    How do I track my order?
                                    <li>
                                        To track your order, sign in to your account and view My Purchases & Returns under My Account.
                                        Locate the Track Your Order button that corresponds to your order number,
                                        and you will be navigated to the online tracking system of the designated courier company.
                                    </li>
                                    <li>
                                        If you have opted for guest checkout, you will still receive a
                                        tracking number in your shipment confirmation email.
                                        Please contact us at information@imonline.shop or on live chat for further assistance.
                                    </li>

                                </ul>
                                <ul>
                                    I have not received my package, what should I do?
                                    <li>
                                        If you did not receive your package by the expected delivery date,
                                        please check on the shipment by tracking your order.
                                        To track your order, sign in to your account and view My Purchases & Returns under My Account.
                                        Locate the Track Your Order button that corresponds to your order number,
                                        and you will be navigated to the online tracking system of the designated courier company.
                                    </li>
                                    <li>
                                        If you have opted for guest checkout, you will still receive a tracking number in your shipment confirmation email.
                                    </li>
                                    <li>
                                        For further assistance, please contact us at information@imonline.shop or on voice chat.
                                    </li>
                                </ul>
                                <ul>
                                    Can I change my shipping address after my order has been dispatched?
                                    <li>
                                        Unfortunately, we are unable to redirect orders once your items have been shipped.
                                        If the address provided is incorrect,
                                        the package will be returned to us by the delivery agent or the unintended recipient.
                                    </li>
                                    <li>
                                        Please ensure you provide the correct shipping address.
                                        Check your address carefully when placing an order and ensure you choose
                                        the right location in the shipping address field (e.g. village name and City name).
                                        To remove an outdated or incorrect address from your account,
                                        sign in to your account and edit/update your address in your Address Book.
                                    </li>
                                    <li>
                                        If no one is available at the delivery address to receive the package,
                                        the delivery agent will leave an “unable to deliver” note at the location and it’s up
                                        to you to follow up and arrange for another delivery date.
                                        Please note that any additional shipping charges for attempted
                                        delivery and returning shipping charges will be borne by you.

                                    </li>
                                </ul>
                                <ul>
                                    How is a failed delivery processed?
                                    <li>
                                        Most of our delivery agents make two attempts to deliver a parcel and they may require a signature upon delivery of parcel.
                                        It is generally up to the delivery agent’s discretion to determine whether a signature is required.
                                        If the first round of delivery is unsuccessful, the agent will contact the recipient using the given
                                        contact number to rearrange for another delivery date.
                                        If the recipient fails to receive the package after the two delivery attempts,
                                        the package will be returned to IM.
                                    </li>
                                </ul>
                                <ul>
                                    My location is not on the list of delivery destinations, can I still place my order?
                                    <li>
                                        Unfortunately, we do not deliver to locations that are not on the list above.
                                    </li>
                                </ul>
                                <ul>
                                    How do I make sure I have selected the correct site to shop on?
                                    <li>
                                        Please select your delivery destination from our site selector tool located at the top left hand corner on our landing page.
                                        A location with a shopping bag icon indicates that online shopping is available there.
                                    </li>

                                </ul>
                                <ul>
                                    Are there any restricted areas you do not ship to?
                                    <li>
                                        We accept deliveries to hotels. You will have to provide the room number and local
                                        contact information. Deliveries will only be made to the Hotel Reception, and you
                                        are required to inform the reception of a pending delivery. Once the order has been
                                        delivered to the reception it will be considered as delivered. IMONLINE.SHOP
                                        shall not be held responsible for any lost orders after goods have been accepted by reception.
                                    </li>
                                    <li>
                                        We are unable to ship to any PO Box, military or protected areas.
                                        Delivery charges for failed attempted delivery to such restricted areas will be borne by you.
                                    </li>
                                </ul>
                                <h6>
                                    DUTIES & TAXES
                                </h6>
                                <ul>
                                    Do I have to pay duties and taxes?
                                    <li>
                                        Please check with your local authorities on your tax responsibilities and rights.
                                    </li>
                                    <li>
                                        We will pay “Additional Charges” for all Express Delivery orders shipped to India.
                                        These “Additional Charges” include, but are not limited to, handling charges imposed by the carrier;
                                        customs handling charges; Goods and Services Tax; and Value-added Tax.
                                    </li>
                                    <li>
                                        Please note that shipping and handling charges do not account for all “Additional Charges”.
                                    </li>
                                    <li>
                                        For Standard Delivery orders, in the event that “Additional Charges” are incurred during shipment and/or
                                        if the assigned delivery agent pays said “Additional Charges” on your behalf, you shall be liable to pay
                                        the tax and/or duties amount to the delivery agent upon receiving the shipment. Any “Additional Charges”
                                        charged or collected by external parties cannot be refunded in any form by us.

                                    </li>
                                    <li>
                                        For Standard Delivery orders, in the event that “Additional Charges” are incurred,
                                        if you refuse to accept delivery of the shipment, you shall be responsible for payment of all return charges,
                                        including any return taxes and/or duties.
                                        These charges will be deducted from the initial payment before a refund is issued.
                                    </li>

                                    <li>
                                        If you are making a return, you shall be responsible for all taxes and/or duties imposed by the customs authorities.
                                        If the returned item(s) is not in its original condition,
                                        we reserve all rights to return the item to you at your cost.
                                    </li>
                                </ul>
                                <ul>
                                    Can I request for a tax refund?
                                    <li>
                                        We do not accept tax refunds for purchases made at IMONLINE.SHOP.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <Footer />
        </Fragment>
    )
}

export default ShippingTracking