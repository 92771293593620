import React, { Fragment, useState, useEffect } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import swal from "sweetalert";
import Loader from "../layout/loader";
import {
  clearErrorAction,
  sendNotificationaActionByUser,
} from "../redux/actions/notificationAction";
import { NOTIFICATION_SEND_RESET } from "../redux/constants/notificationConstants";
import { useDispatch, useSelector } from "react-redux";

import "./createNotification.css";

function CreateNotificationByAdmin() {
  const dispatch = useDispatch();

  const { loading, error, success } = useSelector(
    (state) => state.sendNotification
  );

  const [getUserId, setGetUserId] = useState("");
  const [getMessage, setGetMessage] = useState("");

  useEffect(() => {
    if (error) {
      swal({
        title: "Notification Error",
        text: error,
        icon: "error",
      });
      dispatch(clearErrorAction());
    }
    if (success) {
      swal({
        title: "Notification Sent.",
        text: success,
        icon: "success",
      });
      dispatch({ type: NOTIFICATION_SEND_RESET });
    }
  }, [error, success,dispatch]);

  const getFormDataHandler = (e) => {
    e.preventDefault();
    dispatch(sendNotificationaActionByUser(getUserId, getMessage));
    setGetMessage('')
  };

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <div>
            <div className="admin-panel-notification-main-continer">
              <div className="admin-panel-back-home-div">
                <p>
                  {" "}
                  <ArrowBackIcon />{" "}
                </p>
              </div>
              <h2 className="admin-panel-main-heading">
                Notifications Send Panel.
              </h2>
            </div>
            <form onSubmit={getFormDataHandler}>
              <div className="notification-form-box">
                <div className="notification-input-id">
                  <input
                    type="text"
                    placeholder="Enter User ID.."
                    value={getUserId}
                    onChange={(e) => setGetUserId(e.target.value)}
                    required
                  />
                </div>
                <div
                  className="notification-input-text-area-box"
                  value={getMessage}
                  onChange={(e) => setGetMessage(e.target.value)}
                >
                  <textarea
                    placeholder="Write Message...."
                    cols="30"
                    rows="10"
                  ></textarea>
                </div>
              </div>
              <div className="notification-submit-div">
                <button className="notification-form-submit-btn">
                  {" "}
                  Submit{" "}
                </button>
              </div>
            </form>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
}

export default CreateNotificationByAdmin;
