import React, { Fragment, useEffect } from 'react'
import { DataGrid } from '@material-ui/data-grid'
import { Link, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { clearErrorAction, usersRequestByAdmin, deleteUserByAdminAction } from '../redux/actions/userAction'
import swal from 'sweetalert';
import { Button } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import Loader from '../layout/loader'
import Sidebar from './Sidebar'

import './allUserByAdmin.css'

import { DELETE_USER_DETAIL_RESET } from '../redux/constants/userConstants'

function AllUserByAdmin() {
    const history = useHistory();
    const dispatch = useDispatch();

    const { loading, error, users } = useSelector((state) => state.allUsers)

    //for delete product by admin

    const { loading: deletedLoading, error: deleteError, isDeleted } = useSelector((state) => state.profileUpdateByUser)


    const deleteUserHandler = (id) => {
        dispatch(deleteUserByAdminAction(id))

    }


    useEffect(() => {
        if (error) {
            swal({
                title: "Validation",
                text: error,
                icon: "error",
            })
            dispatch(clearErrorAction())

        }

        if (deleteError) {
            swal({
                title: "Validation",
                text: deleteError,
                icon: "error",
            })
            dispatch(clearErrorAction())

        }
        if (isDeleted) {
            swal({
                title: "Deleted.",
                text: isDeleted,
                icon: "success",
            })
            dispatch({ type: DELETE_USER_DETAIL_RESET })
        }

        dispatch(usersRequestByAdmin())

    }, [deleteError, dispatch, error, history, isDeleted])



    const columns = [
        { field: 'id', headerName: 'User ID', minWidth: 200, flex: 0.5 },
        {
            field: 'name',
            headerName: 'Name',
            minWidth: 150,
            flex: 0.5,
        },
        {
            field: 'email',
            headerName: 'Email',
            minWidth: 300,
            flex: 0.3,
        },
        {
            field: 'role',
            headerName: 'Role',
            minWidth: 250,
            flex: 0.5,
            cellClassName: (params) => {
                return params.getValue(params.id, 'role') === 'admin' ? 'greenColor' : 'redColor'
            },
        },
        {
            field: 'action',
            headerName: 'Action',
            type: 'number',
            minWidth: 150,
            flex: 0.3,
            sortable: false,
            renderCell: (params) => {
                return (
                    <Fragment>
                        <Link to={`/Admin/dashboard/Users/${params.getValue(params.id, 'id')}`}   >
                            <EditIcon />
                        </Link>

                        <Button onClick={() => deleteUserHandler(params.getValue(params.id, 'id'))}   >
                            <DeleteIcon />
                        </Button>
                    </Fragment>
                )
            }
        },
    ]

    const rows = [];
    users && users.forEach(item => {
        rows.push({
            id: item._id,
            email: item.email,
            role: item.role,
            name: item.firstName,
        })
    });


    return (
        <Fragment>
            {deletedLoading ? <Loader /> : (<Fragment>
                {loading ? <Loader /> : (
                    <Fragment>
                        <div className="dashboard-container">
                            <Sidebar />
                            <div className="productListContainer">
                                <h1 className='productHeadingList'> ALL USER'S </h1>
                                <DataGrid

                                    rows={rows}
                                    columns={columns}
                                    pageSize={20}
                                    disableSelectionOnClick
                                    className='productListTable'
                                    autoHeight
                                />
                            </div>

                        </div>
                    </Fragment>
                )}

            </Fragment>)}
        </Fragment>
    )
}

export default AllUserByAdmin