import {
    DIRECT_BUY_REQUEST,
    DIRECT_BUY_REQUEST_SUCCESS,
    DIRECT_BUY_REQUEST_FAIL
} from '../constants/directBuyConstants'




export const directBuyReducer = ((state = { productBuy: {}}, action) => {
    switch (action.type) {
        case DIRECT_BUY_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case DIRECT_BUY_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                productBuy: action.payload
            }
        case DIRECT_BUY_REQUEST_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state;
    }

})