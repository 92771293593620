// FOR CREATE PRODUCT HEADING BY ADMIN
export const CREATE_PRODUCT_HEADING_REQUEST = 'PRODUCT_ALL_HEADING_REQUEST';
export const CREATE_PRODUCT_HEADING_SUCCESS = 'CREATE_PRODUCT_HEADING_SUCCESS';
export const CREATE_PRODUCT_HEADING_FAIL = 'CREATE_PRODUCT_HEADING_FAIL';
export const CREATE_PRODUCT_HEADING_RESET = 'CREATE_PRODUCT_HEADING_RESET';

// FOR UPDATE PRODUCT HEADING BY ADMIN

export const UPDATE_PRODUCT_HEADING_REQUEST = 'UPDATE_ALL_HEADING_REQUEST';
export const UPDATE_PRODUCT_HEADING_SUCCESS = 'UPDATE_PRODUCT_HEADING_SUCCESS';
export const UPDATE_PRODUCT_HEADING_FAIL = 'UPDATE_PRODUCT_HEADING_FAIL';
export const UPDATE_PRODUCT_HEADING_RESET = 'UPDATE_PRODUCT_HEADING_RESET';

// FOR GETTING ALL PRODUCT HEADINGS
export const PRODUCT_ALL_HEADING_REQUEST = 'PRODUCT_ALL_HEADING_REQUEST';
export const PRODUCT_ALL_HEADING_SUCCESS = 'PRODUCT_ALL_HEADING_SUCCESS';
export const PRODUCT_ALL_HEADING_FAIL = 'PRODUCT_ALL_HEADING_FAIL';
export const PRODUCT_ALL_HEADING_RESET = 'PRODUCT_ALL_HEADING_RESET';

// FOR GETTING SINGEL PRODUCT

export const PRODUCT_HEADING_REQUEST_ID = 'PRODUCT_HEADING_REQUEST_ID';
export const PRODUCT_HEADING_REQUEST_ID_SUCCESS = 'PRODUCT_HEADING_REQUEST_ID_SUCCESS';
export const PRODUCT_HEADING_REQUEST_ID_FAIL = 'PRODUCT_HEADING_REQUEST_ID_FAIL';
export const PRODUCT_HEADING_REQUEST_ID_RESET = 'PRODUCT_HEADING_REQUEST_ID_RESET';

export const CLEAR_ERROR = 'CLEAR_ERROR';