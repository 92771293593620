import {
    CREATE_COUPAN_REQUEST,
    CREATE_COUPAN_REQUEST_SUCCESS,
    CREATE_COUPAN_REQUEST_FAIL,
    EDIT_COUPAN_REQUEST,
    EDIT_COUPAN_REQUEST_SUCCESS,
    EDIT_COUPAN_REQUEST_FAIL,
    DELETE_COUPAN_REQUEST,
    DELETE_COUPAN_REQUEST_SUCCESS,
    DELETE_COUPAN_REQUEST_FAIL,
    SINGEL_COUPAN_REQUEST,
    SINGEL_COUPAN_REQUEST_SUCCESS,
    SINGEL_COUPAN_REQUEST_FAIL,
    ALL_COUPAN_REQUEST,
    ALL_COUPAN_REQUEST_SUCCESS,
    ALL_COUPAN_REQUEST_FAIL,
    COUPAN_APPLY_REQUEST,
    COUPAN_APPLY_REQUEST_SUCCESS,
    COUPAN_APPLY_REQUEST_FAIL,
    CLEAR_ERROR

} from '../constants/coupanConstant'

// import {URL} from '../../Url';

import axios from 'axios'


// create coupan by admin
export const createCoupanAction = (coupanCode, coupanValue, coupanMinValue, isActive, isPercent) => async (dispatch) => {
    try {

        dispatch({
            type: CREATE_COUPAN_REQUEST
        })
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        const {
            data
        } = await axios.post(`/api/admin/create-coupan`, {coupanCode, coupanValue, coupanMinValue, isActive, isPercent}, config)
        console.log(data)
        dispatch({
            type: CREATE_COUPAN_REQUEST_SUCCESS,
            payload: data.msg
        })
    } catch (error) {
        dispatch({
            type: CREATE_COUPAN_REQUEST_FAIL,
            payload: error.response.data.msg
        })
    }


}



// coupan apply by user
export const applyCoupanAction = (applyCoupan,totalPrice) => async (dispatch) => {
    try {

        dispatch({
            type: COUPAN_APPLY_REQUEST
        })
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        const {
            data
        } = await axios.post(`/api/coupan/apply-user`, {applyCoupan,totalPrice}, config)
        dispatch({
            type: COUPAN_APPLY_REQUEST_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: COUPAN_APPLY_REQUEST_FAIL,
            payload: error.response.data.msg
        })
    }


}




// edit coupan by admin
export const editCoupanAction = (id, coupanCode, coupanValue, coupanMinValue, isActive, isPercent) => async (dispatch) => {
    try {

        dispatch({
            type: EDIT_COUPAN_REQUEST
        })
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        const {
            data
        } = await axios.put(`/api/admin/update-coupan/${id}`, {
            coupanCode,
            coupanValue,
            coupanMinValue,
            isActive,
            isPercent
        }, config)
        dispatch({
            type: EDIT_COUPAN_REQUEST_SUCCESS,
            payload: data.msg
        })
    } catch (error) {
        dispatch({
            type: EDIT_COUPAN_REQUEST_FAIL,
            payload: error.response.data.msg
        })
    }


}






// delete coupan by admin
export const deleteCoupanAction = (id) => async (dispatch) => {
    try {

        dispatch({
            type: DELETE_COUPAN_REQUEST
        })
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        const {
            data
        } = await axios.delete(`/api/admin/delete-coupan/${id}`, config)
        
        dispatch({
            type: DELETE_COUPAN_REQUEST_SUCCESS,
            payload: data.msg
        })
    } catch (error) {
        dispatch({
            type: DELETE_COUPAN_REQUEST_FAIL,
            payload: error.response.data.msg
        })
    }


}







// get singel coupan by admin
export const singelCoupanAction = (id) => async (dispatch) => {
    try {

        dispatch({
            type: SINGEL_COUPAN_REQUEST
        })

        const {
            data
        } = await axios.get(`/api/admin/get-one-coupan/${id}`)

        dispatch({
            type: SINGEL_COUPAN_REQUEST_SUCCESS,
            payload: data.msg
        })
    } catch (error) {
        dispatch({
            type: SINGEL_COUPAN_REQUEST_FAIL,
            payload: error.response.data.msg
        })
    }


}




// get all coupan by admin
export const getAllCoupanAction = () => async (dispatch) => {
    try {

        dispatch({
            type: ALL_COUPAN_REQUEST
        })
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        const {
            data
        } = await axios.get(`/api/admin/all-coupan`, config)
        dispatch({
            type: ALL_COUPAN_REQUEST_SUCCESS,
            payload: data.allCoupan
        })
    } catch (error) {
        dispatch({
            type: ALL_COUPAN_REQUEST_FAIL,
            payload: error.response.data.msg
        })
    }


}


// clear the error 


export const clearErrorAction = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERROR
    })
}