import React from 'react'
import Signinpage from './components/SigninScreen/Signinpage'
function Signin() {
    return (
        <div>
            <Signinpage/>
        </div>
    )
}

export default Signin
