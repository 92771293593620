import React, { Fragment, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { clearErrorAction, updateAdsAction } from '../redux/actions/productAdsActions'
import swal from 'sweetalert';
import { Button } from '@material-ui/core'
import Loader from '../layout/loader';
import DescriptionIcon from '@material-ui/icons/Description';
import SpellCheckIcon from '@material-ui/icons/Spellcheck'
import Filter6Icon from '@mui/icons-material/Filter6';
import Sidebar from './Sidebar'
import './createNewProduct.css'
import { UPDATE_PRODUCT_HEADING_RESET } from '../redux/constants/productAdsConstants'
import { productAdsDetailAction } from '../redux/actions/productAdsActions'

function EditAdsAdmin(props) {
  const dispatch = useDispatch();
  const history = useHistory();

  const { error, productAdsDetail } = useSelector(state => state.getProductAds)
  const { loading: updateLoading, error: updateError, isUpdated } = useSelector(state => state.productAdsUpdate)

  const [name, setName] = useState('');
  const [tag, setTag] = useState('');
  const [descriptions, setDescriptions] = useState('');
  const [image, setImage] = useState('');


  // geting product id for detail and updates
  const productAds_Id = props.match.params.id;

  useEffect(() => {

    if (productAdsDetail && productAdsDetail._id !== productAds_Id) {
      dispatch(productAdsDetailAction(productAds_Id))
    } else {
      setName(productAdsDetail.name)
      setTag(productAdsDetail.tag)
      setDescriptions(productAdsDetail.descriptions)
    }

    if (error) {
      swal({
        title: "Validation",
        text: error,
        icon: "error",
      })
      dispatch(clearErrorAction())
    }

    if (updateError) {
      swal({
        title: "Validation",
        text: updateError,
        icon: "error",
      })
      dispatch(clearErrorAction())
    }

    if (isUpdated) {
      swal({
        title: "Updated.",
        text: isUpdated,
        icon: "success",
      })
      history.push('/Admin/dashboard')
      dispatch({ type: UPDATE_PRODUCT_HEADING_RESET })
    }

  }, [ dispatch, error, history, isUpdated, productAds_Id, productAdsDetail, updateError])

  // on click function when submit button press

  const createAdsSubmitHandler = async (e) => {
    e.preventDefault()


    let formData = new FormData();
    formData.append('name', name);
    formData.append('descriptions', descriptions);
    formData.append('tag', tag);

    for (let index = 0; index < image.length; index++) {
      formData.append('image', image[index]);
      console.log(image)
    }


    dispatch(updateAdsAction(productAds_Id, formData));

  }

  const createAdsImageChange = (e) => {

    setImage(e.target.files);
  }

  return (
    <Fragment>
      {updateLoading ? <Loader /> : (<Fragment>
        <div className="dashboard-container">
          <Sidebar />

          <div className="newProductContainer">
            <form className='createProductForm'
              onSubmit={createAdsSubmitHandler}  >
              <h1>Create Product Ads</h1>
              <div>
                <SpellCheckIcon />
                <input
                  type='text'
                  placeholder='Product Name'
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>

              <div>
                <Filter6Icon />
                <input
                  type='text'
                  placeholder='tag'
                  required
                  value={tag}
                  onChange={(e) => setTag(e.target.value)}
                />
              </div>

              <div>
                <DescriptionIcon />
                <textarea
                  placeholder='Descripation'
                  required
                  value={descriptions}
                  onChange={(e) => setDescriptions(e.target.value)}
                  cols={10}
                  rows={1}
                ></textarea>
              </div>

              <div id="createProductFormFile">
                <input type="file"
                  name='image'
                  accept='image/*'
                  multiple
                  onChange={createAdsImageChange}
                />
              </div>

              <Button
                id='createProductBtn'
                type='submit'
                disabled={updateLoading ? true : false}

              >
                Create Ads
              </Button>


            </form>
          </div>





        </div>
      </Fragment>)}
    </Fragment>
  )
}

export default EditAdsAdmin