import React, { Fragment } from 'react'
import {Helmet} from 'react-helmet'
import './termsOfUses.css'

import Navbar from '../../HomeScreen/Navbar';
import Footer from '../../HomeScreen/bottomsection/Footer';
import MetaTag from '../../../layout/MetaTag';
import TermsAndConditions from './TermsOfUsesPart2';
import TermsAndConditionsSeconds from './TermsOfUsesPart3';

function TermsOfUses() {
    return (
        <Fragment>

            <Helmet  >
                <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=2.0" />
                <meta name="description" content={`The headings in this Agreement do not have any legal effect nor shall they affect the construction of this Agreement in any way.|| The words "include" and "including" shall not be understood as having any limiting effects. || The Site is owned and maintained by IM ONLINE SHOPPING COMP.[ GULSHAN KUMAR (CEO & Founder ), SHINKU TIWARI (MD & Co-Founder) & PARTNERS]   || any User Content except with prior written consent from IM and the owner of the specific User Content, unless you are the sole owner of said User Content. Without prejudice
                 to the generality of Clause 4(i), you agree not to reproduce, display or otherwise provide access to the Services,IM Content, or Third Party User Content on another website or server. This includes framing, mirroring, linking, spidering, scraping or any other technological means (including any technology available in the future), without the prior written permission of IM  || IM may require you to register an online account ("Account")
                 to enable you to access and use certain parts of this Site or access certain Services. || use your Account for commercial purposes ||You shall provide IM with accurate, complete, and up-to-date Account information. As part of the Account registration process, you will select a password ("Password") and user identification ("User ID"). You may not ||   You agree that IM has the right in its sole and absolute discretion and without notice to:`} />
                <meta name="keywords" content={`  REPRESENTATIONS & WARRANTIES||  DEFINITIONS || The Site is owned || IM may now or in the future offer one or more of the following services on or through the Site (each a "Service" and collectively the "Services" ||  CONTENT USE CONDITIONS || USE OF ACCOUNT INFORMATION  ||  SUSPENSION AND TERMINATION OF ACCOUNT ||   INTELLECTUAL PROPERTY` } />
                <meta name="robots" content="index, follow " />
                <meta property="og:title" content={`   DATA USE & PRIVACY|| TERMINATION||    TERMS OF USE  || ONLINE CONDUCT ||You hereby agree: to comply with these Web Terms, and other notices or guidelines that may be posted on the Site by IM  ||     USER CONTENT & LICENCE||        DISCLAIMERS & LIMITATIONS   || LINKED SITES  IM may provide links to Linked Sites that may be of relevance and interest to our users. || NOTIFICATION OF INFRINGEMENT`} />
                <meta property="og:description" content={`     You agree that IM has the right in its sole and absolute discretion and without notice to restrict, suspend,
                        or terminate your access to all or any part of the Site
                        or Services, without assigning any reason.  || All Infringement Notices shall be sent to IM in the following format ||   VARIATION ||  SEVERABILITY || RELATIONSHIP OF PARTIES ||  Nothing in these Web Terms shall constitute or be considered to constitute as an agency,
                        partnership or joint venture between IM and you. Neither party shall have any authority
                        to bind the other in any way.`} />
                <meta property="og:type" content="website" />
                {/* <meta property="og:url" content={items.imageurl}   key={items.public_id}/>
            <meta property="og:image" content={items.imageurl}  key={items.public_id}/> */}
                <meta property="og:locale" content="en-GB_IN" />
                <meta property="og:site_name" content="IMONLINE.SHOP" />
                {/* <meta property="og:price:currency" content="INR" />
            <meta property="og:price:amount" content={product.price} /> */}
            </Helmet>

            <MetaTag title={'IM Online Shopping Terms and Conditions About Realted Details Page'} />
            <Navbar />
            <div className="terms-and-uses-main-div">
                <div className="heading-of-terms-uses">
                    <h2>TERMS OF USE</h2>
                </div>
                <p className='paragraph-of-terms-uses'>
                    This website, mobile application and/or IM social media pages (“Site”) is operated by IM ONLINE SHOPPING COMP. together with PARTNERS AND INVESTER from (punjab) and its related entities,
                    affiliates and subsidiaries, collectively referred to as 'IM ONLINE SHOPPING COMP'.
                </p>

                <ul className='REPRESENTATIONS-WARRANTIES'>

                    REPRESENTATIONS & WARRANTIES
                    <li>You hereby declare that:</li>
                    <li>you have read and accepted the Web Terms in its entirety;</li>
                    <ul>you are at least 18 years old and have the necessary legal capacity, right,
                        power and authority to accept these Web Terms and you are either;
                        <li>accessing this Site, using the Services and contracting in your own personal capacity; or</li>
                        <li>accessing this Site, using the Services and contracting on behalf of a corporation or another legal entity.</li>
                    </ul>
                    <li>you are authorised to bind the entity (yourself or your corporate entity) on whose behalf you are contracting and such entity agrees to be bound by these Web Terms; and</li>

                    <li>all of the information provided by you to IM (including personal particulars and contact information) is accurate and complete.</li>

                </ul>

                <ul className='DEFINITIONS'>
                    DEFINITIONS
                    <ul>In this Agreement, the following definitions shall apply unless the context does not permit it:
                        <li>Account is defined in Clause 5(i).</li>
                        <li>Agreement means the agreement formed by the Web Terms</li>
                        <li>IM Content means all Content of IM that is made available on or via this Site</li>
                        <li>Computer means your computer; notebook computer; personal digital assistant; mobile phone;
                            tablet device; or other electronic device used to access this Site or the Services.</li>
                        <li>Content means product listings; product descriptions and reviews; materials; information; advertisement; listings;
                            data; input; text; songs; audio; video; picture; graphics; software; podcasts; broadcasts; messages; software; comments; suggestions; ideas; and other content.</li>
                        <li>Linked Sites means website links that appear on the Site.</li>
                        <li>Marks means the logo, trademarks and service marks used on the Site.</li>
                        <li>Privacy Policy means IM Privacy, Cookies and Data Protection Policy as reflected on the Site, which might be amended from time to time.</li>
                        <li>Products means IM products ordered and/or services provided on this Site.</li>
                        <li>Servers means the computer software; systems and servers hosting; and operating, managing, providing or contributing to the Site and the Services.</li>
                        <li>Services is defined in Clause 3(ii).</li>
                        <li>Site means the website at www.imonline.shop or the mobile application.</li>
                        <li>Third Party Products means products and services from third parties that are advertised on or available at the Site or websites linked from the Site.</li>
                        <li>Third Party User Content means all User Content that is not created, transmitted, posted or uploaded by you.</li>
                        <li>User Content means all Content on this Site that is created, transmitted, posted or uploaded by a user of the Site.</li>
                        <li>Web Terms means the Site terms and conditions as well as the Privacy, Cookies and Data Protection Policy.</li>

                    </ul>


                    <li>The words "include" and "including" shall not be understood as having any limiting effects.</li>
                    <li>The headings in this Agreement do not have any legal effect nor shall they affect the construction of this Agreement in any way.
                    </li>
                </ul>
                <ul className='SITE-AND-SERVICES'>
                    <li>The Site is owned and maintained by IM ONLINE SHOPPING COMP.[ GULSHAN KUMAR (CEO & Founder ), SHINKU TIWARI (MD & Co-Founder) & PARTNERS]  </li>
                    <li>IM may now or in the future offer one or more of the following services on or through the Site (each a "Service" and collectively the "Services"):</li>
                    <ul>
                        <li>online ordering of Products</li>
                        <li>access to Content</li>
                        <li>search engines or tools</li>
                        <li>a platform to create, upload and publicly make User Content available</li>
                        <li>product reviews and catalogs; message boards; forums; blogs; and communication tools</li>
                        <li> any other features, content or applications that IM may offer on the Site from time to time in its sole and absolute discretion.</li>
                    </ul>
                    <li>You acknowledge and agree that by placing an order for Products and to access and use certain Services, you will agree to the Web Terms.
                    </li>
                </ul>

                <ul className='CONTENT-USE-CONDITIONS'>
                    CONTENT USE CONDITIONS
                    <ul>You may not reproduce, modify, adapt, translate, publish, display, communicate, transmit, distribute, sell, trade or exploit
                        for any commercial or other purposes, any portion of, or have any access to:

                        <li>any Service</li>
                        <li>the Site</li>
                        <li>any IM Content except with prior written consent from IM or unless expressly permitted in these Web Terms</li>
                        <li>any User Content except with prior written consent from IM and the owner of the specific User Content, unless you are the sole owner of said User Content.</li>
                    </ul>

                    <li>Without prejudice to the generality of Clause 4(i), you agree not to reproduce, display or otherwise provide access to the Services,
                        IM Content, or Third Party User Content on another website or server. This includes framing, mirroring, linking, spidering, scraping or any other
                        technological means (including any technology available in the future), without the prior written permission of IM</li>

                    <li>All IM Content are the copyrighted work of IM or its content or software providers.
                        IM reserves and retains all rights to its Content.
                        Use of some IM Content may be governed by the terms of an accompanying end user license agreement.</li>
                    <li>You may not decompile, reverse engineer or otherwise attempt to discover the source code of any
                        IM Content available on the Site or through a Service except under specific circumstances
                        expressed and permitted by law or by IM in writing.
                    </li>
                </ul>

                <ul className='PASSWORD-ACCOUNT'>
                    <li>IM may require you to register an online account ("Account")
                        to enable you to access and use certain parts of this Site or access certain Services.
                    </li>
                    <li>You agree that your Account is for your sole, personal use. You agree not to:</li>
                    <ul>
                        <li>share with or permit others to use your Account</li>
                        <li>assign or otherwise transfer your Account to any other person or entity</li>
                        <li>use your Account for commercial purposes</li>
                    </ul>

                    <li>You shall provide IM with accurate, complete, and up-to-date Account information.
                        Failure to do so shall constitute a breach of this Agreement, which may result in the restriction,
                        suspension or immediate termination of your Account.
                        You will keep your Account information updated at all times to ensure it is accurate, current and complete.
                    </li>

                    <li>As part of the Account registration process, you will select a password ("Password") and user identification ("User ID"). You may not</li>
                    <ul>
                        <li>select or use a User ID of another person with the intent to impersonate that person</li>
                        <li>use a name that belongs to another person without authorisation</li>
                        <li>use a User ID that IM, in its sole discretion, deems inappropriate or offensive.</li>
                    </ul>


                    <li>You shall promptly notify IM of any known or suspected unauthorised use(s) of your Account,
                        or any known or suspected breach of security, including loss, theft, or unauthorised disclosure of your Password.
                        You will be responsible for safeguarding and maintaining the confidentiality of your User ID and Password.</li>

                    <li>You will be responsible for, and IM shall be entitled to rely on,
                        all communications transmitted through the use of your User ID and Password,
                        and all such communications shall be deemed to be communications made and issued by you.</li>
                    <li>You will be responsible for all User Content, messages, and all online activity at the Site transmitted or conducted through the use of your User ID and Password.</li>
                    <li>IM shall have no responsibility or liability for any loss, damage, cost, expenses,
                        or liabilities arising as a result of or in connection with the wrongful or fraudulent use of your User ID and Password.</li>
                    <li>You agree that IM reserves the right to change or re-assign User IDs and/or Password(s) at its sole discretion by giving you notice.
                        IM shall not be liable for any loss,
                        damage, cost or expense incurred by you as a result of such change or re-assignment.</li>

                </ul>


                <ul className='USE-OF-ACCOUNT-INFORMATION' >USE OF ACCOUNT INFORMATION
                    <li>By providing the information requested for your Account, you consent to IM’s use and disclosure
                        of all such information for the purposes set out in our Privacy Policy.</li>

                </ul>

                <ul className='SUSPENSION-AND-TERMINATION-OF-ACCOUNT'   >
                    SUSPENSION AND TERMINATION OF ACCOUNT
                    <li>
                        <ul>
                            You agree that IM has the right in its sole and absolute discretion and without notice to:
                            <li>restrict, suspend, or terminate your access to all or any part of the Site or Services; and/or terminate,
                                deactivate or suspend your Account,delete your Account and all related information and files in your Account, without assigning any reason</li>
                            <li>without prejudice to the generality of the above, IM reserves the right to deactivate
                                your Account if it has been inactive for a period of six (6) months or more, or if you are in breach of any terms in this Agreement
                                or if IM believes you have been using the Account for unlawful and/or undesirable activities.</li>
                        </ul>
                    </li>
                    <li>You agree not to hold IM liable or responsible for any loss or damage incurred by you arising out of,
                        or in connection with the suspension and/or termination of your Account.</li>
                </ul>

                <ul className='INTELLECTUAL-PROPERTY'>

                    INTELLECTUAL PROPERTY
                    <li>The copyright, patents, Marks, registered designs and all intellectual property rights used in the Services, the Site, and all IM Content, including, and without limitation, User Content copyright,
                        shall remain with IM. The Site as a whole is protected by copyright and all worldwide rights, titles and interests in and to the Site are owned by IM ONLINE SHOPPING COMP. Any unauthorized copying, alteration,
                        distribution, transmission, performance, display, or other use of such intellectual property from any portion of the Site is prohibited.
                        All rights not expressly granted in written are reserved by IM.</li>
                    <li>The Marks displayed on this Site are the property of IM ONLINE SHOPPING COMP. or other third parties, and all rights to the Marks are
                        expressly reserved by IM ONLINE SHOPPING COMP. or relevant third parties. You are not permitted to use any Marks without the prior
                        written consent of IM ONLINE SHOPPING COMP. or such third party.IM ONLINE SHOPPING COMP. aggressively enforces their intellectual property
                        rights to the fullest extent of the law. The name of IM ONLINE SHOPPING COMP. or any other Marks may not be used in any way, including
                        in any advertising or publicity, or
                        as a hyperlink without prior written permission from IM ONLINE SHOPPING COMP.</li>
                    <li>The domain name on which the Site is hosted on is the sole property of IM ONLINE SHOPPING COMP. and you may not use,
                        or otherwise adopt a similar name for your own use.</li>
                </ul>

                <ul className='ONLINE-CONDUCT'>
                    ONLINE CONDUCT
                    <li>
                        <ul>You hereby agree:
                            <li>to comply with these Web Terms, and other notices or guidelines that may be posted on the Site by IM
                                from time to time (which shall be incorporated by reference into these Web Terms);</li>

                            <li>not to use any Service or IM Content for any unlawful purpose, and to comply with all applicable laws and regulations,
                                including and without limitation to copyright law</li>
                            <li>not to hack into, interfere with, disrupt, disable, over-burden or otherwise impair how the Site, Services or Servers work.
                                This includes denial-of-service attacks, spoof attacks, session hacking, sniffing, tampering, reverse engineering or
                                reprogramming
                            </li>
                            <li>not to use the Account of another user at any time, with or without his/her permission.</li>
                        </ul>
                    </li>
                </ul>

                <ul className='USER-CONTENT-LICENCE'>

                    USER CONTENT & LICENCE

                    <li>You agree to exercise respect and to act reasonably when participating in any community feature as part of the Services which
                        allows you to upload or submit User Content. You agree not to use the Site in any manner that is illegal or impairs the
                        operation of the Site or its availability or usage by others.
                    </li>

                    <li>
                        <ul>You may not submit, upload or publish on the Site or through IM:
                            <li>any User Content that is inaccurate, misleading, libellous, defamatory, threatening, pornographic, an invasion of
                                privacy, obscene, indecent, lewd, crude, abusive, improper, illegal, political, racist, religious, blasphemous, offensive,
                                false, misleading, an infringement of any intellectual property or third party rights,
                                or would otherwise encourage the violation of any law or the proprietary or any other third party rights</li>
                            <li>any User Content that solicits funds, commercial solicitation, chain letters, mass mailings or any form of “spam” or includes
                                programs that contain viruses, Trojan horses, worms, time bombs, data miners, web crawlers, bots, spiders or any other programs
                                designed to impair the operation and functionality of the Site, Services, Servers, or any computer</li>

                            <li>without the relevant third party owners’ permission, any content of a third party or links to third party material,
                                third party websites or use any third party Marks,
                                (individually and collectively "Improper Works").</li>
                        </ul>
                    </li>
                    <li>
                        <ul>
                            If, at any time you upload or post User Content to the Site in any manner, whether by submitting Content directly
                            to IM or tagging your social media posts and/or social media images with any hashtags officially used
                            by IM hashtags or handles officially used by IM, you automatically:

                            <li>grant IM and its subcontractors (including its Internet content hosting servers and delivery networks)
                                a non-exclusive, royalty-free, irrevocable, perpetual and worldwide licence to use (including without limitation, to store,
                                reproduce, modify, distribute, publish, display, communicate, transmit, broadcast, podcast, webcast, or broadcast)
                                and to sub-licence the User Content whether or not in connection with the provision of the Services and/or on or via
                                the Site, other websites,
                                in-store displays and other printed and online publications</li>

                            <li>
                                represent and warrant that:
                                <ul>
                                    <li>
                                        all User Content are your own original works and creations and will not infringe the copyright or any other
                                        intellectual property or other rights of any third party
                                    </li>
                                    <li>none of the User Content are proprietary or confidential</li>
                                    <li>none of the User Content are Improper Works, nor will they expose IM to any
                                        civil or criminal proceedings in any part of the world</li>

                                    <li>the use of IM and other users for the purposes and in the manner set out in this Clause 10(iii),
                                        and the hosting of the User Content on the Servers by IM will not require any further licences from,
                                        or infringe any intellectual property or other rights of, any third party</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>IM at all times retains the sole discretion to remove or decline to accept any User Content from the Site without
                        assigning any reason whatsoever. Without limiting the foregoing right, IM may monitor the Site for Improper Works
                        (but is not obliged to do so) and reserves the right to remove any User Content which IM believes
                        are Improper Works, or which is the subject of any dispute.</li>

                    <li>You agree to indemnify and hold IM and its officers, agents, co-branders or other partners, and employees,
                        harmless from all claims, demands, actions, proceedings, liabilities (including statutory liability and liability to third
                        parties), penalties, and costs (including without limitation, legal costs on a full indemnity basis), awards, losses and/or
                        expenses, due to or arising out of any User Content
                        or Improper Works you submit, post to or transmit through the Site or Services.</li>

                    <li>The copyright and intellectual property rights in all User Content which belong to you prior to uploading on
                        the Site shall be retained by you, subject to the licences granted by you under these Web Terms
                        (including under Clause 10(iii)), and subject to IM ONLINE SHOPPING COMP.s rights in the compilation of all User Content.
                    </li>
                </ul>

                <ul className='DISCLAIMERS-LIMITATIONS'>

                    DISCLAIMERS & LIMITATIONS
                    <li>While we make every effort to ensure that all IM Content displayed on the Site is accurate and complete,
                        we provide the IM Content for informative purposes and on an ‘as is’, ‘as available’ basis only without
                        warranties of any kind either express or implied. To the fullest extent permissible pursuant to applicable law,
                        IM disclaims all warranties, express or implied, including, but not limited to, implied warranties
                        of satisfactory quality, merchantability or fitness for a particular purpose, compliance with description,
                        or the warranty of non-infringement. Without limiting the foregoing, IM does not warrant that the
                        functions contained in or access to the Site, Services, IM Content or other content will be timely,
                        uninterrupted or error-free or without omission, that defects will be corrected, or that the Site, Services,
                        IM Content or the Servers are free of viruses or other harmful components, or that the download,
                        installation or use of any IM Content in or with any Computer will not affect the functionality or
                        performance of the Computer. IM does not warrant or make any representations regarding the use or
                        the results from the use of IM Content, Services, Site or Servers in terms of their accuracy, completeness,
                        reliability, or otherwise. You (and not IM) will assume the entire cost of all necessary servicing,
                        repair, or correction, including any defect, problem or damage in any Computer. You agree not to hold IM
                        liable for the loss of any of your User Content that is due
                        to any circumstances beyond the reasonable control of IM.</li>

                    <li>The data and information made available on the Site are of a general nature and do not mean, and shall not in any way
                        constitute an offer or provision of any professional or expert advice. Any arrangement made between you and a third party
                        named on or linked to from these pages is at your sole risk and responsibility.
                        IM does not sponsor, endorse or promote any products, services or information.</li>

                    <li>You acknowledge that it is not IM’s policy to exercise editorial control over, and to review,
                        edit or amend any data, information, materials or contents of any User Content, posting, email or any information
                        that may be inserted or made available on the Site by other users of the Services and that IM does not
                        endorse and shall not be responsible for any such content.
                    </li>

                    <li>You acknowledge and agree that IM does not endorse or recommend, is not an agent, reseller or distributor of,
                        and has no control over Third Party Products, and IM hereby expressly disclaims all liabilities
                        and responsibilities arising in relation to any Third Party Products whether available or advertised via the Site or on Linked Sites.</li>

                    <li>
                        You agree that all statements, offers, information, opinions, materials, User Content, and Third Party Products,
                        from other users and from advertisers and other third parties on this Site should be used, accepted and relied
                        upon only with care and discretion and at your own risk. IM
                        shall not be responsible for any loss, damage or liability incurred by you arising from such use or reliance.
                    </li>

                    <li>You also acknowledge and agree that some Services may enable other users to upload User Content to the Site,
                        and that some of these may be offensive, annoying, unlawful, in breach of these Web Terms, contain viruses or
                        cause you damage. While we may remove any such User Content brought to our notice at our sole and absolute discretion,
                        you acknowledge and agree that we shall not be responsible or liable for any User Content,
                        and you agree to access and use User Content only at your own risk and with care and discretion.</li>

                    <li>
                        You agree that:
                        <ul>
                            <li>IM shall be entitled at any time, at its sole and absolute discretion and without prior notice, add to,
                                vary, terminate, withdraw or suspend the operation of the
                                whole or any part or feature of the Site or Services without assigning any reason</li>

                            <li>access to or the operation of the Site, Servers and/or the Services may from time to time be interrupted or encounter
                                technical or other problems and may not necessarily
                                continue uninterrupted or without technical or other errors</li>

                            <li>and in any such an event, IM shall not be liable for any loss,
                                liability or damage which may be incurred as a result</li>

                        </ul>
                    </li>
                    <li>
                        In no event shall IM be liable to you for any damages, losses, expenses, liabilities under any causes of action
                        (whether in contract or tort including, but not limited to negligence, or otherwise) caused through the use of, or the inability
                        to use, the IM Content, User Content, Services, Third Party Products, any Computers, the Site, or any other website
                        . In the event that IM is found to be liable for damages despite the foregoing provision, you agree that
                        IM ’s aggregate liability to you for any and all causes of action in relation to the IM Content,
                        Services, Site, and the Agreement, shall not exceed the total amount of fees and charges paid by you for the Services to
                        IM for the one (1) month period immediately preceding the time such liability arose. The liability exclusions
                        and limits for Products offered for sale on this Site are set out in the Terms and Conditions of Sale.
                    </li>
                    <li>Under no circumstances, including, but not limited to negligence, shall IM be liable for any indirect,
                        special, consequential, or incidental damages that result from the use of, or the inability to use, the
                        IM Content, Services, Third Party Products, Site, or any other website, even if
                        IM or a IM authorised representative has been advised of, or
                        should have foreseen, the possibility of such damages.
                    </li>

                    <li>You agree that the above exclusions and limitations of liability enable the Services and the
                        IM Content may be provided at a reasonable cost to you.</li>

                </ul>

                <ul className='LINKED-SITES'>
                    LINKED SITES
                    <li>IM may provide links to Linked Sites that may be of relevance and interest to our users.
                        IM has no control over, and is not responsible for the content on the Linked Sites or for
                        any damage you may incur from the Linked Sites (including any virus, spyware, malware, worms, errors or
                        damaging material contained in the Linked Sites) or the availability of any content on the Linked Sites.
                        You hereby irrevocably waive any claim against us with respect to the Linked Sites.
                    </li>
                    <li>IM does not endorse any entities featured on Linked Sites or any products or services which may be
                        available from Linked Sites.</li>
                </ul>

                <ul className='DATA-USE-PRIVACY'>
                    DATA USE & PRIVACY
                    <li>Please do not submit any personal information or data without first reading our Privacy, Cookies and Data
                        Protection Policy which explains our data use and privacy practices in detail.</li>
                </ul>

                <ul className='TERMINATION'>TERMINATION

                    <li>You agree that IM has the right in its sole and absolute discretion and without notice to restrict, suspend,
                        or terminate your access to all or any part of the Site
                        or Services, without assigning any reason.</li>
                </ul>

                <ul className='NOTIFICATION-OF-INFRINGEMENT'>NOTIFICATION OF INFRINGEMENT

                    <li>IM reserves the right to investigate notices of copyright, trademark and other intellectual property
                        infringement ("Infringement") in respect to IM Content, User Content and other material on the Site
                        (“Infringing Material”) and take appropriate action. If you believe your work has been used or copied in a way that
                        constitutes Infringement and such Infringement is occurring on this Site,
                        please notify IM in writing immediately ("Infringement Notice").
                    </li>

                    <li>All Infringement Notices shall be sent to IM in the following format</li>

                    <li >
                        <p>
                            <b> Ecommerce Manager
                                IM Group Headquarters
                                MRCG+87X, Chhanna Rd,Dehliz Kalan,
                                Ahmedgarh,punjab,148021,india
                                <span>information@imonline.shop</span></b>
                        </p>
                    </li>
                    <li>IM will, in response to all Infringement Notices submitted in the above manner,
                        undertake the necessary investigations and if necessary remove the Infringing Material from the Site within a reasonable
                        time. In return, you agree that you shall not take any legal action or exercise any legal remedy you may have against
                        IM in respect to any Infringing Material, unless you have first given IM the Infringement
                        Notice and sufficient opportunity to remove the Infringing Material, and thereafter IM refuses or
                        fails to remove the Infringing Material within a reasonable time. If IM removes the Infringing Material
                        in response to your Infringement Notice, you agree not to exercise and you hereby waive, any right of action against
                        IM under any applicable law which you may have in respect to any
                        Infringing Material appearing on the Site prior to removal by IM.</li>

                    <li>You acknowledge and agree that IM has no control and cannot undertake responsibility or liability in respect
                        to Infringing Material appearing on Linked
                        Sites or other third party sites.</li>

                </ul>
                <ul className='INDEMNITY'>INDEMNITY
                    <li>You agree to indemnify and hold IM, and its officers, agents, partners, and employees, harmless from all claims,
                        demands, actions, proceedings, liabilities (including statutory liability and liability to third parties), penalties, and costs
                        (including without limitation, legal costs on a full indemnity basis), awards, losses and/or expenses that IM,
                        and its officers, agents, co-branders or other partners, and employees, may be subject to, directly or indirectly, due to, or arising out of:
                        <ul>
                            <li>any use of the Site or any Site</li>
                            <li>your connection to the Site</li>
                            <li>your breach of any terms and conditions of these Web Terms</li>
                            <li>your violation of any rights of another person or entity</li>
                            <li>your breach of any statutory requirement, duty or law</li>
                        </ul>

                    </li>
                </ul>
                <ul className='VARIATION'>
                    VARIATION

                    <li>IM reserves the right to change, modify, suspend or discontinue the whole or any portion
                        of the Services or Site at any time. IM may also impose limits on certain features or
                        restrict your access to parts or the entire Services or Site without notice or liability.
                    </li>
                    <li>IM may from time to time vary or amend these Web Terms by posting the amended Web Terms at this Site.
                        Any use of the Services or access to the Site after the amendment of these Web Terms will be considered as acceptance of
                        the amended Web Terms by you. If you do not agree to the amended Web Terms,
                        you have the right to cease using the Services or to exit the Site.</li>


                </ul>

                <ul className='SEVERABILITY'>
                    SEVERABILITY
                    <li>If any provision of these Web Terms is found to be illegal, void or unenforceable under any law that is applicable
                        hereto, or if any court of competent jurisdiction in a final decision so determines these Web Terms shall continue
                        in force save that such provision or part thereof shall be deemed to be deleted.
                    </li>
                </ul>

                <ul className='RELATIONSHIP-OF-PARTIES'>
                    RELATIONSHIP OF PARTIES
                    <li>
                        Nothing in these Web Terms shall constitute or be considered to constitute as an agency,
                        partnership or joint venture between IM and you. Neither party shall have any authority
                        to bind the other in any way.

                    </li>
                </ul>
                <ul className='WAIVER'>WAIVER
                    <li>No waiver of any rights or remedies by IM shall be effective unless made in writing and signed
                        by an authorised representative of IM.</li>

                    <li>A failure by IM to exercise or enforce any rights conferred upon it by these Web Terms shall not be
                        considered to be a waiver or variation of any such rights, or
                        operate so as to bar the exercise or enforcement thereof at any subsequent time or times</li>

                </ul>
                <ul className='RIGHTS-OF-THIRD-PARTIES'>RIGHTS OF THIRD PARTIES

                    <li>A person, or entity, who is not a party to this Agreement shall have no right under the Contracts
                        (Rights of Third Parties) Act (Cap. 53B) to enforce any term of this Agreement, regardless of whether such person or
                        entity has been identified by name,as a member of a class or as answering a particular description.</li>

                </ul>
                <ul className='GOVERNING-LAW-JURISDICTION'>GOVERNING LAW & JURISDICTION

                    <li>These Web Terms and all matters relating to your access to, or use of, this Site and the Services shall be
                        governed by and construed in accordance with the laws of INDIA,
                        without giving effect to any principles of conflicts of law.</li>
                    <li>You hereby agree to submit to the exclusive jurisdiction of the indian courts.</li>
                </ul>
                <ul className='CONTACT'>CONTACT
                    <li>If you have any questions or concerns about these Web Terms or any issues raised in these Web Terms or on the Site,
                        please contact us at inquires@imonline.shop</li>
                </ul>

                <TermsAndConditions />
                <TermsAndConditionsSeconds />
            </div>
            <Footer />

        </Fragment>
    )
}

export default TermsOfUses