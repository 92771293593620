import React, { Fragment } from 'react'

import Navbar from '../../HomeScreen/Navbar';
import MetaTag from '../../../layout/MetaTag';
import Footer from '../../HomeScreen/bottomsection/Footer';

import {Helmet} from 'react-helmet'
import SideBarAccount from './SideBarAccount'




function Security() {
    return (
        <Fragment>
         <Helmet  >
                <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=2.0" />
                <meta name="description" content={`   How do I make a return? || How do I return an order that was purchased online? ||   Our return policy is only applicable to any item(s)  purchased online and delivered in India, within 30 days of receiving your order.    ||     IMONLINE.SHOP (RETURNS DEPT)||   IM Group Headquarters  Chhanna Rd,Dehliz Kalan,Ahmedgarh  || Punjab 148021,INDIA.`} />
                <meta name="keywords" content={`SECURITY ||  GENERAL SECURITY || What is online security fraud screening? ||  Why should I send you my personal information? ||  What should I take note of when sending my personal information?  ||      Will my personal information be kept confidential? ||    Will I receive an online security email every time I place an order at IMONLINE.SHOP? ||  What should I do if I receive an online security email from you?  ||  What if I fail to respond to your email within the given period, will my order still be processed? ||   What should I take note of as an internet user and online shopper?`} />
                <meta name="robots" content="index, follow " />
                <meta property="og:title" content={` COUNTERFEIT EDUCATION ||  Do fake IM websites and social media platforms exist? ||   How can I identify a fake IM website or social media platform? ||  IM anti-counterfeiting efforts   ||      How can I report suspected counterfeit products or fake websites? ||  You may contact us at counterfeit@imonline.shop to report any suspected counterfeit products or fake websites.  `} />
                <meta property="og:description" content={`  What promotions does IM run? ||  Can I use more than one promo code on my order? ||  My promo code is not working, what should I do?    ||     What are the terms and conditions for promotions? ||   What happens if the promotion I’m interested in has expired? || `} />
                <meta property="og:type" content="website" />
                {/* <meta property="og:url" content={items.imageurl}   key={items.public_id}/>
            <meta property="og:image" content={items.imageurl}  key={items.public_id}/> */}
                <meta property="og:locale" content="en-GB_IN" />
                <meta property="og:site_name" content="IMONLINE.SHOP" />
                {/* <meta property="og:price:currency" content="INR" />
            <meta property="og:price:amount" content={product.price} /> */}
            </Helmet>
            <MetaTag title={'IM Online Shopping Website Security And Safety Realted Details Page'} />
            <Navbar />
            <div className="container">
                <div className="row">
                    <div className="col-md-3">
                        <SideBarAccount />
                    </div>
                    <div className="col-md-9">
                        <div className="right-side-paragraph">
                            <div className="h2-heading">
                                <h2> SECURITY </h2>
                            </div>
                            <div className="details-of-account-privileges">
                                <h6>GENERAL SECURITY</h6>
                                <ul>
                                    What is online security fraud screening?
                                    <li>
                                        Online security fraud screening is a IMONLINE.SHOP
                                        initiative that acts as a preventive measure
                                        to protect our customers from unauthorised
                                        and fraudulent credit card,debit card, phonePe,googlePay(etc...) transactions.
                                    </li>
                                </ul>
                                <ul>
                                    Why should I send you my personal information?
                                    <li>
                                        IMONLINE.SHOP takes your security seriously and has established an online security process
                                        that was developed in line with industry best practices. This security process is necessary to protect the
                                        interest of credit cardholders whose accounts may have been compromised,
                                        and IMONLINE.SHOP as the e-commerce merchant.
                                    </li>
                                </ul>
                                <ul>
                                    What should I take note of when sending my personal information?
                                    <li>
                                        For your security, IMONLINE.SHOP advises
                                        that you never send anyone your full credit card number.
                                        We strongly recommend you divulge only the first 6 and the last 4 digits.
                                    </li>
                                    <li>
                                        In the event where you submit a document with your full credit card number,
                                        IMONLINE.SHOP will not be held responsible for any arising complications
                                    </li>
                                </ul>
                                <ul>
                                    Will my personal information be kept confidential?
                                    <li>
                                        All important and relevant information submitted by
                                        our customers will be kept secure and confidential.
                                        Only limited and authorised personnel have the right
                                        to access this information.
                                        At no point will we share, rent or sell your personal
                                        information without your consent. For further details,
                                        please read IM’s Privacy Policy.
                                    </li>
                                </ul>
                                <ul>
                                    Will I receive an online security email every time I place an order at IMONLINE.SHOP?
                                    <li>
                                        Online security verification is ideally done for one-time verification purpose only.
                                        To ensure you do not experience any delay in
                                        receiving your order due to online security checks, we recommend
                                        you use the same credit card for future purchases.
                                    </li>
                                </ul>
                                <ul>
                                    What should I do if I receive an online security email from you?
                                    <li>
                                        Please contact the Customer Service team as instructed in the email
                                        and provide the necessary documents as advised. Once your documents
                                        have been verified as valid, you will have to make a new order
                                        which will be processed at the soonest date.
                                    </li>
                                </ul>
                                <ul>
                                    What if I fail to respond to your email within the given period, will my order still be processed?
                                    <li>
                                        Failure to respond to the online security verification
                                        email within the given timeline will result in an automatic order cancellation.
                                    </li>
                                </ul>
                                <ul>
                                    What should I take note of as an internet user and online shopper?
                                    <li>
                                        IMONLINE.SHOP takes a number of steps to ensure our website is safe for use,
                                        free from malicious activity and that the transactions performed are secure.
                                    </li>
                                    <li>
                                        All the information you key into IMONLINE.SHOP is protected by
                                        Secure Sockets Layer Technology (SSL). SSL encrypts your order information before
                                        it is transmitted to us to avoid anyone besides IMONLINE.SHOP from decoding your information.
                                    </li>
                                    <li>
                                        We strongly advise all users to take the necessary
                                        steps to ensure your safety and security when using
                                        IMONLINE.SHOP. Some measures we recommend include:
                                        <ul>
                                            <li>
                                                Avoid using a public computer or terminal to sign in to your account
                                            </li>
                                            <li>
                                                Keep your passwords private. Create a password that can’t be easily guessed
                                            </li>
                                            <li>
                                                Change your password frequently. Avoid using the same password for multiple logins
                                            </li>
                                            <li>
                                                Avoid saving your password in the browser
                                            </li>
                                            <li>
                                                Clear your browser history – cookies, caches and saved passwords – every time after using the browser
                                            </li>
                                            <li>
                                                Be vigilant of emails and telephone calls allegedly from IMONLINE.SHOP
                                                requesting you to update your account
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        In the event that you suspect you have received an unsolicited email or telephone call from us,
                                        please call +(91) 7658007326 or email us at information@imonline.shop to verify that it is indeed from us.
                                    </li>
                                </ul>
                                <h6>COUNTERFEIT EDUCATION</h6>
                                <ul>
                                    Do fake IM websites and social media platforms exist?
                                    <li>
                                        Yes they do. These sites and platforms may imitate the aesthetics
                                        of the official IM websites, or imply
                                        that they are associated with IM.
                                    </li>
                                    <li>
                                        These sites and platforms may use pictures, photographs
                                        and/or text from our official sites to create an impression of legitimacy.
                                        The IM product may also be offered at a discount.
                                        However, you may receive a counterfeit or even receive nothing in the end.
                                        These sites and platforms may also insert malware into your device,
                                        and/or misappropriate your personal information,
                                        such as your name, address or your credit card details.
                                    </li>
                                </ul>
                                <ul>
                                    How can I identify a fake IM website or social media platform?
                                    <li>
                                        Counterfeiters often deliberately select certain domain names or social media usernames to mislead
                                        you into believing the products promoted
                                        on the website or social media platform are authentic.
                                    </li>
                                    <li>
                                        For example, the domain names of fake IM websites usually contain the words “IMALL”,
                                        or appear slightly varied for example, omission of certain letters or switching the positions of the letters.
                                        These fake websites are usually accompanied with words like bag, vip, sale, outlet, official.
                                    </li>

                                </ul>
                                <ul>
                                    IM anti-counterfeiting efforts
                                    <li>
                                        We protect and enforce our intellectual property rights rigorously.
                                        We cooperate with law enforcement agencies, government bodies,
                                        customs authorities and third party platforms to stop the manufacture,
                                        shipment and sales of counterfeit IM products.
                                        We also work with domain name registrars to take down fraudulent websites.
                                    </li>
                                </ul>
                                <ul>
                                    How can I report suspected counterfeit products or fake websites?
                                    <li>
                                        You may contact us at counterfeit@imonline.shop to report any suspected counterfeit products or fake websites.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <Footer />
        </Fragment>
    )
}

export default Security