import React from 'react'

import Menubar from './components/HomeScreen/Menubar';
import Navbar from './components/HomeScreen/Navbar';
import LatestProducts from './components/HomeScreen/middlesection/LatestProducts';
import ProductsDetailsBar from './components/HomeScreen/middlesection/ProductsDetailsBar';
import LaestProductsProps from './components/HomeScreen/LatestProducts/LaestProductsProps';
import NewArrivalHeading from './components/HomeScreen/LatestProducts/headings/NewArrivalHeading';
import Shoesbottom from './components/HomeScreen/bottomsection/Shoesbottom';
import Footer from './components/HomeScreen/bottomsection/Footer';
import TrendingHeading from './components/HomeScreen/LatestProducts/headings/TrendingHeading';
import BestSeller from './components/HomeScreen/LatestProducts/headings/BestSeller';
import Feature from './components/HomeScreen/LatestProducts/headings/Feature';
import OnSell from './components/HomeScreen/LatestProducts/headings/OnSell';
import MetaTag from './layout/MetaTag';
// import NewCollection from './components/HomeScreen/LatestProducts/headings/NewCollection';


function Home() {
    return (
        <>
            <MetaTag title={'Welcome to IM Online Shopping Its Your Own Shop Next to Your Door,buy Shoes with our trust and Quality'} />
            <Navbar />
            <Menubar />
            <LatestProducts />
            <ProductsDetailsBar />
            <NewArrivalHeading />
            <LaestProductsProps />
            <TrendingHeading />
            <BestSeller />
            <Feature />
            <OnSell />
            <Shoesbottom />

            {/* <NewCollection/> */}
            <Footer />
        </>
    )
}

export default Home
