// GET PRODUCT BY TAG REQUEST
export const GET_PRODUCT_BY_TAG_REQUEST='GET_PRODUCT_BY_TAG_REQUEST'
export const GET_PRODUCT_BY_TAG_SUCCESS='GET_PRODUCT_BY_TAG_SUCCESS'
export const GET_PRODUCT_BY_TAG_FAIL='GET_PRODUCT_BY_TAG_FAIL'

// GET PRODUCT REQUEST
export const PRODUCT_REQUEST ='PRODUCT_REQUEST';
export const PRODUCT_REQUEST_SUCCESS ='PRODUCT_REQUEST_SUCCESS';
export const PRODUCT_REQUEST_FAIL ='PRODUCT_REQUEST_FAIL';

// admin product request
export const ADMIN_PRODUCT_REQUEST ='ADMIN_PRODUCT_REQUEST';
export const ADMIN_PRODUCT_REQUEST_SUCCESS ='ADMIN_PRODUCT_REQUEST_SUCCESS';
export const ADMIN_PRODUCT_REQUEST_FAIL ='ADMIN_PRODUCT_REQUEST_FAIL';

// admin product create request
export const NEW_PRODUCT_REQUEST ='NEW_PRODUCT_REQUEST';
export const NEW_PRODUCT_REQUEST_SUCCESS ='NEW_PRODUCT_REQUEST_SUCCESS';
export const NEW_PRODUCT_RESET ='NEW_PRODUCT_RESET';
export const NEW_PRODUCT_FAIL ='NEW_PRODUCT_FAIL';


// admin delete product request
export const DELETE_PRODUCT_REQUEST ='DELETE_PRODUCT_REQUEST';
export const DELETE_PRODUCT_REQUEST_SUCCESS ='DELETE_PRODUCT_REQUEST_SUCCESS';
export const DELETE_PRODUCT_RESET ='DELETE_PRODUCT_RESET';
export const DELETE_PRODUCT_FAIL ='DELETE_PRODUCT_FAIL';

// update product by admin request

export const UPDATE_PRODUCT_REQUEST ='UPDATE_PRODUCT_REQUEST';
export const UPDATE_PRODUCT_REQUEST_SUCCESS ='UPDATE_PRODUCT_REQUEST_SUCCESS';
export const UPDATE_PRODUCT_RESET ='UPDATE_PRODUCT_RESET';
export const UPDATE_PRODUCT_FAIL ='UPDATE_PRODUCT_FAIL';

// product detail request
export const PRODUCT_DETAIL_REQUEST ='PRODUCT_DETAIL_REQUEST';
export const PRODUCT_DETAIL_REQUEST_SUCCESS ='PRODUCT_DETAIL_REQUEST_SUCCESS';
export const PRODUCT_DETAIL_REQUEST_FAIL ='PRODUCT_DETAIL_REQUEST_FAIL';


// auto complete product search
export const SEARCH_PRODUCT_REQUEST ='SEARCH_PRODUCT_REQUEST';
export const SEARCH_PRODUCT_REQUEST_SUCCESS ='SEARCH_PRODUCT_REQUEST_SUCCESS';
export const SEARCH_PRODUCT_REQUEST_FAIL ='SEARCH_PRODUCT_REQUEST_FAIL';



// Searched item shows data with filters
export const SEARCH_PRODUCT_WITH_FILTERS_REQUEST ='SEARCH_PRODUCT_WITH_FILTERS_REQUEST';
export const SEARCH_PRODUCT_WITH_FILTERS_REQUEST_SUCCESS ='SEARCH_PRODUCT_WITH_FILTERS_REQUEST_SUCCESS';
export const SEARCH_PRODUCT_WITH_FILTERS_REQUEST_FAIL ='SEARCH_PRODUCT_WITH_FILTERS_REQUEST_FAIL';



export const CLEAR_ERROR ='CLEAR_ERROR';



