import React, { Fragment, useEffect } from 'react'
import { DataGrid } from '@material-ui/data-grid'
import { Link, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { clearErrorAction, allReturnOrderByAdminAction } from '../redux/actions/orderAction'
import swal from 'sweetalert';
import EditIcon from '@material-ui/icons/Edit'
import Loader from '../layout/loader'
import Sidebar from './Sidebar'
import './allProductByAdmin.css'

function AllReturnOrdersByAdmin() {

    const history = useHistory();
    const dispatch = useDispatch();

    const { loading, error, returnOrders } = useSelector((state) => state.allReturnOrderByAdmin)


    useEffect(() => {
        if (error) {
            swal({
                title: "Validation",
                text: error,
                icon: "error",
            })
            dispatch(clearErrorAction())

        }

        dispatch(allReturnOrderByAdminAction())

    }, [dispatch, error, history])



    const columns = [
        { field: 'id', headerName: 'Returns Order ID', minWidth: 150, flex: 0.9, },
        {
            field: 'status',
            headerName: 'Status',
            minWidth: 150,
            flex: 0.3,
        },
        {
            field: 'returnType',
            headerName: 'Return Type',
            minWidth: 200,
            flex: 0.4,
        },
        {
            field: 'user',
            headerName: 'User Id',
            minWidth: 250,
            flex: 1,
        },
        {
            field: 'action',
            headerName: 'Action',
            type: 'number',
            minWidth: 150,
            flex: 0.3,
            sortable: false,
            renderCell: (params) => {
                return (
                    <Fragment>
                        <Link to={`/Admin/updatestatus/return/order/${params.getValue(params.id, 'id')}`}   >
                            <EditIcon />
                        </Link>
                    </Fragment>
                )
            }
        },
    ]

    const rows = [];
    returnOrders && returnOrders.forEach(item => {
        rows.push({
            id: item._id,
            status: item.returnInformation.status,
            returnType: item.returnType,
            order_Id: item.orderId,
            user: item.user,
        })
    });



    return (
        <Fragment>
            {loading ? <Loader /> : <Fragment>
                <div className="dashboard-container">
                    <Sidebar />

                    <div className="productListContainer">
                        <h1 className='productHeadingList'> ALL RETURN ORDERS'S </h1>
                        <DataGrid

                            rows={rows}
                            columns={columns}
                            pageSize={20}
                            disableSelectionOnClick
                            className='productListTable'
                            autoHeight
                        />
                    </div>
                </div>

            </Fragment>}
        </Fragment>
    )
}

export default AllReturnOrdersByAdmin