import React, { Fragment, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Footer from '../HomeScreen/bottomsection/Footer'
import ComplainImage from '../../images/undraw_Notebook_re_id0r.png'
import ReplyIcon from '@mui/icons-material/Reply';
import MetaTag from '../../layout/MetaTag';
import swal from 'sweetalert';
import Loader from '../../layout/loader';


// createComplainAction

import { clearErrorAction, createComplainAction } from '../../redux/actions/complainAction'

import { COMPLAIN_REQUEST_RESET } from '../../redux/constants/complainConstants'

import './complainPage.css'


function ComplainPage() {

    const history = useHistory();
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.user)

    const { error, loading, complainSubmit } = useSelector(state => state.complainCreate)

    const [email, setEmail] = useState('');
    const [complainType, setComplainType] = useState('');
    const [msg, setMsg] = useState('');

    useEffect(() => {
        if (error) {
            swal({
                title: "Validation Error",
                text: error,
                icon: "error",
            })
            dispatch(clearErrorAction())
        }

        if (complainSubmit) {
            swal({
                title: "Success",
                text: complainSubmit,
                icon: "success",
            })
            dispatch({ type: COMPLAIN_REQUEST_RESET })
            history.push('/')
        }

    }, [complainSubmit, dispatch, error, history])

    const complainHandler = (e) => {
        e.preventDefault();
        dispatch(createComplainAction(user.firstName,user.number, email, complainType, msg))
    }


    return (
        <Fragment>
            <MetaTag title={'IM Online Shopping,Write Complain or any kind of Query Request Page'} />
            {loading ? <Loader /> : (<Fragment>
                <div className="home-div">
                    <Link to={'/'}><ReplyIcon /></Link>
                </div>
                <div className="middle-section-of-complain">
                    <img src={ComplainImage} alt="www.imonline.shop" />
                </div>

                <div className="complain-formdiv">
                    <div className="heading-of-complain">
                        <p> Submit Request</p>
                    </div>
                    <form onSubmit={complainHandler}>
                        <div className='form-div-of-complain'>
                            <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder='type your email..' required />
                        </div>
                        <div className='form-div-of-complain' >
                            <input type="text" value={complainType} onChange={(e) => setComplainType(e.target.value)} placeholder='Complain Type... @examp: order realted..' required />
                        </div>

                        <div className='form-div-text-area'>
                            <textarea value={msg} onChange={(e) => setMsg(e.target.value)} placeholder='please type your complain or any query @examp:how can i checkout and many more ...etc' cols="30" rows="10" required >
                            </textarea>
                        </div>

                        <button className='complain-btn-div'>submit</button>
                    </form>
                </div>
                <Footer />
            </Fragment>)}
        </Fragment>
    )
}

export default ComplainPage