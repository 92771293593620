import React, { Fragment, useEffect } from 'react'
import { DataGrid } from '@material-ui/data-grid'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import swal from 'sweetalert';
import { Button } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import Sidebar from './Sidebar'
import Loader from '../layout/loader'

import {clearErrorAction,getAllNotificationaActionByAdmin,deleteNotificationaActionByAdmin} from '../redux/actions/notificationAction'
import {DELETE_NOTIFICATION_BY_ADMIN_RESET} from '../redux/constants/notificationConstants'


function AllNotificationByAdmin() {
    const dispatch = useDispatch();
    const { loading,error,notification } = useSelector((state) => state.allNotification)

    const { loading:deleteLoading,error:deleteError,isDeleted } = useSelector((state) => state.deleteNotification)



    useEffect(() => {
      if (error) {
        swal({
          title: "Error",
          text: error,
          icon: "error",
        })
        dispatch(clearErrorAction())
      }
  
      if (deleteError) {
        swal({
          title: "Delete Error",
          text: deleteError,
          icon: "error",
        })
        dispatch(clearErrorAction())
      }
  
      if (isDeleted) {
        swal({
          title: "Notification Deleted.",
          text: isDeleted,
          icon: "success",
        })
        dispatch({type:DELETE_NOTIFICATION_BY_ADMIN_RESET})
      }
  
  
      dispatch(getAllNotificationaActionByAdmin())
    }, [ deleteError, dispatch, error,  isDeleted])

const deleteNotificationHandler = (id) =>{
  dispatch(deleteNotificationaActionByAdmin(id))
}


    const columns = [
      { field: 'id', headerName: 'Notification ID', minWidth: 170, flex: 0.7 },
      {
        field: 'NotificationCreate',
        headerName: 'Notification Create',
        type: 'number',
        minWidth: 150,
        flex: 0.5,
      },
      {
        field: 'NotificationRead',
        headerName: 'Notification Read',
        type: 'number',
        minWidth: 150,
        flex: 0.5,
      },
      {
        field: 'action',
        headerName: 'Action',
        type: 'number',
        minWidth: 150,
        flex: 0.3,
        sortable: false,
        renderCell: (params) => {
          return (
            <Fragment>
              <Link to={`/Admin/Upadte/Notifications/${params.getValue(params.id, 'id')}`}   >
                <EditIcon />
              </Link>
  
              <Button onClick={() => deleteNotificationHandler(params.getValue(params.id, 'id'))}   >
                <DeleteIcon />
              </Button>
            </Fragment>
          )
        }
      },
    ]
  
  
    const rows = [];
    notification && notification.forEach(item => {
      rows.push({
        id: item._id,
        NotificationCreate: item.createdAt,
        NotificationRead: item.notifactionRead,
      })
    });
  



  return (
    <Fragment>
    {deleteLoading ? <Loader /> : (<Fragment>
      {loading ? <Loader /> : (<Fragment>

        <div className="dashboard-container">
          <Sidebar />
          <div className="productListContainer">
            <h1 className='productHeadingList'> ALL NOTIFICATION'S </h1>
            <DataGrid

              rows={rows}
              columns={columns}
              pageSize={10}
              disableSelectionOnClick
              className='productListTable'
              autoHeight
            />
          </div>

        </div>
      </Fragment>)}
    </Fragment>)}

  </Fragment>
  )
}

export default AllNotificationByAdmin