import React, { Fragment, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { Typography } from '@material-ui/core'
import { clearErrorAction, OrderDetailAction } from '../../redux/actions/orderAction'
import Loader from '../../layout/loader'
import swal from 'sweetalert';
import MetaTag from '../../layout/MetaTag'
import './delivery.css';



function DeliveryProductDetail(props) {
  const dispatch = useDispatch()
  const { error, loading, order } = useSelector((state) => state.orderDetail)
  // const { error: inVoiceError, loading: loadingInvoice, invoice } = useSelector((state) => state.createInvoice)


  // const [colorDetail,setColorDetail]= useState('');
  // const [sizeDetail,setSizeDetail]= useState('');
  // const [orderNameDetails,setOrderNameDetails]= useState('');


  useEffect(() => {
    if (error) {
      swal({
        title: "Error",
        text: error,
        icon: 'error',
      });
      dispatch(clearErrorAction())
    }

    // if (error) {
    //   swal({
    //     title: "Error",
    //     text: inVoiceError,
    //     icon: 'error',
    //   });
    //   dispatch(clearErrorAction())
    // }

    dispatch(OrderDetailAction(props.match.params.id))

    // var orderName = order.orderItems && order.orderItems.map((orderItem) => {
    //   return orderItem
    // })
    
    // console.log(orderName)
    // setOrderNameDetails(orderName.name)
    // setColorDetail(orderName.colour)
    // setSizeDetail(orderName.size)

  }, [dispatch, error, props.match.params.id])


  // genreating invoice function

  // const generateInvoiceHandler = (order_id) => {
  //   dispatch(createInvoiceByUserAction(order_id))


  // }


  // const returnOrderData = {
  //   colorDetail,
  //   orderNameDetails,
  //   sizeDetail
  // }

  return (
    <Fragment>
        <MetaTag title={`Ordered Item Detail`} />
        {loading ? (<Loader />) : (
          <div className="container-fluid my-5 d-flex justify-content-center">
            <div className="card card-1">
              <div className="card-header bg-white">
                <div className="media flex-sm-row flex-column-reverse justify-content-between ">
                  <div className="col my-auto">
                    <Typography>  <h4 className="mb-0">Thanks for your Order, <span className="change-color">{order.shippingInfo && order.shippingInfo.name}</span> !</h4></Typography>
                  </div>
                  <div className="col-auto text-center my-auto pl-0 pt-sm-4">
                    <Typography> <p className="mb-4 pt-0 Glasses"> IM ONLINE SHOPPING COMP. </p> </Typography>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="row justify-content-between mb-3">
                  <div className="col-auto">
                    <h6 className="color-1 mb-0 change-color">Receipt</h6>
                  </div>
                  <div className="col-auto "> <b> Order Id :</b><small> {order._id}</small> </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="card card-2">
                      <div className="card-body">

                        {/**map funtion for get the item details */}
                        {order.orderItems && order.orderItems.map((orderItem) => (
                          <div className="media">
                            <div className="sq align-self-center ">
                              <img className="img-fluid my-auto align-self-center mr-2 mr-md-4 pl-0 p-0 m-0" src={orderItem.image} width="105" height="135" alt='www.imonline.shop' />
                            </div>
                            <div className="media-body my-auto text-right">
                              <div className="row my-auto flex-column flex-md-row">
                                <div className="col my-auto">
                                  <h6 className="mb-0"> {orderItem.name}</h6>
                                </div>
                                <div className="col-auto my-auto"> <small>Colour : {orderItem.colour}</small></div>
                                <div className="col my-auto"> <small>Size : {orderItem.size}</small></div>
                                <div className="col my-auto"> <small>Qty : {orderItem.quantity}</small></div>
                                <div className="col my-auto">
                                  <h6 className="mb-0">&#8377; {orderItem.price}</h6>
                                </div>
                              </div>
                            </div>

                          </div>
                        ))}
                        <hr className="my-3 " />
                        <div className="row">
                          <div className="col-md-3 mb-3"> <b> User information <span><i className=" ml-2 fa fa-refresh" aria-hidden="true"></i></span></b> </div>
                          Name : {order.shippingInfo && order.shippingInfo.name}, Email : {order.shippingInfo && order.shippingInfo.email}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col">
                    <div className="card card-2">
                      <div className="card-body">
                        {/** shipping detail */}
                        <h6>Shipping info</h6>
                        <p>Address : {order.shippingInfo && order.shippingInfo.address}</p>
                        Phone : {order.shippingInfo && order.shippingInfo.phoneNo}, Pincode : {order.shippingInfo && order.shippingInfo.pincode} ,
                        State : {order.shippingInfo && order.shippingInfo.state}, City : {order.shippingInfo && order.shippingInfo.city},
                        District:{order.shippingInfo && order.shippingInfo.district}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col">
                    <div className="row justify-content-between">
                      <div className="flex-sm-col text-right col">
                        <p className="mb-1"><b>Total</b></p>
                      </div>
                      <div className="flex-sm-col col-auto">
                        <p className="mb-1">&#8377;{order.itemPrice && order.itemPrice}</p>
                      </div>
                    </div>

                  

                    <div className="row justify-content-between">
                      <div className="flex-sm-col text-right col">
                        <p className="mb-1"><b>GST Tax</b></p>
                      </div>
                      <div className="flex-sm-col col-auto">
                        <p className="mb-1">{order.taxPrice && order.taxPrice}</p>
                      </div>
                    </div>
                    <div className="row justify-content-between">
                      <div className="flex-sm-col text-right col">
                        <p className="mb-1"><b>Delivery Charges</b></p>
                      </div>
                      <div className="flex-sm-col col-auto">
                        <p className="mb-1">{order.shippingPrice && order.shippingPrice}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row invoice ">
                  <div className="col">
                    <p className="mb-1"> Payment : {order.paymentInfo && order.paymentInfo.status}</p>
                    <p className="mb-1">Invoice Date : {String(order.paidAt && order.paidAt).substring(0, 10)}</p>
                    <p className="mb-1"> Order Status : {order.orderStatus && order.orderStatus}</p>
                  </div>
                </div>
                <div className="ship-ment-details">
                  <div className="col">
                    {order.orderStatus && order.orderStatus === 'Cancel' ? (null) : (<Fragment>
                      {order.order_id === null ? (
                        <Fragment>
                          <p className="mb-1">Shipping id : Soon will Update</p>
                          <p className="mb-1">Shipping Order id : Soon will Update </p>
                        </Fragment>
                      ) : (
                        <Fragment>
                          <p className="mb-1">Shipping id : {order.order_id && order.order_id}</p>
                          <p className="mb-1"> Shipping Order id : {order.shipment_id && order.shipment_id}</p>
                        </Fragment>
                      )}
                    </Fragment>)}
                  </div>
                </div>
              </div>
              <div className="card-footer">
                <div className="jumbotron-fluid">
                  <div className="row justify-content-between ">
                    <div className="col-auto my-auto ">
                      <h2 className="mb-0 font-weight-bold">TOTAL PAID</h2>
                    </div>
                    <div className="col-auto my-auto ml-auto">
                      <h1 className="display-3 "> {order.totalPrice && order.totalPrice}</h1>
                    </div>
                  </div>



                  <div className="row justify-content-between">
                      <div className="flex-sm-col text-right col">
                        <p className="mb-1"><b>Pay In Advance</b></p>
                      </div>
                      <div className="flex-sm-col col-auto">
                        <p className="mb-1">&#8377;{order.adavncePayment && order.adavncePayment.amount}</p>
                      </div>
                    </div>

                    <div className="row justify-content-between">
                      <div className="flex-sm-col text-right col">
                        <p className="mb-1"><b>Amount Remain </b></p>
                      </div>
                      <div className="flex-sm-col col-auto">
                        <p className="mb-1">&#8377;    {Number(order.totalPrice && order.totalPrice) - Number(order.adavncePayment && order.adavncePayment.amount)}</p>
                      </div>
                    </div>
                </div>
              </div>
              <div className="cancel-heading-product-div">
                {order.orderStatus && order.orderStatus === 'Returned' ? (
                  <Fragment>
                    <div className="cancel-container-div">
                      <h1>Returned.</h1>
                    </div>
                  </Fragment>
                ) : (<Fragment>
                  {order.orderStatus && order.orderStatus === 'Cancel' ? (<Fragment>
                    <div className="cancel-container-div">
                      <h1>Cancelled.</h1>
                    </div>
                  </Fragment>) : (<Fragment>
                    {/* returns and cancel order buttons */}
                    <div className="cancel-order-detail-button">
                      {order.orderItems && order.orderItems.map((productId, index) => (
                        <Link to={`/ProductDetail/${productId.product}`}><button className='view-product-button' key={index}  > View Product</button></Link>

                      ))}
                      {order.orderStatus === 'Processing' ? (<Fragment>
                        <Link to={`/profile/order-cancel/${order._id}`}><button className='view-product-button'> Cancel Product</button></Link>
                      </Fragment>) : (null)}

                      {order.orderStatus === 'Delivered' ? (<Fragment>
                        {/* <a href={`/user/returnorder/${returnOrderData}/${order._id}}`}><button className='view-product-button'> Return Product</button></a> */}
                      </Fragment>) : (null)}
                    </div>

                    {/* for download invoice of product */}

                    {/* {order.order_id === null ? null : (<Fragment>
                      <div className="invoice-generate-div">
                        <button className='invoice-button' onClick={() => generateInvoiceHandler(order.order_id)}>Request Invoice</button>
                      </div>
                      {invoice ? (<Fragment>
                        <a href={invoice}>download invoice</a>
                      </Fragment>) : (null)}
                    </Fragment>)} */}
                  </Fragment>)}
                </Fragment>)}
              </div>
            </div>
          </div>
        )}
    </Fragment >
  )
}

export default DeliveryProductDetail